import React, { useState } from "react";
import { Menu, MenuItem, Button } from "@mui/material";
import { fontWeight } from "@mui/system";

interface DropdownSelectorProps {
  items: Array<{ key: string; value: string }>;
  onItemSelect: (value: string) => void;
  buttonLabel: string;
  type?: string;
}

const DropdownSelector: React.FC<DropdownSelectorProps> = ({
  items,
  onItemSelect,
  buttonLabel,
  type = "Variable",
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (value: string) => {
    onItemSelect(value);
    handleClose(); // Close menu after selection
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Button
        sx={{
          fontWeight: "bold", // Make the text bold
          color: "black", // Set the text color to black
          backgroundColor: "transparent", // Ensure no background color is set
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.04)", // Optional subtle hover effect
          },
        }}
        disableFocusRipple
        disableRipple
        disableTouchRipple
        onClick={handleClick}
      >
        {buttonLabel}
      </Button>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {items.length > 0 ? (
          items.map((item) => (
            <MenuItem
              key={item.key}
              onClick={() => handleMenuItemClick(item.value)}
            >
              {item.value}
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled> {`No ${type} Available`}</MenuItem>
        )}
      </Menu>
    </div>
  );
};

export default DropdownSelector;
