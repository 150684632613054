import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";
import { TableElementTheme_ } from "../../../../../types/DocumentTemplate";
import { findMatchingFont } from "../../HelperEditorFunctions";
import CustomTableDialog from "../ThemeFomattingSetting/CustomThemeConfig";
import {
  convertMmToPx,
  data,
  getAlignment,
  getDefaultThemeDetails
} from "../HelperFuctions/TableUtils";

export default function TableElementTheme(props: {
  themeStyle: "custom" | "default";
  themeCode?: string;
  customTableTheme?: any;
}) {
  const [customTableDetails, setCustomTableDetails] = React.useState(
    props.themeStyle == "custom"
      ? props.customTableTheme
      : getDefaultThemeDetails(props.themeCode ? props.themeCode : "theme7")
  );

  React.useEffect(() => {
    if (props.customTableTheme !== undefined)
      setCustomTableDetails({ ...props.customTableTheme });
  }, [props.customTableTheme]);

  const handleUpdateProperties = (updatedProperties: any) => {
    setCustomTableDetails(updatedProperties);
  };

  const data = [
    { name: "Microsoft", q1: 20.3, q2: 30.5, q3: 23.5 },
    { name: "Google", q1: 50.2, q2: 40.63, q3: 45.23 },
    { name: "Apple", q1: 25.4, q2: 30.2, q3: 33.3 }
  ];

  function generateTableArray(rows: any, columns: any) {
    const tableArray = [];
    for (let i = 0; i < rows; i++) {
      const row = [];
      for (let j = 0; j < columns; j++) {
        // You can customize the cell content as needed
        row.push(i === 0 ? "Company" : `${i + 1},${j + 1}`);
      }
      tableArray.push(row);
    }

    return tableArray;
  }

  const originalTable = generateTableArray(
    customTableDetails.rows,
    customTableDetails.columns
  );

  const themes: any = {
    theme: {
      table: {
        borderCollapse: "collapse",
        width: "100%",
        marginTop: "10px"
      },

      th: {
        border: `${customTableDetails.CellBorderThickness + "px"} ${
          customTableDetails.CellBorderStyle
        } ${"#" + customTableDetails.CellBorderColor}`,
        backgroundColor: `#${customTableDetails.THBackgroundColor}`,
        color: `#${customTableDetails.THTextColor}`,
        textAlign: getAlignment(customTableDetails.THTextAlign),
        padding: "8px",
        fontSize: customTableDetails.THFontSize
      },
      evenRow: {
        backgroundColor: customTableDetails.evenRowBackgroundColor
      },
      // Inuse
      cell: {
        border: `${
          convertMmToPx(customTableDetails.CellBorderThickness) + "px"
        } ${customTableDetails.CellBorderStyle} ${
          "#" + customTableDetails.CellBorderColor
        }`,
        textAlign: getAlignment(customTableDetails.CellTextAlign),
        padding: "8px",
        fontSize: customTableDetails.CellFontSize,
        color: `#${customTableDetails.CellTextColor}`
      },
      strips: {
        customStripe: {
          backgroundColor: "" // Replace with your desired color
        }
      }
    }
  };

  const theme = themes.theme;

  return (
    <>
      <Box
        padding={1}
        paddingTop={0}
        border={1}
        borderColor="lightgray"
        borderRadius={1}
      >
        <table style={theme.table}>
          {originalTable.map((row: any, rowIndex: any) => (
            <tr key={rowIndex}>
              {row.map((cell: any, colIndex: any) => (
                <td
                  key={colIndex}
                  style={{
                    ...theme.cell,

                    background:
                      rowIndex === 0
                        ? "#" + customTableDetails.THBackgroundColor
                        : //  theme.th.backgroundColor

                        (customTableDetails.stripe === "row" &&
                            rowIndex % 2 === 0) ||
                          (customTableDetails.stripe === "column" &&
                            colIndex % 2 === 1)
                        ? "#" + customTableDetails.StripeBackgroundColor
                        : `#${customTableDetails.CellBackgroundColor}`,

                    // theme.cell.backgroundColor,
                    fontSize:
                      rowIndex === 0
                        ? customTableDetails.THFontSize + "px"
                        : customTableDetails.CellFontSize + "px"
                  }}
                >
                  <div
                    style={{
                      fontFamily: findMatchingFont(
                        customTableDetails.tableFont
                      ),
                      color: rowIndex === 0 ? theme.th.color : theme.cell.color,
                      textAlign:
                        rowIndex === 0
                          ? theme.th.textAlign
                          : theme.cell.textAlign
                    }}
                  >
                    <span>{cell}</span>
                  </div>
                </td>
              ))}
            </tr>
          ))}
        </table>
      </Box>
    </>
  );
}
