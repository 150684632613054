// src/components/EmailInput.tsx

import React from 'react';
import TextField from '@mui/material/TextField';
import { useSnackbar } from 'notistack';

interface EmailInputProps {
  value: string;
  onChange: (newEmail: string) => void;
  label?: string;
  required?: boolean;
}

const EmailInput: React.FC<EmailInputProps> = ({
  value,
  onChange,
  label = "Email",
  required = false,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = React.useState<string | null>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    onChange(newValue);
    if (!newValue.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)) {
      setError('Please enter a valid email address');
    } else {
      setError(null);
    }
  };

  return (
    <TextField
      margin="normal"
      required={required}
      fullWidth
      type="email"
      id="signup_email"
      label={label}
      value={value}
      name="email"
      autoComplete="email"
      error={!!error}
      helperText={error}
      onChange={handleChange}
    />
  );
};

export default EmailInput;
