import * as React from "react";
import Box from "@mui/material/Box";
import FolderIcon from "@mui/icons-material/Folder";

// REF: https://mui.com/components/drawers/#clipped-under-the-app-bar

interface FolderProps {
  text: string;
  isSelected?: boolean;
  selectedIcon: any;
  unselectedIcon: any;
}

export function FilterStackTile(props: FolderProps) {
  const Icon = props.isSelected ? props.selectedIcon : props.unselectedIcon;

  return (
    <Box
      sx={{
        position: "relative",
        width: "14vw",
        backgroundColor: props.isSelected ? "#4C33FF" : "black",
        borderRadius: "6px",
        zIndex: 9999999,
        display: "flex", // Set display to flex to enable flexbox layout
        alignItems: "center", // Center items vertically
        mx: 1.5,
        my: 0.2,
        height: "4.8vh",
      }}
    >
      <Box
        sx={{
          flex: "0 0 auto", // Set the image box to a fixed size
          marginRight: "1rem", // Add margin between the image and text
          marginLeft: "0.5rem", // Add margin between the image and text
          alignContent: "center",
          alignItems: "center",
          display: "flex", // Add display flex to use alignItems property
        }}
      >
        <img src={Icon} alt="publishIcon" width="30vw" height="30vh" />
      </Box>

      <div
        style={{
          color: "white",
          // fontSize: "0.8rem",
          fontSize: "13px",
          // fontWeight: "bold",
          whiteSpace: "normal", // Allow text to wrap to the next line
          fontFamily: "MarkPro",
        }}
      >
        {props.text}
      </div>
    </Box>
  );
}
