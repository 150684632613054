import React, { useState, useEffect, FC } from 'react';
import { Autocomplete, TextField, CircularProgress } from '@mui/material';

interface TimeZoneSelectorProps {
    value: string;
    onChange: (newValue: string | null) => void;
    label?: string;
    required?: boolean;
  }
  
  const TimeZoneSelector: FC<TimeZoneSelectorProps> = ({
    value,
    onChange,
    label = "Time Zone",
    required = false,
  }) => {
  const [timeZones, setTimeZones] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchTimeZones = async () => {
      setLoading(true);
      try {
        const response = await fetch('https://worldtimeapi.org/api/timezone');
        const data = await response.json();
        console.log("🚀 ~ fetchTimeZones ~ data:", data)
        setTimeZones(data);
      } catch (error) {
        console.error('Error fetching time zones:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTimeZones();
  }, []);

  return (
    <Autocomplete
      options={timeZones}
      value={value}
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          margin="normal"
          required={required}
          fullWidth
          label={label}
          type="text"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      getOptionLabel={(option) => option}
      filterSelectedOptions
    />
  );
};

export default TimeZoneSelector;
