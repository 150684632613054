import React from 'react';
import { TextField, MenuItem } from '@mui/material';

interface SelectInputProps<T> {
  label: string;
  value: T;
  onChange: (value: T) => void;
  options: { value: T; label: string }[];
  margin?: 'dense' | 'none' | 'normal';
  fullWidth?: boolean;
}

function SelectInput<T>({
  label,
  value,
  onChange,
  options,
  margin = 'normal',
  fullWidth = true,
}: SelectInputProps<T>) {
  return (
    <TextField
      select
      label={label}
      value={value}
      onChange={(e) => onChange(e.target.value as unknown as T)}
      margin={margin}
      fullWidth={fullWidth}
    >
      {options.map((option:any) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
}

export default SelectInput;
