import React, { Component, useState } from "react";
import Box from "@mui/material/Box";
import InsertPageBreakIcon from "@mui/icons-material/InsertPageBreak";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  DocumentElement,
  DocumentSection,
  DocumentSectionSummary,
  DocumentSubsection,
  DocumentTemplate,
  DocumentTemplateSummary,
} from "../../../types/DocumentTemplate";
import DeleteIcon from "@mui/icons-material/Delete";
import UndoIcon from "@mui/icons-material/Undo";
import useActions, { useAppDispatch } from "../../../app/hooks";
import { clearSelectedComponent, setSelectedComponent } from "../editorSlice";
import Divider from "@mui/material/Divider";
import { ViewPreferences } from "../header/preferencesSlice";
import Typography from "@mui/material/Typography";
import { useDrop } from "react-dnd";
import { DebugID } from "./DebugID";
import { DropSkeleton } from "./DropSkeleton";

import { Approvals } from "./Approvals";
import LibraryContentConfigFormSubSection from "./libraryContentSubSection/LibraryContentFormSubSection";
import { secondarySilver } from "../../../globals";
import { IconButton } from "@mui/material";
import EditorUndoForm from "./CustomEditorDecorators/EditorUndoForm";
import SwitchSubsection from "./CustomEditorDecorators/SwitchSubSection";
import { COLORS } from "../../shared/Constants";
import DeleteUnsharedConfirm from "./CustomEditorDecorators/DeleteUnsharedConfirm";

/**
 * A pagebreak.
 *
 * @export
 * @param {({
 *   element: DocumentElement | null;
 *   parentSubsection: DocumentSubsection | null;
 *   parentSection: DocumentSection;
 *   documentTemplate: DocumentTemplate;
 *   organizationId: number;
 *   workspaceId: number;
 *   viewPreferences: ViewPreferences;
 * })} props
 * @return {*}
 */
export function PagebreakElementEditor(props: {
  element: DocumentElement | null;
  parentSubsection: DocumentSubsection | null;
  parentSection: DocumentSection | DocumentSectionSummary;
  parentSection__: DocumentSection | null;
  documentTemplate: DocumentTemplate | DocumentTemplateSummary;
  organizationId: number;
  workspaceId: number;
  viewPreferences: ViewPreferences;
  readOnly?: boolean;
  approvalsEnabled: boolean;
}) {
  const dispatch = useAppDispatch();
  const actions = useActions();
  const [IsLibraryOpen, SetIsLibarayOpen] = useState<boolean>(false);

  const isDeleted = props.parentSubsection?.pending_deleted_at != null;

  const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);

  const [isDeleteUnsharedOpen, setIsDeleteUnsharedOpen] =
    useState<boolean>(false);
  // Only allow drag+drop if this is not read only.
  const [{ isOver }, drop] = props.readOnly
    ? [{ isOver: null }, null]
    : useDrop(() => ({
        accept: "right-drawer-item",
        drop: (dropItem: any) => addElementToSection({ ...dropItem.element }),
        collect: (monitor) => ({
          isOver: !!monitor.isOver(),
          canDrop: !!monitor.canDrop(),
        }),
      }));

  function addElementToSection(element: DocumentElement) {
    if (element.content_type === "LIBRARYCONTENTSUBSECTION") {
      SetIsLibarayOpen(true);
    } else {
      actions.createSubsectionWithElement({
        section: props.parentSection as DocumentSection,
        documentElement: element,
        subsectionAbove: props.parentSubsection!,
      });
    }
  }
  /**
   * When the component is focused, update the state.
   * @param {*} e
   * @return {*}  {*}
   */
  function handleOnFocus(e: any): any {
    dispatch(
      setSelectedComponent({
        selectedSection: props.parentSection as DocumentSection,
        selectedSubsection: props.parentSubsection,
        selectedComponent: props.element,
      })
    );
  }

  /**
   * Similarly track when focus is lost.
   * @param {*} e
   */
  function handleOnBlur(e: any) {
    dispatch(clearSelectedComponent());
  }

  function handleDeleteClicked(e: any) {
    setIsDeleteUnsharedOpen(true);
    // actions.deleteSubsection({
    //   section: props.parentSection as DocumentSection,
    //   subSection: props.parentSubsection as DocumentSubsection,
    // });
    // dispatch(clearSelectedComponent());
  }

  function border(): any {
    if (props.parentSection.pending_deleted_at != null || isDeleted) {
      return {
        border: COLORS.BORDER_SIZE,
        borderRadius: 0,
        borderStyle: "dashed",
        borderColor: COLORS.NOMIA_RED,
      };
    }
    /// if the subsection is addded show green border to the Approver
    // if (props.approvalsEnabled && props.element !== null  && props.element.should_show ) {
    //   return {
    //     border: COLORS.BORDER_SIZE,
    //     borderColor:
    //     COLORS.NOMIA_GREEN,
    //     borderStyle: "dashed",
    //   };
    // }

    if (props.viewPreferences.showSections) {
      return {
        borderRadius: 0,
        border: `1px dashed ${secondarySilver}`, // Add border property
        borderColor: secondarySilver, // Add border color property
      };
    }
    return {};
  }

  return (
    <>
      <Box
        ref={drop}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        sx={Object.assign(
          {
            mx: 0,
            padding: 1,
            margin: 1,
            paddingBottom: "5px",
            marginBottom: "10px",
            backgroundColor: "white",
          },
          border()
        )}
      >
        <Box
          sx={{
            position: "relative",
            zIndex: 99,
          }}
        >
          {/* <Approvals
        approvalsEnabled={props.approvalsEnabled}
        element={props.element}
        parentSubsection={props.parentSubsection}
        parentSection={props.parentSection__}
      /> */}

          <SwitchSubsection
            parentSection={props.parentSection as DocumentSection}
            parentSubsection={props.parentSubsection as DocumentSubsection}
            direction={"up"}
            isDisabled={false}
            element={props.element as DocumentElement}
            style={{
              paddingBottom: 4,
            }}
            readonly={props.readOnly}
          />

          {isDeleted && props.approvalsEnabled && (
            <IconButton
              onMouseDown={(e) => e.preventDefault()}
              onClick={(e) => {
                e.preventDefault();
                setIsDeleteOpen(true);
                e.stopPropagation(); // Add this line to stop event propagation
              }}
              title="Undo Deletion"
              style={{
                position: "absolute",
                top: 3,
                right: 0,
                transition: "none", // Remove transition on button press
                margin: 0,
                padding: 0,
                width: "fit-content",
                zIndex: 99999,
              }}
            >
              <UndoIcon
                style={{ fontSize: 20, outline: "none", color: "#7284A3" }}
              />
            </IconButton>
          )}

          {!props.approvalsEnabled && !props.readOnly && (
            <IconButton
              style={{
                position: "absolute",
                top: 3,
                right: 0,
                transition: "none", // Remove transition on button press
                margin: 0,
                padding: 0,
                width: "fit-content",
                zIndex: 9999999999,
              }}
              onMouseDown={(e) => e.preventDefault()}
              onClick={handleDeleteClicked}
            >
              <DeleteIcon
                style={{
                  fontSize: 22,
                  outline: "none",
                  color: "#7284A3",
                  zIndex: 9999999999,
                }}
              />
            </IconButton>
          )}
        </Box>
        {props.viewPreferences.showIDs ? (
          <DebugID
            name="Element"
            id={props.element !== null ? props.element.id : -1}
            componentOrder={0}
          />
        ) : null}

        {/* The drag+drop element showing that you are going to drop something here. */}
        <Box>
          <Divider sx={{ width: "100%", paddingBottom: "8px" }}>
            <InsertPageBreakIcon
              fontSize="large"
              color={isDeleted ? "disabled" : "primary"}
            ></InsertPageBreakIcon>
            <Typography>Page Break</Typography>
          </Divider>
          <SwitchSubsection
            parentSection={props.parentSection as DocumentSection}
            parentSubsection={props.parentSubsection as DocumentSubsection}
            direction={"down"}
            isDisabled={false}
            element={props.element as DocumentElement}
            style={{
              paddingTop: 3,
            }}
            readonly={props.readOnly}
          />
        </Box>
      </Box>

      {isOver ? <DropSkeleton /> : null}

      {isDeleteOpen === true ? (
        <EditorUndoForm
          isHeading={false}
          parentSection={props.parentSection as DocumentSection}
          parentSubsection={props.parentSubsection as DocumentSubsection}
          closeDialogHandler={() => setIsDeleteOpen(false)}
        />
      ) : null}

      {isDeleteUnsharedOpen === true ? (
        <DeleteUnsharedConfirm
          isHeading={false}
          parentSection={props.parentSection as DocumentSection}
          parentSubsection={props.parentSubsection as DocumentSubsection}
          closeDialogHandler={() => setIsDeleteUnsharedOpen(false)}
        />
      ) : null}

      {IsLibraryOpen && (
        <LibraryContentConfigFormSubSection
          closeDialogHandler={() => SetIsLibarayOpen(false)}
          organizationId={props.organizationId}
          workspaceId={props.workspaceId}
          parentSection={props.parentSection as DocumentSection}
          parentSubsection={props.parentSubsection as DocumentSubsection}
          viewPreferences={props.viewPreferences}
        />
      )}
    </>
  );
}
