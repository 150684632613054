import React, { useRef, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import AddIcon from "@mui/icons-material/Add";
import MenuItem from "@mui/material/MenuItem";
import SendIcon from "@mui/icons-material/Send";
import FolderIcon from "@mui/icons-material/Folder";
import AddTaskIcon from "@mui/icons-material/AddTask";
import Menu from "@mui/material/Menu";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import NoteIcon from "@mui/icons-material/Note";
import HelpIcon from "@mui/icons-material/Help";
import AccountCircle from "@mui/icons-material/AccountCircle";
import PublishIcon from "../../../src/Icons/MainHeader/Publish.svg";
import MainFolder from "../../../src/Icons/MainHeader/(Library).svg";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import ReceiptIcon from "@mui/icons-material/Receipt";
//immport icon from Icons folder
import logo from "../../nomia-logo.png";
import AccountBoxIcon from "@mui/icons-material/AccountBox";

import useActions, { useAppSelector } from "../../app/hooks";
import {
  selectAuth,
  selectCurrentWorkspace,
  selectCurrentWorkspaceRole,
  selectOrganizationDetails,
  selectOrganizationOwner,
  selectUserRole,
} from "../auth/authSlice";

import { useAppDispatch } from "../../app/hooks";
import { logout } from "../auth/authSlice";
import {
  clearDocumentTemplate,
  clearImpactState,
  disableApprovalMode,
  enableApprovalMode,
  selectApprovalMode,
  selectEditingDocumentTemplate,
  selectIsImpactPending,
  selectStatusState,
} from "../editor/editorSlice";
import {
  selectDocumentTemplates,
  selectLibrary,
  setClearLibraryDocuments,
} from "../library/librarySlice";
import { OrganizationsMenu } from "./OrganizationsMenu";
import { ApprovalMenu } from "./ApprovalMenu";
import { CreateMenu } from "./CreateMenu";
import { getDocumentTemplates } from "../../app/sagas/documentTemplates";
import ShowAdminUsers from "./ShowAdminUsers";
import { clearMasterElementSettings } from "../editor/header/documentMasterSlice";
import Chip from "@mui/material/Chip/Chip";
import { ERROR_CREDIT_COUNT, WARNING_CREDIT_COUNT } from "../../globals";
import CircularProgress from "@mui/material/CircularProgress";
import {
  selectBulkRecordsStatus,
  setClearLibraryRecords,
} from "../library/documentRecordsSlice";
import { useSnackbar } from "notistack";
import OrganizationConfigForm from "./OrganizationConfigForm";
import AdminAssistanceForm from "./organization/AdminAssistanceForm";
import { USER_ROLE } from "../../types/User";
import ManageOrganizations from "./organization/ManageOrganizations";
import SuperUsersConfigForm from "./organization/SuperUserConfigForm";
import APIInformationForm from "./organization/APIInformationForm";
import PaymentModal from "../subscription/PaymentModal";
import { keyframes } from "@mui/material";
import { calculateTrailRemainingDays } from "./utils";
import ExpireTrialModal from "../subscription/ExpireTrailModal";
import { setDocumentLoaded } from "../currentErrors/currentErrorSlice";
import { useSelector } from "react-redux";
import { RootState } from "src/app/store";

// import { Organization } from "../../../types/User";

export function HeaderMenu() {
  const dispatch = useAppDispatch();
  const authState = useAppSelector(selectAuth);
  const bulkRecordsStatus = useAppSelector(selectBulkRecordsStatus);

  // if user is admin than he is Organization Owner
  const organizationOwner = useAppSelector(selectOrganizationOwner);
  const editingDocumentTemplate = useAppSelector(selectEditingDocumentTemplate);
  const librarySlice = useAppSelector(selectLibrary);
  const currentWorkspaceRole = useAppSelector(selectCurrentWorkspaceRole);
  const currentWorkspace = useAppSelector(selectCurrentWorkspace);
  const allDocumentTemplates = useAppSelector(selectDocumentTemplates);
  const approvalsEnabled = useAppSelector(selectApprovalMode);
  const actions = useActions();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const isImpactPending = useAppSelector(selectIsImpactPending);
  const documentLoaded = useSelector(
    (state: RootState) => state.apiError.documentLoaded
  );

  const isTemplateEmpty = !editingDocumentTemplate?.sections?.length;
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const userRole = useAppSelector(selectUserRole);
  const selectedOrg = authState.organizations?.find(
    (org) => org.id === authState.user?.default_organization_id
  );
  const selectedWorkspace = authState.workspaces?.find(
    (workspace) => workspace.id === authState.user?.default_workspace_id
  );

  const currentOrgDetails = useAppSelector(selectOrganizationDetails);

  const allowNegativeCredit = selectedOrg?.allow_negative_credits;

  const isFreeTrial = selectedOrg?.amnesty_mode === "FREE_TRIAL";

  React.useEffect(() => {
    if (currentOrgDetails.isTrialExpired) {
      if (organizationOwner) {
        // setPaymentConfigReg(true)
        setPaymentConfig(true);
      } else {
        setPaymentConfigReg(true);
      }
    }
  }, [currentOrgDetails.isTrialExpired]);

  const [openOrganizationConfig, setOpenOrganizationConfig] =
    React.useState<boolean>(false);
  const [adminUsers, setAdminUsers] = React.useState<boolean>(false);
  const handleGotoLibrary = () => {
    // clear all the state of current document and close it.
    dispatch(clearDocumentTemplate());
    dispatch(setDocumentLoaded(false));
    dispatch(clearMasterElementSettings());
    dispatch(disableApprovalMode());
    actions.getDocumentTemplates();
    dispatch(clearImpactState());
  };

  const [openAdminAssistanceConfig, setOpenAdminAssistanceConfig] =
    React.useState<boolean>(false);
  const [openAccessTokenConfig, setOpenAccessTokenConfig] =
    React.useState<boolean>(false);

  const [openOpenManageOrganizations, setOpenManageOrganizations] =
    React.useState<boolean>(false);

  const [openSuperUserForm, setOpenSuperUserForm] =
    React.useState<boolean>(false);

  const [paymentConfig, setPaymentConfig] = React.useState<boolean>(false);
  const [paymentConfigReg, setPaymentConfigReg] =
    React.useState<boolean>(false);

  const handleOpenSuperUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenSuperUserForm(true);
    setAnchorElUser(null);
  };

  const handleOpenMangeOrgMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenManageOrganizations(true);
    setAnchorElUser(null);
  };

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleOpenCreateOrgUserMenu = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    setAdminUsers(true);
    setAnchorElUser(null);
  };

  const handleOpenAdministration = (event: React.MouseEvent<HTMLElement>) => {
    setOpenOrganizationConfig(true);
    setAnchorElUser(null);
  };

  const handleOpenAdminAssistance = (event: React.MouseEvent<HTMLElement>) => {
    setOpenAdminAssistanceConfig(true);
    setAnchorElUser(null);
  };

  const handleOpenAccessToken = (event: React.MouseEvent<HTMLElement>) => {
    setOpenAccessTokenConfig(true);
    setAnchorElUser(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogoutUserMenu = () => {
    dispatch(clearDocumentTemplate());
    dispatch(setClearLibraryDocuments());
    dispatch(setClearLibraryRecords());
    dispatch(logout());
    setAnchorElUser(null);
  };
  const handlePublishClicked = () => {
    actions.publishDocumentTemplate(editingDocumentTemplate);
    dispatch(disableApprovalMode());
  };

  const handlePublishAgain = () => {
    actions.againPublishDocumentTemplate(editingDocumentTemplate);
    dispatch(disableApprovalMode());
  };

  const handleImpactChanges = () => {
    actions.approvalImpactChanges(editingDocumentTemplate);
  };

  const handleEnableApprovals = () => {
    dispatch(enableApprovalMode());
    actions.enableApprovalMode();
  };
  const handleDisableApprovals = () => {
    dispatch(disableApprovalMode());
    dispatch(clearImpactState());
  };

  // isNew Content Added In Template
  const newContentAdded =
    editingDocumentTemplate != null &&
    editingDocumentTemplate.new_content_added;

  const isEditing =
    editingDocumentTemplate != null && editingDocumentTemplate.is_editing;

  const showPublishButton =
    (editingDocumentTemplate != null && approvalsEnabled) ||
    (editingDocumentTemplate != null &&
      !editingDocumentTemplate.needs_approval);

  // (editingDocumentTemplate != null &&
  //   !editingDocumentTemplate.needs_approval)
  //    ||
  // approvalsEnabled;

  // Show Loading Status while Bulk is in progress
  React.useEffect(() => {
    if (bulkRecordsStatus === "loading") {
      enqueueSnackbar("Bulk Generation in progress...", {
        variant: "info",
      });
    } else if (bulkRecordsStatus === "completed") {
      enqueueSnackbar("Bulk Generation completed successfully.", {
        variant: "success",
      });
    } else if (bulkRecordsStatus === "error") {
      enqueueSnackbar("Failed to create Bulk Generated Documents.", {
        variant: "error",
      });
    }
  }, [bulkRecordsStatus]);

  if (bulkRecordsStatus === "idel" && editingDocumentTemplate === null) {
    const intervalId = setInterval(function () {
      actions.getDocumentTemplates();
    }, 30000); // 30 seconds in milliseconds

    // Optionally, you can clear the interval when you no longer need it
    // For example, when 'bulkRecordsStatus' changes or when you want to stop calling the function.
    // clearInterval(intervalId);
  }

  const fontsize = "0.8em !important";

  return (
    // Note the z-index gracefully puts this above all the drawers, the nominal operation is a little strange.
    // REF: https://mui.com/components/drawers/#clipped-under-the-app-bar
    <AppBar
      position="fixed"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        background: "#000000 !important",
        paddingRight: 0,
      }}
    >
      <Toolbar>
        <img
          src={logo}
          width="100px"
          style={{
            marginRight: "20px",
            // cursor: 'pointer'
          }}
          alt="nomiaLogo"
          // onClick={handleGotoLibrary}
        />

        {/* <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
        </Box> */}
        <Typography
          variant="h6"
          noWrap
          component="div"
          sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
        >
          {(currentWorkspaceRole == "PUBLISHER_CREATOR" ||
            currentWorkspaceRole == "CREATOR") &&
            !currentOrgDetails.isTrialExpired && <CreateMenu></CreateMenu>}
        </Typography>
        <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
          <Button sx={{ color: "white" }} onClick={handleGotoLibrary}>
            <img src={MainFolder} alt="Library" width="30px" height="20px" />
            Library
          </Button>
          {(currentWorkspaceRole == "PUBLISHER_CREATOR" ||
            currentWorkspaceRole == "CREATOR") &&
            !currentOrgDetails.isTrialExpired && <CreateMenu></CreateMenu>}
          {editingDocumentTemplate === null &&
            (currentWorkspaceRole == "PUBLISHER_CREATOR" ||
              currentWorkspaceRole == "PUBLISHER") && (
              <ApprovalMenu
                documentTemplates={allDocumentTemplates}
              ></ApprovalMenu>
            )}
          {/* { editingDocumentTemplate !== null && !editingDocumentTemplate.import_mode && editingDocumentTemplate.publication_no !== null && (currentWorkspaceRole == "PUBLISHER_CREATOR" || currentWorkspaceRole ==  "PUBLISHER") */}
          {editingDocumentTemplate !== null &&
            !editingDocumentTemplate.import_mode &&
            (currentWorkspaceRole == "PUBLISHER_CREATOR" ||
              currentWorkspaceRole == "PUBLISHER") &&
            !isEditing &&
            (editingDocumentTemplate.needs_approval ||
              editingDocumentTemplate.send_for_approvals) && (
              <Stack
                sx={{
                  marginLeft: "10px",

                  borderRadius: "5px",
                  border: "1px solid #555555",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                }}
                direction="row"
                spacing={1}
                alignItems="center"
                onClick={() => {
                  if (approvalsEnabled) {
                    handleDisableApprovals();
                  } else {
                    handleEnableApprovals();
                  }
                }}
              >
                <Typography
                  className="Edit-typography"
                  sx={{ fontSize: "0.9em", marginTop: "-2px" }}
                >
                  EDIT
                </Typography>
                <Switch checked={approvalsEnabled} />
                <Typography
                  className="APPROVALS-typography"
                  sx={{ fontSize: "0.9em", marginTop: "-2px" }}
                >
                  APPROVALS
                </Typography>
              </Stack>
            )}
        </Box>
        {/* Right side */}
        {/* workspace role text */}

        {editingDocumentTemplate === null &&
          (bulkRecordsStatus == "loading" ||
            bulkRecordsStatus == "inprogress") && (
            <Tooltip title="Bulk generation in progress....">
              <CircularProgress
                sx={{ color: "white !important", padding: 0.5 }}
              />
            </Tooltip>
          )}
        <Typography sx={{ mx: 2, fontSize: fontsize }}>
          {currentWorkspaceRole == "PUBLISHER_CREATOR"
            ? "PUBLISHER"
            : currentWorkspaceRole == "CREATOR"
            ? "EDITOR"
            : currentWorkspaceRole == "READER_CREATOR"
            ? "CREATOR"
            : currentWorkspaceRole}
        </Typography>

        {/* 1st time publisg button for Creator */}
        {editingDocumentTemplate !== null &&
        ["CREATOR"].includes(currentWorkspaceRole) &&
        !isTemplateEmpty &&
        // editingDocumentTemplate.publication_no === null &&
        editingDocumentTemplate.new_published_date === null &&
        !editingDocumentTemplate.needs_approval ? (
          <Button
            sx={{ color: "white", fontSize: fontsize, mx: 1 }}
            color="secondary"
            variant="contained"
            disabled={
              !["PUBLISHER", "PUBLISHER_CREATOR", "CREATOR"].includes(
                currentWorkspaceRole
              ) || editingDocumentTemplate.publication_no !== null
            }
            onClick={handlePublishClicked}
          >
            <img
              src={PublishIcon}
              alt="publishIcon"
              width="25px"
              height="20px"
            />
            Publish
          </Button>
        ) : null}

        {editingDocumentTemplate !== null &&
        !isTemplateEmpty &&
        showPublishButton &&
        ["PUBLISHER", "PUBLISHER_CREATOR"].includes(currentWorkspaceRole) &&
        // !isEditing && // show pusblish button if document is in editing state
        editingDocumentTemplate.publication_no === null ? (
          <Button
            sx={{ color: "white", mx: 1, fontSize: fontsize }}
            color="secondary"
            variant="contained"
            disabled={
              !["PUBLISHER", "PUBLISHER_CREATOR"].includes(
                currentWorkspaceRole
              ) || editingDocumentTemplate.publication_no !== null
            }
            title={
              isImpactPending.isPending && approvalsEnabled
                ? "Kindly conduct a comprehensive review and select impactful elements"
                : ""
            }
            onClick={() => {
              if (!isImpactPending.isPending) {
                handlePublishClicked();
                // handleImpactChanges();
              }
            }}
            style={{
              cursor: "pointer",
              backgroundColor: "transparent",
              color:
                approvalsEnabled && isImpactPending.isPending
                  ? "grey"
                  : "white", // Text color conditionally set to grey
            }}
            onMouseOver={(e) => {
              if (isImpactPending.isPending && approvalsEnabled) {
                e.currentTarget.style.cursor = "not-allowed"; // Keep the cursor style for disabled buttons
              } else {
                e.currentTarget.style.cursor = "pointer";
              }
            }}
          >
            <img
              src={PublishIcon}
              alt="publishIcon"
              width="25px"
              height="20px"
            />
            Publish
          </Button>
        ) : null}

        {/* if the current role is creator and need again submit  */}
        {documentLoaded === "loading" ? (
          <CircularProgress sx={{ marginRight: "10px" }} size={"22px"} />
        ) : editingDocumentTemplate !== null &&
          ["CREATOR"].includes(currentWorkspaceRole) &&
          newContentAdded &&
          editingDocumentTemplate.new_published_date ? (
          <Button
            sx={{ color: "white", mx: 1, fontSize: fontsize }}
            color="secondary"
            variant="contained"
            disabled={!["CREATOR"].includes(currentWorkspaceRole)}
            onClick={handlePublishClicked}
          >
            <img
              src={PublishIcon}
              alt="publishIcon"
              width="25px"
              height="20px"
            />
            {/* for creator */}
            {"Publish changes"}
          </Button>
        ) : null}

        {/* editingDocumentTemplate !== null &&
        ["PUBLISHER", "PUBLISHER_CREATOR"].includes(currentWorkspaceRole) &&
        editingDocumentTemplate.publication_no !== null &&
        showPublishButton && !isEditing && approvalsEnabled &&
        (newContentAdded || editingDocumentTemplate.send_for_approvals) */}

        {/* if the current role is publisher and need again submit  */}
        {documentLoaded === "loading" ? (
          <CircularProgress sx={{ marginRight: "10px" }} size={"22px"} />
        ) : editingDocumentTemplate !== null &&
          ["PUBLISHER", "PUBLISHER_CREATOR"].includes(currentWorkspaceRole) &&
          editingDocumentTemplate.publication_no !== null &&
          showPublishButton &&
          // !isEditing &&
          (newContentAdded || approvalsEnabled) ? (
          <Button
            sx={{
              color: "white",
              mx: 1,
              cursor: "pointer",
              fontSize: fontsize,
            }}
            color="secondary"
            variant="contained"
            disabled={
              !["PUBLISHER", "PUBLISHER_CREATOR"].includes(currentWorkspaceRole)
            }
            title={
              approvalsEnabled && isImpactPending.isPending
                ? "Kindly conduct a comprehensive review and select impactful elements"
                : editingDocumentTemplate.needs_approval
                ? "Please perform a thorough review and approve all elements"
                : ""
            }
            onClick={() => {
              if (
                // !(approvalsEnabled && editingDocumentTemplate.needs_approval)
                !isImpactPending.isPending
              ) {
                // handlePublishAgain();
                handleImpactChanges();
              }
            }}
            style={{
              cursor: "pointer",
              backgroundColor: "transparent",
              color:
                // (approvalsEnabled && editingDocumentTemplate.needs_approval) ||
                isImpactPending.isPending ? "grey" : "white", // Text color conditionally set to grey
            }}
            onMouseOver={(e) => {
              if (
                // (approvalsEnabled && editingDocumentTemplate.needs_approval) ||
                isImpactPending.isPending
              ) {
                e.currentTarget.style.cursor = "not-allowed"; // Keep the cursor style for disabled buttons
              } else {
                e.currentTarget.style.cursor = "pointer";
              }
            }}
          >
            <img
              src={PublishIcon}
              alt="publishIcon"
              width="25px"
              height="20px"
            />
            {/* for publisher */}
            {"Publish changes"}
          </Button>
        ) : null}
        {editingDocumentTemplate === null &&
          librarySlice.creditCount != undefined && (
            <Tooltip title="Available Credits">
              <Chip
                color={
                  librarySlice.creditCount <= WARNING_CREDIT_COUNT
                    ? "warning"
                    : librarySlice.creditCount <= ERROR_CREDIT_COUNT
                    ? "error"
                    : "info"
                }
                variant="filled"
                sx={{
                  mx: 2,
                  fontSize: "1.0em",
                  borderRadius: "3px",
                  height: 34.38,
                  fontWeight: "bold",
                }}
                icon={<ReceiptIcon />}
                label={librarySlice.creditCount}
              />
            </Tooltip>
          )}

        {editingDocumentTemplate === null && currentOrgDetails.trail && (
          <Tooltip
            title={
              currentOrgDetails.remainingDays <= 0
                ? "Please subscribe to a plan"
                : `${currentOrgDetails.remainingDays} days remaining`
            }
          >
            <Chip
              color="info"
              variant="filled"
              sx={{
                borderRadius: "3px",
                fontSize: "0.8em",
                fontWeight: "bold",
                boxShadow: "0 0 10px rgba(0, 136, 255, 0.8)", // Glowing effect
                background:
                  currentOrgDetails.remainingDays <= 0
                    ? "#FF0000"
                    : `linear-gradient(90deg, #29CFFF ${
                        calculateTrailRemainingDays(selectedOrg?.expiry_date)
                          .percentage
                      }%,#4C33FF  ${
                        calculateTrailRemainingDays(selectedOrg?.expiry_date)
                          .percentage
                      }%)`, // Adjusted linear gradient with 30% each
                backgroundSize: "200% 200%",
                mx: 2,

                height: 34.38,
              }}
              icon={<HourglassTopIcon />}
              label={
                currentOrgDetails.remainingDays <= 0
                  ? "TRIAL EXPIRED"
                  : "FREE TRIAL"
              }
            />
          </Tooltip>
        )}

        <OrganizationsMenu
          user={authState.user}
          organizations={authState.organizations}
          workspaces={authState.workspaces}
        ></OrganizationsMenu>
        <Box sx={{ flexGrow: 0 }}>
          <Tooltip title="User Account">
            <IconButton
              onClick={handleOpenUserMenu}
              sx={{ p: 1, color: "#FFFFFF" }}
            >
              {/* <AccountCircle /> */}
              <AccountBoxIcon sx={{ fontSize: "1.1em" }} />
            </IconButton>
          </Tooltip>

          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {/* <MenuItem key="profile" onClick={handleCloseUserMenu}>
              <Typography textAlign="center">User Profile</Typography>
          </MenuItem> */}
            {userRole != USER_ROLE.USER && (
              <MenuItem key="logout1" onClick={handleOpenCreateOrgUserMenu}>
                <Typography textAlign="center">Create Admin User</Typography>
              </MenuItem>
            )}

            {authState.user?.super_user && (
              <MenuItem
                key="Manage Super Admins"
                onClick={handleOpenSuperUserMenu}
              >
                <Typography textAlign="center">Manage Super Admins</Typography>
              </MenuItem>
            )}
            {organizationOwner && (
              <MenuItem key="Organization_" onClick={handleOpenAdministration}>
                <Typography textAlign="center">User Administration</Typography>
              </MenuItem>
            )}
            {/* only for SuperUser */}
            {authState.user?.super_user && (
              <MenuItem
                key="manageOrganizations"
                onClick={handleOpenMangeOrgMenu}
              >
                <Typography textAlign="center">Manage Organizations</Typography>
              </MenuItem>
            )}

            {userRole == USER_ROLE.USER && (
              <MenuItem key="Organization_" onClick={handleOpenAdminAssistance}>
                <Typography textAlign="center">
                  Seek Administrator Help
                </Typography>
              </MenuItem>
            )}

            {organizationOwner && (
              <MenuItem key="Acces_Tokens" onClick={handleOpenAccessToken}>
                <Typography textAlign="center">API information</Typography>
              </MenuItem>
            )}
            <MenuItem
              key="Nomia Tutorials"
              onClick={() => {
                window.open("https://nomiadocs.com/tutorials/", "_blank");
              }}
            >
              <Typography textAlign="center">Tutorials</Typography>
            </MenuItem>

            {!allowNegativeCredit && organizationOwner && (
              <MenuItem
                key="Subscription"
                onClick={() => {
                  setPaymentConfig(true);
                  setAnchorElUser(null);
                }}
              >
                <Typography textAlign="center">Subscription</Typography>
              </MenuItem>
            )}

            <MenuItem
              title={userRole}
              key="logout"
              onClick={handleLogoutUserMenu}
            >
              <Typography textAlign="center">Log Out</Typography>
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>

      {openSuperUserForm ? (
        <SuperUsersConfigForm
          closeDialogHandler={() => {
            setOpenSuperUserForm(false);
          }}
        />
      ) : null}

      {paymentConfigReg ? (
        <ExpireTrialModal
          closeDialogHandler={() => {
            setPaymentConfigReg(false);
          }}
        />
      ) : null}
      {openOrganizationConfig ? (
        <OrganizationConfigForm
          workspaces={authState.workspaces!}
          organization={selectedOrg!}
          closeDialogHandler={() => {
            setOpenOrganizationConfig(false);
          }}
        ></OrganizationConfigForm>
      ) : null}

      {openAdminAssistanceConfig ? (
        <AdminAssistanceForm
          organization={selectedOrg!}
          closeDialogHandler={() => {
            setOpenAdminAssistanceConfig(false);
          }}
        />
      ) : null}

      {openOpenManageOrganizations ? (
        <ManageOrganizations
          closeDialogHandler={() => {
            setOpenManageOrganizations(false);
          }}
        />
      ) : null}

      {openAccessTokenConfig ? (
        <APIInformationForm
          closeDialogHandler={() => {
            setOpenAccessTokenConfig(false);
          }}
        />
      ) : null}
      {adminUsers ? (
        <ShowAdminUsers
          closeDialogHandler={() => {
            setAdminUsers(false);
          }}
        />
      ) : null}

      {paymentConfig ? (
        <PaymentModal
          closeDialogHandler={() => {
            setPaymentConfig(false);
          }}
          organization={selectedOrg!}
        />
      ) : null}
    </AppBar>
  );
}
