import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import tempImg from "./images/backgroundImageSignin.jpg"

import useActions, { useAppSelector } from "../../app/hooks";
import { AuthState, logout } from "./authSlice";
import { Email, Warning } from "@mui/icons-material";
import Alert from "@mui/material/Alert";

import logo from "../../nomia-logo2.png";

import { useSnackbar } from "notistack";
import TimeZoneSelector from "../shared/UtilitesComponents/TimeZoneSelector";

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://nomia.co.za/">
        Nomia Pty. Ltd.
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

export default function UpdateUserDetailsModal(props: {
  authState: AuthState;
  email:string;
}) {
  const actions = useActions();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [userDetails,setUserDetails]=React.useState({
    password:"",
    firtsName:"",
    lastName:"",
    timeZone:"",
  })

  const handleFinalizeReset = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const resetData = {
      email: props.email,
      password: userDetails.password,
      first_name:userDetails.firtsName,
      last_name:userDetails.lastName,
      timezone:userDetails.timeZone
    };
    actions.finalizeResetWithUserDetails({userDetails:resetData});
  };

  return (
    <ThemeProvider theme={theme}>
      <Dialog style={{ backgroundImage: `url(${tempImg})`, backgroundSize:'cover'  }}
        open={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
            <img src={logo} width="200px" alt="nomiaLogo"/>
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
        
          <Box
            component="form"
            noValidate
            onSubmit={handleFinalizeReset}
            sx={{ mt: 1, width: 400 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              type="Text"
              id="firstName"
              label="First Name"
            value={userDetails.firtsName}
              name="firstName"
              autoFocus
              onChange={ (event) => setUserDetails({
                ...userDetails,
                firtsName:event.target.value
              })}
            />
               <TextField
              margin="normal"
              required
              fullWidth
              type="Text"
              id="_LastName"
              label="Last Name"
              name="LastName"
              value={userDetails.lastName}
              onChange={ (event) => setUserDetails({
                ...userDetails,
                lastName:event.target.value
              })}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              type="password"
              id="__password"
              label="New Password"
              name="password"
              value={userDetails.password}
              onChange={ (event) => setUserDetails({
                ...userDetails,
                password:event.target.value
              })}
            />

<TimeZoneSelector
        value={userDetails.timeZone}
        onChange={(newTimeZone:any) => setUserDetails({ ...userDetails, timeZone: newTimeZone })}
        label="Time Zone"
        required
      />

            {props.authState.error !== undefined ? (
              <Alert severity="error">
                {props.authState.error}.
              </Alert>
            ) : null}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={userDetails.password===""}

            >
             Update
            </Button>
          </Box> 
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
}
