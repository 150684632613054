import React, { useRef, useState } from "react";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import { clearPreview, selectDocumentPreview } from "../editor/editorSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { pdfjs } from "react-pdf";

//REF: https://github.com/wojtekmaj/react-pdf/issues/991
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function PreviewModal(props: {
  setParentOpenHandler: any;
  preview: any;
}) {
  const dispatch = useAppDispatch();
  const handleClose = () => {
    dispatch(clearPreview());
    props.setParentOpenHandler(false);
  };

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      sx={{
        width: "100%",
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <DialogContent
        dividers
        sx={{
          border: "1px solid rgba(0, 0, 0, 0.3)",
          height: "793px",
          width: "500px",
          overflow: "hidden",
          backgroundColor: "white",
        }}
      >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          PDF Preview
        </Typography>
        <iframe
          src={`${URL.createObjectURL(
            props.preview
          )}#toolbar=0&zoom=page-width`}
          style={{
            backgroundColor: "white",
            width: "100%",
            height: "98%",
            border: "none",
          }}
        >
          {" "}
          <p>Your browser does not support Preview.</p>
        </iframe>
      </DialogContent>
    </Dialog>
  );

  // return (
  //   <Dialog
  //     open={true}
  //     onClose={handleClose}
  //     aria-labelledby="modal-modal-title"
  //     aria-describedby="modal-modal-description"
  //   >
  //     <DialogContent
  //       dividers
  //       sx={{
  //         border: "1px solid rgba(0, 0, 0, 0.3)",
  //         height: "90vh",
  //         width: "600px",
  //         overflow: "hidden",
  //         backgroundColor: "white",
  //       }}
  //     >
  //       <Typography id="modal-modal-title" variant="h6" component="h2">
  //         PDF Preview
  //       </Typography>

  //       <iframe
  //         src={`${URL.createObjectURL(
  //           props.preview
  //         )}#toolbar=0&zoom=page-width`}
  //         style={{
  //           backgroundColor: "white",
  //           width: "100%",
  //           height: "95vh",
  //           border: "none"
  //         }}
  //       >
  //         {" "}
  //         <p>Your browser does not support Preview.</p>
  //       </iframe>
  //     </DialogContent>
  //   </Dialog>
  // );
}
