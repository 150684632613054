import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";

interface OTPScreenProps {
  email: string;
  onVerifyOTP: (otp: string) => void;
  onResendOTP: () => void;
  error?: string;
}

const OTPScreen: React.FC<OTPScreenProps> = ({ email, onVerifyOTP, onResendOTP, error }) => {
  const [otp, setOtp] = React.useState<string[]>(["", "", "", "", "", ""]);
  const [timeLeft, setTimeLeft] = React.useState<number>(300); // Set initial time to 120 seconds
  const otpFields = React.useRef<(HTMLInputElement | null)[]>([]);
  const timerRef = React.useRef<NodeJS.Timeout | null>(null); // Ref to store the timer

  React.useEffect(() => {
    startTimer(); // Start the timer when the component mounts

    return () => clearInterval(timerRef.current!); // Clean up the timer on component unmount
  }, []);

  const startTimer = () => {
    if (timerRef.current) clearInterval(timerRef.current); // Clear any existing timer
    setTimeLeft(300); // Reset time to 120 seconds

    timerRef.current = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timerRef.current!);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const otpValue = otp.join("");
    if (otpValue.length === 6) {
      onVerifyOTP(otpValue);
    }
  };

  const handleOtpChange = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (/^\d$/.test(value) || value === "") {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Move focus to next field if input is not empty and not the last field
      if (value !== "" && index < otpFields.current.length - 1) {
        otpFields.current[index + 1]?.focus();
      }

      // Move focus to previous field if input is empty and not the first field
      if (value === "" && index > 0) {
        otpFields.current[index - 1]?.focus();
      }
    }
  };

  const formatTime = (seconds: number) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins.toString().padStart(2, "0")}:${secs.toString().padStart(2, "0")}`;
  };

  return (
    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1, width: 400 }}>
      <Typography sx={{ fontWeight: "bold" }} variant="h6">Enter the OTP sent to {email}</Typography>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        {otp.map((digit, index) => (
          <TextField
            key={index}
            inputRef={(el) => (otpFields.current[index] = el)}
            margin="normal"
            required
            sx={{ width: "3rem", textAlign: "center" }}
            inputProps={{ style: { textAlign: "center" }, maxLength: 1 }}
            value={digit}
            onChange={handleOtpChange(index)}
          />
        ))}
      </Box>
      <Typography variant="body2" color="textSecondary" align="center" sx={{ mt: 2 }}>
        Time left: {formatTime(timeLeft)}
      </Typography>
      <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} disabled={otp.join("").length !== 6}>
        Verify OTP
      </Button>
      <Button
        fullWidth
        variant="outlined"
        sx={{ mb: 2 }}
        onClick={() => {
          startTimer(); // Restart the timer
          onResendOTP();
        }}
        disabled={timeLeft > 0} // Disable button if timeLeft is greater than 0
      >
        Resend OTP
      </Button>
      {error && <Alert severity="error">{error}</Alert>}
    </Box>
  );
};

export default OTPScreen;
