import { delay, fork, select } from "redux-saga/effects";
import { call, put } from "redux-saga/effects";
import * as actions from "../actions";
import { selectUser, setErrorMsg } from "../../features/auth/authSlice";
import { Effect } from "redux-saga/effects";
import {
  setError as setDocumentRecordError,
  setStatus as setDocumentRecordStatus,
  setDocumentRecords,
  setBulkRecordsStatus,
  setPreviewStatus,
} from "../../features/library/documentRecordsSlice";
import {
  setError as setDocumentTemplateError,
  setStatus as setDocumentTemplateStatus,
  setDocumentTemplates,
  setImagePreview,
  setPreview,
  updateDocumentTemplateLocal,
  selectLibrary,
  LibraryState,
  setDocumentTemplatesStatus,
} from "../../features/library/librarySlice";
import { User } from "../../types/User";
import {
  apiCreateBulkDocumentRecord,
  apiCreateDocumentRecord,
  apiDocumentRecordPreview,
  apiGetDocumentRecords,
} from "../../services/documentTemplatesAPI";
import { DocumentRecord, DocumentTemplate } from "../../types/DocumentTemplate";
import { selectEditingDocumentTemplate } from "../../features/editor/editorSlice";
interface CreateDocumentRecordResult {
  success: boolean;
  error?: string;
}

/**
 * Get all document records.
 *
 * @param {ReturnType<typeof actions.getDocumentRecords>} action
 * @return {*}
 */

export function* createDocumentRecord(
  action: ReturnType<typeof actions.createDocumentRecord>
) {
  yield put(setDocumentRecordStatus({ status: "loading", error: "isLoading" }));
  yield put(  setErrorMsg({
    status:"loading",
    errorMsg:undefined,
    errorType:"createDocumentRecord"
   }))
  try {
    const userState = (yield select(selectUser)) as User;
  const response  =   yield call(
      apiCreateDocumentRecord,
      userState.default_organization_id,
      userState.default_workspace_id,
      action.payload.documentTemplate,
      action.payload.name,
      action.payload.variables,
      true
    );
    // Update all the document templates, which gets all records too.
    yield put(actions.getDocumentTemplates());
    yield put(  setErrorMsg({
      status:"success",
      errorMsg:"Generate successfully!",
      errorType:"createDocumentRecord"
     }))
    yield put(setDocumentRecordStatus({ status: "idle", error: undefined }));
  } catch (e:any) {  
    console.log("error" , e)
    if(e=="402"){
      yield put(  setErrorMsg({
        status:"failed",
        errorMsg:"You have no credits left, please purchase more credits to continue",
        errorType:"createDocumentRecord"
       }))
    }
    else if(e=="409"){
      yield put(  setErrorMsg({
        status:"failed",
        errorMsg:"Document with this name already exist Suggestion:" + action.payload.name +  Math.floor(Math.random() * 1000),
        errorType:"createDocumentRecord"
       }))
    }
   else{
      yield put(  setErrorMsg({
        status:"failed",
        errorMsg:"Unable to generate Document Record",
        errorType:"createDocumentRecord"
       }))
    }
    yield put(setDocumentRecordStatus({ status: "failed", error: "Conflict" }));
    yield put(setDocumentRecordError("Conflict"));
    return e;
  }
}

export function* createBulkDocumentRecord(
  action: ReturnType<typeof actions.createBulkDocumentRecord >
) {
  yield put (setBulkRecordsStatus("loading"))
  const documentTemplate = (yield select(
    selectEditingDocumentTemplate
  )) as DocumentTemplate | null;

  // yield put(setDocumentRecordStatus({ status: "loading", error: "isLoading" }));
  try {
    const userState = (yield select(selectUser)) as User;

  const response  =   yield call(
    apiCreateBulkDocumentRecord,
      userState.default_organization_id,
      userState.default_workspace_id,
      action.payload.documentTemplate,
      action.payload.bulkDocuments
    );
// yield put(setBulkRecordsStatus("completed"))
// yield call(delay, 60000); // 5000 milliseconds = 5 seconds
// if (documentTemplate == null) {
//   // Add a timeout of, for example, 5 seconds before calling actions.getDocumentTemplates()
//   yield put(actions.getDocumentTemplates());
// }
    // yield put (setBulkRecordsStatus("idle"))
  } catch (e) {
    yield put (setBulkRecordsStatus("error"))
    if (documentTemplate == null) {
    yield put(actions.getDocumentTemplates());
    }
    // yield put(setDocumentRecordError("Conflict"));
   
    return e;
  }
}

export function* getDocumentRecords(
  action: ReturnType<typeof actions.getDocumentRecords>
) {
  yield put(setDocumentRecordStatus({ status: "loading", error: undefined }));


  try {
    const userState = (yield select(selectUser)) as User;
    const response = yield call(
      apiGetDocumentRecords,
      userState.default_organization_id,
      userState.default_workspace_id
    );
    yield put(
      setDocumentRecords(
        response.data.document_records.map((dr:DocumentRecord) => ({
          ...dr,
          _type: "record",
        }))
      )
    );
    yield put(setDocumentRecordStatus({ status: "idle", error: undefined }));
  } catch (e) {
    yield put(setDocumentRecordError(String(e)));
    return;
  }
}

export function* previewDocumentRecord(
  action: ReturnType<typeof actions.previewDocumentRecord>
) {
  
  yield put(setDocumentRecordStatus({ status: "loading", error: undefined }));
  yield put( setPreviewStatus("loading"))
  try {
    const userState = (yield select(selectUser)) as User;
    const previewResponse = yield call(
      apiDocumentRecordPreview,
      userState.default_organization_id,
      userState.default_workspace_id,
      action.payload
    );

    // Update all the document templates.
    yield put(setPreview(previewResponse.data));
    yield put( setPreviewStatus("idle"))
    yield put(setDocumentTemplateStatus({ status: "idle", error: undefined }));
  } catch (e) {
    yield put(setPreviewStatus("failed"))

    yield put(
      setDocumentTemplateStatus({
        status: "idle",
        error: "Unable to generate a preview: " + String(e),
      })
    );
    return;
  }
}
