import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Box, Grid, MenuItem, Typography } from "@mui/material";
import TableElementTheme from "./TableElementTheme";
import { TABLE_THEMES } from "../../../../../globals";
import {
  getDefaultThemeDetails,
  tableThemes,
  themeData,
} from "../HelperFuctions/TableUtils";

//component:  User Select the theme he wants to use......
export default function ThemeDialog(props: {
  open: boolean;
  onClose: any;
  onUpdate?: any;
  currentDetails?: any;
}) {
  const themes = tableThemes;
  const [selectedTheme, setSelectedTheme] = React.useState<themeData | null>(
    null
  );
  const handleSelectTheme = (theme: themeData) => {
    if (theme) {
      setSelectedTheme(theme);
    }
  };
  const handleSave = () => {
    if (selectedTheme != null) {
      // console.log(selectedTheme.data);
      props.onUpdate(selectedTheme.data);
      props.onClose();
    }
  };

  return (
    <Dialog
      fullWidth
      open={props.open}
      onClose={props.onClose}
      sx={{ zIndex: 99999 }}
      maxWidth="lg" // Set maxWidth to adjust the width of the dialog
    >
      <DialogTitle sx={{ fontWeight: "bold", fontSize: "22px" }}>
        Table Themes
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          {themes.map((theme, index) => (
            <Grid item key={theme.name} xs={4}>
              <Box
                borderRadius={1}
                sx={{
                  padding: 1,
                  backgroundColor: "white", // Add a light color background
                  border:
                    selectedTheme?.name === theme.name
                      ? "3px solid #4C33FF"
                      : "3px solid white",
                  cursor: "pointer",
                  textAlign: "center", // Center-align the text
                  transition:
                    "border 0.3s ease-in-out, box-shadow 0.3s ease-in-out", // Add smooth transitions
                  boxShadow: "0 0 10px rgba(0, 0, 255, 0)", // Initial box shadow (no glow)
                  // borderRight: index < themesData.length - 1 ? "1px solid #ccc" : "none", // Add a separator line
                  "&:hover": {
                    border:
                      selectedTheme?.name === theme.name
                        ? "3px solid #4C33FF"
                        : "3px solid grey", // Change border color on hover
                    boxShadow: "0 0 20px rgba(0, 0, 255, 0.5)", // Add a glow effect on hover
                  },
                }}
                onClick={() => {
                  handleSelectTheme(theme);
                }}
              >
                <TableElementTheme
                  themeStyle={theme.type}
                  themeCode={theme.name}
                  // You can also pass other properties from theme.data here if needed
                />

                <Typography
                  variant="body1"
                  fontWeight="bold"
                  sx={{ paddingTop: 1 }}
                >
                  {theme.name && getDefaultThemeDetails(theme.name)!.name}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} variant="outlined" color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          variant="contained"
          disabled={selectedTheme === null}
          color="primary"
        >
          save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
