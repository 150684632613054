
import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@mui/material';

export default function ConfirmDialog(
    props:{
        title:string,
        content:string ,
        handleClose:any,
        handleConfirm:any
    }
){
    return (
        <Dialog open={true} onClose={props.handleClose}>
          <DialogTitle>{props.title}</DialogTitle>
          <DialogContent>
            <Typography>{props.content}</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={props.handleClose} color="primary">
              Cancel
            </Button>
            <Button  variant="contained" onClick={props.handleConfirm} color="primary" autoFocus>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      );
}