/* eslint-disable no-debugger */
import React, { useEffect, useMemo, useRef, useState } from "react";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { grey } from "@mui/material/colors";
import { useDrop } from "react-dnd";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { EditorHeaderMenu } from "./header/EditorHeaderMenu";
import SectionEditor from "./document/SectionEditor";
import { SectionAdder } from "./document/SectionAdder";
import { TitlePage } from "./document/TitlePage";
import {
  DocumentElement,
  DocumentTemplate,
} from "../../types/DocumentTemplate";
import {
  selectSelectedSection,
  selectStatusState,
  setHeadingNumbers,
  setSectionNumbers,
  setTextNumbers,
} from "./editorSlice";
import { selecterrorState } from "./editorSlice";
import { useSnackbar, VariantType } from "notistack";
import {
  selectApprovalMode,
  selectEditingDocumentTemplate,
  selectIsEditing,
  selectSelected,
  setDocumentTemplateImportText,
} from "./editorSlice";
import {
  selectAuth,
  selectResponseError,
  setErrorMsg,
} from "../auth/authSlice";
import { DocumentSection } from "../../types/DocumentTemplate";
import {
  clearPreview,
  selectDocumentPreview,
  selectLibrary,
} from "../library/librarySlice";
import PreviewModal from "../preview/PreviewModal";
import { selectPreferences } from "./header/preferencesSlice";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import LibraryContentConfigForm from "./document/libraryContent/LibraryContentForm";
import useActions from "../../app/hooks";

import {
  Alert,
  Checkbox,
  Dialog,
  Divider,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import InputLabel from "@mui/material/InputLabel";
import DialogTitle from "@mui/material/DialogTitle";
import { useMediaQuery } from "@mui/material";
import {
  selectDocumentMasterState,
  selectSectionsDocumentMasterState,
  setSortedSectionsDocumentMasterLocal,
} from "./header/documentMasterSlice";
import EditorHeaderFooter from "./EditorHeaderFooter";
import { TABLE_OF_CONTENTS_SECTION } from "../../globals";
import {
  documentSectionsNumber,
  documentSectionsNumbers,
  documentSubHeadingsNumber,
  documentTextNumber,
} from "./document/CustomEditorDecorators/levelsHelperFunctions";
import { shallowEqual, useSelector } from "react-redux";
import { fixSectionFormatMaster } from "../../app/sagas/utils";
import { isUserFromShareLink } from "./document/TableElement/EditContent/helper";
import { DocumentMaster } from "../../types/DocumentMaster";

/**
 * The main document editor. When a DocumentTemplate is set
 * this is created to allow it to be edited.
 *
 * @export
 * @return {*}
 */
export default function Editor() {
  const dispatch = useAppDispatch();
  const documentTemplate: DocumentTemplate | any = useAppSelector(
    selectEditingDocumentTemplate
  );
  const documentMasterState: DocumentMaster | any = useAppSelector(
    selectDocumentMasterState
  );
  const sectionMasterState = useAppSelector(selectSectionsDocumentMasterState);

  const auth: any = useAppSelector(selectAuth);
  const viewPreferences = useAppSelector(selectPreferences);
  const documentPreview = useAppSelector(selectDocumentPreview);
  const selectedComponents = useSelector(selectSelectedSection, shallowEqual);
  const actions = useActions();
  const approvalsEnabled = useAppSelector(selectApprovalMode);
  const [droppedOnBoxId, setDroppedOnBoxId] = useState<number>();
  const [droppedOnBoxIdR, setDroppedOnBoxIdR] = useState<number>(55);
  const [droppedOnBoxIdLast, setDroppedOnBoxIdLast] = useState<number>(88);
  const [boxBackground, setBoxBackground] = useState<boolean>(false);
  const [isTOCAbove, setIsTOCAbove] = useState<boolean>(false);
  const [isTOCLast, setIsTOCLast] = useState<boolean>(false);
  const [isTOCExist, setIsTOCExist] = useState<boolean>(false);
  const [isTitleExist, setIsTitleExist] = useState<boolean>(false);
  const [isListOfTableExist, setIsListOfTableExist] = useState<boolean>(false);
  const [isListOfFigureExist, setIsListOfFigureExist] =
    useState<boolean>(false);
  const [isLibraryContentFloatOpen, setIsLibraryContentFloatOpen] =
    useState<boolean>(false);
  const [dialogOpenForTablesAndFigure, setDialogOpenForTablesAndFigure] =
    useState<boolean>(false);
  const list = ["List of Tables", "List of Figures"];
  const [listChoice, setListChoice] = React.useState<string>("List of Tables");
  const [isInside, setIsInside] = useState(false);
  function handleDialogOpen() {
    setDialogOpenForTablesAndFigure(true);
  }

  function handleDialogClose() {
    setDialogOpenForTablesAndFigure(false);
  }

  const selectErrorMsgState = useAppSelector(selectResponseError);
  useEffect(() => {
    const fixedSectionFormating = fixSectionFormatMaster(
      documentTemplate,
      documentMasterState.sectionsDocumentMaster,
      documentMasterState.documentMaster
    );
    dispatch(setSortedSectionsDocumentMasterLocal(fixedSectionFormating));
  }, [
    documentMasterState.documentMaster,
    documentMasterState.sectionsDocumentMaster,
  ]);

  useEffect(() => {
    if (documentMasterState.documentMaster) {
      const fixedSectionFormating = fixSectionFormatMaster(
        documentTemplate,
        documentMasterState.sectionsDocumentMaster,
        documentMasterState.documentMaster
      );
      dispatch(setSortedSectionsDocumentMasterLocal(fixedSectionFormating));

      const sectionNumbering = documentSectionsNumbers(
        documentTemplate,
        approvalsEnabled,
        sectionMasterState,
        documentMasterState
      );

      // const sectionNumbering = documentSectionsNumber(documentTemplate ,approvalsEnabled);
      const numbering = documentSubHeadingsNumber(
        documentTemplate,
        documentMasterState,
        sectionNumbering,
        sectionMasterState
      );
      const textNumbering = documentTextNumber(
        documentTemplate,
        documentMasterState,
        sectionNumbering,
        numbering,
        sectionMasterState
      );

      dispatch(setSectionNumbers({ sectionsNumber: sectionNumbering }));
      dispatch(setHeadingNumbers({ headingNumber: numbering }));
      dispatch(setTextNumbers({ textNumber: textNumbering }));
    }
  }, [
    documentTemplate.sections,
    documentMasterState.documentMaster,
    documentMasterState.sectionsDocumentMaster,
    approvalsEnabled,
  ]);

  React.useEffect(() => {
    if (selectErrorMsgState.status == "success") {
      if (
        selectErrorMsgState.errorType === "attachSection" &&
        selectErrorMsgState.msg
      ) {
        enqueueSnackbar(selectErrorMsgState.msg, { variant: "success" });
        dispatch(
          setErrorMsg({
            status: "idle",
            errorMsg: undefined,
            errorType: undefined,
          })
        );
      }
    } else if (selectErrorMsgState.status == "failed") {
      if (
        selectErrorMsgState.errorType === "attachSection" &&
        selectErrorMsgState.msg
      ) {
        enqueueSnackbar(selectErrorMsgState.msg, { variant: "error" });
        // props.parentCloseHandle();
        dispatch(
          setErrorMsg({
            status: "idle",
            errorMsg: undefined,
            errorType: undefined,
          })
        );
      } else if (
        selectErrorMsgState.errorType === "generateDocumentTemplate" &&
        selectErrorMsgState.msg
      ) {
        enqueueSnackbar(selectErrorMsgState.msg, { variant: "error" });
        dispatch(
          setErrorMsg({
            status: "idle",
            errorMsg: undefined,
            errorType: undefined,
          })
        );
      }
    }
  }, [selectErrorMsgState]);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  useEffect(() => {
    setIsTOCExist(false);
    setIsTitleExist(false);
    setIsListOfTableExist(false);
    setIsListOfFigureExist(false);
    documentTemplate.sections!.map((element: any, index: any) => {
      if (
        documentTemplate.sections![index].heading ===
        "Table of Contents Section"
      ) {
        setIsTOCExist(true);
      } else if (documentTemplate.sections![index].heading === "Title Page") {
        setIsTitleExist(false);
      } else if (
        documentTemplate.sections![index].heading === "List of Tables"
      ) {
        setIsListOfTableExist(true);
      } else if (
        documentTemplate.sections![index].heading === "List of Figures"
      ) {
        setIsListOfFigureExist(true);
      }
    });
  }, [documentTemplate.sections]);

  const [pageOrientation, setPageOrientation] = useState<string>("portrait");
  const [pageColor, setPageColor] = useState<string>("FFFFFF");
  const [marginLeft, setMarginLeft] = useState<number>(25.5);
  const [marginRight, setMarginRight] = useState<number>(25.5);
  const [marginTop, setMarginTop] = useState<number>(25.5);
  const [marginBottom, setMarginBottom] = useState<number>(25.5);
  useEffect(() => {
    if (
      documentMasterState.documentMaster !== undefined &&
      documentMasterState.documentMaster !== null
    ) {
      try {
        setPageOrientation(documentMasterState.documentMaster.orientation);
        setPageColor(documentMasterState.documentMaster.page_background_color);
        setMarginLeft(
          documentMasterState.documentMaster.page_margin_left as number
        );
        setMarginRight(
          documentMasterState.documentMaster.page_margin_right as number
        );
        setMarginTop(
          documentMasterState.documentMaster.page_margin_top as number
        );
        setMarginBottom(
          documentMasterState.documentMaster.page_margin_bottom as number
        );
      } catch (e) {
        console.log("Unable to get Document Template master");
      }
    }
  }, [documentMasterState.documentMaster]);

  // useEffect(() => {
  //   // Update component_order when sections are updated
  //   //
  //   if (statusUseSelector === "idle" && isTOCAbove) {
  //     createTOC();
  //   }
  //   if (statusUseSelector === "idle" && isTOCLast) {
  //     createTocLast();
  //   }
  //   setDroppedOnBoxId(0);
  // }, [documentTemplate]);
  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: [
        // "right-drawer-library-content",
        "right-drawer-item-title-page",
        "right-drawer-item-Toc",
        "right-drawer-item-LTF",
      ],
      drop: (dropItem: any) => {
        dropLibraryContentOnDocument({ ...dropItem.element });
        dropTitlePageOnDocument({ ...dropItem.element });
        dropListOfFigureOrTable({ ...dropItem.element });
        createSectionWithTOC({ ...dropItem.element });
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
        canDrop: !!monitor.canDrop(),
      }),
    }),
    [droppedOnBoxId, documentTemplate, actions]
  );

  const [isTitlePageOpen, setIsTitlePageOpen] = useState<boolean>(false);
  const [isLibraryContentOpen, setIsLibraryContentOpen] =
    useState<boolean>(false);
  function dropLibraryContentOnDocument(libraryContent: any) {
    // Let the user select the library content.
    if (String(libraryContent.content_type) === "LIBRARYCONTENT") {
      setIsLibraryContentOpen(true);
    }
  }

  const createSectionWithTOC = (element: any) => {
    if (String(element.content_type) === "TABLEOFCONTENTS") {
      if (!isTOCExist) {
        if (droppedOnBoxId && droppedOnBoxId != 0) {
          const targetSection = documentTemplate.sections?.find(
            (section: any) => Number(section.id) === droppedOnBoxId
          ) as DocumentSection;

          actions.createSectionWithElement({
            heading: "Table of Contents Section",
            component_order: targetSection!.component_order,
            belowSectionID: targetSection.id,
            documentElement: {
              id: 10,
              content: "----- !table-of-contents! -----",
              content_format: JSON.stringify({
                title: "Table of Contents",
                levelDepth: "3",
                font: "arial",
                fontSize: "12",
                color: "000000",
                lineSpacing: "1.5",
                indent: true,
                border: "F",
                isPageBreak: false,
                isPageBreakBelow: false,
                isPageBreakAbove: false,
                isFollowDtm: true,
                isBold: false,
                isItalic: false,
                isUnderline: false,
                isStandard: true,
              }),
              content_type: "TABLEOFCONTENTS",
              heading_level: 2,
            } as DocumentElement,
          });
        } else {
          const component_order =
            documentTemplate.sections!.length > 0
              ? Math.max(
                  ...documentTemplate.sections!.map(function (o: any) {
                    return o.component_order;
                  })
                ) + 10
              : 0;
          actions.createSectionWithElement({
            heading: "Table of Contents Section",
            component_order: component_order,
            belowSectionID: 0,
            documentElement: {
              id: 10,
              content: "----- !table-of-contents! -----",
              content_format: JSON.stringify({
                title: "Table of Contents",
                levelDepth: "3",
                font: "arial",
                fontSize: "12",
                color: "000000",
                lineSpacing: "1.5",
                indent: true,
                border: "F",
                isPageBreak: false,
                isPageBreakBelow: false,
                isPageBreakAbove: false,
                isFollowDtm: true,
                isBold: false,
                isItalic: false,
                isUnderline: false,
                isStandard: true,
              }),
              content_type: "TABLEOFCONTENTS",
              heading_level: 2,
            } as DocumentElement,
          });
        }
      } else {
        enqueueSnackbar("Table of Contents Already Exists", {
          variant: "error",
        });
      }
    }
  };

  const createSectionWithElement = () => {
    if (droppedOnBoxId && droppedOnBoxId != 0) {
      const targetSection = documentTemplate.sections?.find(
        (section: any) => Number(section.id) === droppedOnBoxId
      ) as DocumentSection;
      actions.createSectionWithElement({
        heading: listChoice,
        component_order: targetSection!.component_order,
        belowSectionID: targetSection.id,
        documentElement: {
          id: 10,
          content: "----- !list-of-figures! -----",
          content_format: `{"title":"${listChoice}","font":"arial","fontSize":"12" , "color": "000000", "lineSpacing": "1.5", "type":"${listChoice}" ,"isBold":false,"isItalic":false,"isUnderline":false,"isStandard":true,"isFollowDtm":true}`,
          content_type:
            listChoice === "List of Figures" ? "LISTOFFIGURES" : "LISTOFTABLES",
          heading_level: 2,
        } as DocumentElement,
      });
    } else {
      const component_order =
        documentTemplate.sections!.length > 0
          ? Math.max(
              ...documentTemplate.sections!.map(function (o: any) {
                return o.component_order;
              })
            ) + 10
          : 0;
      actions.createSectionWithElement({
        heading: listChoice,
        component_order: component_order,
        belowSectionID: 0,
        documentElement: {
          id: 10,
          content: "----- !list-of-figures! -----",
          content_format: `{"title":"${listChoice}","font":"arial","fontSize":"16" , "color": "black", "lineSpacing": "1.5","type":"${listChoice}" ,"isBold":false,"isItalic":false,"isUnderline":false,"isStandard":true,"isFollowDtm":true}`,
          content_type:
            listChoice === "List of Figures" ? "LISTOFFIGURES" : "LISTOFTABLES",
          heading_level: 2,
        } as DocumentElement,
      });
    }

    setDialogOpenForTablesAndFigure(false);
  };
  function dropListOfFigureOrTable(ListOfFigureOrTable: any) {
    // Let the user select the ListOfFigureOrTable
    if (String(ListOfFigureOrTable.content_type) === "LISTOFFIGURES") {
      setDialogOpenForTablesAndFigure(true);
    }
  }
  function tableofContentLast() {
    setIsTOCLast(false);
    setIsTOCAbove(false);
    const component_order =
      documentTemplate.sections!.length > 0
        ? Math.max(
            ...documentTemplate.sections!.map(function (o: any) {
              return o.component_order;
            })
          ) + 10
        : 0;
    actions.createSection({
      heading: "Table of Content Section",
      component_order: component_order,
      heading_level: 100,
      content_format: "toc",
    });
    setIsTOCLast(true);
    setIsTOCAbove(false);
    // setTimeout(createTocBelow, 3000);
  }

  function tableofContentAbove() {
    setIsTOCLast(false);
    setIsTOCAbove(false);
    const targetSection = documentTemplate.sections?.find(
      (section: any) => Number(section.id) === droppedOnBoxId
    ) as DocumentSection;
    const newSection = {
      heading: "Table of Content Section",
      component_order: targetSection!.component_order,
      heading_level: 1,
      content_format: "toc",
    };
    actions.createSectionAboveSection({
      belowSection: targetSection!,
      insertedSection: newSection,
    });
    setIsTOCAbove(true);
    setIsTOCLast(false);
    // setTimeout(createTOC, 6000);
  }
  function dropTOCsectionOnDocument(libraryContent: any) {
    // Let the user select the library content.
    if (String(libraryContent.content_type) === "TABLEOFCONTENTS") {
      setIsTOCExist(false);
      documentTemplate.sections!.map((element: any, index: any) => {
        if (
          documentTemplate.sections![index].heading ===
          "Table of Content Section"
        ) {
          setIsTOCExist(true);
        }
      });

      if (!isTOCExist) {
        setIsTOCLast(false);
        setIsTOCAbove(false);
        if (droppedOnBoxId && droppedOnBoxId != 0) {
          tableofContentAbove();
        } else {
          tableofContentLast();
        }
      } else {
        enqueueSnackbar("Table Of Content Already Exist");
      }
    }
  }

  function createTOC() {
    const newCreatedSectionID =
      documentTemplate.sections!.length > 0
        ? Math.max(
            ...documentTemplate.sections!.map(function (o: any) {
              return o.id;
            })
          )
        : 0;

    const foundSection = documentTemplate.sections?.find(
      (section: any) => Number(section.id) === newCreatedSectionID
    );

    const newParms = JSON.stringify({
      title: "Table of Content",
      levelDepth: "3",
      font: "arial",
      fontSize: "16",
      color: "black",
      lineSpacing: "1.5",
      indent: true,
      border: "F",
    });
    actions.createSubsectionWithElement({
      section: foundSection as DocumentSection,
      documentElement: {
        id: 10,
        content: "----- !table-of-contents! -----",
        content_format: newParms,
        content_type: "TABLEOFCONTENTS",
        heading_level: 2,
      } as DocumentElement,
      subsectionAbove: undefined,
    });
    setDroppedOnBoxId(0);
    setIsTOCAbove(false);
  }

  function createTocLast() {
    const newParms = JSON.stringify({
      title: "Table of Content",
      levelDepth: "3",
      font: "arial",
      fontSize: "16",
      color: "black",
      lineSpacing: "1.5",
      indent: true,
      border: "F",
    });
    actions.createSubsectionWithElement({
      section: documentTemplate.sections![
        documentTemplate.sections!.length - 1
      ] as DocumentSection,
      documentElement: {
        id: 1,
        content: "----- !table-of-contents! -----",
        content_format: newParms,
        content_type: "TABLEOFCONTENTS",
        heading_level: 2,
      } as DocumentElement,
      subsectionAbove: undefined,
    });
    setDroppedOnBoxId(0);
    setIsTOCLast(false);
  }

  function dropTitlePageOnDocument(libraryContent: any) {
    // Let the user select the library content.
    if (String(libraryContent.content_type) === "TITLEPAGE") {
      setIsTitleExist(false);
      // documentTemplate.sections!.map((element: any, index: any) => {
      //   if (documentTemplate.sections![index].heading === "_Title Page") {
      //     setIsTitleExist(true);
      //   }
      // });
      if (!isTitleExist) {
        setIsTitlePageOpen(true);
      } else {
        enqueueSnackbar("Title Page Already Exist", { variant: "error" });
      }
    }
  }

  function handlePreviewClose() {
    dispatch(clearPreview());
  }

  function insertSection() {
    const newSection = {
      heading: "Section Heading",
      component_order: selectedComponents.section!.component_order,
      heading_level: 1,
      formatting: JSON.stringify({
        isBold: false,
        isItalic: false,
        isUnderline: false,
        isStandard: true,

        isCustomSettings: false,
      }),
    };
    actions.createSectionAboveSection({
      belowSection: selectedComponents.section!,
      insertedSection: newSection,
    });
  }

  let lastSection: any = null;
  if (documentTemplate?.sections && documentTemplate.sections.length > 0) {
    lastSection =
      documentTemplate.sections[documentTemplate.sections.length - 1].id;
  } else {
    // Handle the case when there are no sections in the documentTemplate
    // You can set a default value or perform any appropriate action here.
  }

  const droppedSectionHandler = (e: any) => {
    const boxId: any = e.currentTarget.dataset.sectionId;
    if (boxId === undefined) {
      setDroppedOnBoxId(0);
      setDroppedOnBoxIdR(0);
    } else if (droppedOnBoxId !== boxId) {
      setDroppedOnBoxId(Number(boxId));
      setDroppedOnBoxIdR(Number(boxId));
      setDroppedOnBoxIdLast(99);
      // Droped Sekeletion Visualization
    }
  };

  if (documentTemplate.import_mode) {
    //const import_set = useMemo( () => documentTemplate.import_text.split("\n"), [documentTemplate])
    return (
      <Box>
        <Typography sx={{ padding: "20px", background: "#FFFFFF" }}>
          You are in import mode. Please select the nature of each text line and
          hit import when done.
        </Typography>
        {documentTemplate.import_text.map((_: any, i: number) => {
          return (
            <Box sx={{ background: "#FFFFFF" }}>
              <Button
                sx={{
                  background: _.part_type == "s" ? "#DDDDFF" : "#EEEEEE",
                  display: "inline",
                }}
                onClick={() => {
                  dispatch(
                    setDocumentTemplateImportText(
                      documentTemplate.import_text.map((_: any, j: number) => {
                        if (i == j) {
                          return {
                            part_type: "s",
                            part_text: _.part_text,
                          };
                        } else return _;
                      })
                    )
                  );
                }}
              >
                Element
              </Button>
              <Button
                sx={{
                  background: _.part_type == "h" ? "#DDDDFF" : "#EEEEEE",
                  display: "inline",
                }}
                onClick={() => {
                  dispatch(
                    setDocumentTemplateImportText(
                      documentTemplate.import_text.map((_: any, j: number) => {
                        if (i == j) {
                          return {
                            part_type: "h",
                            part_text: _.part_text,
                          };
                        } else return _;
                      })
                    )
                  );
                }}
              >
                Heading
              </Button>
              <Button
                sx={{
                  marginRight: "10px",
                  background: _.part_type == "S" ? "#DDDDFF" : "#EEEEEE",
                  display: "inline",
                }}
                onClick={() => {
                  dispatch(
                    setDocumentTemplateImportText(
                      documentTemplate.import_text.map((_: any, j: number) => {
                        if (i == j) {
                          return {
                            part_type: "S",
                            part_text: _.part_text,
                          };
                        } else return _;
                      })
                    )
                  );
                }}
              >
                Section
              </Button>
              <Typography sx={{ display: "inline" }}>{_.part_text}</Typography>
            </Box>
          );
        })}
      </Box>
    );
  }

  const HeaderfullWidth = {
    headerLeft:
      documentMasterState.documentMaster !== undefined &&
      documentMasterState.documentMaster !== null &&
      documentMasterState.documentMaster.header_selection_l === "freeText" &&
      (documentMasterState.documentMaster.header_selection_c == "" ||
        documentMasterState.documentMaster.header_selection_c == "none") &&
      (documentMasterState.documentMaster.header_selection_r == "" ||
        documentMasterState.documentMaster.header_selection_r == "none"),
    headerCenter:
      documentMasterState.documentMaster !== undefined &&
      documentMasterState.documentMaster !== null &&
      documentMasterState.documentMaster.header_selection_c === "freeText" &&
      (documentMasterState.documentMaster.header_selection_l == "" ||
        documentMasterState.documentMaster.header_selection_l == "none") &&
      (documentMasterState.documentMaster.header_selection_r == "" ||
        documentMasterState.documentMaster.header_selection_r == "none"),
    HeaderRight:
      documentMasterState.documentMaster !== undefined &&
      documentMasterState.documentMaster !== null &&
      documentMasterState.documentMaster.header_selection_r === "freeText" &&
      (documentMasterState.documentMaster.header_selection_c == "" ||
        documentMasterState.documentMaster.header_selection_c == "none") &&
      (documentMasterState.documentMaster.header_selection_l == "" ||
        documentMasterState.documentMaster.header_selection_l == "none"),
  };

  const FooterfullWidth = {
    footerLeft:
      documentMasterState.documentMaster !== undefined &&
      documentMasterState.documentMaster !== null &&
      documentMasterState.documentMaster.footer_selection_l === "freeText" &&
      (documentMasterState.documentMaster.footer_selection_c == "" ||
        documentMasterState.documentMaster.footer_selection_c == "none") &&
      (documentMasterState.documentMaster.footer_selection_r == "" ||
        documentMasterState.documentMaster.footer_selection_r == "none"),
    footerCenter:
      documentMasterState.documentMaster !== undefined &&
      documentMasterState.documentMaster !== null &&
      documentMasterState.documentMaster.footer_selection_c === "freeText" &&
      (documentMasterState.documentMaster.footer_selection_l == "" ||
        documentMasterState.documentMaster.footer_selection_l == "none") &&
      (documentMasterState.documentMaster.footer_selection_r == "" ||
        documentMasterState.documentMaster.footer_selection_r == "none"),
    footerRight:
      documentMasterState.documentMaster !== undefined &&
      documentMasterState.documentMaster !== null &&
      documentMasterState.documentMaster.footer_selection_r === "freeText" &&
      (documentMasterState.documentMaster.footer_selection_c == "" ||
        documentMasterState.documentMaster.footer_selection_c == "none") &&
      (documentMasterState.documentMaster.footer_selection_l == "" ||
        documentMasterState.documentMaster.footer_selection_l == "none"),
  };

  return (
    <Box>
      {!isUserFromShareLink() && <AppBar></AppBar>}

      {!isUserFromShareLink() && (
        <Box
          sx={{
            px: 3,
            // position: "fixed",
            // top: 70,
            // zIndex: 1000 // Ensures it's above other content
          }}
        >
          <EditorHeaderMenu
            organizationId={
              auth?.user?.default_organization_id
                ? auth?.user?.default_organization_id
                : documentMasterState.documentMaster!.__organization_id
            }
            workspaceId={
              auth?.user?.default_workspace_id
                ? auth?.user?.default_workspace_id
                : documentTemplate!.workspace_id
            }
            documentTemplate={documentTemplate}
          ></EditorHeaderMenu>
        </Box>
      )}

      {/* Big page view */}
      <Box
        sx={{
          bgcolor: grey[400],
          px: 5,
          display: "flex",
          flexDirection: "column",
          height: isUserFromShareLink() ? "100vh" : "86vh",
          overflow: "hidden",
          overflowY: "scroll",
        }}
      >
        {/* Centered box for page */}

        <Box
          ref={drop}
          sx={{
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          {/* The white paper */}
          {/* <Paper elevation={3} sx={{ my: 1, width: "900px" }}> */}
          <Paper
            elevation={3}
            sx={{
              my: 1,
              borderRadius: 0,

              height: "auto",
              width: pageOrientation === "portrait" ? "725px" : "100%",
              backgroundColor: "#" + pageColor,
              paddingLeft: (marginLeft * 2.5) / 2.2 + "px !important",
              paddingRight: (marginRight * 2.5) / 2.2 + "px !important",
              paddingTop: (marginTop * 2.5) / 2.2 + "px !important",

              marginBottom: (marginBottom * 2.5) / 2.2 + "px !important",
              paddingBottom: (marginBottom * 2.5) / 2.2 + "px !important",
            }}
          >
            {/* Header on Editor Screen  */}

            {documentMasterState.documentMaster !== undefined &&
              documentMasterState.documentMaster !== null &&
              documentMasterState.documentMaster.is_header === true && (
                <Grid
                  container
                  spacing={1}
                  justifyItems="center"
                  alignItems="flex-end"
                  paddingLeft={3}
                  paddingRight={3}
                >
                  <Grid
                    item
                    xs={HeaderfullWidth.headerLeft ? 12 : 4}
                    justifyContent="flex-start"
                    sx={{ padding: 0, margin: 0 }}
                  >
                    <EditorHeaderFooter
                      title="Header Left"
                      type="Header"
                      font={
                        documentMasterState.documentMaster.header_font_style_l
                      }
                      fontSize={documentMasterState.documentMaster.header_font_size_l.toString()}
                      fontStyle={JSON.parse(
                        documentMasterState.documentMaster.header_styles_l
                      )}
                      fontColor={
                        documentMasterState.documentMaster.header_styles_l_color
                      }
                      freeText={
                        documentMasterState.documentMaster.header_free_text_l
                      }
                      selectionChoice={
                        documentMasterState.documentMaster.header_selection_l
                      }
                      pageNumberStyle={
                        documentMasterState.documentMaster.page_number_style
                      }
                      imageID={
                        documentMasterState.documentMaster.header_image_l
                      }
                      pageStyle={
                        documentMasterState.documentMaster.header_pageno_l
                      }
                      position={"left"}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={HeaderfullWidth.headerCenter ? 12 : 4}
                    justifyContent="center"
                    sx={{ padding: 0, margin: 0 }}
                  >
                    <EditorHeaderFooter
                      title="Header Center"
                      type="Header"
                      font={
                        documentMasterState.documentMaster.header_font_style_c
                      }
                      fontSize={documentMasterState.documentMaster.header_font_size_c.toString()}
                      fontStyle={JSON.parse(
                        documentMasterState.documentMaster.header_styles_c
                      )}
                      fontColor={
                        documentMasterState.documentMaster.header_styles_c_color
                      }
                      freeText={
                        documentMasterState.documentMaster.header_free_text_c
                      }
                      selectionChoice={
                        documentMasterState.documentMaster.header_selection_c
                      }
                      pageNumberStyle={
                        documentMasterState.documentMaster.page_number_style
                      }
                      imageID={
                        documentMasterState.documentMaster.header_image_c
                      }
                      pageStyle={
                        documentMasterState.documentMaster.header_pageno_c
                      }
                      position={"center"}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={HeaderfullWidth.HeaderRight ? 12 : 4}
                    alignItems="flex-end"
                    sx={{ padding: 0, margin: 0 }}
                  >
                    <EditorHeaderFooter
                      title="Header right"
                      type="Header"
                      font={
                        documentMasterState.documentMaster.header_font_style_r
                      }
                      fontSize={documentMasterState.documentMaster.header_font_size_r.toString()}
                      fontStyle={JSON.parse(
                        documentMasterState.documentMaster.header_styles_r
                      )}
                      fontColor={
                        documentMasterState.documentMaster.header_styles_r_color
                      }
                      freeText={
                        documentMasterState.documentMaster.header_free_text_r
                      }
                      selectionChoice={
                        documentMasterState.documentMaster.header_selection_r
                      }
                      pageNumberStyle={
                        documentMasterState.documentMaster.page_number_style
                      }
                      imageID={
                        documentMasterState.documentMaster.header_image_r
                      }
                      pageStyle={
                        documentMasterState.documentMaster.header_pageno_r
                      }
                      position={"right"}
                    />
                  </Grid>
                </Grid>
              )}

            <div
              key={documentTemplate.id}
              onDragOver={(event) => {
                // Prevent the default behavior to allow the onDragLeave event to work properly
                event.preventDefault();
                setDroppedOnBoxIdLast(99);
              }}
              onDragEnter={(e) => {
                setIsInside(true);
              }}
              onDragLeave={() => {
                setIsInside(false);
                setTimeout(() => {
                  if (!isInside) {
                    setDroppedOnBoxIdLast(1);
                    setDroppedOnBoxId(0);
                  }
                }, 0);
              }}
              data-section-id={999}
            >
              {documentMasterState.documentMaster !== undefined &&
                documentMasterState.documentMaster !== null &&
                documentMasterState.documentMaster.header_line === true &&
                documentMasterState.documentMaster.is_header === true && (
                  <Box
                    paddingBottom={1}
                    paddingTop={0}
                    paddingLeft={3}
                    paddingRight={3}
                    margin={0}
                  >
                    <Divider
                      sx={{ borderWidth: "0.1px", borderColor: "black" }}
                    />
                  </Box>
                )}

              {/* The sections, sorted already by component_order */}
              {documentTemplate!
                .sections!.filter(
                  (__: any) =>
                    __.pending_deleted_at === null || approvalsEnabled
                )
                .map((section: DocumentSection, index: number) => (
                  <div
                    key={section.id}
                    onDragOver={(event) => {
                      event.preventDefault();
                      if (droppedOnBoxId !== section.id) {
                        droppedSectionHandler(event);
                      }
                      setBoxBackground(true);
                    }}
                    onDragLeave={() => {
                      // setDroppedOnBoxId(0);
                      setBoxBackground(false);
                    }}
                    data-section-id={section.id}
                  >
                    {isOver &&
                    droppedOnBoxIdR === section.id &&
                    droppedOnBoxId === section.id ? (
                      <Box>
                        <Typography variant="h1">{<Skeleton />}</Typography>
                      </Box>
                    ) : null}
                    {!isUserFromShareLink() &&
                    selectedComponents.section?.id === section.id &&
                    selectedComponents.section?.heading !=
                      TABLE_OF_CONTENTS_SECTION &&
                    selectedComponents.section?.heading != "List of Tables" &&
                    !approvalsEnabled &&
                    selectedComponents.section?.heading != "List of Figures" ? (
                      <Divider>
                        <Button
                          onClick={() => {
                            insertSection();
                          }}
                          onMouseDown={(e) => e.preventDefault()}
                          startIcon={<AddCircleIcon fontSize="large" />}
                          size="large"
                        >
                          Insert New Section
                        </Button>
                        <Button
                          onClick={() => {
                            setIsLibraryContentFloatOpen(true);
                          }}
                          onMouseDown={(e) => e.preventDefault()}
                          startIcon={<AddCircleIcon fontSize="large" />}
                          size="large"
                        >
                          Insert Library Section
                        </Button>
                      </Divider>
                    ) : null}
                    <SectionEditor
                      key={section.id}
                      organizationId={
                        auth?.user?.default_organization_id
                          ? auth?.user?.default_organization_id
                          : documentMasterState.documentMaster!
                              .__organization_id
                      }
                      workspaceId={
                        auth?.user?.default_workspace_id
                          ? auth?.user?.default_workspace_id
                          : documentTemplate!.workspace_id
                      }
                      documentTemplate={documentTemplate}
                      section={section}
                      viewPreferences={viewPreferences}
                      isSelected={selectedComponents.section?.id === section.id}
                    />
                  </div>
                ))}
            </div>

            {isOver &&
            (lastSection === null ||
              droppedOnBoxIdR === 55 ||
              (!isInside && droppedOnBoxId === 0) ||
              (droppedOnBoxIdLast === 1 &&
                droppedOnBoxId === 0 &&
                lastSection === droppedOnBoxIdR)) ? (
              <Box>
                <Typography variant="h1">{<Skeleton />}</Typography>
              </Box>
            ) : null}

            {!isUserFromShareLink() && (
              <SectionAdder
                organizationId={
                  auth?.user?.default_organization_id
                    ? auth?.user?.default_organization_id
                    : documentMasterState.documentMaster!.__organization_id
                }
                workspaceId={
                  auth?.user?.default_workspace_id
                    ? auth?.user?.default_workspace_id
                    : documentTemplate!.workspace_id
                }
                documentTemplate={documentTemplate}
              ></SectionAdder>
            )}

            {documentMasterState.documentMaster !== undefined &&
              documentMasterState.documentMaster !== null &&
              documentMasterState.documentMaster.footer_line === true &&
              documentMasterState.documentMaster.is_footer === true && (
                <Box
                  paddingBottom={0}
                  paddingTop={2}
                  paddingLeft={3}
                  paddingRight={3}
                >
                  <Divider
                    sx={{ borderWidth: "0.1px", borderColor: "black" }}
                  />
                </Box>
              )}
            {documentMasterState.documentMaster !== undefined &&
              documentMasterState.documentMaster !== null &&
              documentMasterState.documentMaster.is_footer === true && (
                <Grid
                  container
                  spacing={1}
                  justifyItems="center"
                  paddingLeft={3}
                  paddingRight={3}
                  alignItems="flex-end"
                >
                  <Grid
                    item
                    xs={FooterfullWidth.footerLeft ? 12 : 4}
                    justifyContent="flex-start"
                    sx={{ padding: 0, margin: 0 }}
                  >
                    <EditorHeaderFooter
                      title="Footer Left"
                      type="Footer"
                      font={
                        documentMasterState.documentMaster.footer_font_style_l
                      }
                      fontSize={documentMasterState.documentMaster.footer_font_size_l.toString()}
                      fontStyle={JSON.parse(
                        documentMasterState.documentMaster.footer_styles_l
                      )}
                      fontColor={
                        documentMasterState.documentMaster.footer_styles_l_color
                      }
                      freeText={
                        documentMasterState.documentMaster.footer_free_text_l
                      }
                      selectionChoice={
                        documentMasterState.documentMaster.footer_selection_l
                      }
                      pageNumberStyle={
                        documentMasterState.documentMaster.page_number_style
                      }
                      imageID={
                        documentMasterState.documentMaster.footer_image_l
                      }
                      pageStyle={
                        documentMasterState.documentMaster.footer_pageno_l
                      }
                      position={"left"}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={FooterfullWidth.footerCenter ? 12 : 4}
                    justifyContent="center"
                    sx={{ padding: 0, margin: 0 }}
                  >
                    <EditorHeaderFooter
                      title="Header Center"
                      type="Header"
                      font={
                        documentMasterState.documentMaster.footer_font_style_c
                      }
                      fontSize={documentMasterState.documentMaster.footer_font_size_c.toString()}
                      fontStyle={JSON.parse(
                        documentMasterState.documentMaster.footer_styles_c
                      )}
                      fontColor={
                        documentMasterState.documentMaster.footer_styles_c_color
                      }
                      freeText={
                        documentMasterState.documentMaster.footer_free_text_c
                      }
                      selectionChoice={
                        documentMasterState.documentMaster.footer_selection_c
                      }
                      pageNumberStyle={
                        documentMasterState.documentMaster.page_number_style
                      }
                      imageID={
                        documentMasterState.documentMaster.footer_image_c
                      }
                      pageStyle={
                        documentMasterState.documentMaster.footer_pageno_c
                      }
                      position={"center"}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={FooterfullWidth.footerRight ? 12 : 4}
                    alignItems="flex-end"
                    sx={{ padding: 0, margin: 0 }}
                  >
                    <EditorHeaderFooter
                      title="Header right"
                      type="Header"
                      font={
                        documentMasterState.documentMaster.footer_font_style_r
                      }
                      fontSize={documentMasterState.documentMaster.footer_font_size_r.toString()}
                      fontStyle={JSON.parse(
                        documentMasterState.documentMaster.footer_styles_r
                      )}
                      fontColor={
                        documentMasterState.documentMaster.footer_styles_r_color
                      }
                      freeText={
                        documentMasterState.documentMaster.footer_free_text_r
                      }
                      selectionChoice={
                        documentMasterState.documentMaster.footer_selection_r
                      }
                      pageNumberStyle={
                        documentMasterState.documentMaster.page_number_style
                      }
                      imageID={
                        documentMasterState.documentMaster.footer_image_r
                      }
                      pageStyle={
                        documentMasterState.documentMaster.footer_pageno_r
                      }
                      position={"right"}
                    />
                  </Grid>
                </Grid>
              )}
          </Paper>
        </Box>
      </Box>

      {isLibraryContentFloatOpen ? (
        <LibraryContentConfigForm
          closeDialogHandler={() => setIsLibraryContentFloatOpen(false)}
          organizationId={
            auth?.user?.default_organization_id
              ? auth?.user?.default_organization_id
              : documentMasterState.documentMaster!.__organization_id
          }
          workspaceId={
            auth?.user?.default_workspace_id
              ? auth?.user?.default_workspace_id
              : documentTemplate!.workspace_id
          }
          viewPreferences={viewPreferences}
          sectionBelowInsert={selectedComponents.section!}
        />
      ) : null}

      {isLibraryContentOpen ? (
        <LibraryContentConfigForm
          closeDialogHandler={() => setIsLibraryContentOpen(false)}
          organizationId={
            auth?.user?.default_organization_id
              ? auth?.user?.default_organization_id
              : documentMasterState.documentMaster!.__organization_id
          }
          workspaceId={
            auth?.user?.default_workspace_id
              ? auth?.user?.default_workspace_id
              : documentTemplate!.workspace_id
          }
          viewPreferences={viewPreferences}
        />
      ) : null}

      {isTitlePageOpen ? (
        <TitlePage
          organizationId={
            auth?.user?.default_organization_id
              ? auth?.user?.default_organization_id
              : documentMasterState.documentMaster!.__organization_id
          }
          workspaceId={
            auth?.user?.default_workspace_id
              ? auth?.user?.default_workspace_id
              : documentTemplate!.workspace_id
          }
          documentTemplate={documentTemplate}
          closeDialogHandler={() => setIsTitlePageOpen(false)}
          droppedOnBoxId={droppedOnBoxId}
        ></TitlePage>
      ) : null}

      {
        // selectLibraryOpen.previewOpen
        documentPreview != null ? (
          <PreviewModal
            setParentOpenHandler={handlePreviewClose}
            preview={documentPreview!}
          ></PreviewModal>
        ) : null
      }
      <Dialog
        open={dialogOpenForTablesAndFigure}
        onClose={handleDialogClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Choose List Category</DialogTitle>
        {listChoice === "List of Figures" && isListOfFigureExist && (
          <Alert severity="error">
            List of Figures already exists in the Template.
          </Alert>
        )}
        {listChoice === "List of Tables" && isListOfTableExist && (
          <Alert severity="error">
            List of Tables already exists in the Template.
          </Alert>
        )}
        <Box sx={{ p: 3 }}>
          <TextField
            label="Select Category"
            value={listChoice}
            onChange={(e) => {
              setListChoice(e.target.value);
            }}
            fullWidth
            select
            variant="outlined"
          >
            {list.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <DialogActions>
          <Button onClick={() => handleDialogClose()}>Cancel</Button>
          <Button
            disabled={
              (listChoice === "List of Figures" && isListOfFigureExist) ||
              (listChoice === "List of Tables" && isListOfTableExist)
            }
            onClick={createSectionWithElement}
            variant="contained"
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
