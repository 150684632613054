import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { DocumentTemplateSummary } from "../../../../types/DocumentTemplate";
import { RootState } from "../../../../app/store";

export interface WorkspaceSummaryState {
  workspaceSummaries: DocumentTemplateSummary[] | undefined;
  status: "idle" | "loading" | "failed";
  error: string | undefined;
  searchedElement?:any | undefined;
}

const initialState: WorkspaceSummaryState = {
  workspaceSummaries: undefined,
  status: "idle",
  error: undefined,
  searchedElement:undefined,
};

export const workspaceSummarySlice = createSlice({
  name: "workspace_summary",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setStatus: (
      state,
      action: PayloadAction<{
        status: "idle" | "loading" | "failed";
        error: string | undefined;
        searchedElement?:any;
      }>
    ) => {
      state.status = action.payload.status;
      state.error = action.payload.error;
    },
    setError: (state, action: PayloadAction<string | undefined>) => {
      state.error = action.payload;
    },
    setWorkspaceSummary: (state, action: PayloadAction<any>) => {
      state.workspaceSummaries = action.payload;
    },

    setWorkspaceDocumentSummary: (state, action: PayloadAction<{
      documentID:number,
     documentSummary:any[]
    }>) => {
      const selectedTemplate = state.workspaceSummaries?.find((e) => e.id === action.payload.documentID);
      // Update the document_section_summaries of the found template
      if (selectedTemplate) {
        selectedTemplate.document_section_summaries = action.payload.documentSummary;
      }
    },
    
    SetsearchedElement: (state, action: PayloadAction<any>) => {
      state.searchedElement=action.payload;
    },

  },
});

export const {
  setStatus,
  setError,
  setWorkspaceSummary,
  SetsearchedElement,
  setWorkspaceDocumentSummary,
} = workspaceSummarySlice.actions;

//Selectors
export const selectWorkspaceSummaryState = (state: RootState) =>
  state.workspaceSummary;

export default workspaceSummarySlice.reducer;
