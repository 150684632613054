import React, { useState, useEffect, useRef } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  IconButton,
} from "@mui/material";

import { Tab as TabIcon, Close as CloseIcon } from "@mui/icons-material";
import {
  DocumentElement,
  DocumentSection,
  DocumentSectionSummary,
  DocumentSubsection,
} from "../../../../../types/DocumentTemplate";
import {
  CellType,
  GridSheet,
  SheetProvider,
  createTableRef,
} from "@gridsheet/react-core";
import "./spreadsheet.css";

import useActions, { useAppDispatch } from "src/app/hooks";
import {
  setNeedsApproval,
  setNewContentAdded,
  setSelectedComponent,
  updateDocumentElementLocal,
} from "src/features/editor/editorSlice";
import Toolbar, { CellStyle } from "./Toolbar";

import {
  convertToInitialCells,
  createDimensionsMatrixFromMatrix,
  initialSelect,
  iterateSelectedRange,
  SelectedCell,
  separateContentAndFormatting,
} from "./helper";
import { handleUpdateCell } from "../HelperFuctions/TableElementHelperFunctions";
import getSpreadsheetAddress from "src/services/getSpreadSheetAdresses";
import { apiUpdateDocumentElement } from "src/services/documentTemplatesAPI";

interface IEditContentProps {
  closeHandle: () => void;
  _columns: number;
  _rows: number;
  formatting: any[][];
  content: string[][];
  element: DocumentElement | null;
  parentSubsection: DocumentSubsection | null;
  parentSection: DocumentSection | DocumentSectionSummary;
  open: boolean;
  showonlyStripes: boolean;
  setShowOnlyStripes: any;
  showOnlyBorder: boolean;
  setShowOnlyBorder: any;
  setIsDialogOpen: any;
  setfromEdit: any;
  tableborderColor: any;
  tableborderStyle: any;
  borderThickness: any;
  setFromSettings: any;
  fromSettings: any;
  setIsEditConfigOpen: any;
  setIsUpdateContent: any;
  isUpdateContent: any;
  handleUpdateFormatting: any;
  documentTemplate: any;
  organizationId: any;
  setCustomTableDetails: any;
  customTableDetails: any;
  colType: any;
  setcolType: any;
  rowType: any;
  setRowType: any;
  rowSize: any;
  setRowSize: any;
  setIsApplied: any;
  setisContentChanges: any;
  setRows: any;
  setCol: any;
}

const GridSheetModal: React.FC<IEditContentProps> = ({
  closeHandle,
  open,
  content,
  formatting,
  element,
  parentSubsection,
  parentSection,
  showonlyStripes,
  setShowOnlyStripes,
  showOnlyBorder,
  setShowOnlyBorder,
  setIsDialogOpen,
  setfromEdit,
  tableborderColor,
  tableborderStyle,
  borderThickness,
  setFromSettings,
  fromSettings,
  setIsEditConfigOpen,
  setIsUpdateContent,
  isUpdateContent,
  handleUpdateFormatting,
  documentTemplate,
  organizationId,
  setCustomTableDetails,
  customTableDetails,
  colType,
  setcolType,
  rowType,
  setRowType,
  rowSize,
  setRowSize,
  setIsApplied,
  setisContentChanges,
  setRows,
  setCol,
}) => {
  const actions = useActions();
  const dispatch = useAppDispatch();
  const tableRef = createTableRef();
  const [updateCount, setUpdateCount] = useState(0);

  const [selectedCell, setSelectedCell] = useState(initialSelect);
  const [initialCells, setInitialCells] = useState(
    convertToInitialCells(
      content,
      formatting,
      tableborderColor,
      tableborderStyle,
      borderThickness
    )
  );


  // console.log("CUSTOM TABLE DETAILS GRIDSHEET", customTableDetails);

  // console.log("TABLE CONTENT", content);
  // console.log(tableborderColor, tableborderStyle);

  // console.log(formatting);

  useEffect(() => {
    setInitialCells(
      convertToInitialCells(
        content,
        formatting,
        tableborderColor,
        tableborderStyle,
        customTableDetails.fontFamily
      )
    );
    const timeoutId = setTimeout(() => {
      updateTableSize(formatting);
    }, 50);
    return () => clearTimeout(timeoutId);
  }, [content, formatting]);

  const handleCellUpdate = (
    alignment: string,
    textColor: string,
    backgroundColor: string,
    fontSize: string,
    decorators: {
      fontWeight?: string;
      fontStyle?: string;
      textDecoration?: string;
    },
    textAlign: string,
    content: string
  ) => {
    // check if the range is selected
    if (
      selectedCell.points.selectingFrom.x != -1 &&
      selectedCell.points.selectingFrom.y != -1
    ) {
      iterateSelectedRange(selectedCell.points, (x, y) => {
        updateCellFormatting(
          y,
          x,
          {
            alignItems: alignment,
            justifyContent: alignment,
          },
          decorators,
          backgroundColor,
          textColor,
          fontSize,
          textAlign,
          content
        );
      });
    } else {
      // if single cell is selected
      updateCellFormatting(
        selectedCell.row,
        selectedCell.col,
        {
          alignItems: alignment,
          justifyContent: alignment,
        },
        decorators,
        backgroundColor,
        textColor,
        fontSize,
        textAlign,
        content
      );
    }
    setUpdateCount((prevCount) => prevCount + 1);
  };

  // console.log(selectedCell.style);

  const updateCellFormatting = (
    rowIndex: number,
    colIndex: number,
    newAlignment: {
      alignItems?: string;
      justifyContent?: string;
    },
    decorators: {
      fontWeight?: string;
      fontStyle?: string;
      textDecoration?: string;
    },
    newBackgroundColor: string,
    newTextColor: string,
    fontSize: string,
    textAlign: string,
    content: string
  ) => {
    try {
      if (tableRef.current) {
        const { dispatch, table } = tableRef.current;
        const tableMatrix = table.getMatrix();
        const matrixId = (table as any).idMatrix;
        //const cellId = matrixId[rowIndex][colIndex];

        const address = getSpreadsheetAddress(rowIndex, colIndex);
        // const address = (table as any).addressesById[cellId];

        // console.log(address);

        const cell: CellType | null = tableMatrix[rowIndex - 1]?.[colIndex - 1];
        // console.log(cell);
        if (cell) {
          if (content != "") {
            if (cell.value == null || cell.value.trim() === "") {
              cell.value = content.trim();
            } else {
              cell.value = cell.value + " " + content.trim();
            }
          }

          if (
            newAlignment.justifyContent &&
            newAlignment.justifyContent != ""
          ) {
            cell.justifyContent = newAlignment.justifyContent;
            cell.alignItems = newAlignment.justifyContent;
          }

          const newStyle = { ...cell.style };

          if (textAlign) {
            (newStyle.textAlign as string) = `${textAlign}`;
          }

          if (fontSize !== undefined && fontSize !== "") {
            newStyle.fontSize = `${fontSize}pt`;
          }
          if (decorators.fontStyle !== undefined) {
            newStyle.fontStyle = decorators.fontStyle;
          }
          if (decorators.fontWeight !== undefined) {
            newStyle.fontWeight = decorators.fontWeight;
          }
          if (decorators.textDecoration !== undefined) {
            newStyle.textDecoration = decorators.textDecoration;
          }
          // console.log("NEW BACKCOLOR");
          if (newBackgroundColor !== "") {
            newStyle.backgroundColor = newBackgroundColor;
          }

          if (newTextColor !== "") {
            newStyle.color = newTextColor;
          }
          cell.style = newStyle;

          // console.log(cell.style);
          dispatch(
            table.update({
              diff: { [address]: cell },
            })
          );
        }
      }

      setUpdateCount((prevCount) => prevCount + 1);
    } catch (error) {
      console.error("Error updating cell formatting:", error);
    }
  };

  const updateTableSize = (formatting: any[][]) => {
    const tableRefCurrent = tableRef.current;
    if (!tableRefCurrent) return;

    const { dispatch, table } = tableRefCurrent;
    const { idMatrix, addressesById } = table as any;
    // Update columns width
    const topRow = formatting[0];
    topRow.forEach((cell, index) => {
      const cellId = idMatrix[0][index + 1];
      const currentCell = table.getById(cellId);
      const address = addressesById[cellId];

      if (currentCell) {
        currentCell.width = parseInt(cell.style.editorColumnWidth || "180", 10);

        dispatch(
          table.update({
            diff: { [address]: currentCell },
          })
        );
      }
    });

    // Update rows height
    formatting.forEach((row, rowIndex) => {
      const firstCell = row[0];
      const cellId = idMatrix[rowIndex + 1][0];
      const currentCell = table.getById(cellId);
      const address = addressesById[cellId];

      if (currentCell) {
        currentCell.height = parseInt(
          firstCell.style.editorRowHeight || "30",
          10
        );

        dispatch(
          table.update({
            diff: { [address]: currentCell },
          })
        );
      }
    });
  };

  const handleSelectionChange = (table: any, points: any) => {
    if (points) {
      const { pointing } = points;
      const selected = tableRef.current?.table.getByPoint(pointing);
      // console.log("Ddd", selected?.style?.backgroundColor);
      const style: CellStyle = {
        alignment: selected?.justifyContent || initialSelect.style.alignment,
        fontSize:
          typeof selected?.style?.fontSize === "string"
            ? selected.style.fontSize.slice(0, -2)
            : initialSelect.style.fontSize, // Extracts the font size without 'pt'
        backgroundColor:
          selected?.style?.backgroundColor ||
          initialSelect.style.backgroundColor, // Removes the '#' from the color
        color: selected?.style?.color || initialSelect.style.color, // Removes the '#' from the color
        textDecoration:
          selected?.style?.textDecoration || initialSelect.style.textDecoration,
        fontWeight:
          selected?.style?.fontWeight || initialSelect.style.fontWeight,
        fontStyle: selected?.style?.fontStyle || initialSelect.style.fontStyle,
      };

      setSelectedCell({ row: pointing.y, col: pointing.x, style, points });
    }
  };

  // console.log("SC", selectedCell);

  const handleCloseDialog = () => {
    closeHandle();
    setisContentChanges(false);
    //handleSave();
  };

  const onOpenSettings = (): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      try {
        setIsDialogOpen(true);
        setFromSettings(true);
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };

  const handleSave = (): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      try {
        const tableMatrix = tableRef.current?.table.getMatrix();
        const dimensionsMatrix = tableRef.current?.table.getCols();

        if (tableMatrix != undefined) {
          const dimensions = createDimensionsMatrixFromMatrix(dimensionsMatrix);

          const { content, updatdformatting } = separateContentAndFormatting(
            tableMatrix,
            dimensions,
            formatting
          );
          updateContent(content, updatdformatting);
        }

        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };

  useEffect(() => {
    if (tableRef) {
      const matrix = tableRef?.current?.table.getMatrix();
      setRows(matrix?.length);
      setCol(matrix?.[0]?.length);
    }
  }, [tableRef?.current?.table.getMatrix()]);

  const updateContent = async (
    originalTable: string[][],
    tableFormatting: any[][]
  ) => {
    const newTableArray: Array<string> = [];

    originalTable.forEach((_) => {
      newTableArray.push(_.join("!TC"));
    });
    const newTableString = newTableArray.join("!TR");
    const updatedDocumentElement = { ...element };
    updatedDocumentElement.content = newTableString;
    const contentFormatObject = JSON.parse(element!.content_format);
    contentFormatObject.rows = originalTable.length;
    contentFormatObject.columns = originalTable[0].length;
    updatedDocumentElement.content_format = JSON.stringify(contentFormatObject);
    updatedDocumentElement.content = newTableString;
    updatedDocumentElement.formatting = JSON.stringify(tableFormatting);

    dispatch(
      setSelectedComponent({
        selectedSection: parentSection as DocumentSection,
        selectedSubsection: parentSubsection!,
        selectedComponent: updatedDocumentElement as DocumentElement,
      })
    );

    dispatch(
      updateDocumentElementLocal({
        section: parentSection as DocumentSection,
        subSection: parentSubsection!,
        updatedDocumentElement: updatedDocumentElement as DocumentElement,
      })
    );

    const response = await apiUpdateDocumentElement(
      organizationId,
      0,
      documentTemplate,
      parentSection as DocumentSection,
      parentSubsection!,
      updatedDocumentElement as DocumentElement
    );

    dispatch(setNewContentAdded(response.data.new_content_added));
    dispatch(setNeedsApproval(response.data.needs_approval));

    // actions.updateDocumentElement({
    //   section: parentSection as DocumentSection,
    //   subSection: parentSubsection!,
    //   documentElement: updatedDocumentElement as DocumentElement,
    //   isTextEmpty: true,
    // });
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        closeHandle();
        setIsApplied(false);
        setisContentChanges(false);
        // handleSave();
      }}
      maxWidth="xl"
      fullWidth
    >
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h5" flexGrow={1}>
            Edit Table Content
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => {
              closeHandle();
              setIsApplied(false);
              setisContentChanges(false);
              //handleSave();
            }}
            sx={{ marginLeft: "auto" }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 1000,
          backgroundColor: "#fff",
          width: "100%",
          paddingLeft: 2,
        }}
      >
        <Toolbar
          style={selectedCell.style}
          onAlignmentChange={(alignment) => {
            handleCellUpdate(alignment, "", "", "", {}, alignment, "");
          }}
          onTextColorChange={(color) => {
            handleCellUpdate("", color, "", "", {}, "", "");
          }}
          onBackgroundColorChange={(color) => {
            handleCellUpdate("", "", color, "", {}, "", "");
          }}
          onFontSizeChange={(fontSize) => {
            handleCellUpdate("", "", "", fontSize, {}, "", "");
          }}
          onFormattingChange={(result) => {
            handleCellUpdate("", "", "", "", result, "", "");
          }}
          appendContent={(text) => {
            handleCellUpdate("", "", "", "", {}, "", text);
          }}
          onOpenSettings={() => {
            onOpenSettings().then(() => {
              handleSave();
            });
          }}
          selectedCell={selectedCell}
          formatting={formatting}
          handleUpdateFormatting={handleUpdateFormatting}
          OnOpenColWidth={handleSave}
          setCustomTableDetails={setCustomTableDetails}
          customTableDetails={customTableDetails}
          setColType={setcolType}
          colType={colType}
          rowType={rowType}
          setRowType={setRowType}
          rowSize={rowSize}
          setRowSize={setRowSize}
          setIsApplied={setIsApplied}
          setisContentChanges={setisContentChanges}
        />
      </Box>
      <DialogContent>
        <Box key={updateCount}></Box>
        <SheetProvider>
          <GridSheet
            initialCells={initialCells}
            tableRef={tableRef}
            options={{
              showFormulaBar: true,
              minNumCols: 1,
              minNumRows: 1,
              sheetWidth: 5000,
              sheetHeight: 10000,
              onSelect: handleSelectionChange,
            }}
            className="some-class"
            style={{
              maxWidth: "100%",
              fontSize: "30px !important",
            }}
          />
        </SheetProvider>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleCloseDialog();
            handleSave();
          }}
          variant="contained"
        >
          Update Content
        </Button>
        {/* <Button
          onClick={handleSave}
          variant="contained"
          color="primary"
          autoFocus
        >
          update content
        </Button> */}
      </DialogActions>
    </Dialog>
  );
};

export default GridSheetModal;
