import { attachedSubsectionElement } from "../features/editor/document/utils";
import { MAX_HEADING_SUMMARY_LENGTH } from "../globals";
import { DocumentSubsection, DocumentTemplate } from "../types/DocumentTemplate";
// import { selectEditingDocumentTemplate } from "../features/editor/editorSlice";
// import { useAppSelector } from "../app/hooks";

// const documentTemplate = useAppSelector(selectEditingDocumentTemplate);

export const truncateHeading = (str: string, n: number = MAX_HEADING_SUMMARY_LENGTH) => {
  return str.length > n ? str.substr(0, n - 1) + "..." : str;
};


export const findParentSubHeadingLevel = (documentTemplate:DocumentTemplate, sectionID: number, subsectionID: any ) => {
  let parentLevel = 1;
  let parentLevelFound = false;

  try {
    documentTemplate.sections?.forEach((section) => {
      if (section.id === sectionID && !parentLevelFound) {
        section.subsections?.forEach((subSection) => {
          if (
            subSection.id !== subsectionID &&
            subSection.elements &&
            subSection.elements[0] !== undefined &&
            subSection.elements[0].content_type === "HEADING" &&
            !parentLevelFound
          ) {
            parentLevel =  attachedSubsectionElement(subSection).heading_level;
          } else if (subSection.id === subsectionID && !parentLevelFound) {
            parentLevelFound = true;
          }
        });
      }
    });
  } catch (error) {
    // Handle the exception here
    console.error("An error occurred:", error);
    // You can throw the error or return a default value if needed
    throw error;
    // parentLevel = 1; // Example default value
  }

  return parentLevel;
};


