import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { DocumentRecord, DocumentTemplate } from "../../types/DocumentTemplate";
import Box from "@mui/material/Box";
import useActions from "../../app/hooks";
import { SUB_CATEGORY } from "../../globals";

export default function DocumentRecordInfoForm(props: {
  parentCloseHandle: () => void;
  documentRecord: DocumentRecord;
}) {
  const actions = useActions();
  const [name, setName] = useState(props.documentRecord.name);
  const [purpose, setPurpose] = useState(props.documentRecord.sub_category);
  const [category, setCategory] = useState(props.documentRecord.category);
  const [nameError, setNameError] = React.useState("");
  const [categoryError, setCategoryError] = React.useState("");
  const [purposeError, setPurposeError] = React.useState("");

  function updateDocumentTemplate() {
    const newDocumentTemplate = Object.assign({}, props.documentRecord, {
      name: name,
      purpose: purpose,
      category: category,
    });
    // actions.updateDocumentTemplate(newDocumentTemplate);
    props.parentCloseHandle();
  }
  function handleNameChange(e: any) {
    if (e.target.value.trim() === "") {
      setNameError("Invalid name");
    } else setNameError("");
    setName(e.target.value);
  }
  function handlePurposeChange(e: any) {
    if (e.target.value.trim() === "") {
      setPurposeError("Invalid purpose");
    } else setPurposeError("");
    setPurpose(e.target.value);
  }

  function handleCategoryChange(e: any) {
    if (e.target.value.trim() === "") {
      setCategoryError("Invalid category");
    } else setCategoryError("");
    setCategory(e.target.value);
  }
  
  return (
    <Dialog open={true} onClose={() => props.parentCloseHandle()}>
      <DialogTitle>
        Document Record: {props.documentRecord.name}
      </DialogTitle>
      <DialogContent>
        <Box component="form" sx={{ px: 1}}>
          <TextField
         
            margin="dense"
            id="name"
            label="Name"
            value={name}
            fullWidth
            variant="standard"
            InputProps={{
              readOnly: true,
            }}
            // onChange={handleNameChange}
          />
          <TextField
            margin="dense"
            id="name"
            label="Category"
            value={category}
            fullWidth
            variant="standard"
            InputProps={{
              readOnly: true,
            }}
            // onChange={handleCategoryChange}
          />
          <TextField
            margin="dense"
            id="name"
            label={SUB_CATEGORY}
            value={purpose}
            fullWidth
            variant="standard"
            InputProps={{
              readOnly: true,
            }}
            // onChange={handlePurposeChange}
          />
    <TextField
            margin="dense"
            id="name"
            label="Template/once-off document"
            defaultValue={
              props.documentRecord.template_name
              ?props.documentRecord.template_name
              : ""
            }
            fullWidth
            variant="standard"
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            margin="dense"
            id="name"
            label="Authored by"
            defaultValue={props.documentRecord.user_email ? 
            props.documentRecord.user_email : "N/A"
            }
            fullWidth
            variant="standard"
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            margin="dense"
            id="name"
            label="Created"
            defaultValue={
                props.documentRecord.created_at
                ? new Date(props.documentRecord.created_at).toLocaleString()
                : ""
            }
            fullWidth
            variant="standard"
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            margin="dense"
            id="name"
            label="Last updated"
            defaultValue={
              props.documentRecord.updated_at
                ? new Date(props.documentRecord.updated_at).toLocaleString()
                : ""
            }
            fullWidth
            variant="standard"
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            margin="dense"
            id="name"
            label="Deletion Status"
            defaultValue={
              props.documentRecord.deleted_at
                ? new Date(props.documentRecord.deleted_at).toLocaleString()
                : "This Document has not been deleted"
            }
            fullWidth
            variant="standard"
            InputProps={{
              readOnly: true,
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.parentCloseHandle()}>Cancel</Button>
        {/* <Button
          onClick={updateDocumentTemplate}
          type="submit"
          variant="contained"
          color="primary"
          disabled={
            nameError !== "" || purposeError !== "" || categoryError !== ""
          }
        >
          Save
        </Button> */}
      </DialogActions>
    </Dialog>
  );
}
