import React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ReactMarkdown from "react-markdown";
import useActions, {
  useAppDispatch,
  useAppSelector,
} from "../../../../../app/hooks";
import { normalizeText } from "../../../document/utils";

import AutoAwesomeRoundedIcon from "@mui/icons-material/AutoAwesomeRounded";

import {
  ContentType,
  DocumentSection,
  DocumentSubsection,
} from "../../../../../types/DocumentTemplate";
import {
  selectApprovalMode,
  selectApprovalState,
  selectSelected,
} from "../../../editorSlice";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import { CollectionsBookmark } from "@mui/icons-material";
import TableOfContentsSubSections from "./TableOfContentsSubSections";
import { truncateHeading } from "../../../../../services/utils";
import { isImpactPending } from "../../right/Approval/ApprovalHelperFunctions/approvalHelper";
import { COLORS } from "../../../../shared/Constants";
import { documentMasterFormat } from "../../../document/HelperEditorFunctions";
import {
  selectDocumentMasterState,
  selectFormattingSection,
} from "../../../header/documentMasterSlice";
import { shallowEqual } from "react-redux";

export function TableOfContentsSection(props: {
  id?: any;
  section: DocumentSection;
  icon: any;
  isDragging: boolean;
  draggableProps?: any;
}) {
  const actions = useActions();
  const selected = useAppSelector(selectSelected);
  // For managing subheadings.
  const [open, setOpen] = React.useState(true);
  // is pending Avaiable
  const is_include = props.section.pending_heading ? true : false;
  const approvalsEnabled = useAppSelector(selectApprovalMode);
  const approvalState = useAppSelector(selectApprovalState);
  const _documentMasterState = useAppSelector(
    (state) => selectFormattingSection(state, props.section.id),
    shallowEqual
  );

  const documentMasterState = useAppSelector(selectDocumentMasterState);
  const isFormatMasterSettings =
    props.section.formatting && JSON.parse(props.section.formatting).isStandard;
  const h1Formatting = documentMasterFormat(_documentMasterState, "HEADING", 1);

  const hideNumbering =
    _documentMasterState.documentMaster &&
    _documentMasterState.documentMaster.numberingSetting &&
    _documentMasterState.documentMaster.numberingSetting == "NN";
  const handleClick = () => {
    const boxElement = document.querySelector(`#section-${props.section.id}`);
    if (boxElement) {
      boxElement.scrollIntoView({ behavior: "smooth" });
    }
  };
  let border = "0px solid lightgrey";
  if (props.isDragging) border = "1px dashed steelblue";
  if (props.section === selected.section) border = "1px solid orange";

  // Check if there are subheadings.

  const ApprovalpendingCount = () => {
    return (props.section.subsections || []).reduce((count, subsection) => {
      return (
        count +
        (subsection.elements || []).reduce((subCount, element) => {
          return subCount + (element.element_approvals ? 1 : 0);
        }, 0)
      );
    }, 0);
  };
  // this one is for impact
  const pendingImpactCount = () => {
    return (props.section.subsections || []).reduce((count, subsection) => {
      if (isImpactPending(approvalState, subsection.id)) {
        return count + 1;
      }
      return count;
    }, 0);
  };

  // console.log(" ApprovalpendingCount", ApprovalpendingCount (), "pendingCount()" ,pendingCount()  )

  const subheadings = props.section.subsections?.filter(
    (subsection) =>
      subsection.elements![0]?.content_type === ContentType.Heading
  );
  // Make a sublist - replace markdown heading prefix.
  const subItems =
    subheadings != null ? (
      subheadings.length === 0 ? null : (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <TableOfContentsSubSections parentSection={props.section} />
        </Collapse>
      )
    ) : null;
  // TODO: Cleanup style
  return (
    <Box>
      <Box {...props.draggableProps}>
        <ListItemButton
          id={props.id}
          sx={{
            m: "0px",
            padding: 0,
            margin: 0,
            paddingLeft: 1,
            paddingRight: 1,
            bgcolor: null,
            border: !approvalsEnabled
              ? border
              : pendingImpactCount()
              ? ` 1px solid ${COLORS.NOMIA_PINK}`
              : "white",
            boxShadow: 1,
            opacity: props.isDragging ? 0.5 : 1,
            height: "min-content",
          }}
          onClick={handleClick}
        >
          {approvalsEnabled &&
            (is_include || props.section.pending_deleted_at != null) && (
              <AutoAwesomeRoundedIcon
                sx={{
                  color:
                    props.section.pending_deleted_at != null
                      ? "red"
                      : //  "#4C33FF"
                        COLORS.NOMIA_PINK,
                }}
              />
            )}

          {/* if any pending Approval subection found */}
          {approvalsEnabled &&
            pendingImpactCount() !==
              // +ApprovalpendingCount()
              0 && (
              <div
                style={{
                  paddingLeft: "5px",
                  color: COLORS.NOMIA_PINK,
                  // color: "#00C482",
                  textShadow: "0 0 10px rgba(0,0,0,0.5)",
                  fontWeight: "bold",
                }}
              >
                {`(${pendingImpactCount()})`}
              </div>
            )}

          <ListItemText
            sx={{
              padding: 0,
              margin: 0,
              paddingLeft: 1,
              color: props.section.pending_deleted_at != null ? "red" : "black",
            }}
            primary={
              <>
                {isFormatMasterSettings ? (
                  <p
                    style={{
                      fontStyle: h1Formatting.fontStyle.includes("italic")
                        ? "italic"
                        : "normal",
                      fontWeight: h1Formatting.fontStyle.includes("bold")
                        ? "bold"
                        : "normal",
                    }}
                  >
                    {props.section.pending_heading
                      ? props.section.pending_heading.replace(/[+*_]/g, "")
                      : props.section.heading.replace(/[+*_]/g, "")}
                  </p>
                ) : (
                  <ReactMarkdown>
                    {props.section.pending_heading
                      ? props.section.pending_heading.replace(/\+\+/g, "")
                      : props.section.heading.replace(/\+\+/g, "")}
                  </ReactMarkdown>
                )}
              </>
            }
          />

          {/* Only show drop down if there are items */}
          {subItems !== null ? (
            open ? (
              <ExpandLess
                onClick={(e) => {
                  e.preventDefault();
                  setOpen(!open);
                  e.stopPropagation(); // Add this line to stop event propagation
                }}
                sx={{ zindex: 100000 }}
              />
            ) : (
              <ExpandMore
                onClick={(e) => {
                  e.preventDefault();
                  setOpen(!open);
                  e.stopPropagation(); // Add this line to stop event propagation
                }}
                sx={{ zindex: 100000 }}
              />
            )
          ) : null}
        </ListItemButton>
      </Box>
      {subItems}
    </Box>
  );
}
export default TableOfContentsSection;
