import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { FileUpload, FileUploadProps } from "../../shared/FileUpload";
import useActions, { useAppSelector } from "../../../app/hooks";
import { Alert, Autocomplete, Box, Typography } from "@mui/material";
import { SUB_CATEGORY } from "../../../globals";
import { selectAuth } from "../../auth/authSlice";
import { ExcelFileUpload } from "../../shared/ExcelFileUpload";

export default function ImportRecordForm(props: { 
    open: boolean;
    closeDialogHandler: any }) {
const auth = useAppSelector(selectAuth);
const actions = useActions();
  const [file, setFile] = React.useState<File | null>(null);
  const [name, setName] = React.useState("");
  const [category, setCategory] = React.useState("");
  const [purpose, setPurpose] = React.useState("");
  const [purposeList, setPurposeList] = React.useState([]);
  const [categoryList, setCategoryList] = React.useState([]);
  const [invalidFileType, setInvalidFileType] = React.useState(false);


  const fileUploadProp: FileUploadProps = {
    accept: "application/pdf",
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files !== null && event.target?.files?.length > 0) {
        const droppedFile = event.target.files[0];
        if (droppedFile && droppedFile.name.endsWith(".pdf")) {
            setFile(droppedFile);
            setInvalidFileType(false);
        } else {
          setInvalidFileType(true);
          setFile(null);
        }
      }
    },
    onDrop: (event: React.DragEvent<HTMLElement>) => {
        const droppedFile = event.dataTransfer.files[0];
        if (droppedFile && droppedFile.name.endsWith(".pdf")) {
          setFile(droppedFile);
          setInvalidFileType(false);
        } else {
          setFile(null);
        }
    },
  };

  const handleNameChange = (e: any) => {
    setName(e.target.value);
  };

  const handleCategoryOptionChange = (event: any, newValue: any) => {
    setCategory(newValue);
  };

  function handleCategoryChange(e: any) {
    setCategory(e.target.value);
  }

  const handlePurposeChange = (e: any) => {
    setPurpose(e.target.value);
  };

  const handleOptionChangePurpose = (event: any, newValue: any) => {
    setPurpose(newValue);
  };

  return (
    <Dialog
    fullWidth
      open={true}
      onClose={(e) => {
        props.closeDialogHandler();
      }}
    >
      <DialogTitle>Import Document Record</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Name"
          fullWidth
          variant="standard"
          required
          onChange={handleNameChange}
          error={name === ""}
          value={name}
          helperText={name === "" ? "Name cannot be empty" : null}
        />

        <Autocomplete
          id="auto"
          freeSolo
          autoSelect
          options={categoryList}
          onChange={handleCategoryOptionChange}
          renderInput={(params) => (
            <TextField
              {...params}
              margin="dense"
              id="category"
              label="Category"
              fullWidth
              required
              onChange={handleCategoryChange}
              error={category === ""}
              variant="standard"
            />
          )}
        />

        <Autocomplete
          id="autoc"
          freeSolo
          autoSelect
          options={purposeList}
          onChange={handleOptionChangePurpose}
          renderInput={(params) => (
            <TextField
              {...params}
              margin="dense"
              id="purpose"
              label={SUB_CATEGORY}
              fullWidth
              required
              onChange={handlePurposeChange}
              error={purpose === ""}
              // error={purposeError!==""}
              variant="standard"
            />
          )}
        />

<Box sx={{padding:1}}>
        <ExcelFileUpload {...fileUploadProp} />

        </Box>

        {invalidFileType ? (
            <Alert severity="error">
              Please upload a valid PDF file.
            </Alert>
          ) : null}

{file !== null && (
            <Typography
              sx={{
                p: 2,
                fontWeight: "bold",
                color:"green" ,
              }}
            >
              {" "}
              File Name: {file.name}
            </Typography>
          )}
      
        {/* <DialogContentText>
          Upload a template PDF h
          </DialogContentText> */}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(e) => {
            props.closeDialogHandler();
          }}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={(e) => {
            props.closeDialogHandler();
          }}
        disabled={file == null || name ===""|| purpose ==="" || category===""}>Upload</Button>
      </DialogActions>
    </Dialog>
  );
}
