import { client } from "./client";


export function apiSubscribePlan(
    organizationId: number,
    subscriptionType:string,
    planId:string,
    email:string,
  ) {
    const body = {
        email: email,
        plan_id:planId ,
        subscription_type: subscriptionType,
        organization_id: organizationId,
        requested_credit_: 0,
        credits: 0,
    };
    return client.post("/subscriptions/create", body);
  }
  
  export function apiTransactionHistory(
    organizationId: number,
  ) {
    return client.get(`/subscriptions/${organizationId}`);
  }
  export function apiActivePlan(
    organizationId: number,
  ) {
    return client.get(`/subscriptions/${organizationId}/active`);
  }

  export function apiCancelSubscription(
    subscriptionID: string,
  ) {
    return client.post(`/subscriptions/${subscriptionID}/disable`,{});
  }