import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export function DebugID(props: {
  name: string;
  id: number;
  componentOrder: number;
}) {
  return (
    <Box>
      <Typography>
        <i>
          <b>{props.name}</b> ID: {props.id}; Component order: {props.componentOrder}
        </i>
      </Typography>
    </Box>
  );
}
