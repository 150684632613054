import React, { useState } from "react";
import { grey } from "@mui/material/colors";
import { Box, IconButton, ImageList, Typography } from "@mui/material";
import DocumentTemplateLibraryItem from "../../DocumentTemplateLibraryItem";
import DocumentRecordLibraryItem from "../../DocumentRecordLibraryItem";
import useActions, { useAppSelector } from "../../../../app/hooks";
import {
  selectCurrentWorkspace,
  selectCurrentWorkspaceRole,
  selectWorkspaces,
} from "../../../auth/authSlice";
import CloseIcon from "@mui/icons-material/Close";
import { selectFavourites, selectLibrary } from "../../librarySlice";
import { Modal, Paper, Button } from "@mui/material";
import {
  DocumentRecord,
  DocumentTemplate,
} from "../../../../types/DocumentTemplate";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import { GroupFolderIcons } from "./GroupFolderIcon";
export default function GroupByLibraryItem(props: {
  title: string;
  groupByTemplates: any;
  type: string;
}) {
  const customModalStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const paperStyle = {
    width: "80%",
    height: "auto",
    backgroundColor: grey[200],
    padding: "16px", // You can adjust the padding as needed
    paddingRight:"0px",
    // marginLeft: "10px",
    // marginRight: "10px", // You can adjust the margin as needed
    borderRadius: "0px", // You can adjust the border radius as needed
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // You can adjust the shadow as needed
  };

  const headerStyle = {
    display: "flex",
    alignItems: "center",
    marginBottom: "16px", // You can adjust the margin as needed
  };

  const iconStyle = {
    marginRight: "8px", // You can adjust the margin as needed
    fontSize: "1.5rem",
    color: "#4C33FF", // You can adjust the color as needed
  };

  const closeButtonStyle = {
    marginLeft: "auto", // You can adjust the margin as needed
  };

  const currentWorkspaceRole = useAppSelector(selectCurrentWorkspaceRole);
  const workspaces = useAppSelector(selectWorkspaces);
  const currentWorkspace = useAppSelector(selectCurrentWorkspace);
  const favourites = useAppSelector(selectFavourites);
  const librarySlice = useAppSelector(selectLibrary);
  const actions = useActions();

  // Sort the Document on the base of user preference
  const list = [...props.groupByTemplates];
  if (librarySlice.sort === "Alphabetical") {
    list.sort((a, b) => a.name.localeCompare(b.name));
  } else if (librarySlice.sort === "Date") {
    list.sort((a, b) => {
      if (a.created_at && b.created_at) {
        return b.created_at.localeCompare(a.created_at); // Compare in reverse order for descending
      } else if (!a.created_at && !b.created_at) {
        return 0; // Both objects are missing created_at, so their order doesn't matter
      } else if (!a.created_at) {
        return 1; // b comes before a since a doesn't have created_at
      } else {
        return -1; // a comes before b since b doesn't have created_at
      }
    });
  }
  const purpose = list[0]._type=="template"?  list[0].purpose : list[0].sub_category ;
  const category = list[0].category;

  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  const generateRandomKey = () => {
    return Math.random().toString(36).substring(7);
  }
  // mapping he Documents
  const content = (
    <ImageList  cols={6}>
      {list.map((template: any) =>
        !template._type || template._type == "template" ? (
          <DocumentTemplateLibraryItem
            // key={template.id+"_"+generateRandomKey()}
            documentTemplate={template as DocumentTemplate}
            workspaceRole={currentWorkspaceRole}
            workspaces={workspaces}
            currentWorkspace={currentWorkspace}
            favourite={
              favourites.filter((_) => _.document_template_id == template.id)
                .length > 0
            }
          />
        ) : (
          <DocumentRecordLibraryItem
            // key={template.id+"_" + generateRandomKey()}
            documentRecord={template as DocumentRecord}
            workspaceRole={currentWorkspaceRole}
          />
        )
      )}
    </ImageList>
  );

  return (
    <>
      <Box
        sx={{
          cursor: "pointer", // Add pointer cursor on hover
        }}
        onClick={openDialog}
      >
        {props.type == "PDF" ? (
          <GroupFolderIcons
            text={props.title}
            type={props.type}
            category={category}
            subcategory={purpose}
          />
        ) : (
          <GroupFolderIcons text={props.title} type={props.type} />
        )}
      </Box>

      <Modal
        open={isDialogOpen}
        onClose={closeDialog}
        style={customModalStyle}
        sx={{ display: "flex", alignItems: "left", justifyContent: "center" }}
      >
        <Paper style={{ ...paperStyle, maxHeight: "90vh", overflowY: "auto" }}>
          <div style={headerStyle}>
            <FolderCopyIcon style={iconStyle} />
            <Typography variant="h6">Group by: {props.type}</Typography>
            <IconButton style={closeButtonStyle} onClick={closeDialog}>
              <CloseIcon />
            </IconButton>
          </div>
          <div>{content} </div>
          {/* <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "auto",
            }}
          >
            <Button variant="contained" onClick={closeDialog}>
              Close
            </Button>
          </div> */}
        </Paper>
      </Modal>
    </>
  );
}
