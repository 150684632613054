import {
  AccountCircle,
  AccountCircleOutlined,
  CancelRounded,
  DeleteOutline,
  ReplayCircleFilledOutlined,
} from "@mui/icons-material";
import {
  Box,
  Divider,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useLayoutEffect, useState } from "react";
import ReplyIcon from "@mui/icons-material/Reply";
import { blue, grey } from "@mui/material/colors";
import EditNoteIcon from "@mui/icons-material/EditNote";
import EditIcon from "@mui/icons-material/Edit";
import { isTemplateExpression } from "typescript";
import SendIcon from "@mui/icons-material/Send";

type Props = {
  item: any;
  fromEditor?: boolean;
  onReply?: any;
  showActions?: boolean;
  fromReply?: boolean;
  showUserIcons?: any;
  onEdit?: any;
  isEdit?: boolean;
  editValue?: any;
  onCommentChange?: any;
  editedComment?: any;
  onSubmitEditedComment?: any;
  isReply?: boolean;
  onDeleteComment?: any;
};

const CommentsCard = (props: Props) => {
  const [value, setvalue] = useState(props.editedComment);

  useEffect(() => {
    if (props.editedComment) setvalue(props.editedComment);
  }, [props.editedComment]);

  const EditIconCard = () => (
    <Tooltip
      title={props.editValue ? "Cancel Edit" : "Edit"}
      placement="top"
      onClick={props.onEdit}
    >
      {props.editValue && props.editValue.id === props.item.id ? (
        <CancelRounded
          sx={{
            color: grey[600],
            cursor: "pointer",
            fontSize: "14px",
          }}
        />
      ) : (
        <EditIcon
          sx={{
            color: grey[600],
            cursor: "pointer",
            fontSize: "14px",
          }}
        />
      )}
    </Tooltip>
  );

  return (
    <Box
      sx={{
        backgroundColor: props.showActions ? grey[100] : grey[100],
        borderRadius: props.showActions ? "8px" : "8px",
        position: "relative",
        padding: "6px",
        marginX: "6px",
        marginY: props.showActions ? "3px" : "3px",
        zIndex: 999,
      }}
      width={props.fromEditor ? "100%" : "94%"}
    >
      <Box
        alignItems={"center"}
        position={"sticky"}
        justifyContent={"space-between"}
        display={"flex"}
        sx={{
          position: "relative",
        }}
      >
        <Box display={"flex"} alignItems={"center"}>
          <AccountCircle style={{ color: "gray" }} />
          <Typography fontSize={"12px"} fontWeight={"bold"}>
            {props.item?.name}
          </Typography>
        </Box>
        {props.showActions && (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {!props.isReply && (
              <Tooltip title="Reply" placement="top" onClick={props.onReply}>
                <ReplyIcon
                  sx={{ color: grey[600], cursor: "pointer", fontSize: "16px" }}
                />
              </Tooltip>
            )}
            {props.showUserIcons(
              props.item.commenter_id || props.item.user_id
            ) && (
              <Tooltip
                title="Delete"
                placement="top"
                onClick={props.onDeleteComment}
              >
                <DeleteOutline
                  sx={{ color: grey[600], cursor: "pointer", fontSize: "16px" }}
                />
              </Tooltip>
            )}
            {props.showUserIcons(
              props.item.commenter_id || props.item.user_id
            ) && <EditIconCard />}
          </Box>
        )}
      </Box>
      {!props.fromReply ? (
        props.isEdit && props.item.id === props.editValue.id ? (
          <>
            <TextField
              id="outlined-comment"
              value={value}
              onChange={(e) => setvalue(e.target.value)}
              fullWidth
              multiline
              minRows={3}
              InputLabelProps={{
                style: {
                  fontSize: "12px",
                  color: grey[700],
                },
              }}
              color="primary"
              InputProps={{
                style: {
                  fontSize: "12px",
                  padding: "12px",
                },
              }}
              focused={true}
              sx={{
                width: "100%",
                maxWidth: "600px",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "8px",
                  "&:hover fieldset": {
                    borderColor: blue[300],
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: blue[500],
                  },
                },
              }}
            />
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <IconButton
                disabled={value === props.item.comment || !value}
                sx={{
                  color: blue[500],
                  backgroundColor: blue[50],
                  transition: "background-color 0.2s ease, color 0.2s ease",
                  "&:hover": {
                    backgroundColor: blue[100],
                  },
                }}
                onClick={() => {
                  props.onSubmitEditedComment(value);
                }}
              >
                <SendIcon sx={{ fontSize: "15px" }} />
              </IconButton>
            </Box>
          </>
        ) : (
          <Typography
            fontSize={"12px"}
            textAlign={"left"}
            maxWidth={props.fromEditor ? "250px" : "100%"}
          >
            {props.item.comment}
          </Typography>
        )
      ) : (
        <Typography
          fontSize={"12px"}
          textAlign={"left"}
          maxWidth={props.fromEditor ? "250px" : "100%"}
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: "2",
            WebkitBoxOrient: "vertical",
          }}
        >
          {props.item.comment}
        </Typography>
      )}
      {!props.fromReply && (
        <Typography
          fontSize={"10px"}
          textAlign={"right"}
          color={"#4c3fff"}
          width={"100%"}
        >
          {props.item.updated_at && "(edited)"}{" "}
          {new Date(props.item.created_at).toLocaleString()}
        </Typography>
      )}
      {/* <Divider /> */}
    </Box>
  );
};

export default CommentsCard;
