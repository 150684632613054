import {
  AddCommentOutlined,
  CancelRounded,
  CloseOutlined,
} from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  Popover,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import AddCommentCard from "./AddCommentCard";
import { useAppSelector } from "src/app/hooks";
import { selectEditingDocumentTemplate } from "../editor/editorSlice";
import {
  apiAddComments,
  apiDeleteComment,
  apiGetComments,
  apiGetCommentsCount,
  apiUpdateComment,
} from "src/services/shareAndCommentsAPI";
import { getCommenterId } from "../editor/document/TableElement/EditContent/helper";
import CommentsCard from "./CommentsCard";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentComments } from "./commentingDetailsSlice";
import { RootState } from "src/app/store";
import { blue, grey, purple, red, yellow } from "@mui/material/colors";
import { ArrowRightIcon } from "@mui/x-date-pickers";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { selectUser } from "../auth/authSlice";
import CommentsThread from "../../Icons/Comments/CommentsThread.svg";
import { transform } from "typescript";

type Props = {
  onClick?: any;
  disabled?: boolean | null;
  totalComments: number;
  psectionid?: number | null;
  ssectionid?: number | null;
  esectionid?: number | null;
};

const CommentsIcon = (props: Props) => {
  const { totalComments } = props;
  const [showCommentsCard, setshowCommentsCard] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [comment, setcomment] = useState("");
  const [commentSent, setcommentSent] = useState(false);
  const [editcommentSent, seteditcommentSent] = useState(false);
  const [isCommentsLoading, setisCommentsLoading] = useState(false);
  const [selectedComment, setselectedComment] = useState<any>(null);
  const [editComment, seteditComment] = useState<any>(null);
  const [editableComment, seteditableComment] = useState<any>("");
  const selectedDocumentTemplate: any = useAppSelector(
    selectEditingDocumentTemplate
  );
  const commentsData = useSelector(
    (state: RootState) => state.apiCommenting.comments
  );
  const userData = useAppSelector(selectUser);

  const dispatch = useDispatch();

  const { organization_id, workspace_id, id } = selectedDocumentTemplate as any;

  const { name, email, commenter_id } = useSelector(
    (state: RootState) => state.apiCommenting.commenter
  ) as any;

  const showUserIcons: any = (commenter: any) =>
    commenter_id === commenter || userData?.user_id === commenter;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setshowCommentsCard(true);
    props.onClick();
  };

  const handleClose = () => {
    setAnchorEl(null);
    setshowCommentsCard(false);
    dispatch(setCurrentComments(null));
    setselectedComment(null);
    seteditComment(null);
    seteditableComment(null);
    seteditcommentSent(false);
  };

  const _handleAddComments = async () => {
    const _payload = {
      document_sub_section_id: props.ssectionid,
      document_element_id: props.esectionid,
      comment,
      commenter_id: getCommenterId() ? commenter_id : null,
      user_id:
        userData?.user_id && !getCommenterId() ? userData?.user_id : null,
      supercedes_id: selectedComment ? selectedComment.id : null,
    };
    await apiAddComments(
      organization_id,
      workspace_id,
      id,
      props.psectionid,
      _payload
    );
  };

  const _handleEditComment = async (updatedComment: any) => {
    const _payload = {
      comment: updatedComment,
      commenter_id: getCommenterId() ? commenter_id : null,
      user_id:
        userData?.user_id && !getCommenterId() ? userData?.user_id : null,
      supercedes_id: selectedComment ? selectedComment.id : null,
    };
    const response = await apiUpdateComment(
      organization_id,
      workspace_id,
      id,
      props.psectionid,
      editComment.id,
      _payload
    );
    if (response.data) {
      seteditComment(null);
      seteditableComment(null);
      seteditcommentSent((prev: any) => !prev);
    }
  };

  const _handleDeleteComment = async (comment_id: any) => {
    const _payload = {
      commenter_id: getCommenterId() ? commenter_id : null,
      user_id:
        userData?.user_id && !getCommenterId() ? userData?.user_id : null,
    };
    const response = await apiDeleteComment(
      organization_id,
      workspace_id,
      id,
      props.psectionid,
      comment_id,
      _payload
    );
    if (response.data) {
      seteditcommentSent((prev: any) => !prev);
    }
  };

  const _handleGetComments = async () => {
    const _payload = {
      document_section_id: props.psectionid,
      document_sub_section_id: props.ssectionid,
      document_element_id: props.esectionid,
    };

    const response = await apiGetComments(
      organization_id,
      workspace_id,
      id,
      _payload
    );

    if (response.data) {
      dispatch(setCurrentComments(response.data.data));
    }
  };

  const open = Boolean(anchorEl);
  const commentsEndRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (open) {
      _handleGetComments();
    }
  }, [open, commentSent, editcommentSent]);

  useEffect(() => {
    if (!commentsData) {
      setisCommentsLoading(true);
    } else {
      setisCommentsLoading(false);
    }
  }, [commentsData]);

  useEffect(() => {
    if (open && commentsData?.length && commentsEndRef.current) {
      setTimeout(() => {
        commentsEndRef.current?.scrollIntoView({ behavior: "smooth" });
      }, 100);
    }
  }, [commentsData, open]);

  const ReplyCard = () => (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: yellow[50],
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "2px",
          width: "98%",
        }}
      >
        <Typography
          fontSize={"small"}
          sx={{ paddingTop: "6px", paddingLeft: "8px" }}
        >
          Replying to
        </Typography>
        <Tooltip
          title="cancel reply"
          sx={{
            cursor: "pointer",
            top: 0,
            right: 0,
          }}
          onClick={() => setselectedComment(null)}
        >
          <CancelRounded sx={{ color: grey[500] }} />
        </Tooltip>
      </Box>

      <CommentsCard
        showActions={false}
        item={selectedComment}
        fromEditor={true}
        fromReply={true}
      />
    </Box>
  );

  return (
    <>
      {/* Icon to open comment */}
      <Box display={"flex"} onClick={handleClick}>
        <Tooltip title="Add Comment" placement="left">
          <AddCommentOutlined
            style={{
              fontSize: 22,
              outline: "none",
              color: "#7284A3",
            }}
          />
        </Tooltip>
      </Box>

      {/* Popover to show the comment card */}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        disableRestoreFocus
        sx={{
          width: "80%",
        }}
      >
        <Box
          sx={{
            minHeight: "0vh",
            maxHeight: "40vh",
            overflow: "auto",
            paddingTop: "2px",
          }}
        >
          {isCommentsLoading ? (
            <Box display={"flex"} justifyContent={"center"} padding={4}>
              <CircularProgress />
            </Box>
          ) : commentsData?.length === 0 ? (
            <Box display={"flex"} justifyContent={"center"} padding={4}>
              <Typography fontSize={"small"}>No Comments Yet</Typography>
            </Box>
          ) : (
            commentsData?.map((mainComment: any, index: number) => (
              <>
                {!mainComment.supercedes_id && (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <CommentsCard
                      showActions={true}
                      item={mainComment}
                      key={mainComment.id || index}
                      fromEditor={true}
                      isEdit={editComment ? true : false}
                      onReply={() => {
                        setselectedComment(mainComment);
                      }}
                      onEdit={() => {
                        if (editComment && editComment.id === mainComment.id) {
                          seteditComment(null);
                          seteditableComment(null);
                        } else {
                          seteditComment(mainComment);
                          seteditableComment(mainComment.comment);
                        }
                      }}
                      onSubmitEditedComment={_handleEditComment}
                      editValue={editComment}
                      showUserIcons={showUserIcons}
                      onCommentChange={seteditableComment}
                      editedComment={editableComment}
                      onDeleteComment={() => {
                        _handleDeleteComment(mainComment.id);
                      }}
                    />
                  </div>
                )}

                {commentsData
                  ?.filter(
                    (subComment: any, _i: number) =>
                      subComment.supercedes_id === mainComment.id
                  )
                  .map((subComment: any, subIndex: number) => {
                    return (
                      <div
                        key={subComment.id || subIndex}
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          position: "relative",
                        }}
                      >
                        {/* Thread Line (Vertical and Horizontal) */}
                        <div
                          style={{
                            position: "absolute",
                            left: "10px",
                            top: "0",
                            bottom: "0",
                            width: "10px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            style={{
                              position: "relative",
                              width: "2px",
                              backgroundColor: "#4c3fff",
                              height: "100%",
                            }}
                          >
                            <div
                              style={{
                                position: "absolute",
                                top: "50%",
                                left: "0",
                                width: "20px",
                                height: "2px",
                                backgroundColor: "#4c3fff",
                              }}
                            ></div>
                          </div>
                        </div>

                        {/* CommentsCard for each sub-comment */}
                        <div
                          style={{
                            marginLeft: "20px",
                            minWidth: "82%",
                            maxWidth: "82%",
                          }}
                        >
                          <CommentsCard
                            showActions={true}
                            item={subComment}
                            fromEditor={true}
                            isReply={true}
                            isEdit={editComment ? true : false}
                            onReply={() => {
                              setselectedComment(subComment);
                            }}
                            onEdit={() => {
                              if (
                                editComment &&
                                editComment.id === mainComment.id
                              ) {
                                seteditComment(null);
                                seteditableComment(null);
                              } else {
                                seteditComment(subComment);
                                seteditableComment(subComment.comment);
                              }
                            }}
                            onSubmitEditedComment={_handleEditComment}
                            editValue={editComment}
                            showUserIcons={showUserIcons}
                            onCommentChange={seteditableComment}
                            editedComment={editableComment}
                            onDeleteComment={() => {
                              _handleDeleteComment(subComment.id);
                            }}
                          />
                        </div>
                      </div>
                    );
                  })}
              </>
            ))
          )}
          <div ref={commentsEndRef} />
        </Box>
        {/* Add Comment Card inside the Popover */}
        {selectedComment && <ReplyCard />}
        <Box p={0} sx={{ backgroundColor: selectedComment ? yellow[50] : "" }}>
          <AddCommentCard
            fromEditor={true}
            value={comment}
            onChangeValue={setcomment}
            isReply={selectedComment ? true : false}
            onComment={() => {
              _handleAddComments();
              setcomment("");
              setcommentSent((prev: any) => !prev);
              setselectedComment(null);
              // handleClose();
            }}
          />
        </Box>
      </Popover>
    </>
  );
};

export default CommentsIcon;
