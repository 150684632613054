import React from 'react';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  DialogContent,
  DialogContentText,
  IconButton,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

export default function ExpireTrialModal(props: { closeDialogHandler: any }) {
  const handleClose = () => {
    props.closeDialogHandler();
  };

  const dialogStyle = {
    maxWidth: "600px",
    margin: "0 auto",
    padding: "20px",
    // backgroundColor: "#f5f5f5",
  };

  const titleStyle = {
    textAlign: "left",
    fontWeight: "bold",
    borderBottom: "1px solid #ddd",
    paddingBottom: "10px",
  };

  const contentStyle = {
    marginTop: "20px",
  };

  const alertStyle = {
    marginBottom: "20px",
  };

  return (
    <Dialog open={true} onClose={handleClose} style={dialogStyle}>
      <DialogTitle>
       Your trial has ended
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      
      <DialogContent style={contentStyle}>
        <Alert severity="warning" style={alertStyle}>
          <Typography variant="body1" color="textPrimary">
            Your trial period has expired. Please contact your administrator for further assistance.
          </Typography>
        </Alert>
        <DialogContentText style={{ textAlign: "justify" }}>
          <Typography variant="body2" color="textPrimary">
            Your access to premium features and services has been suspended due to the expiration of your trial period.
            <br /><br />
            You may experience restrictions and potential disruptions in your workflow, including:
            <ul>
              <li>Limited access to certain features</li>
              <li>Increased risk of errors in document processing</li>
            </ul>
            To ensure uninterrupted access and maintain the integrity of your work, it is imperative that you subscribe to a plan at the earliest convenience.
          </Typography>
        </DialogContentText>
      </DialogContent>
      
  
    </Dialog>
  );
}
