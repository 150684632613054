import React from "react";
import Box from "@mui/material/Box";
import { grey } from "@mui/material/colors";

import useActions, { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  clearPreview,
  selectFilteredDocumentTemplatesAndRecords,
  selectIsFilterApplied,
  selectLibrary,
  setError,
} from "./librarySlice";
import ImageList from "@mui/material/ImageList";
import Toolbar from "@mui/material/Toolbar";
import Alert from "@mui/material/Alert";
import DocumentTemplateLibraryItem from "./DocumentTemplateLibraryItem";
import { selectDocumentPreview, selectFavourites } from "./librarySlice";
import PreviewModal from "../preview/PreviewModal";
import {
  selectAuth,
  selectCurrentWorkspace,
  selectCurrentWorkspaceRole,
  selectOrganizationDetails,
  selectWorkspaces,
} from "../auth/authSlice";
import { LibraryHeaderMenu } from "./header/LibraryHeaderMenu";
import DocumentRecordLibraryItem from "./DocumentRecordLibraryItem";
import { DocumentRecord, DocumentTemplate } from "../../types/DocumentTemplate";
import { LeftDrawerLibrary } from "./drawers/LeftDrawer/LeftDrawer";
import { height } from "@mui/system";
import { AppBar, CircularProgress, Grid } from "@mui/material";
import GroupByLibraryItem from "./drawers/RightDrawer/GroupByLibraryItem";
import { setEditingDocumentTemplate } from "../../app/sagas/editor";
import { selectEditingDocumentTemplate } from "../editor/editorSlice";
import { getRememberMeState } from "../../services/userAPI";

export default function Library() {
  const librarySlice = useAppSelector(selectLibrary);
  const documentTemplate = useAppSelector(selectEditingDocumentTemplate);
  const groupByTemplate = librarySlice.groupByTemplates;
  const filteredDocumentTemplates = useAppSelector(
    selectFilteredDocumentTemplatesAndRecords
  );
  const currentOrgDetails = useAppSelector(selectOrganizationDetails);
  const dispatch = useAppDispatch();
  const actions = useActions();
  const documentPreview = useAppSelector(selectDocumentPreview);
  const authState = useAppSelector(selectAuth);
  const currentWorkspaceRole = useAppSelector(selectCurrentWorkspaceRole);
  const workspaces = useAppSelector(selectWorkspaces);
  const currentWorkspace = useAppSelector(selectCurrentWorkspace);
  const favourites = useAppSelector(selectFavourites);
  const isFilterApplied = useAppSelector(selectIsFilterApplied);

  function handlePreviewClose() {
    dispatch(clearPreview());
  }
  const [numColumns, setNumColumns] = React.useState(5); // Default number of columns

  // Update number of colums according to the screen size

  // Resize the Documnents according to screen size
  React.useEffect(() => {
    const updateNumColumns = () => {
      const screenWidth = window.innerWidth;
      // Calculate the desired number of columns based on the screen width
      const desiredColumns = Math.floor(screenWidth / 230); // Assuming each item has a width of 200px
      setNumColumns(desiredColumns);
    };
    // Attach event listener to window resize
    window.addEventListener("resize", updateNumColumns);
    // Call the function initially
    updateNumColumns();

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("resize", updateNumColumns);
    };
  }, []);

  const fullScreenLoadingStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "auto",
    height: "80vh", // This will make the spinner take up the full viewport height
    // backgroundColor: 'rgba(255, 255, 255, 0.8)', // Optional: add a semi-transparent background
  };

  const noDocumentStyle = {
    fontWeight: "bold",
    fontSize: "22px",
    position: "absolute",
    top: "50%",
    left: "59%",
    transform: "translate(-50%, -50%)",
    textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)", // Add a subtle text shadow
    color: "#000000 ", // Change the text color
    padding: "16px", // Add some padding for readability
  } as React.CSSProperties;

  const roleFilteredDocumentTemplates = filteredDocumentTemplates.filter(
    (_) => {
      if (_._type == "template") {
        if ((_ as DocumentTemplate).publication_no === null) {
          if (currentWorkspaceRole == "READER") {
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }
      } else {
        return true;
      }
    }
  );
  // Display Group by screen
  const groupByContent = (
    <ImageList cols={numColumns}>
      {groupByTemplate && Object.keys(groupByTemplate).length === 0 ? (
        <div style={noDocumentStyle}>
          {librarySlice.filterString !== ""
            ? "No document folder available"
            : "No document folder available"}
        </div>
      ) : (
        groupByTemplate &&
        Object.keys(groupByTemplate).map((key, index) => (
          <GroupByLibraryItem
            // key={index}
            title={key}
            groupByTemplates={groupByTemplate[key]}
            type={librarySlice.groupBy as string}
          />
        ))
      )}
    </ImageList>
  );

  // console.log("roleFilteredDocumentTemplates", roleFilteredDocumentTemplates);

  // show Documents & records
  const content = (
    <ImageList cols={numColumns}>
      {filteredDocumentTemplates.length === 0 &&
      (isFilterApplied ||
        (authState.status != "loading" &&
          authState.workspaces?.length == 0)) ? (
        <div style={noDocumentStyle}>
          {authState.status != "loading" && authState.workspaces?.length == 0
            ? "No workspace exists"
            : librarySlice.filterString !== ""
            ? "No results found"
            : "No documents available"}
        </div>
      ) : (
        roleFilteredDocumentTemplates
          .filter(
            (_) =>
              !(
                _._type == "template" &&
                (currentWorkspaceRole == "READER" ||
                  currentOrgDetails.isTrialExpired)
              )
          )
          .map((template) =>
            !template._type || template._type == "template" ? (
              <DocumentTemplateLibraryItem
                key={template.id}
                documentTemplate={template as DocumentTemplate}
                workspaceRole={currentWorkspaceRole}
                workspaces={workspaces}
                currentWorkspace={currentWorkspace}
                favourite={
                  favourites.filter(
                    (_) => _.document_template_id == template.id
                  ).length > 0
                }
              />
            ) : (
              <DocumentRecordLibraryItem
                key={template.id}
                documentRecord={template as DocumentRecord}
                workspaceRole={currentWorkspaceRole}
              />
            )
          )
      )}
    </ImageList>
  );

  // TODO: https://stackoverflow.com/questions/69383051/dynamically-adjust-imagelist-columns-based-on-screen-size?noredirect=1&lq=1
  //?this is completed
  return (
    <Box
      sx={{
        bgcolor: grey[200],
        px: 5,
        py: 1,
        height: "100vh",
        width: "100%",
        padding: 0,
      }}
    >
      {/* Slightly awkward way to pad the top as per MUI guidelines */}
      <Toolbar />
      <Box sx={{ paddingLeft: 2, paddingRight: 2, paddingTop: 1 }}>
        <LibraryHeaderMenu />
      </Box>

      <Box
        sx={{
          bgcolor: grey[200],
          flexGrow: 2,
          // width: "15%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "right",
          overflow: "hidden",
          padding: 0,
          paddingTop: "5.5vh",
        }}
      >
        <Grid
          container
          spacing={0}
          sx={{ width: "100%", marginTop: 0, paddingTop: 0 }}
        >
          <Grid
            item
            xs={2}
            sx={{ margin: 0, padding: 0 }}
            style={{
              position: "fixed",
              height: "100%",
              borderRight: "2px solid #7284A3",
            }}
          >
            <LeftDrawerLibrary />
          </Grid>
          <Grid
            item
            xs={10}
            style={{
              marginTop: 0,
              padding: 0,
              marginLeft: "20%" /* 2/12 column width in 12-column grid */,
              // width: "100%",
            }}
          >
            {librarySlice.documentTemplatesStatus === "loading" ? (
              <div style={fullScreenLoadingStyle}>
                <CircularProgress />
              </div>
            ) : librarySlice.groupBy == "none" ? (
              content
            ) : (
              groupByContent
            )}
          </Grid>
        </Grid>
      </Box>
      {documentPreview != null ? (
        <PreviewModal
          setParentOpenHandler={handlePreviewClose}
          preview={documentPreview!}
        ></PreviewModal>
      ) : null}
    </Box>
  );
}
