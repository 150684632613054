import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import FormatIndentIncreaseIcon from "@mui/icons-material/FormatIndentIncrease";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import FormatIndentDecreaseIcon from "@mui/icons-material/FormatIndentDecrease";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import ButtonGroup from "@mui/material/ButtonGroup";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";

import SettingsBackupRestoreSharpIcon from "@mui/icons-material/SettingsBackupRestoreSharp";
import TagSharpIcon from "@mui/icons-material/Numbers";
import { Checkbox } from "@mui/material";
import {
  selectCurrentEditorState,
  selectCurrentMarkdownEditorDetails,
  setAlignment,
  setEditorState,
  toggleBold,
  toggleItalic,
  toggleListDecreaseIndent,
  toggleListIncreaseIndent,
  toggleOrderedList,
  toggleUnderline,
  toggleUnorderedList
} from "../markdownTextEditorSlice";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  selectApprovalMode,
  selectEditingDocumentTemplate,
  selectSelected,
  selectSelectedTable,
  setSelectedComponent
} from "../editorSlice";
import {
  ContentType,
  DocumentElement,
  DocumentSection,
  DocumentSubsection,
  DocumentTemplate
} from "../../../types/DocumentTemplate";
import useActions from "../../../app/hooks";
import {
  selectDocumentMasterState,
  selectFormattingSection
} from "./documentMasterSlice";
import {
  applyMasterFormatting,
  documentMasterFormat,
  isBoldApplied,
  isEditorDisabled,
  isItalicApplied,
  isOrderedListApplied,
  isUnderlineApplied,
  isUnorderedListApplied,
  removeInlineStyles
} from "../document/HelperEditorFunctions";
import {
  findParentSubHeadingLevel,
  findParentTextElementLevel,
  findParentTextElementLevelDetails
} from "../../../app/sagas/utils";
import { parse } from "path";
import {
  getCellStyle,
  updateCellTextAlign
} from "../document/TableElement/HelperFuctions/TableUtils";
import { shallowEqual } from "react-redux";

export function FormattingMenu() {
  const actions = useActions();
  const dispatch = useAppDispatch();
  const currentMarkdownEditorDetails = useAppSelector(
    selectCurrentMarkdownEditorDetails
  );

  //Todo : Update SetSelected: done
  const documentTemplate = useAppSelector(selectEditingDocumentTemplate);
  const selectedComponents = useAppSelector(selectSelected);
  // const documentMasterState = useAppSelector(selectDocumentMasterState);
  const documentMasterState = useAppSelector(
    (state) => selectFormattingSection(state, selectedComponents.section?.id),
    shallowEqual
  );
  const [selectedjustify, setselectedjustify] = useState<string>("");
  const approvalsEnabled = useAppSelector(selectApprovalMode);
  const isHeadingSelected =
    selectedComponents.section !== null &&
    selectedComponents.subSection === null &&
    selectedComponents.documentElement === null;
  const isSubheadingSelected =
    selectedComponents.documentElement !== null &&
    selectedComponents.documentElement.content_type === ContentType.Heading;
  const isTextSelected =
    selectedComponents.documentElement !== null &&
    selectedComponents.documentElement.content_type === ContentType.Text;
  const [selectedCurrentIndent, setselectedCurrentIndent] = useState<number>(0);
  const isSelected = selectedComponents.section === null ? false : true;
  const [isBoldSelected, setIsBoldSelected] = useState<boolean>(false);
  const [isUnderlineSelected, setIsUnderlineSelected] =
    useState<boolean>(false);
  const [isItalicSelected, setIsItalicSelected] = useState<boolean>(false);
  const [isOrderedListSelected, setIsOrderedListSelected] =
    useState<boolean>(false);
  const [isUnorderedListSelected, setIsUnorderedListSelected] =
    useState<boolean>(false);
  const isTableSelected =
    selectedComponents.documentElement !== null &&
    selectedComponents.documentElement.content_type === ContentType.Table;
  const selectedTableComponents = useAppSelector(selectSelectedTable);
  const isCustomIndentApplied =
    selectedComponents.documentElement !== null &&
    (selectedComponents.documentElement.content_type === ContentType.Text ||
      selectedComponents.documentElement.content_type ===
        ContentType.Heading) &&
    JSON.parse(selectedComponents.documentElement.content_format)
      .isIndentApplied;
  const isAutoIndentAppled =
    documentMasterState.documentMaster &&
    documentMasterState.documentMaster.default_indent &&
    !isTableSelected &&
    !isEditorDisabled(selectedComponents.section?.heading);

  const selectedCurrentEditorState = useAppSelector(selectCurrentEditorState);

  useEffect(() => {
    try {
      if (
        isTableSelected &&
        selectedTableComponents.documentElement &&
        selectedTableComponents.subSection &&
        selectedTableComponents.section
      ) {
        const formatting = JSON.parse(
          selectedTableComponents.documentElement!.formatting!
        );
        const alignment = getCellStyle(
          selectedTableComponents.row,
          selectedTableComponents.column,
          formatting
        ).textAlign;
        setselectedjustify(alignment);
      }

      if (
        selectedComponents.documentElement &&
        selectedComponents.subSection &&
        selectedComponents.section &&
        (selectedComponents.documentElement.content_type === "TEXT" ||
          selectedComponents.documentElement.content_type === "HEADING")
      ) {
        const format = JSON.parse(
          selectedComponents.documentElement.content_format
        );
        if (format.isJustificationApplied === true) {
          setselectedjustify(
            JSON.parse(selectedComponents.documentElement.content_format)
              .justify
          );
        } else {
          if (
            documentMasterState.documentMaster !== undefined &&
            documentMasterState.documentMaster !== null
          ) {
            if (selectedComponents.documentElement.content_type === "TEXT") {
              if (selectedComponents.documentElement.heading_level === 1) {
                setselectedjustify(
                  JSON.parse(
                    documentMasterState.documentMaster.paragraph1_formatting
                  ).alignment
                );
              } else if (
                selectedComponents.documentElement.heading_level === 2
              ) {
                setselectedjustify(
                  JSON.parse(
                    documentMasterState.documentMaster.paragraph2_formatting
                  ).alignment
                );
              } else if (
                selectedComponents.documentElement.heading_level === 3
              ) {
                setselectedjustify(
                  JSON.parse(
                    documentMasterState.documentMaster.paragraph3_formatting
                  ).alignment
                );
              } else if (
                selectedComponents.documentElement.heading_level === 4
              ) {
                setselectedjustify(
                  JSON.parse(
                    documentMasterState.documentMaster.paragraph4_formatting
                  ).alignment
                );
              } else if (
                selectedComponents.documentElement.heading_level === 5
              ) {
                setselectedjustify(
                  JSON.parse(
                    documentMasterState.documentMaster.paragraph5_formatting
                  ).alignment
                );
              } else if (
                selectedComponents.documentElement.heading_level === 6
              ) {
                setselectedjustify(
                  JSON.parse(
                    documentMasterState.documentMaster.paragraph6_formatting
                  ).alignment
                );
              }
            } else if (
              selectedComponents.documentElement.content_type === "HEADING"
            ) {
              if (selectedComponents.documentElement.heading_level === 1) {
                setselectedjustify(
                  JSON.parse(
                    documentMasterState.documentMaster.heading1_formatting
                  ).alignment
                );
              } else if (
                selectedComponents.documentElement.heading_level === 2
              ) {
                setselectedjustify(
                  JSON.parse(
                    documentMasterState.documentMaster.heading2_formatting
                  ).alignment
                );
              } else if (
                selectedComponents.documentElement.heading_level === 3
              ) {
                setselectedjustify(
                  JSON.parse(
                    documentMasterState.documentMaster.heading3_formatting
                  ).alignment
                );
              } else if (
                selectedComponents.documentElement.heading_level === 4
              ) {
                setselectedjustify(
                  JSON.parse(
                    documentMasterState.documentMaster.heading4_formatting
                  ).alignment
                );
              } else if (
                selectedComponents.documentElement.heading_level === 5
              ) {
                setselectedjustify(
                  JSON.parse(
                    documentMasterState.documentMaster.heading5_formatting
                  ).alignment
                );
              } else if (
                selectedComponents.documentElement.heading_level === 6
              ) {
                setselectedjustify(
                  JSON.parse(
                    documentMasterState.documentMaster.heading6_formatting
                  ).alignment
                );
              }
            }
          }
        }
        setselectedCurrentIndent(
          JSON.parse(selectedComponents.documentElement.content_format).indent
        );
      }

      if (isHeadingSelected && selectedComponents.section) {
        if (selectedComponents.section.formatting) {
          const formatting = JSON.parse(selectedComponents.section.formatting);
          if (formatting.justify) {
            setselectedjustify(
              JSON.parse(selectedComponents.section.formatting).justify
            );
          } else {
            if (selectedComponents.section.heading_level === 1) {
              setselectedjustify(
                JSON.parse(
                  documentMasterState.documentMaster.heading1_formatting
                ).alignment
              );
            } else if (selectedComponents.section.heading_level === 2) {
              setselectedjustify(
                JSON.parse(
                  documentMasterState.documentMaster.heading2_formatting
                ).alignment
              );
            } else if (selectedComponents.section.heading_level === 3) {
              setselectedjustify(
                JSON.parse(
                  documentMasterState.documentMaster.heading3_formatting
                ).alignment
              );
            } else if (selectedComponents.section.heading_level === 4) {
              setselectedjustify(
                JSON.parse(
                  documentMasterState.documentMaster.heading4_formatting
                ).alignment
              );
            } else if (selectedComponents.section.heading_level === 5) {
              setselectedjustify(
                JSON.parse(
                  documentMasterState.documentMaster.heading5_formatting
                ).alignment
              );
            } else if (selectedComponents.section.heading_level === 6) {
              setselectedjustify(
                JSON.parse(
                  documentMasterState.documentMaster.heading6_formatting
                ).alignment
              );
            }
          }
        } else {
          if (selectedComponents.section.heading_level === 1) {
            setselectedjustify(
              JSON.parse(documentMasterState.documentMaster.heading1_formatting)
                .alignment
            );
          } else if (selectedComponents.section.heading_level === 2) {
            setselectedjustify(
              JSON.parse(documentMasterState.documentMaster.heading2_formatting)
                .alignment
            );
          } else if (selectedComponents.section.heading_level === 3) {
            setselectedjustify(
              JSON.parse(documentMasterState.documentMaster.heading3_formatting)
                .alignment
            );
          } else if (selectedComponents.section.heading_level === 4) {
            setselectedjustify(
              JSON.parse(documentMasterState.documentMaster.heading4_formatting)
                .alignment
            );
          } else if (selectedComponents.section.heading_level === 5) {
            setselectedjustify(
              JSON.parse(documentMasterState.documentMaster.heading5_formatting)
                .alignment
            );
          } else if (selectedComponents.section.heading_level === 6) {
            setselectedjustify(
              JSON.parse(documentMasterState.documentMaster.heading6_formatting)
                .alignment
            );
          }
        }

        setselectedCurrentIndent(() => {
          if (
            selectedComponents.section &&
            selectedComponents.section.formatting
          ) {
            const formatting = JSON.parse(
              selectedComponents.section.formatting
            );
            if (formatting.indent) {
              return formatting.indent as number;
            } else return 0;
          }
          return 0;
        });
      } else if (selectedComponents.section === null) {
        setselectedjustify("");
        setselectedCurrentIndent(0);
      }
      if (selectedComponents.section === null) {
        setIsBoldSelected(false);
        setIsItalicSelected(false);
        setIsUnderlineSelected(false);
        setIsOrderedListSelected(false);
        setIsUnorderedListSelected(false);
      }
    } catch (e) {
      console.log("error in Formatting Menu", e);
    }
  }, [
    selectedComponents?.documentElement?.content_format,
    selectedComponents?.section?.content_format,
    selectedComponents?.section?.formatting,
    selectedTableComponents
  ]);

  const isFormattingDisabled =
    (currentMarkdownEditorDetails?.disabled ?? true) ||
    isHeadingSelected ||
    isSubheadingSelected;

  const handleRemoveTextNumbering = (shouldDisplay: any) => {
    try {
      {
        if (
          selectedComponents.documentElement &&
          selectedComponents.subSection &&
          selectedComponents.section
        ) {
          const updatedElement = { ...selectedComponents.documentElement };

          const contentFormatObject = JSON.parse(updatedElement.content_format);

          contentFormatObject.isNumberDisplay = shouldDisplay;
          updatedElement.content_format = JSON.stringify(contentFormatObject);
          updatedElement.formatting = JSON.stringify({
            ...JSON.parse(
              selectedComponents.documentElement?.formatting !== null &&
                selectedComponents.documentElement?.formatting !== undefined
                ? selectedComponents.documentElement?.formatting
                : "{}"
            ),
            isNumberDisplay: shouldDisplay
          });
          if (updatedElement.content_type === "TEXT") {
            actions.updateDocumentElementContent({
              section: selectedComponents.section as DocumentSection,
              subSection: selectedComponents.subSection!,
              documentElement: updatedElement as DocumentElement,
              updateLevels: true
            });
            dispatch(
              setSelectedComponent({
                selectedSection: selectedComponents.section as DocumentSection,
                selectedSubsection: selectedComponents.subSection!,
                selectedComponent: updatedElement as DocumentElement
              })
            );
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleUpdateTableCellAlignment = (align: string) => {
    try {
      if (
        selectedTableComponents.documentElement &&
        selectedTableComponents.row != null &&
        selectedTableComponents.column != null &&
        selectedTableComponents.subSection &&
        selectedTableComponents.documentElement.formatting
      ) {
        const Formatting = JSON.parse(
          selectedTableComponents.documentElement.formatting
        );
        const updatedFormatting = updateCellTextAlign(
          Formatting,
          selectedTableComponents.row,
          selectedTableComponents.column,
          align
        );
        const updatedElement = { ...selectedTableComponents.documentElement };
        updatedElement.formatting = JSON.stringify(updatedFormatting);
        actions.updateDocumentElementContent({
          section: selectedComponents.section as DocumentSection,
          subSection: selectedComponents.subSection!,
          documentElement: updatedElement as DocumentElement
        });
        dispatch(
          setSelectedComponent({
            selectedSection: selectedComponents.section as DocumentSection,
            selectedSubsection: selectedComponents.subSection!,
            selectedComponent: updatedElement as DocumentElement
          })
        );
      }
    } catch (e) {
      console.log("unable to update the Table cell alignmnet");
    }
  };

  const handleUpdateAlignment = (justification: string) => {
    try {
      //if the Selected is Element
      if (
        selectedComponents.documentElement &&
        selectedComponents.subSection &&
        selectedComponents.section
      ) {
        const updatedElement = { ...selectedComponents.documentElement };

        const contentFormatObject = JSON.parse(updatedElement.content_format);
        contentFormatObject.justify = justification;
        contentFormatObject.isJustificationApplied = true;
        updatedElement.content_format = JSON.stringify(contentFormatObject);
        updatedElement.formatting = JSON.stringify({
          ...JSON.parse(
            selectedComponents.documentElement?.formatting !== null &&
              selectedComponents.documentElement?.formatting !== undefined
              ? selectedComponents.documentElement?.formatting
              : "{}"
          ),
          justify: justification
        });

        actions.updateDocumentElementContent({
          section: selectedComponents.section as DocumentSection,
          subSection: selectedComponents.subSection!,
          documentElement: updatedElement as DocumentElement
        });
        dispatch(
          setSelectedComponent({
            selectedSection: selectedComponents.section as DocumentSection,
            selectedSubsection: selectedComponents.subSection!,
            selectedComponent: updatedElement as DocumentElement
          })
        );
      } else {
        if (selectedComponents.section) {
          const updatedSection = Object.assign(
            {},
            selectedComponents.section as DocumentSection,
            {
              ...selectedComponents.section,
              formatting: JSON.stringify({
                ...JSON.parse(
                  selectedComponents.section?.formatting !== null &&
                    selectedComponents.section?.formatting !== undefined
                    ? selectedComponents.section?.formatting
                    : "{}"
                ),
                justify: justification,
                isCustomSettings: true
              })
            }
          );
          actions.updateSection(updatedSection);
          dispatch(
            setSelectedComponent({
              selectedSection: updatedSection as DocumentSection,
              selectedSubsection: selectedComponents.subSection!,
              selectedComponent:
                selectedComponents.documentElement as DocumentElement
            })
          );
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleResetSettings = () => {
    try {
      if (selectedCurrentEditorState) {
        const fomatting = isHeadingSelected
          ? documentMasterFormat(documentMasterState, "HEADING", 1)
          : documentMasterFormat(
              documentMasterState,
              selectedComponents.documentElement!.content_type,
              selectedComponents.documentElement!.heading_level
            );
        const style = {
          bold: fomatting.fontStyle.includes("bold"),
          italic: fomatting.fontStyle.includes("italic"),
          underline: fomatting.fontStyle.includes("underline")
        };

        const _editorState = removeInlineStyles(
          selectedCurrentEditorState,
          true,
          true,
          true
        );
        const __editorState = applyMasterFormatting(
          _editorState,
          style.bold,
          style.italic,
          style.underline
        );
        dispatch(setEditorState(__editorState));
      }
      if (
        selectedComponents.documentElement &&
        selectedComponents.subSection &&
        selectedComponents.section
      ) {
        const updatedElement = { ...selectedComponents.documentElement };
        updatedElement.content_format = JSON.stringify({
          format: "MARKDOWN",
          color: "000000",
          justify: "raggedright",
          isBold: false,
          isItalic: false,
          isUnderline: false,
          isStandard: true,
          indent: 0,
          isColorApplied: false,
          isJustificationApplied: false,
          isIndentApplied: false,
          isNumberDisplay: true
        });
        updatedElement.formatting = undefined;
        dispatch(
          setSelectedComponent({
            selectedSection: selectedComponents.section as DocumentSection,
            selectedSubsection: selectedComponents.subSection!,
            selectedComponent: updatedElement as DocumentElement
          })
        );
        actions.updateDocumentElementContent({
          section: selectedComponents.section as DocumentSection,
          subSection: selectedComponents.subSection!,
          documentElement: updatedElement as DocumentElement
        });
      } else if (selectedComponents.section && isHeadingSelected) {
        const updatedSection = Object.assign(
          {},
          selectedComponents.section as DocumentSection,
          {
            ...selectedComponents.section,
            formatting: JSON.stringify({
              isBold: false,
              isItalic: false,
              isUnderline: false,
              isStandard: true,
              isCustomSettings: false
            })
          }
        );
        dispatch(
          setSelectedComponent({
            selectedSection: updatedSection as DocumentSection,
            selectedSubsection: selectedComponents.subSection!,
            selectedComponent:
              selectedComponents.documentElement as DocumentElement
          })
        );
        actions.updateSectionContent(updatedSection);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleUpdateInlineStyle = (
    propertyApplied: "bold" | "italic" | "underline"
  ) => {
    try {
      //if the Selected is Element
      if (
        selectedComponents.documentElement &&
        selectedComponents.subSection &&
        selectedComponents.section
      ) {
        const updatedElement = { ...selectedComponents.documentElement };
        const contentFormatObject = JSON.parse(updatedElement.content_format);
        if (propertyApplied == "bold") {
          contentFormatObject.isBold = true;
        } else if (propertyApplied == "italic") {
          contentFormatObject.isItalic = true;
        } else if (propertyApplied == "underline") {
          contentFormatObject.isUnderline = true;
        }
        contentFormatObject.isStandard = false;
        updatedElement.content_format = JSON.stringify(contentFormatObject);
        actions.updateDocumentElementContent({
          section: selectedComponents.section as DocumentSection,
          subSection: selectedComponents.subSection!,
          documentElement: updatedElement as DocumentElement
        });

        updatedElement.formatting = JSON.stringify({
          ...JSON.parse(
            selectedComponents.documentElement?.formatting !== null &&
              selectedComponents.documentElement?.formatting !== undefined
              ? selectedComponents.documentElement?.formatting
              : "{}"
          ),
          isStandard: false
        });

        dispatch(
          setSelectedComponent({
            selectedSection: selectedComponents.section as DocumentSection,
            selectedSubsection: selectedComponents.subSection!,
            selectedComponent: updatedElement as DocumentElement
          })
        );
      } else {
        if (selectedComponents.section) {
          const updatedSection = {
            ...selectedComponents.section,
            formatting: JSON.stringify({
              ...JSON.parse(selectedComponents.section?.formatting || "{}"),
              isStandard: false,
              isCustomSettings: true,
              [propertyApplied === "bold"
                ? "isBold"
                : propertyApplied === "italic"
                ? "isItalic"
                : "isUnderline"]: true
            })
          };
          actions.updateSection(updatedSection);

          dispatch(
            setSelectedComponent({
              selectedSection: updatedSection as DocumentSection,
              selectedSubsection: selectedComponents.subSection!,
              selectedComponent:
                selectedComponents.documentElement as DocumentElement
            })
          );
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleIncreaseIndent = () => {
    setselectedCurrentIndent((selectedCurrentIndent as number) + 2);
    if (
      selectedComponents.documentElement &&
      selectedComponents.subSection &&
      selectedComponents.section
    ) {
      const updatedElement = { ...selectedComponents.documentElement };
      const contentFormatObject = JSON.parse(updatedElement.content_format);
      contentFormatObject.indent = (selectedCurrentIndent as number) + 2;
      contentFormatObject.isIndentApplied = true;
      updatedElement.content_format = JSON.stringify(contentFormatObject);
      updatedElement.formatting = JSON.stringify({
        ...JSON.parse(
          selectedComponents.documentElement?.formatting !== null &&
            selectedComponents.documentElement?.formatting !== undefined
            ? selectedComponents.documentElement?.formatting
            : "{}"
        ),
        indent: ((selectedCurrentIndent as number) + 2) as number
      });
      actions.updateDocumentElementContent({
        section: selectedComponents.section as DocumentSection,
        subSection: selectedComponents.subSection!,
        documentElement: updatedElement as DocumentElement
      });
      dispatch(
        setSelectedComponent({
          selectedSection: selectedComponents.section as DocumentSection,
          selectedSubsection: selectedComponents.subSection!,
          selectedComponent: updatedElement as DocumentElement
        })
      );
    } else if (selectedComponents.section && isHeadingSelected) {
      const updatedSection = Object.assign(
        {},
        selectedComponents.section as DocumentSection,
        {
          ...selectedComponents.section,
          formatting: JSON.stringify({
            ...JSON.parse(
              selectedComponents.section?.formatting
                ? selectedComponents.section?.formatting
                : "{}"
            ),
            indent: ((selectedCurrentIndent as number) + 2) as number,
            isCustomSettings: true
          })
        }
      );
      actions.updateSectionContent(updatedSection);
      dispatch(
        setSelectedComponent({
          selectedSection: updatedSection as DocumentSection,
          selectedSubsection: selectedComponents.subSection!,
          selectedComponent:
            selectedComponents.documentElement as DocumentElement
        })
      );
    }
  };
  const handleDecreaseIndentLeft = () => {
    if (selectedCurrentIndent == 0) {
      if (
        selectedComponents.documentElement &&
        selectedComponents.subSection &&
        selectedComponents.section
      ) {
        if (
          selectedComponents.documentElement &&
          selectedComponents.subSection &&
          selectedComponents.section
        ) {
          const masterIndent = documentMasterFormat(
            documentMasterState,
            selectedComponents.documentElement.content_type,
            selectedComponents.documentElement.heading_level
          ).indent;

          const updatedIndent =
            masterIndent > 0 ? masterIndent - 1 : masterIndent;
          const updatedElement = { ...selectedComponents.documentElement };
          const contentFormatObject = JSON.parse(updatedElement.content_format);
          contentFormatObject.indent = updatedIndent as number;
          contentFormatObject.isIndentApplied = true;
          updatedElement.content_format = JSON.stringify(contentFormatObject);
          updatedElement.formatting = JSON.stringify({
            ...JSON.parse(
              selectedComponents.documentElement?.formatting !== null &&
                selectedComponents.documentElement?.formatting !== undefined
                ? selectedComponents.documentElement?.formatting
                : "{}"
            ),
            indent: updatedIndent as number
          });

          actions.updateDocumentElementContent({
            section: selectedComponents.section as DocumentSection,
            subSection: selectedComponents.subSection!,
            documentElement: updatedElement as DocumentElement
          });

          dispatch(
            setSelectedComponent({
              selectedSection: selectedComponents.section as DocumentSection,
              selectedSubsection: selectedComponents.subSection!,
              selectedComponent: updatedElement as DocumentElement
            })
          );
        }
      } else if (isHeadingSelected) {
        const masterIndent = documentMasterFormat(
          documentMasterState,
          "HEADING",
          1
        ).indent;

        const updatedIndent =
          masterIndent > 0 ? masterIndent - 1 : masterIndent;

        const updatedSection = Object.assign(
          {},
          selectedComponents.section as DocumentSection,
          {
            ...selectedComponents.section,
            formatting: JSON.stringify({
              ...JSON.parse(
                selectedComponents.section?.formatting
                  ? selectedComponents.section?.formatting
                  : "{}"
              ),
              indent: updatedIndent,
              isCustomSettings: true
            })
          }
        );
        actions.updateSectionContent(updatedSection);
        dispatch(
          setSelectedComponent({
            selectedSection: updatedSection as DocumentSection,
            selectedSubsection: selectedComponents.subSection!,
            selectedComponent:
              selectedComponents.documentElement as DocumentElement
          })
        );
      }
    } else if ((selectedCurrentIndent as number) - 2 >= 0) {
      setselectedCurrentIndent((selectedCurrentIndent as number) - 2);
      if (
        selectedComponents.documentElement &&
        selectedComponents.subSection &&
        selectedComponents.section
      ) {
        const updatedElement = { ...selectedComponents.documentElement };

        const contentFormatObject = JSON.parse(updatedElement.content_format);
        contentFormatObject.indent = (selectedCurrentIndent as number) - 2;
        updatedElement.content_format = JSON.stringify(contentFormatObject);
        updatedElement.formatting = JSON.stringify({
          ...JSON.parse(
            selectedComponents.documentElement?.formatting !== null &&
              selectedComponents.documentElement?.formatting !== undefined
              ? selectedComponents.documentElement?.formatting
              : "{}"
          ),
          indent: ((selectedCurrentIndent as number) - 2) as number
        });

        actions.updateDocumentElementContent({
          section: selectedComponents.section as DocumentSection,
          subSection: selectedComponents.subSection!,
          documentElement: updatedElement as DocumentElement
        });

        dispatch(
          setSelectedComponent({
            selectedSection: selectedComponents.section as DocumentSection,
            selectedSubsection: selectedComponents.subSection!,
            selectedComponent: updatedElement as DocumentElement
          })
        );
      } else if (selectedComponents.section?.formatting && isHeadingSelected) {
        const updatedSection = Object.assign(
          {},
          selectedComponents.section as DocumentSection,
          {
            ...selectedComponents.section,
            formatting: JSON.stringify({
              ...JSON.parse(selectedComponents.section?.formatting),
              indent: ((selectedCurrentIndent as number) - 2) as number
            })
          }
        );
        actions.updateSectionContent(updatedSection);
        dispatch(
          setSelectedComponent({
            selectedSection: updatedSection as DocumentSection,
            selectedSubsection: selectedComponents.subSection!,
            selectedComponent:
              selectedComponents.documentElement as DocumentElement
          })
        );
      }
    }
  };

  const handleLevelIncrease = () => {
    try {
      if (
        selectedComponents.documentElement &&
        selectedComponents.subSection &&
        selectedComponents.section
      ) {
        if (selectedComponents.documentElement.heading_level < 6) {
          const parentLevel = findParentSubHeadingLevel(
            documentTemplate as DocumentTemplate,
            selectedComponents.section as DocumentSection,
            selectedComponents.subSection
          );

          let currentHeadingLevel =
            selectedComponents.documentElement.heading_level;
          if (selectedComponents.documentElement.content_type === "HEADING") {
            if (
              selectedComponents.documentElement.heading_level < parentLevel ||
              selectedComponents.documentElement.heading_level === parentLevel
            ) {
              currentHeadingLevel =
                selectedComponents.documentElement.heading_level + 1;
            }
          } else if (
            selectedComponents.documentElement.content_type === "TEXT"
          ) {
            const parentLevelText = findParentTextElementLevelDetails(
              documentTemplate as DocumentTemplate,
              selectedComponents.section as DocumentSection,
              selectedComponents.subSection
            );

            if (
              selectedComponents.documentElement.heading_level + 1 <=
                parentLevelText.parentSubHeadingLevel ||
              selectedComponents.documentElement.heading_level ===
                parentLevelText.parentSubHeadingLevel
            ) {
              currentHeadingLevel =
                selectedComponents.documentElement.heading_level + 1;
            }
          }

          actions.setHeadingLevel({
            selected: selectedComponents,
            heading_level:
              currentHeadingLevel > 6 ? 6 : (currentHeadingLevel as number)
          });
          dispatch(
            setSelectedComponent({
              selectedSection: selectedComponents.section as DocumentSection,
              selectedSubsection: selectedComponents.subSection!,
              selectedComponent: {
                ...selectedComponents.documentElement,
                heading_level:
                  currentHeadingLevel > 6 ? 6 : (currentHeadingLevel as number)
              } as DocumentElement
            })
          );
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const findParentElementLevel = () => {
    let parentLevel = 1;
    let parentLevelFound = false;

    if (
      selectedComponents.documentElement &&
      selectedComponents.subSection &&
      selectedComponents.section
    ) {
      documentTemplate.sections?.forEach((section) => {
        if (
          section.id === selectedComponents.section?.id &&
          !parentLevelFound
        ) {
          section.subsections?.forEach((subSection) => {
            if (
              subSection.id !== selectedComponents.subSection?.id &&
              subSection.elements &&
              subSection.elements[0] !== undefined &&
              subSection.elements[0].content_type === "HEADING" &&
              !parentLevelFound
            ) {
              parentLevel = subSection.elements[0].heading_level;
            } else if (
              subSection.id === selectedComponents.subSection?.id &&
              !parentLevelFound
            ) {
              parentLevelFound = true;
            }
          });
        }
      });
    }
    return parentLevel;
  };

  const handleLevelDecrease = () => {
    // To check is Text/heading Selected
    try {
      if (
        selectedComponents.documentElement &&
        selectedComponents.subSection &&
        selectedComponents.section
      ) {
        // const parentLevel = findParentElementLevel();

        const parentLevel = findParentSubHeadingLevel(
          documentTemplate as DocumentTemplate,
          selectedComponents.section as DocumentSection,
          selectedComponents.subSection!
        );
        if (
          selectedComponents.documentElement.content_type === "TEXT" &&
          selectedComponents.documentElement?.heading_level > 2
        ) {
          let currentHeadingLevel =
            selectedComponents.documentElement.heading_level;

          if (
            selectedComponents.documentElement.heading_level - 1 >
            parentLevel
          ) {
            currentHeadingLevel =
              selectedComponents.documentElement.heading_level - 1;
          }

          actions.setHeadingLevel({
            selected: selectedComponents,
            heading_level: currentHeadingLevel as number
          });
          dispatch(
            setSelectedComponent({
              selectedSection: selectedComponents.section as DocumentSection,
              selectedSubsection: selectedComponents.subSection!,
              selectedComponent: {
                ...selectedComponents.documentElement,
                heading_level: currentHeadingLevel as number
              } as DocumentElement
            })
          );
        } else if (
          selectedComponents.documentElement?.heading_level > 2 &&
          selectedComponents.documentElement?.content_type === "HEADING"
        ) {
          let currentHeadingLevel =
            selectedComponents.documentElement.heading_level;
          if (selectedComponents.documentElement.content_type === "HEADING") {
            currentHeadingLevel =
              selectedComponents.documentElement.heading_level - 1;
          }
          actions.setHeadingLevel({
            selected: selectedComponents,
            heading_level: currentHeadingLevel as number
          });
          dispatch(
            setSelectedComponent({
              selectedSection: selectedComponents.section as DocumentSection,
              selectedSubsection: selectedComponents.subSection!,
              selectedComponent: {
                ...selectedComponents.documentElement,
                heading_level: currentHeadingLevel as number
              } as DocumentElement
            })
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const firstElement = selectedComponents.subSection?.elements?.[0];
  const firstElementContent = firstElement?.content || "";
  const isUnorderedListAppliedToFirstElement =
    isUnorderedListApplied(firstElementContent);
  const isOrderedListAppliedToFirstElement =
    isOrderedListApplied(firstElementContent);

  const isCheckedUnorder =
    (isTextSelected &&
      firstElementContent &&
      isUnorderedListAppliedToFirstElement) ||
    isUnorderedListSelected;

  const isCheckedOrder =
    (isTextSelected &&
      firstElementContent &&
      isOrderedListAppliedToFirstElement) ||
    isOrderedListSelected;

  return (
    <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
      <ButtonGroup
        variant="text"
        aria-label="outlined button group"
        sx={{ mx: 1 }}
      >
        <Checkbox
          icon={<FormatBoldIcon />}
          checkedIcon={<FormatBoldIcon />}
          disableRipple
          checked={
            isSelected &&
            (isTextSelected || isSubheadingSelected || isHeadingSelected) &&
            currentMarkdownEditorDetails?.bold
          }
          onClick={(e) => {
            setIsBoldSelected(!isBoldSelected);
            handleUpdateInlineStyle("bold");

            dispatch(toggleBold());
          }}
          onMouseDown={(e) => {
            setIsBoldSelected(false);
            e.preventDefault();
          }}
          disabled={
            !isSelected ||
            approvalsEnabled ||
            isTableSelected ||
            (isHeadingSelected &&
              isEditorDisabled(selectedComponents.section?.heading))
          }
          style={{ width: "0px", padding: "5px", display: "center" }}
        />
        <Checkbox
          icon={<FormatItalicIcon />}
          checkedIcon={<FormatItalicIcon />}
          disableRipple
          checked={
            (isSelected &&
              (isTextSelected || isSubheadingSelected) &&
              currentMarkdownEditorDetails?.italic) ||
            (isHeadingSelected && currentMarkdownEditorDetails?.italic)
          }
          onClick={(e) => {
            setIsItalicSelected(!isItalicSelected);
            handleUpdateInlineStyle("italic");
            dispatch(toggleItalic());
          }}
          onMouseDown={(e) => {
            setIsItalicSelected(false);
            e.preventDefault();
          }}
          disabled={
            !isSelected ||
            approvalsEnabled ||
            isTableSelected ||
            (isHeadingSelected &&
              isEditorDisabled(selectedComponents.section?.heading))
          }
          style={{ width: "40px", padding: "5px", display: "center" }}
        />
        <Checkbox
          icon={<FormatUnderlinedIcon />}
          checkedIcon={<FormatUnderlinedIcon />}
          disableRipple
          checked={
            (isSelected &&
              (isTextSelected || isSubheadingSelected) &&
              currentMarkdownEditorDetails?.underlined) ||
            (isHeadingSelected && currentMarkdownEditorDetails?.underlined)
          }
          onClick={(e) => {
            setIsUnderlineSelected(!isUnderlineSelected);
            handleUpdateInlineStyle("underline");
            dispatch(toggleUnderline());
          }}
          onMouseDown={(e) => {
            setIsUnderlineSelected(false);
            e.preventDefault();
          }}
          disabled={
            isTableSelected ||
            !isSelected ||
            isTableSelected ||
            approvalsEnabled ||
            (isHeadingSelected &&
              isEditorDisabled(selectedComponents.section?.heading))
          }
          style={{
            width: "2px",
            padding: "5px",
            display: "center",
            paddingRight: "0px"
          }}
        />
      </ButtonGroup>
      <ButtonGroup
        variant="text"
        aria-label="outlined button group"
        sx={{ mx: 1 }}
      >
        <Checkbox
          icon={<FormatListBulletedIcon />}
          checkedIcon={<FormatListBulletedIcon />}
          checked={isCheckedUnorder}
          disableRipple
          onClick={(e) => {
            setIsUnorderedListSelected(!isUnorderedListSelected);
            dispatch(toggleUnorderedList());
          }}
          onMouseDown={(e) => e.preventDefault()}
          disabled={
            !isSelected ||
            approvalsEnabled ||
            isTableSelected ||
            isHeadingSelected ||
            isSubheadingSelected
          }
        />
        <Checkbox
          icon={<FormatListNumberedIcon />}
          checkedIcon={<FormatListNumberedIcon />}
          checked={isCheckedOrder}
          onClick={(e) => {
            setIsOrderedListSelected(!isOrderedListSelected);
            dispatch(toggleOrderedList());
          }}
          onMouseDown={(e) => e.preventDefault()}
          disabled={
            !isSelected ||
            approvalsEnabled ||
            isHeadingSelected ||
            isTableSelected ||
            isSubheadingSelected
          }
          style={{ width: "15px", padding: "10px", display: "center" }}
        />

        <Checkbox
          icon={<FormatIndentDecreaseIcon />}
          checkedIcon={<FormatIndentDecreaseIcon />}
          checked={false}
          disableRipple
          disabled={
            (selectedCurrentIndent <= 0 && isCustomIndentApplied) ||
            !isSelected ||
            approvalsEnabled ||
            isAutoIndentAppled ||
            (isHeadingSelected &&
              isEditorDisabled(selectedComponents.section?.heading)) ||
            isTableSelected
          }
          onClick={handleDecreaseIndentLeft}
          onMouseDown={(e) => e.preventDefault()}
        />

        <Checkbox
          icon={<FormatIndentIncreaseIcon />}
          checkedIcon={<FormatIndentIncreaseIcon />}
          checked={false}
          disableRipple
          disabled={
            !isSelected ||
            approvalsEnabled ||
            isTableSelected ||
            isAutoIndentAppled ||
            (isHeadingSelected &&
              isEditorDisabled(selectedComponents.section?.heading))
          }
          onClick={handleIncreaseIndent}
          onMouseDown={(e) => e.preventDefault()}
          style={{ width: "10px", padding: "10px", display: "center" }}
        />
      </ButtonGroup>

      <ButtonGroup>
        <Checkbox
          icon={<FormatAlignLeftIcon />}
          checkedIcon={<FormatAlignLeftIcon />}
          disableRipple
          checked={selectedjustify === "raggedright"}
          onClick={() => {
            if (isTableSelected) {
              handleUpdateTableCellAlignment("raggedright");
            } else {
              handleUpdateAlignment("raggedright");
            }
          }}
          onMouseDown={(e) => e.preventDefault()}
          disabled={
            !isSelected ||
            isTableSelected ||
            approvalsEnabled ||
            (isHeadingSelected &&
              isEditorDisabled(selectedComponents.section?.heading))
          }
        />

        <Checkbox
          icon={<FormatAlignCenterIcon />}
          checkedIcon={<FormatAlignCenterIcon />}
          checked={selectedjustify === "centering"}
          onClick={() => {
            if (isTableSelected) {
              handleUpdateTableCellAlignment("centering");
            } else {
              handleUpdateAlignment("centering");
            }
          }}
          onMouseDown={(e) => e.preventDefault()}
          disableRipple
          disabled={
            !isSelected ||
            approvalsEnabled ||
            isTableSelected ||
            isAutoIndentAppled ||
            (isHeadingSelected &&
              isEditorDisabled(selectedComponents.section?.heading))
          }
          style={{ width: "10px", padding: "10px", display: "center" }}
        />
        <Checkbox
          icon={<FormatAlignRightIcon />}
          checkedIcon={<FormatAlignRightIcon />}
          checked={selectedjustify === "raggedleft"}
          disableRipple
          onClick={() => {
            if (isTableSelected) {
              handleUpdateTableCellAlignment("raggedleft");
            } else {
              handleUpdateAlignment("raggedleft");
            }
          }}
          onMouseDown={(e) => e.preventDefault()}
          disabled={
            !isSelected ||
            approvalsEnabled ||
            isAutoIndentAppled ||
            isTableSelected ||
            (isHeadingSelected &&
              isEditorDisabled(selectedComponents.section?.heading))
          }
        />
        <Checkbox
          icon={<FormatAlignJustifyIcon />}
          checkedIcon={<FormatAlignJustifyIcon />}
          disableRipple
          checked={selectedjustify === "justifying"}
          onClick={() => {
            if (isTableSelected) {
              handleUpdateTableCellAlignment("justifying");
            } else {
              handleUpdateAlignment("justifying");
            }
          }}
          onMouseDown={(e) => e.preventDefault()}
          disabled={
            !isSelected ||
            approvalsEnabled ||
            isTableSelected ||
            isTableSelected ||
            (isHeadingSelected &&
              isEditorDisabled(selectedComponents.section?.heading))
          }
          style={{ width: "10px", padding: "10px", display: "center" }}
        />
      </ButtonGroup>

      <Tooltip title="Decrease Level">
        <Checkbox
          icon={<ArrowBackIcon />}
          checkedIcon={<ArrowBackIcon />}
          onClick={() => {
            handleLevelDecrease();
          }}
          onMouseDown={(e) => e.preventDefault()}
          disableRipple
          disabled={
            !isSelected ||
            approvalsEnabled ||
            isHeadingSelected ||
            !selectedComponents.documentElement ||
            (selectedComponents.documentElement?.content_type == "TEXT" &&
              JSON.parse(selectedComponents.documentElement?.content_format)
                .isNumberDisplay == false) ||
            isTableSelected
          }
          style={{
            // Override default highlighting styles
            width: "40px",
            padding: "10px",
            display: "center",
            outline: "none", // Remove the focus outline
            boxShadow: "none", // Remove any box shadow
            border: "1px solid transparent", // Remove the border
            backgroundColor: "transparent", // Set a transparent background
            color:
              !isSelected ||
              approvalsEnabled ||
              isHeadingSelected ||
              !selectedComponents.documentElement ||
              (selectedComponents.documentElement?.content_type == "TEXT" &&
                JSON.parse(selectedComponents.documentElement?.content_format)
                  .isNumberDisplay == false) ||
              isTableSelected
                ? "grey"
                : "black" // Inherit the text color
          }}
        />
      </Tooltip>
      <Tooltip title="Increase Level">
        <Checkbox
          icon={<ArrowForwardIcon />}
          checkedIcon={<ArrowForwardIcon />}
          onClick={() => {
            handleLevelIncrease();
          }}
          onMouseDown={(e) => e.preventDefault()}
          disableRipple
          disabled={
            !isSelected ||
            approvalsEnabled ||
            selectedComponents.documentElement?.heading_level === 6 ||
            isHeadingSelected ||
            isTableSelected ||
            (selectedComponents.documentElement?.content_type == "TEXT" &&
              JSON.parse(selectedComponents.documentElement?.content_format)
                .isNumberDisplay == false)
          }
          style={{
            // Override default highlighting styles
            width: "5px",
            padding: "5px",
            display: "center",
            outline: "none", // Remove the focus outline
            boxShadow: "none", // Remove any box shadow
            border: "1px solid transparent", // Remove the border
            backgroundColor: "transparent", // Set a transparent background
            color:
              !isSelected ||
              approvalsEnabled ||
              selectedComponents.documentElement?.heading_level === 6 ||
              (selectedComponents.documentElement?.content_type == "TEXT" &&
                JSON.parse(selectedComponents.documentElement?.content_format)
                  .isNumberDisplay == false) ||
              isHeadingSelected ||
              isTableSelected
                ? "grey"
                : "black" // Inherit the text color
          }}
        />
      </Tooltip>

      {isTextSelected ? (
        <Tooltip title="Remove Numbering">
          <Checkbox
            icon={<TagSharpIcon />}
            checkedIcon={<TagSharpIcon />}
            checked={
              isTextSelected &&
              selectedComponents.documentElement?.content_format
                ? !JSON.parse(selectedComponents.documentElement.content_format)
                    .isNumberDisplay
                : false
            }
            disableRipple
            onClick={() => {
              const isNumberDisplay =
                isTextSelected &&
                selectedComponents?.documentElement?.content_format
                  ? JSON.parse(
                      selectedComponents.documentElement.content_format
                    ).isNumberDisplay
                  : false;
              handleRemoveTextNumbering(!isNumberDisplay);
            }}
            onMouseDown={(e) => e.preventDefault()}
            disabled={
              !isSelected ||
              isHeadingSelected ||
              isSubheadingSelected ||
              approvalsEnabled
            }
            style={{
              // Override default highlighting styles
              width: "5px",
              // paddingLeft: "20px",
              marginLeft: "14px",
              display: "center",
              outline: "none", // Remove the focus outline
              boxShadow: "none", // Remove any box shadow
              border: "1px solid transparent", // Remove the border
              backgroundColor: "transparent", // Set a transparent background
              transition: "background-color 0.2s ease-in-out" // Transition for the background color change
            }}
          />
        </Tooltip>
      ) : null}

      <Tooltip title="Reset Formatting">
        <Checkbox
          icon={<SettingsBackupRestoreSharpIcon />}
          checkedIcon={<SettingsBackupRestoreSharpIcon />}
          checked={false}
          onClick={() => {
            handleResetSettings();
          }}
          disableRipple
          onMouseDown={(e) => e.preventDefault()}
          disabled={
            !isSelected ||
            isTableSelected ||
            approvalsEnabled ||
            (isHeadingSelected &&
              selectedComponents.section?.formatting != null &&
              JSON.parse(selectedComponents.section?.formatting)
                .isCustomSettings == false) ||
            (isTextSelected &&
              (selectedComponents.documentElement?.formatting
                ? false
                : true)) ||
            (isSubheadingSelected &&
              (selectedComponents.documentElement?.formatting ? false : true))
          }
          style={{ width: "20px", marginLeft: "10px", display: "center" }}
        />
      </Tooltip>
    </Box>
  );
}
