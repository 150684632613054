import Box from "@mui/material/Box";
import { grey } from "@mui/material/colors";
import { LeftDrawer } from "./drawers/left/LeftDrawer";
import { RightDrawer } from "./drawers/right/RightDrawer";
import Toolbar from "@mui/material/Toolbar";
import Editor from "./Editor";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectDocumentState, setError } from "./editorSlice";
import Alert from "@mui/material/Alert";
import { isUserFromShareLink } from "./document/TableElement/EditContent/helper";
import CommentsContainer from "../comments/CommentsContainer";
import { useSelector } from "react-redux";
import { RootState } from "src/app/store";
import { CircularProgress, Typography } from "@mui/material";

export default function EditorContainer() {
  const dispatch = useAppDispatch();
  const documentState = useAppSelector(selectDocumentState);
  function handleErrorClose() {
    dispatch(setError(undefined));
  }

  const documentLoaded = useSelector(
    (state: RootState) => state.apiError.documentLoaded
  );

  return (
    <Box sx={{ display: "flex" }}>
      <LeftDrawer></LeftDrawer>

      <Box sx={{ bgcolor: grey[400], flexGrow: 2, width: "100%" }}>
        {/* Slightly awkward way to pad the top as per MUI guidelines */}
        {!isUserFromShareLink() && <Toolbar />}

        <Editor />
      </Box>

      {isUserFromShareLink() ? (
        <></>
      ) : (
        <RightDrawer
          documentTemplate={documentState!.documentTemplate!}
        ></RightDrawer>
      )}
    </Box>
  );
}
