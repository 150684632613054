import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../app/store";
import {
  clearLocalUserState,
  clearRememberMeState,
  getLocalUserState,
  getRememberMeState,
  getSessionExpired,
  setLocalUserState,
} from "../../services/userAPI";
import {
  Organization,
  User,
  USER_ROLE,
  Workspace,
  WorkspaceUserRole,
} from "../../types/User";
import { calculateTrailRemainingDays } from "../headermenu/utils";
import { isUserFromShareLink } from "../editor/document/TableElement/EditContent/helper";

export interface AuthState {
  user: User | null;
  organizations: Organization[] | null;
  workspaces: any[] | null;
  organization_owner: boolean;
  Admins: [];
  status:
    | "logged-in"
    | "loading"
    | "logged-out"
    | "change-password"
    | "update-user-details";
  error: string | undefined;
  creditStatus: "idle" | "loading" | "failed" | "success";
  createNewUserError: string | undefined;
  errorMsg: {
    status: "success" | "failed" | "idle" | "loading";
    errorType: undefined | string;
    msg: undefined | string;
  };
}

const savedAuthState = getLocalUserState();
const rememberMeState = getRememberMeState();
const isSessionExpired = getSessionExpired();

const initialState: AuthState = {
  user: savedAuthState != null ? savedAuthState : null,
  organizations: null,
  workspaces: null,
  organization_owner: false,
  Admins: [],
  // status: "logged-out",
  // if user exist in localstorage relogin the user
  // && rememberMeState
  status: isUserFromShareLink()
    ? "logged-in"
    : savedAuthState != null && !isSessionExpired
    ? "logged-in"
    : "logged-out",
  error: undefined,
  creditStatus: "idle",
  createNewUserError: undefined,
  errorMsg: {
    status: "idle",
    errorType: undefined,
    msg: undefined,
  },
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setStatus: (
      state,
      action: PayloadAction<{
        status: "logged-in" | "loading" | "logged-out" | "change-password";
        error: string | undefined;
      }>
    ) => {
      state.status = action.payload.status;
      state.error = action.payload.error;
    },
    setError: (state, action: PayloadAction<string | undefined>) => {
      state.error = action.payload;
    },

    setCreaterUserError: (state, action: PayloadAction<string | undefined>) => {
      state.createNewUserError = action.payload;
    },

    setErrorMsg: (
      state,
      action: PayloadAction<{
        errorType: string | undefined;
        errorMsg: string | undefined;
        status: "idle" | "failed" | "loading" | "success";
      }>
    ) => {
      state.errorMsg.errorType = action.payload.errorType;
      state.errorMsg.msg = action.payload.errorMsg;
      state.errorMsg.status = action.payload.status;
    },

    setCreditStatus: (
      state,
      action: PayloadAction<"idle" | "failed" | "loading" | "success">
    ) => {
      state.creditStatus = action.payload;
    },

    getUserFromLocalState: (state) => {
      state.user = getLocalUserState();
    },
    setUser: (state, action: PayloadAction<User | null>) => {
      state.user = action.payload;

      if (state.user !== null && state.user.first_login) {
        if (state.user !== null) setLocalUserState(state.user);
        else {
          clearLocalUserState();
          clearRememberMeState();
        }
        state.status = "change-password";
        //  state.status="update-user-details"
      } else {
        state.status = "logged-in";

        // Save the auth state to local storage.
        if (state.user != null) setLocalUserState(state.user);
        else clearLocalUserState();
      }
    },
    updateOrganizationUsers: (
      state,
      action: PayloadAction<{ organizationId: number; users: User[] | null }>
    ) => {
      const organization = state.organizations?.findIndex(
        (org) => org.id === action.payload.organizationId
      );
      if (organization != null) {
        state.organizations![organization] = Object.assign(
          state.organizations![organization],
          { users: action.payload.users }
        );
      }
    },

    updateOrganizationAdminUsers: (
      state,
      action: PayloadAction<{ organizationId: number; users: User[] | null }>
    ) => {
      const organization = state.organizations?.findIndex(
        (org) => org.id === action.payload.organizationId
      );
      if (organization != null) {
        state.organizations![organization] = Object.assign(
          state.organizations![organization],
          { adminUsers: action.payload.users }
        );
      }
    },

    setOrganizations: (state, action: PayloadAction<Organization[]>) => {
      state.organizations = action.payload;
    },
    setOrganizationOwner: (
      state,
      action: PayloadAction<{ is_owner: boolean }>
    ) => {
      state.organization_owner = action.payload.is_owner;
    },

    setOrganizationAdmins: (
      state,
      action: PayloadAction<{ adminList: [] }>
    ) => {
      state.Admins = action.payload.adminList;
    },

    setWorkspaces: (state, action: PayloadAction<Workspace[]>) => {
      state.workspaces = action.payload;
    },
    setWorkspaceLocal: (state, action: PayloadAction<Workspace>) => {
      const index = state.workspaces?.findIndex(
        (w) => w.id === action.payload.id
      );
      if (index != null) state.workspaces![index] = action.payload;
    },
    logout: (state) => {
      state.error = undefined;
      state.user = null;
      state.organizations = [];
      clearLocalUserState();
      clearRememberMeState();
      state.status = "logged-out";
    },
  },
});

export const {
  setStatus,
  setError,
  logout,
  setCreditStatus,
  getUserFromLocalState,
  setUser,
  setOrganizations,
  setOrganizationOwner,
  updateOrganizationUsers,
  setWorkspaces,
  setWorkspaceLocal,
  updateOrganizationAdminUsers,
  setCreaterUserError,
  setErrorMsg,
} = authSlice.actions;

//Selectors
export const selectAuth = (state: RootState) => state.auth;
export const selectUser = (state: RootState) => state.auth.user;

export const selectResponseError = (state: RootState) => state.auth.errorMsg;

export const selectCreateUserError = (state: RootState) =>
  state.auth.createNewUserError;

export const selectOrganizationDetails = (state: RootState) => {
  const currentOrg = state.auth?.organizations?.find(
    (o) => state.auth.user?.default_organization_id === o.id
  );

  return {
    organization: currentOrg,
    trail:
      currentOrg?.amnesty_mode === "FREE_TRIAL" &&
      currentOrg.expiry_date != null,
    remainingDays: calculateTrailRemainingDays(currentOrg?.expiry_date)
      .remainingDays,
    // trail:true,
    // remainingDays:0,
    // isTrialExpired:true
    isTrialExpired:
      currentOrg?.amnesty_mode === "FREE_TRIAL" &&
      currentOrg.expiry_date != null &&
      calculateTrailRemainingDays(currentOrg?.expiry_date).remainingDays <= 0,
  };
};

export const selectOrganizations = (state: RootState) =>
  state.auth.organizations;

export const selectUserRole = (state: RootState) => {
  if (state.auth.user?.super_user) {
    return USER_ROLE.SUPER_USER;
  } else if (state.auth.organization_owner) {
    return USER_ROLE.ADMIN;
  } else {
    return USER_ROLE.USER;
  }
};

export const selectWorkspaces = (state: RootState) => state.auth.workspaces;
export const selectCreditStatus = (state: RootState) => state.auth.creditStatus;
export const selectCurrentOrganization = (state: RootState) =>
  state.auth?.organizations?.find(
    (o) => state.auth.user?.default_organization_id === o.id
  );

export const selectOrganizationOwner = (state: RootState) =>
  state.auth?.organization_owner;
export const selectCurrentWorkspace = (state: RootState) =>
  state.auth?.workspaces?.find(
    (w) => state.auth.user?.default_workspace_id === w.id
  );
export const selectCurrentWorkspaceRole = (state: RootState) => {
  const currentWorkspace = selectCurrentWorkspace(state);
  const currentUser = selectUser(state);
  const userRole = currentWorkspace?.user_roles?.find(
    (userRole: WorkspaceUserRole) => userRole.user.id === currentUser?.user_id
  );
  //console.log("999", currentWorkspace, userRole?.role_type);
  return userRole?.role_type;
};

export default authSlice.reducer;
