import "./subscriptionStyle.css";
import {
  Checkbox,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Fade,
  Zoom,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/CheckCircle";
import { PaymentPlan } from "./utils";
import { useState } from "react";

export default function DetailCard(props: {
  title: string;
  content: string;
  paymentPlan: any;
  setPaymentPlan: any;
  plans?: PaymentPlan[];
  subscribedPlan: any;
}) {
  const [hovered, setHovered] = useState(false);

  return (
    <Zoom in={true}>
      <Paper
        elevation={4}
        sx={{
          p: 3,
          mb: 3,
          borderRadius: 2,
          backgroundColor: "#ffffff",
          boxShadow: "0 4px 10px rgba(0,0,0,0.1)",
          overflow: "hidden",
          position: "relative",
          border: "2px solid transparent",
          transition: "border-color 0.3s ease-in-out, transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
          transform: hovered ? "scale(1.02)" : "scale(1)",
          "&:hover": {
            borderColor: "#4C33FF",
            transform: "scale(1.02)",
            boxShadow: "0 0 20px rgba(76, 51, 255, 0.7)", // Glow effect
          },
          "&:hover::before": {
            content: '""',
            position: "absolute",
            top: "-2px",
            left: "-2px",
            width: "calc(100% + 4px)",
            height: "calc(100% + 4px)",
            border: "2px solid transparent",
            borderRadius: "10px",
            transition: "border-color 0.3s ease-in-out",
            borderColor: "#4C33FF",
          },
        }}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <Typography
          variant="h6"
          gutterBottom
          sx={{
            fontWeight: "bold !important",
            textAlign: "center",
            color: "#3f51b5",
          }}
        >
          {props.title}
        </Typography>
        <Typography
          variant="body2"
          gutterBottom
          sx={{ textAlign: "center", color: "text.secondary" }}
        >
          {props.content}
        </Typography>
        <Table sx={{ mt: 2, borderRadius: "8px", overflow: "hidden" }}>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#4C33FF" }}>
              <TableCell
                align="center"
                sx={{ color: "#ffffff", fontWeight: "bold" }}
              >
                Select
              </TableCell>
              <TableCell
                align="center"
                sx={{ color: "#ffffff", fontWeight: "bold" }}
              >
                Price
              </TableCell>
              <TableCell
                align="center"
                sx={{ color: "#ffffff", fontWeight: "bold" }}
              >
                Credits
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(props.plans || []).map((plan, index) => (
              <Fade in={true} timeout={500} key={index}>
                <TableRow
                  key={index}
                  sx={{
                    backgroundColor:
                      plan.planId === props.subscribedPlan
                        ? "#e3f2fd"
                        : "#ffffff",
                    transition: "background-color 0.3s ease, transform 0.3s ease",
                    "&:hover": {
                      backgroundColor: "#bbdefb",
                      transform: "scale(1.02)",
                    },
                    "&.Mui-selected": {
                      backgroundColor: "#c5cae9",
                    },
                  }}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      icon={<CheckIcon />}
                      checkedIcon={<CheckIcon color="primary" />}
                      checked={props.paymentPlan === plan.id}
                      onChange={() => {
                        if (props.paymentPlan === plan.id) {
                          props.setPaymentPlan(null);
                        } else {
                          props.setPaymentPlan(plan.id);
                        }
                      }}
                      sx={{
                        "& .MuiSvgIcon-root": {
                          transition: "transform 0.3s ease, color 0.3s ease",
                        },
                        "&.Mui-checked .MuiSvgIcon-root": {
                          transform: "scale(1.2)",
                          color: "#4C33FF",
                        },
                      }}
                    />
                  </TableCell>
                  <TableCell align="center">ZAR {plan.price}</TableCell>
                  <TableCell align="center">{plan.credits}</TableCell>
                </TableRow>
              </Fade>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </Zoom>
  );
}
