import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
  Box,
  Divider,
  Paper,
  Grid,
  IconButton,
  Collapse,
} from "@mui/material";
import {
  ArrowUpward,
  ArrowDownward,
  ArrowBack,
  ArrowForward,
  Tab as TabIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import LowPriorityIcon from "@mui/icons-material/LowPriority";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import PlaylistRemoveIcon from "@mui/icons-material/PlaylistRemove";
import FormatPaintIcon from "@mui/icons-material/FormatPaint";

interface ShortcutDialogProps {
  closeHandle: () => void;
  open: boolean;
}

const ShortcutDialog: React.FC<ShortcutDialogProps> = ({
  closeHandle,
  open,
}) => {
  const [isShortcutCollapsed, setIsShortcutCollapsed] = React.useState(true);
  const [isDynamicCollapsed, setIsDynamicCollapsed] = React.useState(true);
  const [isImportCollapsed, setIsImportCollapsed] = React.useState(true);
  const [isContextCollapsed, setIsContextCollapsed] = React.useState(true);

  const customStyle = {
    color: "#4C33FF",
    fontSize: "2em",
  };

  const shortcuts = [
    {
      keys: "Tab",
      action: "Jump to Next Cell",
      icon: (
        <TabIcon
          style={{
            ...customStyle,
          }}
        />
      ),
    },
    {
      keys: "Shift + Tab",
      action: "Jump to Previous Cell",
      icon: <TabIcon style={{ transform: "scaleX(-1)", ...customStyle }} />,
    },
    {
      keys: "Ctrl + Shift + F",
      action: "Format Table",
      icon: (
        <FormatPaintIcon
          style={{
            ...customStyle,
          }}
        />
      ),
    },
    {
      keys: "Shift + Arrow Up",
      action: "Move Up",
      icon: (
        <ArrowUpward
          style={{
            ...customStyle,
          }}
        />
      ),
    },
    {
      keys: "Shift + Arrow Down",
      action: "Move Down",
      icon: (
        <ArrowDownward
          style={{
            ...customStyle,
          }}
        />
      ),
    },
    {
      keys: "Shift + Arrow Left",
      action: "Move Left",
      icon: (
        <ArrowBack
          style={{
            ...customStyle,
          }}
        />
      ),
    },
    {
      keys: "Shift + Arrow Right",
      action: "Move Right",
      icon: (
        <ArrowForward
          style={{
            ...customStyle,
          }}
        />
      ),
    },
    {
      keys: "Ctrl + Shift + Arrow Up",
      action: "Insert Row Above",
      icon: (
        <VerticalAlignBottomIcon
          style={{
            transform: "scaleY(-1)",
            ...customStyle,
          }}
        />
      ),
    },
    {
      keys: "Ctrl + Shift + Arrow Down",
      action: "Insert Row Below",
      icon: (
        <VerticalAlignBottomIcon
          style={{
            ...customStyle,
          }}
        />
      ),
    },
    {
      keys: "Ctrl + Shift + Arrow Left",
      action: "Insert Column Left",
      icon: (
        <VerticalAlignBottomIcon
          style={{
            transform: "rotate(90deg) ",
            ...customStyle,
          }}
        />
      ),
    },
    {
      keys: "Ctrl + Shift + Arrow Right",
      action: "Insert Column Right",
      icon: (
        <VerticalAlignBottomIcon
          style={{
            transform: "rotate(270deg)",
            ...customStyle,
          }}
        />
      ),
    },
    {
      keys: "Ctrl + Alt + Arrow Up",
      action: "Duplicate Row Above",
      icon: (
        <ContentCopyIcon
          style={{
            ...customStyle,
          }}
        />
      ),
    },
    {
      keys: "Ctrl + Alt + Arrow Down",
      action: "Duplicate Row Below",
      icon: (
        <ContentCopyIcon
          style={{
            ...customStyle,
          }}
        />
      ),
    },
    {
      keys: "Ctrl + Alt + Arrow Left",
      action: "Duplicate Column Left",
      icon: (
        <ContentCopyIcon
          style={{
            ...customStyle,
          }}
        />
      ),
    },
    {
      keys: "Ctrl + Alt + Arrow Right",
      action: "Duplicate Column Right",
      icon: (
        <ContentCopyIcon
          style={{
            ...customStyle,
          }}
        />
      ),
    },
    {
      keys: "Alt + Shift + Arrow Up",
      action: "Move Row Above",
      icon: (
        <LowPriorityIcon style={{ transform: "scaleY(-1)", ...customStyle }} />
      ),
    },
    {
      keys: "Alt + Shift + Arrow Down",
      action: "Move Row Below",
      icon: (
        <LowPriorityIcon
          style={{
            ...customStyle,
          }}
        />
      ),
    },
    {
      keys: "Alt + Shift + Arrow Left",
      action: "Move Column Left",
      icon: (
        <LowPriorityIcon
          style={{ transform: "rotate(90deg)", ...customStyle }}
        />
      ),
    },
    {
      keys: "Alt + Shift + Arrow Right",
      action: "Move Column Right",
      icon: (
        <LowPriorityIcon
          style={{ transform: "rotate(270deg) scaleX(-1)", ...customStyle }}
        />
      ),
    },
    {
      keys: "Alt  + Delete",
      action: "Delete Entire Row",
      icon: <PlaylistRemoveIcon style={{ ...customStyle }} />,
    },
    {
      keys: "Ctrl + Delete",
      action: "Delete Entire Column",
      icon: (
        <PlaylistRemoveIcon
          style={{ transform: "rotate(270deg) scaleX(-1)", ...customStyle }}
        />
      ),
    },
  ];

  return (
    <Dialog open={open} onClose={closeHandle} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h5" flexGrow={1}>
            Quick Guide
          </Typography>
          <IconButton
            aria-label="close"
            onClick={closeHandle}
            sx={{ marginLeft: "auto" }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        {/* <Paper
          sx={{
            p: 1,

            my: 3,
            border: "1px solid #f0f0f0", // Light grey border
            boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.1)" // Box shadow for a subtle glow
          }}
        >
          <Grid container alignItems="center">
            <Grid item xs={10}>
              <Typography variant="h6" sx={{ fontWeight: "600 !important" }}>
                Shortcut Keys
              </Typography>
            </Grid>
            <Grid item xs={2} container justifyContent="flex-end">
              <IconButton
                onClick={() => setIsShortcutCollapsed((prev) => !prev)}
                aria-expanded={!isShortcutCollapsed}
                aria-label="toggle grid"
              >
                {isShortcutCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
              </IconButton>
            </Grid>
          </Grid>

          <Collapse in={!isShortcutCollapsed} timeout="auto">
            <List>
              {shortcuts.map((shortcut, index) => (
                <React.Fragment key={index}>
                  <ListItem>
                    <ListItemIcon>{shortcut.icon}</ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography
                          variant="body1"
                          style={{ fontWeight: "bold" }}
                        >
                          {shortcut.keys}
                        </Typography>
                      }
                      secondary={shortcut.action}
                    />
                  </ListItem>
                  {index < shortcuts.length - 1 && <Divider />}
                </React.Fragment>
              ))}
            </List>
          </Collapse>
        </Paper> */}

        {/* <Paper
          elevation={1}
          sx={{
            p: 1,
            my: 3,
            border: "1px solid #f0f0f0", // Light grey border
            boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.1)" // Box shadow for a subtle glow
          }}
        >
          <Grid container alignItems="center">
            <Grid item xs={10}>
              <Typography variant="h6" sx={{ fontWeight: "600 !important" }}>
                Context Menu
              </Typography>
            </Grid>
            <Grid item xs={2} container justifyContent="flex-end">
              <IconButton
                onClick={() => setIsContextCollapsed((prev) => !prev)}
                aria-expanded={!isContextCollapsed}
                aria-label="toggle grid"
              >
                {isContextCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
              </IconButton>
            </Grid>
          </Grid>

          <Collapse in={!isContextCollapsed} timeout="auto">
            <Typography variant="body1">
              Right-click on a cell to access the context menu with the
              following options:
            </Typography>
            <Typography variant="body1">
              <ul>
                <li>
                  <strong>Insert:</strong> Add rows or columns.
                </li>
                <ul>
                  <li>Insert Row Above</li>
                  <li>Insert Row Below</li>
                  <li>Insert Column to the Left</li>
                  <li>Insert Column to the Right</li>
                </ul>
                <li>
                  <strong>Duplicate:</strong> Copy rows or columns.
                </li>
                <ul>
                  <li>Duplicate Row Above</li>
                  <li>Duplicate Row Below</li>
                  <li>Duplicate Column to the Left</li>
                  <li>Duplicate Column to the Right</li>
                </ul>
                <li>
                  <strong>Move:</strong> Shift rows or columns.
                </li>
                <ul>
                  <li>Move Row Above</li>
                  <li>Move Row Below</li>
                  <li>Move Column to the Left</li>
                  <li>Move Column to the Right</li>
                </ul>
                <li>
                  <strong>Delete:</strong> Remove rows or columns.
                </li>
                <ul>
                  <li>Delete Entire Row</li>
                  <li>Delete Entire Column</li>
                </ul>
                <li>
                  <strong>Background Color:</strong> Change cell, row, or column
                  color.
                </li>
                <ul>
                  <li>Change Cell Color</li>
                  <li>Change Entire Row Color</li>
                  <li>Change Entire Column Color</li>
                </ul>
                <li>
                  <strong>Content Color:</strong> Change text color in cells,
                  rows, or columns.
                </li>
                <ul>
                  <li>Change Cell Text Color</li>
                  <li>Change Entire Row Text Color</li>
                  <li>Change Entire Column Text Color</li>
                </ul>
                <li>
                  <strong>Column Width:</strong> Adjust column width.
                </li>
                <ul>
                  <li>Custom Width</li>
                  <li>Reset To Default</li>
                  <li>5mm, 10mm, 15mm</li>
                </ul>
                <li>
                  <strong>Row Alignment:</strong> Align text in rows.
                </li>
                <ul>
                  <li>Align Left</li>
                  <li>Align Center</li>
                  <li>Align Right</li>
                </ul>
                <li>
                  <strong>Column Alignment:</strong> Align text in columns.
                </li>
                <ul>
                  <li>Align Left</li>
                  <li>Align Center</li>
                  <li>Align Right</li>
                </ul>
              </ul>
            </Typography>
          </Collapse>
        </Paper> */}

        <Paper
          elevation={1}
          sx={{
            p: 1,
            my: 3,
            border: "1px solid #f0f0f0", // Light grey border
            boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.1)", // Box shadow for a subtle glow
          }}
        >
          <Grid container alignItems="center">
            <Grid item xs={10}>
              <Typography variant="h6" sx={{ fontWeight: "600 !important" }}>
                Import from Excel
              </Typography>
            </Grid>
            <Grid item xs={2} container justifyContent="flex-end">
              <IconButton
                onClick={() => setIsImportCollapsed((prev) => !prev)}
                aria-expanded={!isImportCollapsed}
                aria-label="toggle grid"
              >
                {isImportCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
              </IconButton>
            </Grid>
          </Grid>

          <Collapse in={!isImportCollapsed} timeout="auto">
            <Typography variant="body1">
              Most convenient way to create a table from an existing Excel file
              in a couple of clicks
            </Typography>
            <Typography variant="body1">
              <ul>
                <li>Click on the Import icon above the table.</li>
                <li>Upload your desired Excel file.</li>
                <li>Press "IMPORT"</li>
                <li>
                  It will inform you about the number of rows and columns that
                  will be imported.
                </li>
                <li>
                  Press "CREATE" to create a table with your desired content.
                </li>
                <li>
                  Click the Settings icon and apply your desired table theme.
                </li>
              </ul>
            </Typography>
          </Collapse>
        </Paper>

        <Paper
          elevation={1}
          sx={{
            p: 1,
            my: 3,
            border: "1px solid #f0f0f0", // Light grey border
            boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.1)", // Box shadow for a subtle glow
          }}
        >
          <Grid container alignItems="center">
            <Grid item xs={10}>
              <Typography variant="h6" sx={{ fontWeight: "600 !important" }}>
                Dynamic Table
              </Typography>
            </Grid>
            <Grid item xs={2} container justifyContent="flex-end">
              <IconButton
                onClick={() => setIsDynamicCollapsed((prev) => !prev)}
                aria-expanded={!isDynamicCollapsed}
                aria-label="toggle grid"
              >
                {isDynamicCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
              </IconButton>
            </Grid>
          </Grid>

          <Collapse in={!isDynamicCollapsed} timeout="auto">
            <Typography variant="body1">
              Placeholder to create a dynamic table of unknown rows using
              Postman Collection.
            </Typography>
            <Typography variant="body1">
              <ul>
                <li>Turn on the Dynamic Table toggle.</li>
                <li>
                  Select a placeholder from the existing dynamic table list or
                  create a new placeholder.
                </li>
                <li>Press "SAVE" to create the dynamic table.</li>
                <li>
                  Now it will function like a regular table. You can add/edit
                  content and generate/preview as usual.
                </li>
                <li>
                  Using the Postman collection and "API Details," you can
                  generate a dynamic table.
                </li>
              </ul>
            </Typography>
          </Collapse>
        </Paper>
      </DialogContent>
    </Dialog>
  );
};

export default ShortcutDialog;
