import React, { useEffect, useRef, useState } from "react";
import StarIcon from "@mui/icons-material/Star";
import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Organization, User, Workspace } from "../../types/User";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import WorkspaceConfigForm from "./WorkspaceConfigForm";
import OrganizationConfigForm from "./OrganizationConfigForm";
import Divider from "@mui/material/Divider";
import { keyframes } from "@mui/system";

import {
  selectAuth,
  selectCurrentWorkspaceRole,
  selectOrganizationOwner,
} from "../auth/authSlice";
import useActions, { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  clearFilters,
  setClearLibraryDocuments,
  setDocumentRecords,
  setDocumentTemplates,
} from "../library/librarySlice";
import { DocumentRecord, DocumentTemplate } from "../../types/DocumentTemplate";
import { fontSize } from "@mui/system";
import { setClearLibraryRecords } from "../library/documentRecordsSlice";
import DeleteWorkspaceDialog from "./DeleteWorkspaceDialog";
import { useSelector } from "react-redux";
import { RootState } from "src/app/store";

/**
 * The organization and workspace menu.
 *
 * @export
 * @param {{user: User | null, organizations: Organization[] | null, workspaces: Workspace[] | null}} props
 * @return {*}
 */
export function OrganizationsMenu(props: {
  user: User | null;
  organizations: Organization[] | null;
  workspaces: Workspace[] | null;
}) {
  const actions = useActions();
  const dispatch = useAppDispatch();
  const authState = useAppSelector(selectAuth);
  const [anchorElOrg, setAnchorElOrg] = React.useState<null | HTMLElement>(
    null
  );

  const [showDeleteWorkspaceDialog, setshowDeleteWorkspaceDialog] =
    useState(false);

  const currentWorkspaceRole = useAppSelector(selectCurrentWorkspaceRole);
  const organizationOwner = useAppSelector(selectOrganizationOwner);

  const handleOpenOrgMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElOrg(event.currentTarget);
  };

  const handleCloseOrgMenu = () => {
    setAnchorElOrg(null);
  };

  const [anchorElWorkspace, setAnchorElWorkspace] =
    React.useState<null | HTMLElement>(null);

  const handleOpenWorkspaceMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElWorkspace(event.currentTarget);
  };

  const handleCloseWorkspaceMenu = () => {
    setAnchorElWorkspace(null);
  };

  const handleClickOrg = (org: Organization) => {
    setAnchorElOrg(null);
    if (props.user?.default_organization_id !== org.id)
      dispatch(setClearLibraryDocuments());
    dispatch(setClearLibraryRecords());
    actions.setOrganization(org);
  };

  const handleClickWorkspace = (workspace: Workspace) => {
    setAnchorElWorkspace(null);
    actions.setWorkspace(workspace);
  };

  const [waitingWorkspace, setWaitingWorkspace] = React.useState<string>("");
  const [isCreatingNewWorkspace, setIsCreatingNewWorkspace] =
    React.useState<boolean>(false);
  const [openWorkspaceConfig, setOpenWorkspaceConfig] =
    React.useState<boolean>(false);

  useEffect(() => {
    if (waitingWorkspace != "") {
      const found = props.workspaces!.filter((_) => _.name == waitingWorkspace);
      if (found.length > 0) {
        setIsCreatingNewWorkspace(false);
        setOpenWorkspaceConfig(true);
        setWaitingWorkspace("");
      }
    }
  });

  const [openOrganizationConfig, setOpenOrganizationConfig] =
    React.useState<boolean>(false);

  const currentError = useSelector(
    (state: RootState) => state.apiError.currentError
  );

  const selectedOrg = props.organizations?.find(
    (org) => org.id === props.user?.default_organization_id
  );
  const selectedWorkspace = props.workspaces?.find(
    (workspace) => workspace.id === props.user?.default_workspace_id
  );

  const organizationMenuItems = props.organizations?.map((org) => (
    <MenuItem
      key={org.id + "__"}
      onClick={(e) => handleClickOrg(org)}
      selected={org === selectedOrg ? true : false}
    >
      {org === selectedOrg ? (
        <ListItemIcon>
          <StarIcon></StarIcon>
        </ListItemIcon>
      ) : null}
      <ListItemText key={org.name}> {org.name}</ListItemText>
    </MenuItem>
  ));

  const workspaceMenuItems = props.workspaces?.map((workspace) => (
    <MenuItem
      key={workspace.id + "__"}
      onClick={(e) => {
        // clear filters of currently selected workspace
        dispatch(clearFilters());
        handleClickWorkspace(workspace);
      }}
      selected={workspace === selectedWorkspace ? true : false}
    >
      <ListItemIcon>
        {workspace === selectedWorkspace ? <StarIcon></StarIcon> : null}
      </ListItemIcon>
      <ListItemText>{workspace.name}</ListItemText>
      {organizationOwner && workspace.name != "FREE CONTENT" && (
        <IconButton
          edge="end"
          aria-label="edit"
          onClick={(e) => {
            // Update the workspace

            // actions.getWorkspace(workspace.id);
            // not to show Previus workspace Document here.
            // dispatch(setDocumentTemplates([]));
            // dispatch(setDocumentRecords([]));
            // Edit it.
            setIsCreatingNewWorkspace(false);
            setOpenWorkspaceConfig(true);
          }}
        >
          <MoreHorizIcon />
        </IconButton>
      )}
    </MenuItem>
  ));

  const colorSlide = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

  const workspaceStyle = {
    borderRadius: "3px",
    fontSize: "0.8em",
    fontWeight: "bold",
    boxShadow: "0 0 10px rgba(0, 136, 255, 0.8)", // Glowing effect
    background: "linear-gradient(45deg, #2196F3, #21CBF3,  #4C33FF)", // Vibrant background with additional color for animation
    backgroundSize: "200% 200%",
    animation: `${colorSlide} 4s ease infinite`, // Continuous color slide animation
    transition: "box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out",
  };

  return (
    <Box sx={{ flexGrow: 0 }}>
      <Tooltip title="Organizations">
        <Button
          color="info"
          // fontSize:"1em" , fontWeight:"bold" , px:1
          sx={{ borderRadius: "3px", fontSize: "0.8em" }} // Set border radius to 0
          variant="contained"
          onClick={handleOpenOrgMenu}
          endIcon={
            anchorElOrg == null ? (
              <KeyboardArrowDownIcon />
            ) : (
              <KeyboardArrowUpIcon />
            )
          }
        >
          {selectedOrg?.name}
        </Button>
      </Tooltip>
      {" / "}
      <Tooltip title="Workspaces">
        <Button
          color="info"
          // fontSize:"1em" , fontWeight:"bold" , px:1
          sx={
            props.workspaces?.length == 0 && authState.status
              ? workspaceStyle
              : { borderRadius: "3px", fontSize: "0.8em" }
          }
          variant="contained"
          onClick={handleOpenWorkspaceMenu}
          endIcon={
            anchorElWorkspace == null ? (
              <KeyboardArrowDownIcon />
            ) : (
              <KeyboardArrowUpIcon />
            )
          }
        >
          {selectedWorkspace
            ? selectedWorkspace.name
            : props.workspaces?.length == 0 && authState.status != "loading"
            ? "Create workspace here"
            : "[No Workspace Selected]"}
        </Button>
      </Tooltip>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElOrg}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElOrg)}
        onClose={handleCloseOrgMenu}
      >
        {/* { organizationOwner &&
         <MenuItem
          onClick={(e) => {
            setOpenOrganizationConfig(true);
          }}
        >
          <ListItemText>User Administration</ListItemText>
        </MenuItem>} */}
        <Divider></Divider>
        {organizationMenuItems}
      </Menu>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElWorkspace}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElWorkspace)}
        onClose={handleCloseWorkspaceMenu}
      >
        {organizationOwner && (
          <MenuItem
            onClick={(e) => {
              setIsCreatingNewWorkspace(true);
              setOpenWorkspaceConfig(true);
            }}
          >
            <ListItemIcon>
              <AddIcon />
            </ListItemIcon>
            <ListItemText>Create New Workspace</ListItemText>
          </MenuItem>
        )}
        <Divider></Divider>
        {workspaceMenuItems}
      </Menu>
      {openWorkspaceConfig && !showDeleteWorkspaceDialog ? (
        <WorkspaceConfigForm
          setshowDeleteWorkspaceDialog={setshowDeleteWorkspaceDialog}
          workspace={isCreatingNewWorkspace ? undefined : selectedWorkspace}
          workspaces={props.workspaces!}
          organization={selectedOrg!}
          closeDialogHandler={() => {
            setIsCreatingNewWorkspace(false);
            setOpenWorkspaceConfig(false);
          }}
          newWorkspaceHandler={(name: string) => {
            setIsCreatingNewWorkspace(false);
            setOpenWorkspaceConfig(false);
            setWaitingWorkspace(name);
          }}
        ></WorkspaceConfigForm>
      ) : null}
      {openOrganizationConfig ? (
        <OrganizationConfigForm
          workspaces={props.workspaces!}
          organization={selectedOrg!}
          closeDialogHandler={() => {
            setOpenOrganizationConfig(false);
          }}
        ></OrganizationConfigForm>
      ) : null}
    </Box>
  );
}
