/* WYSIWYG Editing cell, which produces and parses markdown.
Draft.js react editor.
REF: https://jpuri.github.io/react-draft-wysiwyg/#/docs.
REF: https://draftjs.org/docs/api-reference-data-conversion
Exporting and importing reference: https://www.npmjs.com/package/markdown-draft-js
*/

// React and hooks
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { memo } from "react";

// Material-UI components and icons
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Grid, IconButton } from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import UndoIcon from "@mui/icons-material/Undo";
import FastRewindIcon from "@mui/icons-material/FastRewind";

// Third-party libraries
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertFromRaw, Modifier, RichUtils } from "draft-js";
import { useSnackbar } from "notistack";
import { useDrop } from "react-dnd";
import { DropSkeleton } from "./DropSkeleton";
import { Typography } from "@mui/material";
import { shallowEqual, TypedUseSelectorHook, useSelector } from "react-redux";
import { RootState } from "../../../app/rootReducer";

// Custom components
import VersionElementSelector from "./versionControl/VersionElementSelector";
import SectionVersionControl from "./versionControl/SectionVersionControl";
import { isIncludeImpact } from "../drawers/right/Approval/ApprovalHelperFunctions/approvalHelper";
import EditorUndoForm from "./CustomEditorDecorators/EditorUndoForm";
import DeleteConfirmForm from "./CustomEditorDecorators/DeleteConfirmForm";
import LibraryContentIcon from "./CustomEditorDecorators/LibraryContentIcon";
import { convertMmToPt } from "./TableElement/HelperFuctions/TableUtils";
import { DebugID } from "./DebugID";
import "./style.css";
import { ViewPreferences } from "../header/preferencesSlice";
import ApprovalIcon from "./CustomEditorDecorators/ApporvalIcon";
import DeleteUnsharedConfirm from "./CustomEditorDecorators/DeleteUnsharedConfirm";
import LibraryContentConfigFormSubSection from "./libraryContentSubSection/LibraryContentFormSubSection";
// Snackbar notification integration. REF: https://www.npmjs.com/package/material-ui-snackbar-provider

// Types and Globle
import {
  ContentType,
  DocumentElement,
  DocumentSection,
  DocumentSectionSummary,
  DocumentSubsection,
  DocumentTemplate,
  DocumentTemplateSummary,
} from "../../../types/DocumentTemplate";
import { dummyString, secondarySilver } from "../../../globals";
import { COLORS } from "../../shared/Constants";
import { ModalState } from "../../shared/Constants";
import { DocumentMaster } from "../../../types/DocumentMaster";

// Custom hooks and utilities
import {
  DocumentMasterState,
  selectDocumentMasterState,
  selectSectionsDocumentMasterState,
  selectFormattingSection,
} from "../header/documentMasterSlice";
import useActions, { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  clearSelectedComponent,
  selectDocumentState,
  selectElementBelowState,
  selectStatusState,
  setSelectedComponent,
  selectApprovalState,
  selectCurrentTemplateState,
  selectsHeadingNumber,
  selectSectionsNumber,
  selectsTextNumber,
  selectSelectedCustomSelector,
} from "../editorSlice";
import {
  makeSelectCurrentEditorStateCustom,
  selectCurrentEditorState,
  setEditorState,
} from "../markdownTextEditorSlice";
import validateContent from "./validation";

// Custom Function
import {
  findChildSubSections,
  findChildSubSectionsOfSubHeadingH6,
  findParentElementToFindPosition,
  findParentSubHeading,
  findParentSubHeadingLevel,
  findParentTextElementLevelDetails,
} from "../../../app/sagas/utils";

import {
  attachedSubsectionElement,
  editorStateToMarkdown,
  isDeletedSubsection,
  markdowntoEditorState,
  normalizeHeadingDifference,
  normalizeText,
} from "./utils";

import {
  applySeperators,
  applySeperatorsHeader,
  customStyleMap,
  documentMasterFormat,
  findMatchingFont,
  highlightVariables,
  isEditorDisabled,
  isItalicApplied,
  isUnderlineApplied,
  moveSelectedSubsectionDownward,
  moveSelectedSubsectionUpward,
  handleDeleteSelection,
  nextFocus,
  isSubsectionVersionAvailable,
  getBoxPositionById,
  handleDetachSelection,
  removeWhitespace,
  applyMasterFormatting,
  removeInlineStyles,
  isActiveSuggestion,
  scrollToActiveSuggestion,
} from "./HelperEditorFunctions";
import { isUserFromShareLink } from "./TableElement/EditContent/helper";
import { CommentOutlined } from "@mui/icons-material";
import CommentsIcon from "src/features/comments/CommentsIcon";

/**
 * Rich text editor for a document element.
 * Responsible for allowing editing and persisting the changes asynchronously.
 *
 * @export
 * @param {({
 *   element: DocumentElement | null;
 *   parentSubsection: DocumentSubsection | null;
 *   parentSection: DocumentSection;
 *   documentTemplate: DocumentTemplate;
 *   organizationId: number;
 *   workspaceId: number;
 *   isHeading: boolean;
 *   viewPreferences: ViewPreferences;
 * })} props
 * @return {*}
 *
 *
 */
function DocumentElementEditor(props: {
  element: DocumentElement | null;
  parentSubsection: DocumentSubsection | null;
  parentSection: DocumentSection | DocumentSectionSummary;
  documentTemplate: DocumentTemplate | DocumentTemplateSummary;
  organizationId: number;
  workspaceId: number;
  isHeading: boolean;
  viewPreferences: ViewPreferences;
  readOnly?: boolean;
  forApproval: boolean;
  approvalsEnabled: boolean;
  approvalOf: number;
}) {
  const dispatch = useAppDispatch();
  const actions = useActions();
  const editorRef = useRef<Editor>(null);
  const boxRef = useRef<HTMLDivElement>(null);

  // Selectors
  const approvalState = useAppSelector(selectApprovalState);
  const selectedSubHeadingNumber = useAppSelector(selectsHeadingNumber);
  const selectTextNumbering = useAppSelector(selectsTextNumber);
  const selectedSectioNumber = useAppSelector(selectSectionsNumber);
  const status = useAppSelector(selectStatusState);
  const elementBelow = useAppSelector(selectElementBelowState);
  const selectedCurrentTemplateState = useAppSelector(
    selectCurrentTemplateState,
    shallowEqual
  );

  const documentMasterState = useAppSelector(selectDocumentMasterState);
  const selectedCurrentEditorState = useAppSelector((state) =>
    makeSelectCurrentEditorStateCustom(state, props)
  );

  const _documentMasterState = useAppSelector(
    (state) => selectFormattingSection(state, props.parentSection.id),
    shallowEqual
  );

  const [editorState, setEditorStates] = useState<EditorState>(
    EditorState.createEmpty()
  );

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const variableNames =
    (props.documentTemplate as DocumentTemplate).variables?.map((variable) => {
      return "@" + variable.variable_name; // Assuming 'name' is the correct property name
    }) || [];

  // Dailog Box Opener
  const [openModel, setOpenModel] = useState<ModalState>(ModalState.None);

  const [openVersionScreen, setOpenVersionScreen] = useState(false);
  const [isVersionOpen, setIsversionOpen] = useState<boolean>(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] =
    useState<boolean>(false);
  const [isDeleteUnsharedOpen, setIsDeleteUnsharedOpen] =
    useState<boolean>(false);
  const [IsLibraryOpen, SetIsLibarayOpen] = useState<boolean>(false);

  const [fontColor, setFontColor] = useState("070707");
  const [spacingAbove, setSpacingAbove] = useState("0");
  const [spacingBelow, setSpacingBelow] = useState("12");
  const [font, setFont] = useState("Book Antique");
  const [fontSize, setFontSize] = useState("16");
  const [isNumberDisplay, setIsNumberDisplay] = useState<boolean>(true);
  const [justification, setJustification] = useState<string>("left");
  const [indent, setIndent] = useState(0);
  const [isSuggestActive, setisSuggestActive] = useState<boolean>(false);
  const [isSuggest, setisSuggest] = useState<boolean>(false);
  const [isFocusNext, setisFocusNext] = useState<boolean>(false);
  const [isPendingRequest, setisPendingRequest] = useState<boolean>(false);

  const isPublished = (props.parentSection as DocumentSection)?.edit ?? false;
  const [isEditing, setIsEditing] = React.useState<boolean>(false);
  const [isFocused, setIsFocused] = React.useState<boolean>(false);

  const originalMarkdown = props.isHeading
    ? props.parentSection.pending_heading !== null
      ? "# " + normalizeText(props.parentSection.pending_heading)
      : "# " + normalizeText(props.parentSection.heading)
    : normalizeText(props.element!.content);

  const oldHeadingMarkdown =
    props.isHeading !== null && props.parentSection.pending_heading !== null
      ? normalizeText(props.parentSection.heading)
      : "";

  const oldHeadingEditorState = EditorState.createWithContent(
    markdowntoEditorState(oldHeadingMarkdown)
  );

  const hideNumbering =
    _documentMasterState.documentMaster &&
    _documentMasterState.numberingSetting &&
    _documentMasterState.numberingSetting == "NN";

  //this is document master inline styles
  const InlineStyle = {
    isHeading: props.isHeading,
    ID: props.isHeading ? props.parentSection.id : props.parentSubsection!.id,
    formatMaster: {
      bold:
        props.isHeading && props.parentSection
          ? documentMasterFormat(
              _documentMasterState,
              "HEADING",
              1
            ).fontStyle.includes("bold")
          : !props.isHeading && props.element
          ? documentMasterFormat(
              _documentMasterState,
              props.element.content_type,
              props.element.heading_level
            ).fontStyle.includes("bold")
          : false,
      italic:
        props.isHeading && props.parentSection
          ? documentMasterFormat(
              _documentMasterState,
              "HEADING",
              1
            ).fontStyle.includes("italic")
          : !props.isHeading && props.element
          ? documentMasterFormat(
              _documentMasterState,
              props.element.content_type,
              props.element.heading_level
            ).fontStyle.includes("italic")
          : false,
      underline:
        props.isHeading && props.parentSection
          ? documentMasterFormat(
              _documentMasterState,
              "HEADING",
              1
            ).fontStyle.includes("underline")
          : !props.isHeading && props.element
          ? documentMasterFormat(
              _documentMasterState,
              props.element.content_type,
              props.element.heading_level
            ).fontStyle.includes("underline")
          : false,
    },
    contentFormatting: {
      bold:
        props.isHeading && props.parentSection && props.parentSection.formatting
          ? JSON.parse(props.parentSection.formatting).isBold
          : !props.isHeading && props.element
          ? JSON.parse(props.element.content_format).isBold
          : false,
      italic:
        props.isHeading && props.parentSection && props.parentSection.formatting
          ? JSON.parse(props.parentSection.formatting).isItalic
          : !props.isHeading && props.element
          ? JSON.parse(props.element.content_format).isItalic
          : false,
      underline:
        props.isHeading && props.parentSection && props.parentSection.formatting
          ? JSON.parse(props.parentSection.formatting).underline
          : !props.isHeading && props.element
          ? JSON.parse(props.element.content_format).isUnderline
          : false,
      isStandard:
        props.isHeading && props.parentSection && props.parentSection.formatting
          ? JSON.parse(props.parentSection.formatting).isStandard
          : !props.isHeading && props.element
          ? JSON.parse(props.element.content_format).isStandard
          : false,
    },
  };

  const styleProperties = {
    applyLineStyle: {
      bold:
        InlineStyle.formatMaster.bold &&
        InlineStyle.contentFormatting.isStandard,
      italic:
        InlineStyle.formatMaster.italic &&
        InlineStyle.contentFormatting.isStandard,
      underline:
        InlineStyle.formatMaster.underline &&
        InlineStyle.contentFormatting.isStandard,
    },

    removeInlineStyle: {
      bold:
        // InlineStyle.formatMaster.bold &&
        InlineStyle.contentFormatting.isStandard,
      italic:
        // InlineStyle.formatMaster.italic &&
        InlineStyle.contentFormatting.isStandard,
      underline:
        // InlineStyle.formatMaster.underline &&
        InlineStyle.contentFormatting.isStandard,
    },
  };

  // check if current item is Library content
  const isLibraryItem =
    !props.readOnly &&
    ((!props.isHeading &&
      !!(props.documentTemplate as DocumentTemplate).included_subSections?.[
        props.parentSubsection!.id
      ]) ||
      (!props.isHeading &&
        !!(props.documentTemplate as DocumentTemplate).imported_sub_sections?.[
          props.parentSubsection!.id
        ]));

  // Do we need to apply auto indent
  const isAutoIndentApplied =
    !props.isHeading &&
    !props.readOnly &&
    props.element?.content_type === "TEXT" &&
    documentMasterState.documentMaster &&
    _documentMasterState.documentMaster.default_indent;

  const isDeleted = props.isHeading
    ? props.parentSection.pending_deleted_at !== null
    : isDeletedSubsection(props.parentSubsection!) ||
      props.parentSection.pending_deleted_at !== null;

  const isSelected = isFocused;

  // Only allow drag+drop if this is not read only.
  const [{ isOver }, drop] = props.readOnly
    ? [{ isOver: null }, null]
    : useDrop(
        () => ({
          accept: "right-drawer-item",
          drop: (dropItem: any) => addElementToSection({ ...dropItem.element }),
          collect: (monitor) => ({
            isOver: !!monitor.isOver(),
            canDrop: !!monitor.canDrop(),
          }),
        }),
        [props.parentSection]
      );

  function addElementToSection(element: DocumentElement) {
    dispatch(clearSelectedComponent());
    dispatch(setEditorState(null));
    // if Library Section Object is Droped on Editor , Open Library Section Window.
    if (element.content_type === "LIBRARYCONTENTSUBSECTION") {
      setOpenModel(ModalState.ImportLibraryContent);
      SetIsLibarayOpen(true);
    } else {
      if (props.isHeading) {
        actions.createSubsectionWithElement({
          section: props.parentSection as DocumentSection,
          documentElement: element,
          subsectionAbove: props.isHeading
            ? undefined
            : props.parentSubsection!,
        });
      } else {
        actions.createSubsectionWithElement({
          section: props.parentSection as DocumentSection,
          documentElement: element,
          subsectionAbove: props.isHeading
            ? undefined
            : props.parentSubsection!,
        });
      }
    }
  }

  // Editor Properties Setter (Document Template Master | Ribbon Bar)
  // Todo : Better this Code
  const setFormattingParams = (params: any) => {
    if (params.topMargin) {
      setSpacingAbove(params.topMargin);
    }
    // Document Master
    if (params.bottomMargin) {
      setSpacingBelow(params.bottomMargin);
    }

    if (!props.isHeading && params.isIndentApplied) {
      setIndent(params.indent);
    } else if (!props.isHeading && params.isIndentApplied === undefined) {
      if (
        !props.isHeading &&
        props.parentSection.heading == "Title Page" &&
        props.parentSubsection!.elements![0].content_type === "HEADING"
      ) {
        setIndent(0);
      } else {
        setIndent(params.indent);
      }
    } else if (props.isHeading) {
      if (params.indent != undefined) {
        setIndent(params.indent);
      }
    }
    /// this is for text and heading element
    if (params.isColorApplied === true) {
      setFontColor(params.color);
    }

    if (params.isJustificationApplied) {
      if (params.justify === "raggedright") {
        setJustification("left");
      } else if (params.justify === "centering") {
        setJustification("center");
      } else if (params.justify === "raggedleft") {
        setJustification("right");
      } else if (params.justify === "justifying") {
        setJustification("justify");
      }
    }

    if (params.color && props.isHeading) {
      setFontColor(params.color);
    }
    if (params.fontColor) {
      setFontColor(params.fontColor);
    }
    if (props.isHeading) {
      if (params.justify === "raggedright") {
        setJustification("left");
      } else if (params.justify === "centering") {
        setJustification("center");
      } else if (params.justify === "raggedleft") {
        setJustification("right");
      } else if (params.justify === "justifying") {
        setJustification("justify");
      }
    }

    if (params.alignment === "raggedright") {
      setJustification("left");
    } else if (params.alignment === "centering") {
      setJustification("center");
    } else if (params.alignment === "raggedleft") {
      setJustification("right");
    } else if (params.alignment === "justifying") {
      setJustification("justify");
    }

    if (params.font) {
      setFont(findMatchingFont(params.font));
    }
    if (params.fontSize) {
      setFontSize(params.fontSize);
    }
  };

  const showAlert = () => {
    const state = isFocused ? selectedCurrentEditorState : editorState;
    if (state != null) {
      let newContentheading = editorStateToMarkdown(state);
      newContentheading = newContentheading.replace(/[*]/g, "");
      newContentheading = newContentheading.replace(/[_]/g, "");
      newContentheading = newContentheading.replace(/[+]/g, "");
      newContentheading = newContentheading.replace(/\s/g, "");
      if (newContentheading == "#" && props.isHeading) {
        enqueueSnackbar("Heading Should Not be empty!", {
          variant: "error", // Use the 'error' variant for red color
        });
        enqueueSnackbar("Reverted!", {
          variant: "error", // Use the 'error' variant for red color
        });
        return true;
      }
      return false;
    }
  };
  /**
   * Returns true if the passed in (or if passed in is null, the current state)
   * content is not the same as the committed content.
   * @return {*}  {boolean}
   *
   */

  //To check is anything changed in Document editor <Editor>
  // Only Return "True" & "False"
  function isDirty(newEditorState: EditorState | null = null): boolean {
    // Shortcut, if readonly it can't be dirty.
    if (props.readOnly) return false;
    const state = newEditorState
      ? newEditorState
      : isFocused
      ? selectedCurrentEditorState
      : editorState;
    if (!state) return false;

    const newMarkdown = editorStateToMarkdown(state);
    if (props.isHeading) {
      return props.parentSection.pending_heading !== null
        ? newMarkdown !== "# " + props.parentSection.pending_heading
        : newMarkdown !== "# " + props.parentSection.heading;
    }
    if (
      (props.viewPreferences.logDebug &&
        removeWhitespace(originalMarkdown) !== removeWhitespace(newMarkdown)) ||
      props.viewPreferences.logAllTextChecking
    ) {
      console.log(
        `Dirty content check for section=${
          props.parentSection.id
        }, subsection=${props.parentSubsection?.id}, heading=${
          props.isHeading
        }\r\n
          Old = ${originalMarkdown}\r\n
          New = ${newMarkdown}
          IsHeading =${props.isHeading}
          SectionHeading =${props.parentSection.heading}
          isFocused=${isFocused}
          OldParsed = \r\n ${removeWhitespace(originalMarkdown)}\r\n
          NewParsed = ${removeWhitespace(newMarkdown)}

        `
      );
    }
    return removeWhitespace(originalMarkdown) !== removeWhitespace(newMarkdown);
    // normalizeText(originalMarkdown) !== normalizeText(newMarkdown);
  }

  // --- Timer code --- ///
  /**
   * @param {EditorState} editorState
   *
   */

  const handlePastedText = (
    text: string,
    html: string,
    editorState: EditorState,
    onChange: (editorState: EditorState) => void
  ): boolean => {
    // Custom logic to handle pasted text
    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();
    const contentFormatting =
      props.isHeading && props.parentSection
        ? documentMasterFormat(_documentMasterState, "HEADING", 1)
        : !props.isHeading && props.element
        ? documentMasterFormat(
            _documentMasterState,
            props.element?.content_type,
            props.element?.heading_level
          )
        : documentMasterFormat(_documentMasterState, "HEADING", 2);

    const allStyles: string[] = contentFormatting.fontStyle.map(
      (element: string) => {
        if (element != null) element.toUpperCase();
      }
    );

    // Filter out only BOLD, ITALIC, and UNDERLINE styles from allStyles array
    const allowedStyles: string[] = allStyles.filter((style) =>
      ["BOLD", "ITALIC", "UNDERLINE"].includes(style)
    );
    let newContentState = contentState;

    // If stylesToApply array is not empty, apply the specified styles to the pasted text
    if (allowedStyles.length > 0) {
      let currentInlineStyle = editorState.getCurrentInlineStyle();
      allowedStyles.forEach((style: string) => {
        if (style == "BOLD" || style == "ITALIC" || style === "UNDERLINE") {
          currentInlineStyle = currentInlineStyle.add(style);
        }
      });

      newContentState = Modifier.replaceText(
        newContentState,
        selectionState,
        text,
        currentInlineStyle
      );
    } else {
      // If stylesToApply array is empty, just paste the normal text
      const currentInlineStyle = editorState.getCurrentInlineStyle();
      newContentState = Modifier.replaceText(
        newContentState,
        selectionState,
        text,
        currentInlineStyle
      );
    }

    // Create a new EditorState with the modified content
    const newEditorState = EditorState.push(
      editorState,
      newContentState,
      "insert-characters"
    );

    // onChange(finalEditorState); // Update the editor state with the finalEditorState
    handleEditorStateChange(newEditorState);

    return true; // Return true to indicate that the pasted text has been handled
  };
  function handleEditorStateChange(newEditorState: EditorState) {
    if (
      (props.isHeading && isEditorDisabled(props.parentSection.heading)) ||
      props.approvalsEnabled
    ) {
      // Do nothing
    } else {
      setIsEditing(true);
      // const UpdatednewEditorState = removeAllColorEntitiesAndCreateNew(newEditorState)
      const newContent = editorStateToMarkdown(newEditorState);
      if (
        validateContent(
          newContent,
          props.isHeading ? ContentType.Heading : props.element!.content_type
        ).valid
      ) {
        const contentState = newEditorState.getCurrentContent();
        const isEmpty = contentState.getPlainText().trim() === "";

        if (isEmpty) {
          const contentFormatting =
            props.isHeading && props.parentSection
              ? documentMasterFormat(_documentMasterState, "HEADING", 1)
              : !props.isHeading && props.element
              ? documentMasterFormat(
                  _documentMasterState,
                  props.element?.content_type,
                  props.element?.heading_level
                )
              : documentMasterFormat(_documentMasterState, "HEADING", 2);

          const stylesToApply: string[] = [];

          contentFormatting.fontStyle.forEach((element: string) => {
            stylesToApply.push(element.toUpperCase());
          });
          if (stylesToApply.length != 0) {
            let currentInlineStyle = newEditorState.getCurrentInlineStyle();
            stylesToApply.forEach((style) => {
              if (
                style == "BOLD" ||
                style == "ITALIC" ||
                style === "UNDERLINE"
              ) {
                currentInlineStyle = currentInlineStyle.add(style);
              }
            });

            const contentWithStyle = Modifier.insertText(
              contentState,
              contentState.getSelectionAfter(),
              " ",
              currentInlineStyle
            );

            dispatch(
              setEditorState(
                EditorState.push(
                  newEditorState,
                  contentWithStyle,
                  "insert-characters"
                )
              )
            );
          } else {
            dispatch(setEditorState(newEditorState));
          }
        } else dispatch(setEditorState(newEditorState));

        const timeoutId = setTimeout(() => {
          isActiveSuggestion();
        }, 120); // 2 seconds timeout
      }
    }
  }

  //
  /**
   * Commit the changes to the database and in the case of
   * edit mode, set back to read-only.
   */

  const handleSave = () => {
    if (props.approvalsEnabled) return false;
    if (showAlert()) return false;
    if (isPublished) setIsEditing(false);
    const state = isFocused ? selectedCurrentEditorState : editorState;
    if (!state) return false;

    const updatedState = removeInlineStyles(
      state,
      styleProperties.removeInlineStyle.bold,
      styleProperties.removeInlineStyle.italic,
      styleProperties.removeInlineStyle.underline
    );
    let newContent = editorStateToMarkdown(updatedState);

    // let newContent = editorStateToMarkdown(state);
    // check if the content is same
    if (newContent.trimEnd() == originalMarkdown.trimEnd()) return false;
    const alphanumericRegex = /^[^a-zA-Z0-9]+$/;
    if (alphanumericRegex.test(newContent)) {
      newContent = newContent.replace(/[*]/g, "");
      newContent = newContent.replace(/[_]/g, "");
      newContent = newContent.replace(/[+]/g, "");
    }

    newContent = newContent.replace(/(^|\s)\*(?=\s)(?!\*)/g, "$1\\*");
    newContent = newContent.replace(/\n{2,}/g, "\n");

    if (props.isHeading) {
      if (props.isHeading && newContent.startsWith("# "))
        newContent = newContent.substring(2);
      const updatedSection = Object.assign(
        {},
        props.parentSection as DocumentSection,
        {
          heading: newContent,
          heading_level: props.parentSection.heading_level,
        }
      );

      actions.updateSection(updatedSection);
    } else {
      // Update the content
      const updatedDocumentElement = { ...props.element };

      const updatedContent = newContent
        .replace(/“/g, '"')
        .replace(/”/g, '"')
        .replace(/’/g, "'")
        .replace(/‘/g, "'");
      updatedDocumentElement.content = updatedContent;
      // console.log("UPDATED CONTENT", updatedDocumentElement.content);
      actions.updateDocumentElement({
        section: props.parentSection as DocumentSection,
        subSection: props.parentSubsection!,
        documentElement: updatedDocumentElement as DocumentElement,
        isTextEmpty:
          props.element?.content_type === "TEXT" && props.element.content === ""
            ? true
            : false,
      });
    }
  };

  /**
   * When the component is focused, update the state.
   * @param {*} e
   * @return {*}  {*}
   */

  const handleOnFocus = useCallback(() => {
    if (props.readOnly) return false;
    dispatch(
      setSelectedComponent({
        selectedSection: props.parentSection as DocumentSection,
        selectedSubsection: props.isHeading ? null : props.parentSubsection,
        selectedComponent: props.isHeading ? null : props.element,
      })
    );
    // Only set the editor state if it's not already set
    if (!editorState) {
      dispatch(setEditorState(editorState));
    }
    setIsFocused(true);
  }, [
    // dispatch,
    props.parentSection,
    props.isHeading,
    props.parentSubsection,
    props.element,
    // editorState,
  ]);

  function handleOnBlur(e: any) {
    setisPendingRequest(false);
    if (props.readOnly) return;
    setIsFocused(false);
    setisSuggestActive(false);
    setisSuggest(false);
    // handleSave();
    //Save before we move away.
    if (isDirty() && !isPublished) handleSave();
    if (isDirty() && isPublished) handleSave();
    dispatch(clearSelectedComponent());
    dispatch(setEditorState(null));
  }

  // Borders of editor Screen
  // Heading and subheading
  function getBorder(): any {
    //   if (isDirty())
    // return {
    // border: "5px",
    //   borderColor: "pink",
    //   //  "warning.main",
    //   borderStyle: "dashed",
    // };
    if (
      props.isHeading &&
      props.parentSection.pending_deleted_at !== null &&
      props.approvalsEnabled
    ) {
      return {
        border: COLORS.BORDER_SIZE,
        borderColor: COLORS.NOMIA_RED,
        borderStyle: isSelected ? "solid" : "dashed",
      };
    }

    if (
      !props.isHeading &&
      (isDeletedSubsection(props.parentSubsection!) ||
        props.parentSection.pending_deleted_at !== null) &&
      props.approvalsEnabled
    ) {
      return {
        border: COLORS.BORDER_SIZE,
        borderColor: COLORS.NOMIA_RED,
        borderStyle: isSelected ? "solid" : "dashed",
      };
    }

    if (
      !props.isHeading &&
      props.parentSubsection &&
      props.approvalsEnabled &&
      isIncludeImpact(approvalState, props.parentSubsection.id)
    ) {
      return {
        border: COLORS.BORDER_SIZE,
        borderColor: COLORS.NOMIA_ORANGE,
        borderStyle: isSelected ? "solid" : "dashed",
      };
    }

    /// if the subsection is addded show green border to the Approver
    if (
      !props.isHeading &&
      props.approvalsEnabled &&
      props.element !== null &&
      props.element.should_show
    ) {
      return {
        border: COLORS.BORDER_SIZE,
        borderColor: COLORS.NOMIA_GREEN,
        borderStyle: isSelected ? "solid" : "dashed",
      };
    }

    /// if the new section is addded show green border to the Approver
    if (
      props.isHeading &&
      props.approvalsEnabled &&
      props.parentSection &&
      (props.parentSection as DocumentSection).is_new
    ) {
      return {
        border: COLORS.BORDER_SIZE,
        borderColor: COLORS.NOMIA_GREEN,
        borderStyle: isSelected ? "solid" : "dashed",
      };
    }

    if (
      props.isHeading &&
      props.parentSection &&
      props.parentSection.pending_heading &&
      props.approvalsEnabled
    ) {
      return {
        border: COLORS.BORDER_SIZE,
        // borderColor: props.approvalsEnabled ? "orange !important" : "green",
        borderColor: props.approvalsEnabled
          ? COLORS.NOMIA_ORANGE
          : secondarySilver,
        borderStyle: isSelected ? "solid" : "dashed",
      };
    }

    //props.element.element_approvals
    if (
      props.element !== null &&
      props.element.difference_content != null &&
      props.element.difference_content
    ) {
      return {
        border: props.approvalsEnabled ? COLORS.BORDER_SIZE : 1,
        borderColor: props.approvalsEnabled
          ? "orange !important"
          : secondarySilver,
        borderStyle: isSelected ? "solid" : "dashed",
      };
    }

    // if (
    //   props.parentSection.pending_deleted_at !== null &&
    //   props.approvalsEnabled
    // ) {
    //   return {
    //     border: 1,
    //     borderColor: "error.main",
    //     borderStyle: isSelected ? "solid" : "dashed",
    //   };
    // }
    if (
      props.element !== null &&
      props.element.pending_addition &&
      props.approvalsEnabled
    ) {
      return {
        border: 1,
        borderColor: "green",
        borderStyle: isSelected ? "solid" : "dashed",
      };
    }
    //Dashed box if draft and subheading
    if (
      props.element !== null &&
      props.element.pending_addition &&
      !props.approvalsEnabled
    ) {
      return {
        border: 1,
        borderColor: secondarySilver,
        borderStyle: isSelected ? "solid" : "dashed",
      };
    }

    // if (props.readOnly) return {};

    // if user is currently in the selected editor Box
    if (isEditing && isSelected)
      return { border: 1, borderColor: "silver.main" };

    //  Editor Border
    if (props.viewPreferences.showSections)
      return {
        border: 1,
        // borderColor: "primary.main",
        borderColor: "silver.main",
        borderStyle: isSelected ? "solid" : "dashed",
      };
    //Otherwise nothing
    return {};
  }

  function getSuggestions() {
    if ((props.documentTemplate as DocumentTemplate).variables == undefined)
      return [];
    return (props.documentTemplate as DocumentTemplate).variables?.map(
      (variable) => {
        return {
          text: variable.fillable_field
            ? variable.variable_name + ":Fillable"
            : variable.variable_name + ":Variable",
          value: variable.variable_name, // Add a non-breaking space here
          url: "Not used",
          // Add a space when the suggestion is selected
        };
      }
    );
  }

  const handleLevelIncrease = () => {
    if (!props.isHeading && props.element != null) {
      const parentLevel = findParentSubHeadingLevel(
        props.documentTemplate as DocumentTemplate,
        props.parentSection as DocumentSection,
        props.parentSubsection!
      );
      let currentHeadingLevel = props.element.heading_level;

      if (props.element.content_type === "HEADING") {
        if (
          props.element.heading_level < parentLevel ||
          props.element.heading_level === parentLevel
        ) {
          currentHeadingLevel = props.element.heading_level + 1;
        }
      } else if (props.element.content_type === "TEXT") {
        //  Find the parent of the text Element
        const parentLevelText = findParentTextElementLevelDetails(
          props.documentTemplate as DocumentTemplate,
          props.parentSection as DocumentSection,
          props.parentSubsection!
        );
        // if the current element heading level is less or equal to parent element than increment + 1
        if (
          props.element.heading_level + 1 <=
            parentLevelText.parentSubHeadingLevel ||
          props.element.heading_level === parentLevelText.parentSubHeadingLevel
        ) {
          currentHeadingLevel = props.element.heading_level + 1;
        }
      }

      if (
        props.element.heading_level < 6 &&
        currentHeadingLevel != props.element.heading_level
      ) {
        actions.setHeadingLevel({
          selected: {
            section: props.parentSection! as DocumentSection,
            subSection: props.parentSubsection!,
            documentElement: props.element as DocumentElement,
          },
          heading_level:
            currentHeadingLevel > 6 ? 6 : (currentHeadingLevel as number),
        });
      }
    }
  };

  const handleLevelDecrease = () => {
    if (!props.isHeading && props.element != null) {
      if (props.element.heading_level > 2) {
        const parentLevel = findParentSubHeadingLevel(
          props.documentTemplate as DocumentTemplate,
          props.parentSection as DocumentSection,
          props.parentSubsection!
        );
        let currentHeadingLevel = props.element.heading_level;

        if (props.element.content_type === "TEXT") {
          if (props.element.heading_level - 1 > parentLevel) {
            currentHeadingLevel = props.element.heading_level - 1;
          }
        } else if (props.element.content_type === "HEADING") {
          currentHeadingLevel = props.element.heading_level - 1;
        }
        if (currentHeadingLevel != props.element.heading_level) {
          actions.setHeadingLevel({
            selected: {
              section: props.parentSection! as DocumentSection,
              subSection: props.parentSubsection!,
              documentElement: props.element as DocumentElement,
            },
            heading_level: currentHeadingLevel as number,
          });
        }
      }
    }
  };

  // condition Check on Hit Enter.
  useEffect(() => {
    if (
      elementBelow === true &&
      status === "idle" &&
      isSuggestActive &&
      isFocusNext
    ) {
      nextFocus();
      setisFocusNext(false);
    }
  }, [status, elementBelow, isFocusNext]);

  useMemo(() => {
    if (
      documentMasterState.documentMaster !== undefined &&
      documentMasterState.documentMaster !== null
    ) {
      //Todo: Optimse this code Using Helper Function
      try {
        if (props.isHeading && props.parentSection) {
          const format = documentMasterFormat(
            _documentMasterState,
            "HEADING",
            1
          );
          if (!isEditorDisabled(props.parentSection.heading)) {
            setFormattingParams(format);
          }
        } else if (
          !props.isHeading &&
          props.parentSection.heading == "Title Page" &&
          props.element &&
          props.element.content_type == "TEXT"
        ) {
          //
          const format = documentMasterFormat(_documentMasterState, "TEXT", 1);
          setFormattingParams(format);
        } else if (props.element?.content_type === "TEXT") {
          const format = documentMasterFormat(
            _documentMasterState,
            "TEXT",
            props.element.heading_level
          );
          setFormattingParams(format);
        } else if (props.element?.content_type === "HEADING") {
          const format = documentMasterFormat(
            _documentMasterState,
            "HEADING",
            props.element.heading_level
          );
          setFormattingParams(format);
        }
        // If it is an Element (Heading | Text) and SomeThing is Applied From the Ribbon Bar
        if (
          props.element &&
          (JSON.parse(props.element?.content_format).isColorApplied ||
            JSON.parse(props.element?.content_format).isJustificationApplied ||
            JSON.parse(props.element?.content_format).indent ||
            !JSON.parse(props.element?.content_format).isNumberDisplay ||
            JSON.parse(props.element?.content_format).isNumberDisplay)
        ) {
          const params = JSON.parse(props.element.content_format);
          setFormattingParams(params);
          setIsNumberDisplay(params.isNumberDisplay);
        }
        // If it is an Heading and SomeThing is Applied From the Ribbon Bar
        if (
          props.isHeading &&
          props.parentSection &&
          props.parentSection.formatting !== undefined &&
          props.parentSection.formatting !== null
        ) {
          const paragraphFormat = JSON.parse(props.parentSection?.formatting);
          setFormattingParams(paragraphFormat);
        }
      } catch (error) {
        console.error("Error parsing formatting parameters:", error);
      }
    }
  }, [
    props.element?.content_format,
    props.element?.formatting,
    props.element?.heading_level,
    props.parentSection.formatting,
    documentMasterState.documentMaster,
    documentMasterState.sectionsDocumentMaster,
    documentMasterState.sortedSectionsDocumentMaster,
    documentMasterState.status,
  ]);

  useEffect(() => {
    if (!props.approvalsEnabled) {
      // setEditorStates(
      //   EditorState.createWithContent(
      //     markdowntoEditorState(
      //       props.isHeading ?
      //       props.parentSection.pending_heading !== null?
      //       "# " + props.parentSection.pending_heading
      //         : "# " + normalizeText(props.parentSection.heading)
      //         : normalizeText(props.element!.content)
      //     )
      //   )
      // );

      // const newEditorState =  markdownToEditorStateFunction(
      //   props.isHeading
      //     ? props.parentSection.pending_heading !== null
      //       ? "# " + props.parentSection.pending_heading
      //       : "# " + normalizeText(props.parentSection.heading)
      //     : normalizeText(props.element!.content)
      // )

      // const newEditorState = highlightVariables(
      //   EditorState.createWithContent(
      //     markdowntoEditorState(
      //       props.isHeading
      //         ? props.parentSection.pending_heading !== null
      //           ? "# " + props.parentSection.pending_heading
      //           : "# " + normalizeText(props.parentSection.heading)
      //         : normalizeText(props.element!.content)
      //     )
      //   ),
      //   variableNames
      // );

      if (!props.isHeading && props.element) {
        {
          const newEditorState = highlightVariables(
            // applyColorToEditorState(
            EditorState.createWithContent(
              markdowntoEditorState(
                props.isHeading
                  ? props.parentSection.pending_heading !== null
                    ? "# " + props.parentSection.pending_heading
                    : "# " + normalizeText(props.parentSection.heading)
                  : normalizeText(props.element!.content)
              )
            ),
            variableNames
          );
          const formattedEditorState = applyMasterFormatting(
            newEditorState,
            styleProperties.applyLineStyle.bold,
            styleProperties.applyLineStyle.italic,
            styleProperties.applyLineStyle.underline
          );

          setEditorStates(formattedEditorState);
          // setEditorStates(newEditorState);
        }
      } else {
        const newEditorState = highlightVariables(
          EditorState.createWithContent(
            markdowntoEditorState(
              props.isHeading
                ? props.parentSection.pending_heading !== null
                  ? "# " + props.parentSection.pending_heading
                  : "# " + normalizeText(props.parentSection.heading)
                : normalizeText(props.element!.content)
            )
          ),
          variableNames
        );

        const formattedEditorState = applyMasterFormatting(
          newEditorState,
          styleProperties.applyLineStyle.bold,
          styleProperties.applyLineStyle.italic,
          styleProperties.applyLineStyle.underline
        );

        setEditorStates(formattedEditorState);
        // setEditorStates(newEditorState);
      }
    } else if (props.approvalsEnabled) {
      // if (
      //   !props.isHeading &&
      //   props.parentSubsection &&
      //   (props.documentTemplate as DocumentTemplate).is_version_show?.[
      //     props.parentSubsection!.id
      //   ] &&
      //   !isDeleted
      // ) {
      //   const newEditorState = highlightVariables(
      //     EditorState.createWithContent(
      //       markdowntoEditorState(
      //         props.isHeading
      //           ? props.parentSection.pending_heading !== null
      //             ? "# " + props.parentSection.pending_heading
      //             : "# " + normalizeText(props.parentSection.heading)
      //           : normalizeText(props.element!.content)
      //       )
      //     ),
      //     variableNames
      //   );
      //   setEditorStates(newEditorState);
      // } else
      {
        const initialEditorState = EditorState.createWithContent(
          markdowntoEditorState(
            props.isHeading
              ? props.parentSection.difference_content !== null &&
                props.parentSection.difference_content !== undefined
                ? "# " + normalizeText(props.parentSection.difference_content)
                : props.parentSection.pending_heading !== null
                ? "# " + normalizeText(props.parentSection.pending_heading)
                : "# " + normalizeText(props.parentSection.heading)
              : props.element?.content_type === "HEADING"
              ? props.element.difference_content !== null &&
                props.element.difference_content !== undefined
                ? normalizeHeadingDifference(props.element.difference_content)
                : normalizeText(props.element.content)
              : props.element?.content_type === "TEXT"
              ? props.element.difference_content !== null &&
                props.element.difference_content !== undefined
                ? normalizeText(props.element.difference_content)
                : normalizeText(props.element.content)
              : ""
          )
        );

        const formattedEditorState = applyMasterFormatting(
          initialEditorState,
          styleProperties.applyLineStyle.bold,
          styleProperties.applyLineStyle.italic,
          styleProperties.applyLineStyle.underline
        );

        setEditorStates(formattedEditorState);
      }
    }
  }, [
    documentMasterState.documentMaster,
    documentMasterState.sectionsDocumentMaster,
    documentMasterState.sortedSectionsDocumentMaster,
    props.element,
    props.parentSection,
    props.approvalsEnabled,
  ]);

  const returnNumbering = () => {
    try {
      if (
        props.isHeading &&
        props.parentSection &&
        _documentMasterState.documentMaster.heading1_formatting
      ) {
        const headingFormat1 = JSON.parse(
          _documentMasterState.documentMaster.heading1_formatting
        );
        const sectionCount = selectedSectioNumber.find(
          (s) => s.id === props.parentSection.id
        )?.count;
        if (headingFormat1 && sectionCount) {
          return applySeperators(headingFormat1, sectionCount);
        }
      } else if (
        !props.isHeading &&
        props.element &&
        props.parentSubsection &&
        props.element.content_type === ContentType.Heading
      ) {
        const headingNumbering = selectedSubHeadingNumber.find(
          (s) =>
            s.elementId === props.element?.id &&
            s.componentOrder == props.parentSubsection?.component_order
        )?.number;
        return headingNumbering;
      } else if (
        !props.isHeading &&
        props.element &&
        props.parentSubsection &&
        props.element.content_type === ContentType.Text
      ) {
        const textNumbering = selectTextNumbering.find(
          (s) =>
            s.elementId === props.element?.id &&
            s.componentOrder == props.parentSubsection?.component_order
        )?.number;
        return textNumbering;
      }
      return "";
    } catch (e) {
      return "";
    }
  };

  const numbering = useMemo(() => {
    return returnNumbering();
  }, [selectedSubHeadingNumber]);

  // Create Element when user hit Enter.
  function isText(e: any) {
    if (e.key == "ArrowDown" || e.key == "ArrowUp") {
      scrollToActiveSuggestion();
    }

    // To Upadate the Numbering of the Current Section
    if (e.shiftKey && e.ctrlKey && e.key === "F") {
      actions.updateSectionHeadingLevels({
        documentTemplate: props.documentTemplate as DocumentTemplate,
        section: props.parentSection as DocumentSection,
      });
    }
    if (e.shiftKey && e.key === "Tab") {
      handleLevelDecrease();
      e.preventDefault();
    } else if (e.key === "Tab") {
      // handleIncreaseIndent();
      handleLevelIncrease();
      e.preventDefault();
    }
    if (props.isHeading) {
      //
    } else {
      if (props.element!.content_type === "TEXT") {
        setisSuggest(true);
      }
      setisSuggestActive(true);
      if (e.key === "Enter" && e.shiftKey && !isPendingRequest) {
        if (isUserFromShareLink()) {
          console.log("DO NOTHING");
        }
        // if (e.key === "Enter" && !isPendingRequest) {
        else if (props.element!.content_type === "TEXT") {
          setisSuggest(false);
          setisPendingRequest(true);
          actions.createSubsectionWithElement({
            section: props.parentSection as DocumentSection,
            documentElement: {
              ...props.element,
              content: "",
            } as DocumentElement,
            subsectionAbove: props.isHeading
              ? undefined
              : props.parentSubsection!,
            isFocusNext: true,
          });
          setisSuggestActive(true);
          setisFocusNext(true);
        } else if (props.element!.content_type === "HEADING") {
          actions.createSubsectionWithElement({
            section: props.parentSection as DocumentSection,
            documentElement: {
              ...props.element,
              content: "## Subheading",
            } as DocumentElement,
            subsectionAbove: props.isHeading
              ? undefined
              : props.parentSubsection!,
            isFocusNext: true,
          });
          setisFocusNext(true);
          setisPendingRequest(true);
        } else {
          // perform default behavior of the browser
          return true;
        }
        e.preventDefault(); // prevent default browser behavior
      }
    }
    return false;
  }

  // Helper Function to Count the Text Elements of the Parent SubHeading

  const getPositonOfElement = () => {
    try {
      // case 1: if the parent element exist
      if (
        !props.isHeading &&
        props.parentSubsection?.elements![0].content_type == "TEXT"
      ) {
        const aboveSubsection = findParentElementToFindPosition(
          props.documentTemplate as DocumentTemplate,
          props.parentSection as DocumentSection,
          props.parentSubsection as DocumentSubsection
        );
        // debugging
        // console.log("current element" , props.parentSubsection.id , "above Element" , aboveSubsection)
        if (aboveSubsection.subsection) {
          const position = getBoxPositionById(
            false,
            aboveSubsection.subsection.id
          );
          if (
            aboveSubsection.subsection?.elements![0].content_type === "HEADING"
          ) {
            return position
              ? position?.numberLeft - position?.parentLeft + "px"
              : 0;
          } else if (
            aboveSubsection.subsection?.elements![0].content_type === "TEXT"
          ) {
            if (
              aboveSubsection.subsection?.elements![0].heading_level ===
              props.element?.heading_level
            ) {
              return position
                ? position?.numberLeft - position?.parentLeft + "px"
                : 0;
            }
            // Issue here todo? find the above same level element in the parent subsection heading
            // issue resolved
            else if (aboveSubsection.aboveSameLevel) {
              const aboveLevelPosition = getBoxPositionById(
                false,
                aboveSubsection.aboveSameLevel.id
              );
              return aboveLevelPosition
                ? aboveLevelPosition?.numberLeft -
                    aboveLevelPosition?.parentLeft +
                    "px"
                : 0;
            } else {
              return position
                ? position?.contentLeft - position?.parentLeft + "px"
                : 0;
            }
          }
        }
        // case 2: if the parent is Section
        else if (aboveSubsection.subsection === null) {
          const position = getBoxPositionById(true, props.parentSection.id);
          return position
            ? position?.numberLeft - position?.parentLeft + "px"
            : 0;
        }
      }
      return indent;
    } catch (e) {
      return indent;
    }
  };

  const contentFormatting = (
    isHeading: boolean,
    section: DocumentSection,
    subsection: DocumentSubsection | null,
    documentElement: DocumentElement | null,
    propertyApplied: "bold" | "italic" | "underline"
  ) => {
    try {
      if (!isHeading && subsection != null && documentElement != null) {
        const updatedElement = { ...documentElement };
        const contentFormatObject = JSON.parse(updatedElement.content_format);
        if (propertyApplied == "bold") {
          contentFormatObject.isBold = true;
        } else if (propertyApplied == "italic") {
          contentFormatObject.isItalic = true;
        } else if (propertyApplied == "underline") {
          contentFormatObject.isUnderline = true;
        }
        contentFormatObject.isStandard = false;
        updatedElement.content_format = JSON.stringify(contentFormatObject);

        updatedElement.formatting = JSON.stringify({
          ...JSON.parse(
            documentElement?.formatting !== null &&
              documentElement?.formatting !== undefined
              ? documentElement?.formatting
              : "{}"
          ),
          isStandard: false,
        });

        dispatch(
          setSelectedComponent({
            selectedSection: section as DocumentSection,
            selectedSubsection: subsection,
            selectedComponent: updatedElement as DocumentElement,
          })
        );
      } else if (isHeading && section) {
        const updatedSection = {
          ...section,
          formatting: JSON.stringify({
            ...JSON.parse(section?.formatting || "{}"),
            isStandard: false,
            isCustomSettings: true,
            [propertyApplied === "bold"
              ? "isBold"
              : propertyApplied === "italic"
              ? "isItalic"
              : "isUnderline"]: true,
          }),
        };

        dispatch(
          setSelectedComponent({
            selectedSection: updatedSection as DocumentSection,
            selectedSubsection: subsection!,
            selectedComponent: documentElement as DocumentElement,
          })
        );
      }
    } catch (e) {
      console.warn("Unable to apply shortcut Inline Styling");
    }
  };

  const handleKeyCommand = (command: any, editorState: any) => {
    if (
      command === "backspace" &&
      !props.isHeading &&
      props.element &&
      props.element.content_type === ContentType.Heading
    ) {
      const selection = editorState.getSelection();
      const content = editorState.getCurrentContent();
      const blockKey = selection.getStartKey();
      const block = content.getBlockForKey(blockKey);

      if (selection.isCollapsed() && selection.getStartOffset() === 0) {
        const blockType = block.getType();

        // Check if the block type is a subheading (##)
        if (blockType === "header-two") {
          // Do nothing to prevent the style from being removed
          return "handled";
        }

        if (blockType !== "unstyled") {
          // Prevent block type change and remove block type specific data for other block types
          const newContentState = Modifier.setBlockType(
            content,
            selection,
            "unstyled"
          );
          const newState = EditorState.push(
            editorState,
            newContentState,
            "change-block-type"
          );
          dispatch(setEditorState(newState));
          return "handled";
        }
      }
    }

    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (command === "bold") {
      contentFormatting(
        props.isHeading,
        props.parentSection as DocumentSection,
        props.parentSubsection,
        props.element,
        command
      );
    } else if (command === "italic") {
      contentFormatting(
        props.isHeading,
        props.parentSection as DocumentSection,
        props.parentSubsection,
        props.element,
        command
      );
      //
    } else if (command === "underline") {
      contentFormatting(
        props.isHeading,
        props.parentSection as DocumentSection,
        props.parentSubsection,
        props.element,
        command
      );
      //
    }

    if (newState) {
      dispatch(setEditorState(newState));
      return "handled";
    }
    return "not-handled";
  };

  return (
    <>
      <Box
        id={
          props.isHeading
            ? `_section-${props.parentSection.id}__`
            : `_subsection-${props.parentSubsection!.id}__`
        }
        key={
          props.isHeading
            ? `_section-${props.parentSection.id}__key`
            : `_subsection-${props.parentSubsection!.id}__key`
        }
      >
        <Box
          key={
            props.isHeading
              ? `_key-${props.parentSection.id}`
              : `_key-${props.parentSubsection!.id}`
          }
          id={
            props.isHeading
              ? `section-${props.parentSection.id}`
              : `subsection-${props.parentSubsection!.id}`
          }
          ref={drop}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          sx={Object.assign(
            {
              padding: 1,
              paddingTop: props.isHeading
                ? "0px !important"
                : props.element!.content_type === "TEXT"
                ? "15px !important"
                : "0px !important",
              paddingBottom: "0px !important",
              margin: 1,
              // REF: https://mui.com/system/borders/
              borderRadius: 0,
              transform: "translateZ(0px)",
              flexGrow: 1,
              overflow: "visible",
              background: isSelected ? "#FFFFFF" : "#FFFFFF",
              boxShadow: isSelected ? 18 : "none",
              zIndex: -1,
            },
            getBorder()
          )}
        >
          {props.viewPreferences.showIDs ? (
            <DebugID
              name="Element"
              id={props.element !== null ? props.element.id : -1}
              componentOrder={0}
            />
          ) : null}

          {props.approvalsEnabled &&
            props.isHeading &&
            props.parentSection.pending_deleted_at !== null && (
              <IconButton
                style={{
                  position: "absolute",
                  top: 3,
                  right: 7,
                  transition: "none", // Remove transition on button press
                  margin: 0,
                  padding: 0,
                  width: "fit-content",
                }}
                onMouseDown={(e) => e.preventDefault()}
                onClick={(e) => {
                  e.preventDefault();
                  setOpenModel(ModalState.UndoConfirm);
                  setIsDeleteOpen(true);
                  e.stopPropagation(); // Add this line to stop event propagation
                }}
                title="Undo Deletion"
              >
                <UndoIcon fontSize="small" />
              </IconButton>
            )}

          {/* SUB-SECTION (ACTUALLY ELEMENT) DELETE APPROVALS */}
          {(props.approvalsEnabled &&
            !props.isHeading &&
            !props.readOnly &&
            props.parentSubsection &&
            !isIncludeImpact(approvalState, props.parentSubsection.id) &&
            isDeletedSubsection(props.parentSubsection!) &&
            !(
              props.documentTemplate as DocumentTemplate
            ).deleted_sub_sections.includes(props.parentSubsection!.id)) ||
            (!props.isHeading &&
              props.approvalsEnabled &&
              props.parentSubsection &&
              !isIncludeImpact(approvalState, props.parentSubsection.id) &&
              !props.readOnly &&
              (
                props.documentTemplate as DocumentTemplate
              ).deleted_sub_sections.includes(props.parentSubsection!.id) &&
              !props.readOnly &&
              (
                props.documentTemplate as DocumentTemplate
              ).is_undo_show!.includes(props.parentSubsection!.id) && (
                // props.parentSubsection?.pending_deleted_at !== null
                <IconButton
                  style={{
                    position: "absolute",
                    top: 3,
                    right: 7,
                    transition: "none", // Remove transition on button press
                    margin: 0,
                    padding: 0,
                    width: "fit-content",
                  }}
                  onMouseDown={(e) => e.preventDefault()}
                  onClick={(e) => {
                    e.preventDefault();
                    setIsDeleteOpen(true);
                    setOpenModel(ModalState.UndoConfirm);
                    e.stopPropagation(); // Add this line to stop event propagation
                  }}
                  title="Undo Deletion"
                >
                  <UndoIcon fontSize="small" />
                </IconButton>
              ))}
          {/* Editor Properties  Font Size ,  Line Spacing , Alignmnet Center , Right  */}
          {!props.forApproval && (
            <Box
              id={
                props.isHeading
                  ? `p_section-${props.parentSection.id}`
                  : `p_subsection-${props.parentSubsection!.id}`
              }
              sx={
                props.isHeading
                  ? {
                      lineHeight: isEditorDisabled(props.parentSection.heading)
                        ? 0
                        : (((documentMasterFormat(
                            _documentMasterState,
                            "HEADING",
                            1
                          ).fontSize as number) *
                            2) /
                            96) *
                            72 +
                          "px !important",

                      fontSize: isEditorDisabled(props.parentSection.heading)
                        ? 8
                        : ((documentMasterFormat(
                            _documentMasterState,
                            "HEADING",
                            1
                          ).fontSize as number) /
                            96) *
                            72 +
                          "px !important",

                      width:
                        justification === "right" || justification === "center"
                          ? "fit-content"
                          : "100%",
                      margin: justification === "center" ? "auto" : "default",
                      marginLeft:
                        justification === "right" ? "auto" : "default",
                    }
                  : {
                      lineHeight:
                        props.parentSection.heading == "Title Page" &&
                        props.element &&
                        props.element.content_type == "TEXT"
                          ? (((documentMasterFormat(
                              _documentMasterState,
                              "TEXT",
                              1
                            ).fontSize as number) *
                              1.2 *
                              documentMasterFormat(
                                _documentMasterState,
                                "TEXT",
                                1
                              ).linespacing) as number) + "px !important"
                          : props.element?.content_type === "TEXT"
                          ? (((documentMasterFormat(
                              _documentMasterState,
                              props.element!.content_type,
                              props.element?.heading_level
                            ).fontSize as number) *
                              1.2 *
                              documentMasterFormat(
                                _documentMasterState,
                                props.element?.content_type,
                                props.element?.heading_level
                              ).linespacing) as number) + "px !important"
                          : documentMasterState.documentMaster
                              ?.paragraph_font_size_pt *
                              documentMasterState.documentMaster
                                ?.paragraph_spacing_pt +
                            "px !important",

                      fontSize:
                        props.parentSection.heading == "Title Page" &&
                        props.element &&
                        props.element.content_type == "TEXT"
                          ? (documentMasterFormat(
                              _documentMasterState,
                              "TEXT",
                              1
                            ).fontSize as number) + "px !important"
                          : props.element?.content_type === "HEADING"
                          ? ((documentMasterFormat(
                              _documentMasterState,
                              props.element!.content_type,
                              props.element?.heading_level
                            ).fontSize as number) /
                              96) *
                              72 +
                            "px !important"
                          : (documentMasterFormat(
                              _documentMasterState,
                              props.element!.content_type,
                              props.element?.heading_level
                            ).fontSize as number) + "px !important",

                      width:
                        (justification === "right" ||
                          justification === "center") &&
                        props.element?.content === ""
                          ? "100%" //"30%"
                          : justification === "right" ||
                            justification === "center"
                          ? "100%" // "fit-content"
                          : "100%",

                      // To Move Numberng Along with Editor
                      margin: justification === "center" ? "auto" : "default",
                      marginLeft:
                        justification === "right" ? "auto" : "default",
                    }
              }
            >
              {props.viewPreferences.showDebug && (
                <div>
                  <p>
                    editorRef present:{" "}
                    {editorRef !== null && editorRef !== undefined
                      ? "true"
                      : "false"}{" "}
                  </p>
                  <p>
                    editorState present:{" "}
                    {editorState !== null && editorState !== undefined
                      ? "true"
                      : "false"}{" "}
                  </p>
                  <p>
                    selectedCurrentEditorState present:{" "}
                    {selectedCurrentEditorState !== null &&
                    selectedCurrentEditorState !== undefined
                      ? "true"
                      : "false"}{" "}
                  </p>
                  <Button
                    onClick={() => {
                      console.log("Editor State", editorState);
                      console.log("Editor Red", editorRef);
                    }}
                  >
                    CONSOLE LOG EDITOR STATE
                  </Button>
                </div>
              )}
              <Box
                id={
                  props.isHeading
                    ? `_section-${props.parentSection.id}`
                    : `_subsection-${props.parentSubsection!.id}`
                }
                key={
                  props.isHeading
                    ? `key__number_section-${props.parentSection.id}`
                    : `key__number_subsection-${props.parentSubsection!.id}`
                }
                sx={{
                  overflow: "visible",

                  //  indentation
                  paddingLeft:
                    isAutoIndentApplied &&
                    !isEditorDisabled(props.parentSection.heading)
                      ? getPositonOfElement()
                      : // marginLeft:
                      justification === "left" || justification === "justify"
                      ? convertMmToPt(indent) + "pt"
                      : justification === "center"
                      ? convertMmToPt(indent) + "pt"
                      : justification === "right"
                      ? convertMmToPt(indent) + "pt"
                      : "0pt",

                  display: "flex",
                  alignItems: "baseline",
                  margin: 0,
                  marginTop:
                    (props.parentSection.heading == "Title Page" &&
                      props.isHeading) ||
                    props.isHeading
                      ? "0px"
                      : 1,
                  //  spaceBelow
                  paddingBottom:
                    props.isHeading &&
                    isEditorDisabled(props.parentSection.heading)
                      ? "0px"
                      : (spacingBelow as unknown as number) < 12
                      ? "12px"
                      : spacingBelow + "px",
                  //  spaceAbove
                  paddingTop:
                    props.isHeading &&
                    isEditorDisabled(props.parentSection.heading)
                      ? "0px"
                      : spacingAbove + "px",
                }}
              >
                <Box
                  id={
                    props.isHeading
                      ? `number_section-${props.parentSection.id}`
                      : `number_subsection-${props.parentSubsection!.id}`
                  }
                  key={
                    props.isHeading
                      ? `key-number_section-${props.parentSection.id}`
                      : `key-number_subsection-${props.parentSubsection!.id}`
                  }
                  sx={{
                    whiteSpace: "nowrap",
                  }}
                  paddingBottom={-10}
                  paddingTop={
                    props.isHeading &&
                    !isEditorDisabled(props.parentSection.heading)
                      ? 1
                      : 0
                  }
                >
                  {props.isHeading &&
                  !isEditorDisabled(props.parentSection.heading) &&
                  documentMasterFormat(_documentMasterState, "SECTION", 1)
                    .numbering !== "hidden" &&
                  !hideNumbering ? (
                    <h1
                      className={
                        documentMasterFormat(
                          _documentMasterState,
                          "SECTION",
                          1
                        ).fontStyle.includes("bold")
                          ? "bold-text"
                          : "normal-text"
                      }
                      ref={boxRef}
                      style={{
                        fontFamily: font, // Set the font family
                        color: "#" + fontColor, // Set the color
                        fontWeight: documentMasterFormat(
                          _documentMasterState,
                          "SECTION",
                          1
                        ).fontStyle.includes("bold")
                          ? "bold"
                          : "normal", // Set the font weight to bold if applicable
                        fontSize: "2 em", // Set the font size
                        fontStyle: documentMasterFormat(
                          _documentMasterState,
                          "SECTION",
                          1
                        ).fontStyle.includes("italic")
                          ? "italic"
                          : "normal", // Set the font style to italic if applicable
                        textDecoration: isUnderlineApplied(
                          props.parentSection.heading
                        )
                          ? "underline"
                          : "none", // Set text decoration to underline if applicable
                      }}
                    >
                      {numbering}
                    </h1>
                  ) : props.element?.content_type === "HEADING" &&
                    !isEditorDisabled(props.parentSection.heading) &&
                    documentMasterFormat(
                      _documentMasterState,
                      props.element?.content_type,
                      props.element?.heading_level
                    ).numbering !== "hidden" &&
                    !hideNumbering ? (
                    <span
                      className={
                        documentMasterFormat(
                          _documentMasterState,
                          props.element?.content_type,
                          props.element?.heading_level
                        ).fontStyle.includes("bold")
                          ? "bold-text"
                          : "normal-text"
                      }
                      style={{
                        fontSize: "1.5em", // Set the font size to 1.5em
                        fontFamily: font, // Set the font family
                        color: "#" + fontColor, // Set the color
                        fontWeight: documentMasterFormat(
                          _documentMasterState,
                          props.element?.content_type,
                          props.element?.heading_level
                        ).fontStyle.includes("bold")
                          ? "bold !important"
                          : "normal", // Set the font weight to bold if applicable
                        fontStyle: documentMasterFormat(
                          _documentMasterState,
                          props.element?.content_type,
                          props.element?.heading_level
                        ).fontStyle.includes("italic")
                          ? "italic"
                          : "normal", // Set the font style to italic if applicable
                      }}
                    >
                      {numbering}
                    </span>
                  ) : props.element?.content_type === "TEXT" &&
                    isNumberDisplay &&
                    props.element?.heading_level !== 1 &&
                    !isEditorDisabled(props.parentSection.heading) &&
                    documentMasterFormat(
                      _documentMasterState,
                      props.element?.content_type,
                      props.element?.heading_level
                    ).numbering !== "hidden" &&
                    !hideNumbering ? (
                    <Typography
                      fontFamily={font}
                      fontSize={parseFloat(fontSize as string) + "px"}
                      color={isNumberDisplay ? "#" + fontColor : "white"}
                    >
                      {numbering}
                    </Typography>
                  ) : (
                    ""
                  )}
                </Box>
                <Grid
                  key={
                    props.isHeading
                      ? `text-section-key-grid${props.parentSection.id}`
                      : `text-subsection-key-grid${props.parentSubsection!.id}`
                  }
                  id={
                    props.isHeading
                      ? `grid-text-section-${props.parentSection.id}`
                      : `grid-text-subsection-${props.parentSubsection!.id}`
                  }
                  padding={"0px  !important"}
                  container
                  spacing={1}
                  sx={{ display: "inline !important", margin: 0, padding: 0 }}
                >
                  <Grid item xs={12} padding={"0px  !important"}>
                    <Box
                      key={
                        props.isHeading
                          ? `text-section-key${props.parentSection.id}`
                          : `text-subsection-key${props.parentSubsection!.id}`
                      }
                      id={
                        props.isHeading
                          ? `text-section-${props.parentSection.id}`
                          : `text-subsection-${props.parentSubsection!.id}`
                      }
                      paddingTop={0}
                      marginLeft={
                        !isNumberDisplay || hideNumbering ? "0" : "5.97pt"
                      }
                    >
                      <form onKeyDown={(e) => isText(e)}>
                        {!props.approvalsEnabled &&
                        props.element &&
                        (props.element.content_type === "TEXT" ||
                          props.element.content_type === "HEADING") &&
                        !props.readOnly ? (
                          <IconButton
                            disableRipple
                            style={{
                              position: "absolute",
                              top: -10,
                              left: -5,
                              // right: "50%",
                              // transform: "translate(50%, -50%)",
                              zIndex: "99",
                              color: "silver.main !important",
                              backgroundColor: "transparent", // Remove the grey color effect on hover
                            }}
                            onMouseDown={(e) => e.preventDefault()}
                            onClick={(e: any) => {
                              e.preventDefault();
                              moveSelectedSubsectionUpward(
                                props.parentSection as DocumentSection,
                                props.parentSubsection!,
                                actions.switchSubsections
                              );
                              e.stopPropagation(); // Add this line to stop event propagation
                            }}
                          >
                            {" "}
                            <ExpandLessIcon
                              fontSize="small"
                              style={{
                                fontSize: 22,
                                outline: "none",
                                color: "#7284A3",
                              }}
                            />
                          </IconButton>
                        ) : null}

                        {!(
                          isEditorDisabled(props.parentSection.heading) &&
                          props.parentSection.heading != "Title Page"
                        ) &&
                        !props.readOnly &&
                        !props.approvalsEnabled ? (
                          <IconButton
                            style={{
                              position: "absolute",
                              top: 3,
                              right: 7,
                              transition: "none", // Remove transition on button press
                              margin: 0,
                              padding: 0,
                              width: "fit-content",
                              zIndex: 99,
                            }}
                            onMouseDown={(e) => e.preventDefault()}
                            onClick={() => {
                              if (
                                (props.isHeading &&
                                  (!!(
                                    props.documentTemplate as DocumentTemplate
                                  ).included_section?.[
                                    props.parentSection.id
                                  ] ||
                                    !!(
                                      props.documentTemplate as DocumentTemplate
                                    ).imported_sections?.[
                                      props.parentSection.id
                                    ])) ||
                                (!props.isHeading &&
                                  (!!(
                                    props.documentTemplate as DocumentTemplate
                                  ).included_subSections?.[
                                    props.parentSubsection!.id
                                  ] ||
                                    !!(
                                      props.documentTemplate as DocumentTemplate
                                    ).imported_sub_sections?.[
                                      props.parentSubsection!.id
                                    ]))
                              ) {
                                if (
                                  selectedCurrentTemplateState.approval ||
                                  selectedCurrentTemplateState.editing ||
                                  !selectedCurrentTemplateState.draft
                                ) {
                                  setOpenModel(ModalState.DeleteConfirmShared);
                                  setIsDeleteConfirmOpen(true);
                                } else {
                                  handleDetachSelection(
                                    props,
                                    actions,
                                    dispatch
                                  );
                                }
                              } else {
                                setIsDeleteUnsharedOpen(true);
                                setOpenModel(ModalState.DeleteConfirmUnshared);
                                // handleDeleteSelection(props, actions, dispatch);
                              }
                            }}
                          >
                            <DeleteIcon
                              style={{
                                fontSize: 22,
                                outline: "none",
                                color: "#7284A3",
                              }}
                            />
                          </IconButton>
                        ) : (
                          isUserFromShareLink() && (
                            <IconButton
                              style={{
                                position: "absolute",
                                top: 0,
                                left: -40,
                                transition: "none",
                                margin: 0,
                                padding: 0,
                                width: "fit-content",
                                zIndex: 99,
                              }}
                            >
                              <CommentsIcon
                                totalComments={113}
                                psectionid={props.parentSection.id || null}
                                ssectionid={props.parentSubsection?.id || null}
                                esectionid={props.element?.id || null}
                                onClick={() => {
                                  console.log("ONCLICK :::");
                                }}
                              />
                            </IconButton>
                          )
                        )}

                        {(!props.isHeading &&
                          !isDeleted &&
                          (!props.readOnly ||
                            props.readOnly === (null || undefined)) &&
                          props.parentSubsection && // Condition 3: props.parentSubsection exists
                          //            and has a property 'elements'
                          // props.approvalsEnabled &&
                          (props.documentTemplate as DocumentTemplate)
                            .is_version_show?.[props.parentSubsection!.id]) ||
                        //    &&
                        // isSubsectionVersionAvailable(
                        //   props.documentTemplate as DocumentTemplate,
                        //   props.parentSubsection!.id
                        // )

                        //  &&
                        // props.parentSubsection.elements.length >= 2
                        // (props.documentTemplate as DocumentTemplate)
                        //   .is_version_available?.[
                        //   props.parentSubsection!.id
                        // ]
                        (props.approvalsEnabled &&
                          !isDeleted &&
                          props.isHeading &&
                          props.parentSection.pending_heading &&
                          !props.readOnly) ? ( // Condition 3: elements has a leng
                          <IconButton
                            style={{
                              position: "absolute",
                              top: 3,
                              // right: 7,
                              right:
                                props.isHeading || !isLibraryItem ? 35 : 62,
                              transition: "none", // Remove transition on button press
                              margin: 0,
                              padding: 0,
                              width: "fit-content",
                              zIndex: 9999,
                            }}
                            onMouseDown={(e) => e.preventDefault()}
                            onClick={(e) => {
                              e.preventDefault();
                              if (props.isHeading) {
                                setOpenModel(ModalState.SelectSectionVersion);
                                setOpenVersionScreen(true);
                              } else {
                                setIsversionOpen(true);
                                setOpenModel(ModalState.SelectElementVersion);
                              }
                              e.stopPropagation(); // Add this line to stop event propagation
                            }}
                            title="Version Control"
                          >
                            <FastRewindIcon
                              style={{
                                fontSize: 22,
                                outline: "none",
                                color:
                                  (props.isHeading &&
                                    props.parentSection.pending_heading) ||
                                  (props.element &&
                                    props.element.element_approvals)
                                    ? "#7284A3"
                                    : "#7284A3",
                              }}
                            />
                          </IconButton>
                        ) : null}

                        {props.approvalsEnabled && (
                          <ApprovalIcon
                            isHeading={props.isHeading}
                            parentSection={
                              props.parentSection as DocumentSection
                            }
                            parentSubsection={
                              props.parentSubsection as DocumentSubsection
                            }
                            approvalState={approvalState}
                            style={{
                              outline: "none",
                              color: "#7284A3",
                              position: "absolute",
                              top: 3,
                              left: -40,
                              transition: "none", // Remove transition on button press
                              margin: 0,
                              padding: 0,
                              width: "fit-content",
                            }}
                          />
                        )}

                        <LibraryContentIcon
                          isHeading={props.isHeading}
                          parentSection={props.parentSection as DocumentSection}
                          parentSubsection={
                            props.parentSubsection as DocumentSubsection
                          }
                          documentTemplate={
                            props.documentTemplate as DocumentTemplate
                          }
                          style={{
                            outline: "none",
                            color: "#7284A3",
                            position: "absolute",
                            top: 3,
                            right: 35,
                            transition: "none", // Remove transition on button press
                            margin: 0,
                            padding: 0,
                            width: "fit-content",
                          }}
                        />
                        <Editor
                          //  spellCheck
                          wrapperClassName={
                            isFocused ? "wrapper-class-section" : ""
                          }
                          editorClassName={
                            props.isHeading &&
                            isEditorDisabled(props.parentSection.heading)
                              ? `EditorPositionscenter`
                              : `EditorPositions${justification}`
                          }
                          editorStyle={{
                            marginTop:
                              props.parentSection.heading == "Title Page"
                                ? "10px"
                                : props.isHeading
                                ? "0px"
                                : "30px",
                            width:
                              justification === "right" ||
                              justification === "center"
                                ? "fit-content"
                                : "100%",
                            display:
                              props.isHeading &&
                              isEditorDisabled(props.parentSection.heading)
                                ? "flex"
                                : "inline",
                            overflowX: "hidden",
                            justifyContent:
                              props.isHeading &&
                              isEditorDisabled(props.parentSection.heading)
                                ? "center"
                                : justification === "justify"
                                ? "start"
                                : justification,
                            // Editor Color
                            color:
                              props.isHeading &&
                              isEditorDisabled(props.parentSection.heading)
                                ? "#6b6a6a"
                                : "#" + fontColor,
                            height: "100vh !important",

                            overflow: "visible !important",
                            fontSize: parseInt(fontSize) + "pt !important",
                            fontFamily: findMatchingFont(font),
                          }}
                          key={
                            (props.element !== null
                              ? props.element.id
                              : props.parentSection.id) + "__editor"
                          }
                          toolbarHidden={true}
                          editorState={
                            isFocused &&
                            selectedCurrentEditorState !== null &&
                            selectedCurrentEditorState !== undefined
                              ? selectedCurrentEditorState
                              : editorState
                          }
                          onEditorStateChange={handleEditorStateChange}
                          handleKeyCommand={handleKeyCommand}
                          handlePastedText={handlePastedText}
                          onFocus={handleOnFocus}
                          customStyleMap={customStyleMap}
                          mention={{
                            separator: " ",
                            trigger: "@",
                            suggestions: getSuggestions(),
                          }}
                          ref={editorRef}
                          placeholder="Add content here... "
                        />

                        {!props.approvalsEnabled &&
                        props.element &&
                        (props.element.content_type === "TEXT" ||
                          props.element.content_type === "HEADING") &&
                        !props.readOnly ? (
                          <IconButton
                            disableRipple
                            style={{
                              position: "absolute",
                              bottom: -10,
                              left: -5,

                              zIndex: "99",
                              color: "silver.main !important",
                              backgroundColor: "transparent", // Remove the grey color effect on hover
                            }}
                            onMouseDown={(e) => e.preventDefault()}
                            onClick={(e: any) => {
                              e.preventDefault();
                              moveSelectedSubsectionDownward(
                                props.parentSection as DocumentSection,
                                props.parentSubsection as DocumentSubsection,
                                actions.switchSubsections
                              );
                            }}
                          >
                            {" "}
                            <ExpandMoreIcon
                              fontSize="small"
                              style={{
                                fontSize: 22,
                                outline: "none",
                                color: "#7284A3",
                              }}
                            />
                          </IconButton>
                        ) : null}
                      </form>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          )}
        </Box>
        {isOver &&
        !(
          isEditorDisabled(props.parentSection.heading) &&
          props.parentSection.heading != "Title Page"
        ) ? (
          <DropSkeleton />
        ) : null}
      </Box>

      {
        // isVersionOpen === true ?
        openModel == ModalState.SelectElementVersion ? (
          <VersionElementSelector
            documentTemplateSummary={props.documentTemplate as DocumentTemplate}
            documentSectionSummary={
              props.parentSection as DocumentSectionSummary
            }
            parentSubsection={props.parentSubsection as DocumentSubsection}
            organizationId={props.organizationId}
            workspaceId={props.workspaceId}
            viewPreferences={props.viewPreferences}
            currentSelectedElement={props.element!.id}
            // closeDialogHandler={() => setIsversionOpen(false)}
            closeDialogHandler={() => setOpenModel(ModalState.None)}
          />
        ) : null
      }

      {openModel == ModalState.UndoConfirm ? (
        <EditorUndoForm
          isHeading={props.isHeading}
          parentSection={props.parentSection as DocumentSection}
          parentSubsection={props.parentSubsection as DocumentSubsection}
          // closeDialogHandler={() => setIsDeleteOpen(false)}
          closeDialogHandler={() => setOpenModel(ModalState.None)}
        />
      ) : null}

      {openModel == ModalState.DeleteConfirmShared ? (
        <DeleteConfirmForm
          isHeading={props.isHeading}
          parentSection={props.parentSection as DocumentSection}
          parentSubsection={props.parentSubsection as DocumentSubsection}
          closeDialogHandler={() => setOpenModel(ModalState.None)}
        />
      ) : null}

      {openModel == ModalState.DeleteConfirmUnshared ? (
        <DeleteUnsharedConfirm
          isHeading={props.isHeading}
          parentSection={props.parentSection as DocumentSection}
          parentSubsection={props.parentSubsection as DocumentSubsection}
          closeDialogHandler={() => setOpenModel(ModalState.None)}
        />
      ) : null}

      {openModel == ModalState.SelectSectionVersion && (
        <SectionVersionControl
          closeDialogHandler={() => setOpenModel(ModalState.None)}
          documentSectionSummary={props.parentSection as DocumentSection}
          documentTemplateSummary={props.documentTemplate as DocumentTemplate}
          organizationId={props.organizationId}
          workspaceId={props.workspaceId}
          viewPreferences={props.viewPreferences}
        />
      )}

      {
        // IsLibraryOpen
        openModel == ModalState.ImportLibraryContent && (
          <LibraryContentConfigFormSubSection
            // closeDialogHandler={() => SetIsLibarayOpen(false)}
            closeDialogHandler={() => setOpenModel(ModalState.None)}
            organizationId={props.organizationId}
            workspaceId={props.workspaceId}
            parentSection={props.parentSection as DocumentSection}
            parentSubsection={props.parentSubsection as DocumentSubsection}
            viewPreferences={props.viewPreferences}
          />
        )
      }
    </>
  );
}

export default memo(DocumentElementEditor);

// function parseColorStyle(styleString: any) {
//   const colorRegex = /color-rgb\((\d+),(\d+),(\d+)\)/;
//   const match = styleString.match(colorRegex);
//   if (match) {
//     const r = parseInt(match[1], 10);
//     const g = parseInt(match[2], 10);
//     const b = parseInt(match[3], 10);
//     return {
//       color: rgbToHex(r, g, b).substring(1),
//       property: `color-rgb(${r},${g},${b})`,
//     };
//   } else {
//     return null;
//   }
// }

// function removeAllColorEntitiesAndCreateNew(editorState: EditorState) {
//   const contentState = editorState.getCurrentContent();
//   let newContentState = contentState;

//   contentState.getBlockMap().forEach((contentBlock: any) => {
//     contentBlock.findEntityRanges(
//       (character: any) => character.getEntity() !== null,
//       (start: any, end: any) => {
//         const entityKey = contentBlock.getEntityAt(start);
//         const entity = contentState.getEntity(entityKey);
//         const entityType = entity.getType();

//         // Check if the entity is a color entity
//         if (entityType === "COLOR") {
//           // Remove the existing color entity
//           newContentState = Modifier.applyEntity(
//             newContentState,
//             SelectionState.createEmpty(contentBlock.getKey()).merge({
//               anchorOffset: start,
//               focusOffset: end,
//             }),
//             null
//           );
//         }
//       }
//     );
//   });

//   // Apply new color entities based on inline styles
//   newContentState.getBlockMap().forEach((contentBlock: any) => {
//     let currentStyle = null;
//     let startOffset = 0;

//     for (let i = 0; i < contentBlock.getLength(); i++) {
//       const styles = contentBlock.getInlineStyleAt(i);
//       console.log(
//         "🚀 ~ file: DocumentElementEditor.tsx:555 ~ newContentState.getBlockMap ~ styles:",
//         styles.toJS()
//       );
//       const nextStyles = contentBlock.getInlineStyleAt(i + 1);

//       const isLastCharacter = i === contentBlock.getLength() - 1;

//       if (!styles.equals(currentStyle) || isLastCharacter) {
//         // If the style changes or we reach the end of the block, create an entity
//         if (currentStyle && startOffset <= i) {
//           const colorData = parseColorStyle(currentStyle.toString());

//           if (colorData != null) {
//             const newEntityKey = Entity.create("COLOR", "MUTABLE", colorData);

//             newContentState = Modifier.applyEntity(
//               newContentState,
//               SelectionState.createEmpty(contentBlock.getKey()).merge({
//                 anchorOffset: startOffset,
//                 focusOffset: isLastCharacter ? i + 1 : i,
//               }),
//               newEntityKey
//             );
//           }
//         }
//         // Update the current style and start offset for the next range
//         currentStyle = nextStyles.equals(currentStyle)
//           ? currentStyle
//           : nextStyles;
//         startOffset = i;
//       }
//     }
//   });

//   const newEditorState = EditorState.createWithContent(newContentState);

//   console.log(
//     "convertToRaw(newEditorState the updated editor state",
//     convertToRaw(newEditorState.getCurrentContent())
//   );

//   return newEditorState;
// }

// correct 100%
// function removeAllColorEntitiesAndCreateNew(editorState:EditorState) {
//   const contentState = editorState.getCurrentContent();
//   let newContentState = contentState;

//   contentState.getBlockMap().forEach((contentBlock:any) => {
//     contentBlock.findEntityRanges(
//       (character:any) => character.getEntity() !== null,
//       (start:any, end:any) => {
//         const entityKey = contentBlock.getEntityAt(start);
//         const entity = contentState.getEntity(entityKey);
//         const entityType = entity.getType();

//         // Check if the entity is a color entity
//         if (entityType === 'COLOR') {
//           // Remove the existing color entity
//           newContentState = Modifier.applyEntity(
//             newContentState,
//             SelectionState.createEmpty(contentBlock.getKey()).merge({
//               anchorOffset: start,
//               focusOffset: end,
//             }),
//             null
//           );
//         }
//       }
//     );
//   })

//     // Apply new color entities based on inline styles
//     newContentState.getBlockMap().forEach((contentBlock: any) => {
//       let currentStyle = null;
//       let startOffset = 0;

//       for (let i = 0; i <= contentBlock.getLength(); i++) {
//         const styles = contentBlock.getInlineStyleAt(i);
//         const isLastCharacter = i === contentBlock.getLength();

//         if (!styles.equals(currentStyle) || isLastCharacter) {
//           // If the style changes or we reach the end of the block, create an entity
//           if (currentStyle && startOffset < i) {

//             const colorData = parseColorStyle(currentStyle.toString());
//             console.log("🚀 ~ file: DocumentElementEditor.tsx:560 ~ newContentState.getBlockMap ~ colorData:", colorData , "currentStyle.toString()" , currentStyle.toString() , "styles" , styles)

//             if(colorData!=null){
//               const newEntityKey = Entity.create('COLOR', 'MUTABLE', colorData);

//               newContentState = Modifier.applyEntity(
//                 newContentState,
//                 SelectionState.createEmpty(contentBlock.getKey()).merge({
//                   anchorOffset: startOffset,
//                   focusOffset: i,
//                 }),
//                 newEntityKey
//               );
//             }
//           }
//           // Update the current style and start offset for the next range
//           currentStyle = styles;
//           startOffset = i;
//         }
//       }
//     });

//   const newEditorState = EditorState.createWithContent(newContentState);

//  console.log("the updated editor state" , convertToRaw(newEditorState .getCurrentContent()))

//   return newEditorState;
// }

// const selectedCurrentEditorStatess = useSelector((state: RootState) => {makeSelectCurrentEditorStateCustom(state,props)})
//  const selectedCurrentEditorState = useSelector((state: RootState) => {
//   if(props.isHeading && state.document.selectedSection?.id===props.parentSection.id){
//     return state.markdownEditor.currentEditorState
//   }else if( !props.isHeading && props.parentSubsection?.id === state.document.selectedSubsection?.id) {
//     return state.markdownEditor.currentEditorState
//   }
//   return null
//  });
