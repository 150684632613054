import React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import useActions from "../../../../../app/hooks";

import {
  ContentType,
  DocumentSection,
  DocumentSubsection,
} from "../../../../../types/DocumentTemplate";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { CollectionsBookmark } from "@mui/icons-material";
import ReactMarkdown from "react-markdown";
import { selectApprovalMode, selectApprovalState } from "../../../editorSlice";
import AutoAwesomeRoundedIcon from "@mui/icons-material/AutoAwesomeRounded";

import { useAppSelector } from "../../../../../app/hooks";
import { attachedSubsectionElement } from "../../../document/utils";
import { isImpactPending } from "../../right/Approval/ApprovalHelperFunctions/approvalHelper";
import { COLORS } from "../../../../shared/Constants";

const MAX_LENGTH = 32;
const truncate = (str: string, n: number) => {
  return str.length > n ? str.substr(0, n - 1) + "..." : str;
};

export default function TableOfContentsSubSections(props: {
  parentSection: DocumentSection;
}) {
  const actions = useActions();
  const approvalsEnabled = useAppSelector(selectApprovalMode);
  const approvalState = useAppSelector(selectApprovalState);

  const subheadings = props.parentSection.subsections?.filter(
    (subsection) =>
      subsection.elements![0]?.content_type === ContentType.Heading
  );
  // console.log("the subsection" ,subheadings )

  /**
   * Sort subsections when they are dragged around.
   *
   * @param {*} result
   * @return {*}
   */

  function onDragEnd(result: any) {
    if (
      !result.destination ||
      result.source === result.destination ||
      subheadings == null
    ) {
      return;
    }
    actions.switchSubsectionBlock({
      section: props.parentSection,
      sourceSubsection: subheadings[result.source.index],
      destinationSubsection: subheadings[result.destination.index],
      onlyHeading: true,
    });
    // actions.switchSubsections({
    //   section: props.parentSection,
    //   sourceSubsection: subheadings[result.source.index],
    //   destinationSubsection: subheadings[result.destination.index],
    //   onlyHeading: true,
    // });
  }

  const handleScroll = (subSectionID: number) => {
    const boxElement = document.querySelector(`#subsection-${subSectionID}`);
    if (boxElement) {
      boxElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  // ? if subsection contains any unapproved element?
  const containsPendingApproval = (currentSubsection: DocumentSubsection) => {
    return (
      currentSubsection.elements &&
      currentSubsection.elements[0] &&
      currentSubsection.elements[0].difference_content !== null
    );
  };

  return (
    <List component="div" disablePadding>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable
          droppableId={`subsection_${props.parentSection.id}_droppable`}
        >
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              // style={getListStyle(snapshot.isDraggingOver)}
            >
              {!approvalsEnabled &&
                subheadings
                  ?.filter(
                    (subsection) => subsection.pending_deleted_at === null
                  )
                  .map((subSection: DocumentSubsection, index: number) => (
                    <Draggable
                      key={subSection.id}
                      draggableId={String(subSection.id)}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <Box
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          // style={getItemStyle(
                          //   snapshot.isDragging,
                          //   provided.draggableProps.style
                          // )}
                        >
                          <ListItem key={subSection.id} sx={{ pl: 3 }}>
                            {/* <ListItemIcon>
                            <CollectionsBookmark color="primary" />
                          </ListItemIcon> */}
                            {/* {approvalsEnabled &&
                            containsPendingApproval(subSection) && (
                              <AutoAwesomeRoundedIcon
                                sx={{ color: "#4C33FF", pr: "5px" }}
                              />
                            )} */}

                            {approvalsEnabled &&
                              isImpactPending(approvalState, subSection.id) && (
                              //  ||   containsPendingApproval(subSection)
                                <AutoAwesomeRoundedIcon
                                  sx={{
                                    color: isImpactPending(
                                      approvalState,
                                      subSection.id
                                    )
                                      ? //  "#00C482"
                                        COLORS.NOMIA_PINK
                                      : COLORS.NOMIA_PINK, //"#FF0062",

                                    // "#4C33FF",
                                    pr: "5px",
                                  }}
                                />
                              )}

                            <ListItemText
                              onClick={(e) => handleScroll(subSection.id)}
                              style={{
                                height: "min-content",
                                minHeight: "auto",
                              }}
                              primary={
                                // <ReactMarkdown>{
                                truncate(
                                  attachedSubsectionElement(
                                    subSection
                                  ).content.replace(/## |\+\+|\*|_/g, ""),
                                  32
                                )

                                // }</ReactMarkdown>
                                // <ReactMarkdown>{
                                //   truncate(
                                //   subSection.elements![0].content.replace(
                                //     "## ",
                                //     ""
                                //   ).replace(/\+\+/g, ''),
                                //   MAX_LENGTH
                                // )

                                // }</ReactMarkdown>
                              }
                            />
                          </ListItem>
                        </Box>
                      )}
                    </Draggable>
                  ))}

              {approvalsEnabled &&
                subheadings?.map(
                  (subSection: DocumentSubsection, index: number) => (
                    <Draggable
                      key={subSection.id}
                      draggableId={String(subSection.id)}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <Box
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          // style={getItemStyle(
                          //   snapshot.isDragging,
                          //   provided.draggableProps.style
                          // )}
                          sx={{
                            border:
                              approvalsEnabled &&
                              isImpactPending(approvalState, subSection.id)
                                ? `1px solid ${COLORS.NOMIA_PINK} !important`
                                : "white",
                          }}
                        >
                          <ListItem key={subSection.id} sx={{ pl: 3 }}>
                            {/* <ListItemIcon>
                            <CollectionsBookmark color="primary" />
                          </ListItemIcon> */}
                            {/* {approvalsEnabled &&
                            containsPendingApproval(subSection) && (
                              <AutoAwesomeRoundedIcon
                                sx={{ color: "#4C33FF", pr: "5px" }}
                              />
                            )} */}

                            {approvalsEnabled &&
                              (isImpactPending(approvalState, subSection.id) ||
                                // ||   containsPendingApproval(subSection)
                                subSection.pending_deleted_at != null) && (
                                <AutoAwesomeRoundedIcon
                                  sx={{
                                    color:
                                      subSection.pending_deleted_at != null
                                        ? COLORS.NOMIA_PINK
                                        : isImpactPending(
                                            approvalState,
                                            subSection.id
                                          )
                                        ? COLORS.NOMIA_PINK //"#00C482"
                                        : // if the approver approves it change color
                                          COLORS.NOMIA_PINK, //"#FF0062",  // "#4C33FF",
                                    pr: "5px",
                                  }}
                                />
                              )}

                            <ListItemText
                              onClick={(e) => handleScroll(subSection.id)}
                              style={{
                                height: "min-content",
                                minHeight: "auto",
                                color:
                                  props.parentSection.pending_deleted_at !=
                                    null ||
                                  subSection.pending_deleted_at != null
                                    ? "red"
                                    : "black",
                              }}
                              primary={
                                // <ReactMarkdown>{

                                truncate(
                                  attachedSubsectionElement(
                                    subSection
                                  ).content.replace(/## |\+\+|\*|_/g, ""),
                                  32
                                )

                                // }</ReactMarkdown>
                                // <ReactMarkdown>{
                                //   truncate(
                                //   subSection.elements![0].content.replace(
                                //     "## ",
                                //     ""
                                //   ).replace(/\+\+/g, ''),
                                //   MAX_LENGTH
                                // )

                                // }</ReactMarkdown>
                              }
                            />
                          </ListItem>
                        </Box>
                      )}
                    </Draggable>
                  )
                )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </List>
  );
}
