import { client } from "./client";
import { DocumentTemplate } from "../types/DocumentTemplate";
import { DocumentMaster } from "../types/DocumentMaster";
import { host } from "./client";
import { getLocalUserToken } from "./userAPI";

export function apiGetDocumentMaster(
  organizationId: number,
  documentTemplate: DocumentTemplate
) {
  return client.get(
    `/organizations/${organizationId}/document_templates/${documentTemplate.id}/document_template_master`
  );
}

export function apiGetSectionsDocumentMaster(
  organizationId: number,
  workspaceId: number,
  documentTemplate: DocumentTemplate
) {
  return client.get(
    `/organizations/${organizationId}/document_templates/${documentTemplate.id}/workspaces/${workspaceId}/sections_formatting`
  );
}

export function apiUpdateSectionsDocumentMaster(
  organizationId: number,
  workspaceId: number,
  documentTemplate: DocumentTemplate,
  sectionID: number,
  sectionMaster: any
) {
  return client.put(
    `/organizations/${organizationId}/document_templates/${documentTemplate.id}/document_sections//${sectionID}/update_section_formatting`,
    {   formatting:JSON.stringify( sectionMaster)}
  );
}

export async function apiGetCategory(organization: number, workspace: number) {
  try {
    const response = await client.get(
      `/organizations/${organization}/workspaces/${workspace}/document_templates/get_purpose_category`
    );
    return response.data;
  } catch (error) {
    console.error("Error retrieving category:", error);
  }
}

// export async function apiGetWorkspaceDocumentMasters(
//   workspaceId: number,
//   organizationId: number
// ) {
//   try {
//     const response = await client.get(
//       `/document_template_master/workspace/${workspaceId}`
//     );

//     return response.data;
//   } catch (error) {
//     console.error(
//       "Error retrieving Workspace all document TemplateMasters:",
//       error
//     );
//   }
// }
 export async function apiGetWorkspaceDocumentMasters(
  workspaceId: number,
   organizationId: number
 ) {   try {
    const response = await client.get(
     `/document_template_master/organizations/${organizationId}`
    );

     return response.data;
   } catch (error) {
     console.error(
       "Error retrieving Workspace all document TemplateMasters:",
       error
     );
   } }

export function apiUpdateDocumentMaster(
  organizationId: number,
  documentTemplate: DocumentTemplate,
  // documentMaster: DocumentMaster
  documentMaster: any
) {
  return client.post(
    `/organizations/${organizationId}/document_templates/${documentTemplate.id}/document_template_master`,
documentMaster
  );
}

export function apiCreateDocumentMaster(
  organizationId: number,
  documentTemplate: DocumentTemplate,
  // documentMaster: DocumentMaster
  documentMaster: any
): Promise<{ status: number; data: any; headers: Headers; url: string; }> {
  return client.post(
    `/organizations/${organizationId}/document_templates/${documentTemplate.id}/document_template_master/save_as`,
    documentMaster
  );
}

export function apiUpdateWorkspaceDocumentMaster(
  organizationId: number,
  documentTemplate: DocumentTemplate,
  // documentMaster: DocumentMaster
  documentMaster: any
) {
  return client.put(
    `/organizations/${organizationId}/document_templates/${documentTemplate.id}/document_template_master/accros_workspace`,
    documentMaster
  );
}

function readFileAsync(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = reject;
    reader.readAsArrayBuffer(file);
  });
}

export async function apiUploadImageDocumentMaster(
  organizationId: number,
  documentTemplate: DocumentTemplate,
  file: File,
  purpose?: string
) {
  // Temporary construction of the URL until client is refactored.
  const url = `${host}/organizations/${organizationId}/document_templates/${documentTemplate.id}/document_images_png`;
  let headers = {};
  // Content-Type: image/png
  const token = getLocalUserToken();
  if (token) {
    headers = {
      Authorization: token,
      "Content-Type": "application/x-www-form-urlencoded",
    };
  }
  const contentBuffer = await readFileAsync(file);
  const response = await window.fetch(url, {
    method: "POST",
    body: contentBuffer as BodyInit,
    headers: headers,
  });
  const data = await response.json();
  if (response.ok) {
    // Return a result object similar to Axios
    return {
      status: response.status,
      data,
      headers: response.headers,
      url: response.url,
    };
  }
  throw new Error(response.statusText);
}
