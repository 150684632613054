import React from "react";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Grow from "@mui/material/Grow";
import Typography from "@mui/material/Typography";

export function DropSkeleton(props: { height?: number; message?: string }) {
  return (
    <Grow in={true}>
      <Box sx={{ position: "relative" }}>
        <Skeleton
          variant="rectangular"
          height={props.height ?? 40}
          sx={{ px: 5 }}
        />
        <Box sx={{ p: 1, position: "absolute", top: "0%", left: "5%" }}>
          <Typography>
            <i>{props.message}</i>
          </Typography>
        </Box>
      </Box>
    </Grow>
  );
}
