interface CommentingDetailsState {
  commenter: string | null;
  comments: any;
  allComments: any;
}

const initialCommentingState: CommentingDetailsState = {
  commenter: null,
  comments: null,
  allComments: null,
};

// Action Types
const SET_COMMENTER_DATA = "SET_COMMENTER_DATA";
const SET_CURRENT_COMMENTS = "SET_CURRENT_COMMENTS";
const SET_ALL_COMMENTS = "SET_ALL_COMMENTS";

// Action Creators
export const setCommenter = (data: any) => ({
  type: SET_COMMENTER_DATA,
  payload: data,
});

export const setCurrentComments = (data: any) => ({
  type: SET_CURRENT_COMMENTS,
  payload: data,
});

export const setAllComments = (data: any) => ({
  type: SET_ALL_COMMENTS,
  payload: data,
});

// Reducer
const apiCommentingReducer = (
  state = initialCommentingState,
  action: any
): CommentingDetailsState => {
  switch (action.type) {
    case SET_COMMENTER_DATA:
      return { ...state, commenter: action.payload };
    case SET_CURRENT_COMMENTS:
      return { ...state, comments: action.payload };
    case SET_ALL_COMMENTS:
      return { ...state, allComments: action.payload };
    default:
      return state;
  }
};

export default apiCommentingReducer;
