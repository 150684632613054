import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Input,
  Typography,
} from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import useActions, { useAppSelector } from "../../../app/hooks";
import React, { useEffect } from "react";
import { apiGetSuperUsers } from "../../../services/organizationAPI";
import { User } from "../../../types/User";
import { selectAuth } from "../../auth/authSlice";

export default function SuperUsersConfigForm(props: {
  closeDialogHandler: any;
}) {
  const actions = useActions();
  const [users, setUsers] = React.useState([]);
  const [requestStatus, setRequestStatus] = React.useState("idle");
  const authState = useAppSelector(selectAuth);
  const [selectedUser, setSelectedUser] = React.useState<User | null>(null);
  const [deleteConfig, setDeleteConfig] = React.useState(false);

  const handleClose = () => {
    props.closeDialogHandler();
  };

  const fetchData = async () => {
    try {
      setRequestStatus("loading");
      const UsersResponse = await apiGetSuperUsers(123);
      setUsers(UsersResponse.super_user_results);
      setRequestStatus("idle");
    } catch (error) {
      setRequestStatus("failed");
      console.error(
        "An error occurred while fetching organization admins:",
        error
      );
    }
  };
  const handleDeleteSuperUser = () => {
    if (selectedUser != null) {
      actions.deleteSuperUser({
        userID: parseInt(selectedUser.id, 10),
      });
    }
    setDeleteConfig(false);
    fetchData();
    fetchData();
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  const handleDeleteClick = (user: User) => {
    setSelectedUser(user);
    setDeleteConfig(true);
  };
  
  const dialogStyle = {
    maxWidth: "600px",
    margin: "0 auto",
  };

  const copyButtonStyle = {
    marginLeft: "16px",
  };

  const inputStyle = {
    width: "400px",
    marginBottom: "16px",
    display: "flex",
    alignItems: "center",
  };

  return (
    <>
      <Dialog open={true} onClose={handleClose} style={dialogStyle}>
        <DialogTitle style={{ textAlign: "left", fontWeight: "bold" }}>
          Super Admins
        </DialogTitle>
        {requestStatus == "idle" ? (
          <Box style={{ padding: "20px", paddingLeft: "25px", paddingTop: 0 }}>
            {users.map((user: User, index) => (
              <div style={inputStyle}>
                <Input
                  value={user.email}
                  fullWidth
                  key={user.email}
                  readOnly
                  style={{ flex: 1 }}
                />
                <IconButton
                  onClick={() => {
                    handleDeleteClick(user);
                  }}
                  disabled={user.id === authState.user?.user_id}
                >
                  <DeleteForeverIcon sx={{ fontSize: "0.9em" }} />
                </IconButton>
              </div>
            ))}
            {/* <Snackbar
            open={copied}
            autoHideDuration={3000}
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          >
            <Alert severity="success" onClose={handleCloseSnackbar}>
              Email copied to clipboard
            </Alert>
          </Snackbar> */}
          </Box>
        ) : (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "150px",
              width: "420px",
            }}
          >
            {requestStatus == "loading" ? (
              <CircularProgress />
            ) : (
              <Typography fontWeight="bold">Unable to fetch data</Typography>
            )}
          </Box>
        )}
      </Dialog>

      <Dialog
        open={deleteConfig}
        onClose={() => {
          setDeleteConfig(false);
        }}
      >
        <DialogTitle sx={{ fontWeight: "bold" }}>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography variant="body1" color="textPrimary">
              Are you sure you want to proceed with deleting this Super Admin?
              This action is irreversible and cannot be undone.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setDeleteConfig(false);
            }}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeleteSuperUser}
            variant="contained"
            color="primary"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
