import { client } from "./client";
import { Organization } from "../types/User";
import { isUserFromShareLink } from "src/features/editor/document/TableElement/EditContent/helper";

export function apiGetOrganizations() {
  return client.get("/organizations");
}

export function apiGetOrganization(organizationId: number) {
  return client.get(`/organizations/${organizationId}`);
}

export function apiGetOrganizationUsers(organizationId: number) {
  return client.get(`/organizations/${organizationId}/users`);
}

export async function apiGetOrganizationAdmins(organizationId: number) {
  try {
    const response = await client.get(
      `/organizations/${organizationId}/admin_users`
    );
    return response.data;
  } catch (error) {
    console.error("Error retrieving Admins of Current Organization:", error);
  }
}

export async function apiGetWorkspaceApiKey(
  organizationId: number,
  workspacesId: number
) {
  try {
    const response = await client.get(
      `/organizations/${organizationId}/workspaces/${workspacesId}/get_ws_api_key`
    );
    return response.data;
  } catch (error) {
    console.error("Error retrieving Admins of Current Organization:", error);
  }
}

export async function apiResetWorkspaceApiKey(
  organizationId: number,
  workspacesId: number
) {
  try {
    const response = await client.post(
      `/organizations/${organizationId}/workspaces/${workspacesId}/refresh_api`,
      {}
    );
    return response.data;
  } catch (error) {
    console.error("Error retrieving Admins of Current Organization:", error);
  }
}

// #[get("/admin_users/super_user/list_all")]

export async function apiGetSuperUsers(organizationId: number) {
  try {
    const response = await client.get(`/admin_users/super_user/list_all`);
    return response.data;
  } catch (error) {
    console.error("Error retrieving Admins of Current Organization:", error);
  }
}

export function apiCreateOrganization(organization: Organization) {
  return client.post(`/organizations`, organization);
}

export function apiUpdateOrganization(organization: Organization) {
  return client.put(`/organizations/${organization.id}`, organization);
}

export function apiCreateUserInOrg(
  organizationId: number,
  email: string,
  password: string,
  workspaces: { id: number; role: string }[]
) {
  return client.post(`/organizations/${organizationId}/users`, {
    email,
    password,
    workspaces,
  });
}

export function apiCreateUserWithOrg(email: string, organization_name: string) {
  return client.post(`/admin_users`, {
    email,
    organization_name,
  });
}

export function apiCreateAdminUserInOrg(
  email: string,
  organization_ID: number
) {
  return client.post(`/admin_users/creation_admin_withorg`, {
    email: email,
    organization_id: organization_ID,
  });
}

export function apiCreateSuperUserInOrg(
  email: string,
  organization_ID: number
) {
  return client.post(`/admin_users/super_user`, {
    email: email,
    // organization_id: organization_ID,
  });
}

// #[post("/admin_users/super_user/users/<_user_id>/false")]

export function apiDeleteSuperUser(UserID: number) {
  return client.post(`/admin_users/super_user/users/${UserID}/false`, {});
}

export function apiDeleteOrganization(organization_ID: number) {
  return client.delete(`/organizations/${organization_ID}/delete`, {});
}

export function apiUpdateOrganizationCreditCount(
  Organization_ID: number,
  updatedCredit: number,
  organizationName: string,
  allowNegitiveCredits: boolean
) {
  return client.put(
    `/organizations/${Organization_ID}/metric_limit/update_organization`,
    {
      name: organizationName,
      metric_limit: updatedCredit,
      allow_negative_credits: allowNegitiveCredits,
    }
  );
}

export async function apiGetOrganizationList() {
  try {
    const response = await client.get(`/organizations/metric_limit`);
    return response.data;
  } catch (e) {
    console.error("Error retrieving Organizations list ", e);
  }
}
