import React, { useState } from "react";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { DocumentTemplate, Workspace } from "../../types/DocumentTemplate";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useActions, { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectResponseError, setErrorMsg } from "../auth/authSlice";
import { MESSAGES } from "../shared/Constants";
import { useSnackbar, VariantType } from "notistack";
import { FormControl, InputLabel } from "@mui/material";

export default function DocumentTemplateMoveForm(props: {
  parentCloseHandle: () => void;
  documentTemplate: DocumentTemplate;
  workspaces: Array<Workspace> | null;
  currentWorkspace: Workspace | null;
}) {
  const actions = useActions();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const selectErrorMsgState = useAppSelector(selectResponseError);
  const dispatch = useAppDispatch();

  const [workspaceId, setWorkspaceId] = useState(-1);

  function movetoWorkspace() {
    /*const newDocumentTemplate = Object.assign({}, props.documentTemplate, {
      name: name,
      purpose: purpose,
      category: category,
    });
    actions.updateDocumentTemplate(newDocumentTemplate);
    props.parentCloseHandle();*/

    const selWorkspaces = props.workspaces!.filter((_) => _.id == workspaceId);

    if (selWorkspaces.length > 0) {
      actions.moveDocumentTemplate({
        document_template: props.documentTemplate,
        target_workspace_id: selWorkspaces[0].id,
      });
      // props.parentCloseHandle();
    }
  }

  function handleSelectWorkspace(e: any) {
    setWorkspaceId(e.target.value);
  }

  const RESPONSE_TYPE = {
    SUCCESS: "success",
    ERROR: "error",
  };

  function showMessage(message: string, variant: VariantType = "success") {
    enqueueSnackbar(message, { variant: variant });
  }

  function successAlert(message: string) {
    showMessage(message, "success");
    dispatch(
      setErrorMsg({
        status: "idle",
        errorMsg: undefined,
        errorType: undefined,
      })
    );
  }
  function errorAlert(message: string) {
    showMessage(message, "success");
  }
  function warnAlert(message: string) {
    showMessage(message, "error");
  }

  React.useEffect(() => {
    if (selectErrorMsgState.status === "success") {
      if (selectErrorMsgState.errorType === "moveDocumentTemplate") {
        successAlert(MESSAGES.DOCUMNET_SUCCESS_MOVED);
        dispatch(
          setErrorMsg({
            status: "idle",
            errorMsg: undefined,
            errorType: undefined,
          })
        );
        props.parentCloseHandle();
      }
    } else if (selectErrorMsgState.status === "failed") {
      if (selectErrorMsgState.errorType === "moveDocumentTemplate") {
        warnAlert(MESSAGES.DOCUMENT_UNABLE_MOVE);
        // props.parentCloseHandle();
        dispatch(
          setErrorMsg({
            status: "idle",
            errorMsg: undefined,
            errorType: undefined,
          })
        );
      }
    }
  }, [selectErrorMsgState]);

  return (
    <Dialog open={true} onClose={() => props.parentCloseHandle()}>
      <DialogTitle>
        <Typography variant="h6" sx={{ marginBottom: "5px" }}>
          Move Document Template: {props.documentTemplate.name}
        </Typography>

      
      </DialogTitle>

      <DialogContent sx={{zIndex:9999}}>
      {/* <Typography variant="body2">Select your target workspace</Typography> */}
   
        <FormControl fullWidth sx={{my:1}}>
      
        <InputLabel htmlFor="workspace-select-label" id="workspace-select-label">Select your target workspace</InputLabel>
          <Select
        
          labelId="workspace-select-label"
          id="workspace-select-label"
           label="Select your target workspace"
            value={workspaceId}
            onChange={handleSelectWorkspace}
            fullWidth={true}
          >
            {props
              .workspaces!.filter((__) => __.id != props.currentWorkspace!.id)
              .map((_) => (
                <MenuItem value={_.id}>{_.name}</MenuItem>
              ))}
          </Select>
          </FormControl>
   
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.parentCloseHandle()}>Cancel</Button>
        <Button
          onClick={movetoWorkspace}
          type="submit"
          variant="contained"
          color="primary"
          disabled={workspaceId == -1}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
