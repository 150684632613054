import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { fixComponentOrderFromUpdatedElement } from "../../app/sagas/utils";
import { RootState } from "../../app/store";
import {
  ContentType,
  DocumentElement,
  DocumentSection,
  DocumentSubsection,
  DocumentTemplate,
  Reference,
  Variable,
  Workspace
} from "../../types/DocumentTemplate";
import {
  SectionInfo,
  SubHeadingInfo,
  TextElementInfo
} from "./document/CustomEditorDecorators/levelsHelperFunctions";
import { isEditorDisabled } from "./document/HelperEditorFunctions";

export interface DocumentState {
  documentTemplate: DocumentTemplate | null;
  approvalMode: boolean;
  isEditing: boolean;
  headingLevel: number;
  selectedSection: DocumentSection | null;
  selectedSubsection: DocumentSubsection | null;
  selectedElement: DocumentElement | null;
  tableRow: number | null;
  tableColumn: number | null;
  documentPreview: any;
  status: "idle" | "loading" | "failed";
  error: string | undefined;
  sectionNumbers: SectionInfo[];
  headingNumbers: SubHeadingInfo[];
  textNumbers: TextElementInfo[];
  createElementBelow?: boolean;
  approved_changed_sections: Array<number>;
  approved_deleted_sections: Array<number>;
  approved_deleted_subsections: Array<number>;
  declined_changed_sections: Array<number>;
  declined_deleted_sections: Array<number>;
  declined_deleted_subsections: Array<number>;

  skipped_changed_sections: Array<number>;
  skipped_deleted_sections: Array<number>;
  skipped_deleted_subsections: Array<number>;

  approved_changed_elements: Array<number>;
  approved_deleted_elements: Array<number>;
  declined_changed_elements: Array<number>;
  declined_deleted_elements: Array<number>;
  skipped_changed_elements: Array<number>;
  skipped_deleted_elements: Array<number>;
  impact: Array<Array<Workspace | DocumentTemplate | DocumentSection>>;
  ImpactState: any[];
}

const initialState: DocumentState = {
  documentTemplate: null,
  approvalMode: false,
  isEditing: false,
  headingLevel: 2,
  tableRow: null,
  tableColumn: null,
  selectedSection: null,
  selectedSubsection: null,
  selectedElement: null,
  documentPreview: null,
  status: "idle",
  error: undefined,
  createElementBelow: false,
  approved_changed_sections: [],
  approved_deleted_sections: [],
  approved_deleted_subsections: [],
  sectionNumbers: [],
  headingNumbers: [],
  textNumbers: [],

  declined_changed_sections: [],
  declined_deleted_sections: [],
  declined_deleted_subsections: [],

  skipped_changed_sections: [],
  skipped_deleted_sections: [],
  skipped_deleted_subsections: [],

  approved_changed_elements: [],
  approved_deleted_elements: [],
  declined_changed_elements: [],
  declined_deleted_elements: [],
  skipped_changed_elements: [],
  skipped_deleted_elements: [],
  impact: [],
  ImpactState: []
};

export const editorSlice = createSlice({
  name: "editor",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    clearDocumentTemplate: (state) => {
      state.documentTemplate = null;
      state.error = undefined;
      state.status = "idle";
    },
    setStatus: (
      state,
      action: PayloadAction<{
        status: "idle" | "loading" | "failed";
        error: string | undefined;
      }>
    ) => {
      state.status = action.payload.status;
      state.error = action.payload.error;
    },
    setError: (state, action: PayloadAction<string | undefined>) => {
      state.error = action.payload;
    },
    setCreateElementBelow: (state, action: PayloadAction<boolean>) => {
      state.createElementBelow = action.payload;
    },
    setDocumentTemplate: (state, action: PayloadAction<DocumentTemplate>) => {
      state.documentTemplate = action.payload;
      // state.approvalMode = false;
    },

    setNeedsApproval: (state, action: PayloadAction<boolean>) => {
      state.documentTemplate!.needs_approval = action.payload;
    },

    setNewContentAdded: (state, action: PayloadAction<boolean>) => {
      state.documentTemplate!.new_content_added = action.payload;
    },

    setDocumentTemplateImportText: (
      state,
      action: PayloadAction<Array<{ part_type: string; part_text: string }>>
    ) => {
      state.documentTemplate!.import_text = action.payload;
    },
    setVariables: (state, action: PayloadAction<Variable[]>) => {
      state.documentTemplate!.variables = action.payload;
    },
    setReferences: (state, action: PayloadAction<Reference[]>) => {
      state.documentTemplate!.references = action.payload;
    },
    addSectionLocal: (state, action: PayloadAction<DocumentSection>) => {
      const section = { ...action.payload };
      section.pending_addition =
        state.documentTemplate!.publication_no !== null &&
        state.documentTemplate!.publication_no !== undefined;
      state.documentTemplate!.sections!.push(section);
      const template = state.documentTemplate;
      if (template?.publication_no !== null || template.needs_approval) {
        template!.new_content_added = true;
      }
    },
    addSectionTopLocal: (state, action: PayloadAction<DocumentSection>) => {
      const section = { ...action.payload };
      section.pending_addition =
        state.documentTemplate!.publication_no !== null &&
        state.documentTemplate!.publication_no !== undefined;
      state.documentTemplate!.sections!.splice(0, 0, section);
      const template = state.documentTemplate;
      if (template?.publication_no !== null || template.needs_approval) {
        template!.new_content_added = true;
      }
    },

    // // new Added here to Enhance the CreateSubsectionWithElement API.

    // new Added here to Enhance the CreateSubsectionWithElement API.
    addSubSectionWithElementLocal: (
      state,
      action: PayloadAction<{
        section: DocumentSection;
        subSection: DocumentSubsection;
        documentElement: DocumentElement;
        subsectionAbove: DocumentSubsection | undefined;
        subSectionToUpdate: any[];
      }>
    ) => {
      // Update the document element.
      // Look it up in case the section/subsection is a copy

      const section = state.documentTemplate!.sections?.find(
        (sec) => sec.id === action.payload.section.id
      );
      if (section !== undefined && section !== null) {
        const sectionLatest = action.payload.section as DocumentSection;
        const subSectionLatest = action.payload
          .subSection as DocumentSubsection;
        const documentElementLatest = action.payload
          .documentElement as DocumentElement;
        const subSectionAboveLatest = action.payload
          .subsectionAbove as DocumentSubsection;
        subSectionLatest.elements?.push(documentElementLatest);
        const newSubsections = [...section.subsections!];
        let splicedIndex;
        if (section !== undefined && section !== null) {
          // console.log(" in top condition ");
          const subIndex = subSectionAboveLatest
            ? sectionLatest.subsections?.findIndex(
                (sub) =>
                  sub.component_order === subSectionAboveLatest.component_order
              )
            : 0;
          // console.log("subIndex", subIndex);
          // If the Element is Droped on Top so we donot have to update Below Subsection Order.

          if (
            subSectionAboveLatest === null ||
            subSectionAboveLatest === undefined
          ) {
            // console.log("In condition 1 ", subIndex);

            newSubsections.splice(0, 0, subSectionLatest);

            splicedIndex = 0;
          }
          // if the Element is Droped at the Last.There is no Below Element to update.
          else if (
            subIndex === action.payload.section.subsections!.length - 1 &&
            subSectionAboveLatest != undefined
          ) {
            newSubsections.push(subSectionLatest);
            splicedIndex = action.payload.section.subsections!.length;
            // console.log("In condition 2 ", subIndex, splicedIndex);
          }

          // if the Element is Dropped Between SubSection. We placd the Element under the Dropped  subsectionAbove, Update All Below SubSection Componenet Order.
          else {
            newSubsections.splice(subIndex! + 1, 0, subSectionLatest);
            splicedIndex = subIndex! + 1;
            // console.log("In condition 3 ", subIndex, splicedIndex);
          }
        }
        // console.log("In condition Fixcomponenet ", splicedIndex);
        if (splicedIndex != undefined) {
          const subSectionsToUpdate = fixComponentOrderFromUpdatedElement(
            newSubsections,
            splicedIndex,
            action.payload.subSection.component_order
          );
          for (const updatedSubsection of subSectionsToUpdate) {
            // Push the update.
            // Update locally
            const subSectionIndex = newSubsections.findIndex(
              (s) => s.id == (updatedSubsection as DocumentSubsection).id
            );

            if (subSectionIndex >= 0)
              newSubsections[subSectionIndex] =
                updatedSubsection as DocumentSubsection;
          }
        }

        const updatedSection = Object.assign({}, section, {
          subsections: newSubsections
        });
        const index = state.documentTemplate!.sections!.findIndex(
          (sec) => sec.id === updatedSection.id
        );
        if (index !== undefined && index >= 0)
          state.documentTemplate!.sections![index] = updatedSection!;
      }
    },

    updateSubsectionElementID: (
      state,
      action: PayloadAction<{
        section: DocumentSection;
        uniqueID: number;
        updatedSubsectionID: number;
        updatedElementID: number;
      }>
    ) => {
      const section = state.documentTemplate?.sections?.find(
        (s) => s.id === action.payload.section.id
      );
      const subsection = section?.subsections?.find(
        (subsec) => subsec.id === action.payload.uniqueID
      );
      subsection!.id = action.payload.updatedSubsectionID;
      // We only update the first element.
      subsection!.elements![0].id = action.payload.updatedElementID;

      const template = state.documentTemplate;
      if (
        (template?.publication_no !== null || template.needs_approval) &&
        subsection!.elements![0].content_type != ContentType.PageBreak &&
        subsection!.elements![0].content_type != ContentType.Spacer
      ) {
        template!.new_content_added = true;
      }
    },

    updateDocumentImangeContent: (state, action: PayloadAction<string>) => {
      const template = state.documentTemplate;
      if (template) {
        template.image_content_format = action.payload;
      }
    },

    updateDocumentElementHeadingLocal: (
      state,
      action: PayloadAction<{
        section: DocumentSection;
        documentElementID: number;
        headinglevel: number;
      }>
    ) => {
      // Update the document element.
      // Look it up in case the section/subsection is a copy
      const section = state.documentTemplate!.sections?.find(
        (sec) => sec.id === action.payload.section.id
      );

      section?.subsections?.map((subsection) => {
        subsection.elements?.map((element) => {
          if (element.id === action.payload.documentElementID) {
            element.heading_level = action.payload.headinglevel;
          }
        });
      });
    },

    updateDocumentsubSectionHeadingLocal: (
      state,
      action: PayloadAction<{
        section: DocumentSection;
        HeadingLevelstoUpdate: any;
      }>
    ) => {
      const { section } = action.payload;

      console.log("updateDocumentsubSectionHeadingLocal");

      // Update the document element.
      // Look it up in case the section/subsection is a copy
      const updatedSection = state.documentTemplate!.sections?.find(
        (sec) => sec.id === section.id
      );

      updatedSection?.subsections?.forEach((subsection) => {
        if (
          Object.prototype.hasOwnProperty.call(
            action.payload.HeadingLevelstoUpdate,
            subsection.id
          )
        ) {
          const level = action.payload.HeadingLevelstoUpdate[subsection.id];
          console.log(
            "Updating heading level for subsection:",
            subsection.id,
            "to level:",
            level
          );
          if (subsection.elements && subsection.elements.length > 0) {
            // Update the heading level of the first element in the subsection
            subsection.elements[0].heading_level = level;
            console.log(
              "Updated heading level for subsection:",
              subsection.id,
              "to level:",
              level
            );
          }
        }
      });

      // Ensure to return the updated state
    },

    updateSubsectionComponentOrder: (
      state,
      action: PayloadAction<{
        section: DocumentSection;
        subsectionID: number;
        componentOrder: number;
      }>
    ) => {
      const indexSection = state.documentTemplate!.sections!.findIndex(
        (sec) => sec.id === action.payload.section.id
      );

      const section = state.documentTemplate?.sections?.find(
        (s) => s.id === action.payload.section.id
      );

      const subSectionIndex = section?.subsections?.findIndex(
        (subsec) => subsec.id === action.payload.subsectionID
      );

      if (subSectionIndex !== -1 && subSectionIndex != undefined) {
        state.documentTemplate!.sections![indexSection]!.subsections![
          subSectionIndex
        ]!.component_order = action.payload.componentOrder;
      }
      const subSectionupdated =
        state.documentTemplate!.sections![indexSection]!.subsections;
      if (subSectionupdated != undefined) {
        subSectionupdated.sort((a, b) => a.component_order - b.component_order);
      }
    },

    updateSubsectionComponentOrderLocal: (
      state,
      action: PayloadAction<DocumentSection>
    ) => {
      // Add the subsection to the parent section.
      const replaceSection = state.documentTemplate?.sections?.find(
        (s) => s.id === action.payload.id
      );
      const index = state.documentTemplate!.sections!.findIndex(
        (sec) => sec.id === action.payload.id
      );
      if (index !== undefined && index >= 0) {
        state.documentTemplate!.sections![index] = action.payload;
      } else
        console.error(
          `Cannot find section with ID ${action.payload.id} to update. Please refresh the document.`
        );
    },

    updateSectionLocal: (state, action: PayloadAction<DocumentSection>) => {
      // Add the subsection to the parent section.
      const index = state.documentTemplate!.sections!.findIndex(
        (sec) => sec.id === action.payload.id
      );
      if (index !== undefined && index >= 0)
        state.documentTemplate!.sections![index] = action.payload;
      else
        console.error(
          `Cannot find section with ID ${action.payload.id} to update. Please refresh the document.`
        );
    },

    updateAllSections: (state, action: PayloadAction<DocumentSection[]>) => {
      // Add the subsection to the parent section.

      if (state.documentTemplate?.sections) {
        state.documentTemplate.sections = action.payload;
      } else
        console.error(
          `Cannot find section with ID ${action.payload} to update. Please refresh the document.`
        );
    },

    updateSectionComponenetOrder: (
      state,
      action: PayloadAction<DocumentSection>
    ) => {
      // Add the subsection to the parent section.
      const index = state.documentTemplate!.sections!.findIndex(
        (sec) => sec.id === action.payload.id
      );

      if (index !== undefined && index >= 0) {
        state.documentTemplate!.sections![index].component_order =
          action.payload.component_order;

        state.documentTemplate!.sections?.sort((sectionA, sectionB) => {
          return sectionA.component_order - sectionB.component_order;
        });
      } else
        console.error(
          `Cannot find section with ID ${action.payload.id} to update. Please refresh the document.`
        );
    },

    updateSectionLocalID: (
      state,
      action: PayloadAction<{
        DocumentSection: DocumentSection;
        uniqueID: number;
      }>
    ) => {
      // Add the subsection to the parent section.
      const index = state.documentTemplate!.sections!.findIndex(
        (sec) => sec.id === action.payload.uniqueID
      );
      if (index !== undefined && index >= 0)
        state.documentTemplate!.sections![index] =
          action.payload.DocumentSection;
      else
        console.error(
          `Cannot find section with ID ${action.payload.DocumentSection.id} to update. Please refresh the document.`
        );
    },
    deleteSectionLocal: (state, action: PayloadAction<DocumentSection>) => {
      console.log("In local delete!");
      const index = state.documentTemplate?.sections?.findIndex(
        (s) => s.id === action.payload.id
      );

      if (index !== undefined && index >= 0)
        state.documentTemplate?.sections?.splice(index, 1);
      else
        console.error(
          `Cannot find section with ID ${action.payload.id} to update. Please refresh the document.`
        );
    },
    undoDeletedSectionLocal: (
      state,
      action: PayloadAction<DocumentSection>
    ) => {
      const updatedSection = { ...action.payload, pending_deleted_at: null };
      const index = state.documentTemplate!.sections!.findIndex(
        (sec) => sec.id === action.payload.id
      );
      if (index !== undefined && index >= 0)
        state.documentTemplate!.sections![index] = updatedSection;
      else
        console.error(
          `Cannot find section with ID ${action.payload.id} to update. Please refresh the document.`
        );
    },
    markDeleteSectionLocal: (state, action: PayloadAction<DocumentSection>) => {
      console.log("In local delete!");
      const section = state.documentTemplate?.sections?.find(
        (s) => s.id === action.payload.id
      );
      //console.log("Deleting section: " + index);
      if (section !== undefined && section != null) {
        // state.documentTemplate?.sections?.splice(index, 1);
        section.pending_deleted_at = "yes";
      } else
        console.error(
          `Cannot find section with ID ${action.payload.id} to update. Please refresh the document.`
        );
    },

    markDeleteSubSectionLocal: (
      state,
      action: PayloadAction<DocumentSubsection>
    ) => {
      let subsection: DocumentSubsection | null = null;

      const section = state.documentTemplate?.sections?.forEach((_, i) => {
        const result = _.subsections?.find((s) => s.id === action.payload.id);

        if (result !== null && result !== undefined) {
          subsection = result;
        }
      });

      //console.log("Deleting section: " + index);
      if (subsection !== undefined && subsection != null) {
        // state.documentTemplate?.sections?.splice(index, 1);
        (subsection as any).pending_deleted_at = "yes";
      } else
        console.error(
          `Cannot find section with ID ${action.payload.id} to update. Please refresh the document.`
        );
    },
    updateSubsectionsLocal: (
      state,
      action: PayloadAction<{
        section: DocumentSection;
        newSubsections: DocumentSubsection[];
      }>
    ) => {
      const section = state.documentTemplate?.sections?.find(
        (s) => s.id === action.payload.section.id
      );
      if (section !== undefined && section !== null) {
        section.subsections = action.payload.newSubsections;
      } else
        console.error(
          `Cannot find section with ID ${action.payload.section.id} to update. Please refresh the document.`
        );
    },
    deleteSubsectionLocal: (
      state,
      action: PayloadAction<{
        section: DocumentSection;
        subSection: DocumentSubsection;
      }>
    ) => {
      // Delete the subsection defensively.
      const section = state.documentTemplate!.sections!.find(
        (sec) => sec.id === action.payload.section.id
      );
      if (section !== undefined && section !== null) {
        const index = section!.subsections!.findIndex(
          (s) => s.id === action.payload.subSection.id
        );
        section.subsections!.splice(index!, 1);
      } else {
        console.error(
          `Cannot find section with ID ${action.payload.section.id} to update. Please refresh the document.`
        );
      }
    },
    updateDocumentElementLocal: (
      state,
      action: PayloadAction<{
        section: DocumentSection;
        subSection: DocumentSubsection;
        updatedDocumentElement: DocumentElement;
      }>
    ) => {
      // Update the document element.
      // Look it up in case the section/subsection is a copy
      const section = state.documentTemplate!.sections?.find(
        (sec) => sec.id === action.payload.section.id
      );
      const subsection = section?.subsections?.find(
        (subsec) => subsec.id === action.payload.subSection.id
      );
      // We only update the first element.
      subsection!.elements![0] = action.payload.updatedDocumentElement;
    },

    setSelectedComponent: (
      state,
      action: PayloadAction<{
        selectedSection: DocumentSection | null;
        selectedSubsection: DocumentSubsection | null;
        selectedComponent: DocumentElement | null;
      }>
    ) => {
      state.selectedSection = action.payload.selectedSection;
      state.selectedSubsection = action.payload.selectedSubsection;
      state.selectedElement = action.payload.selectedComponent;

      // Establish the heading level on editor menu
      if (state.selectedElement === null) {
        state.headingLevel =
          state.selectedSection === null
            ? 1
            : state.selectedSection.heading_level;
      } else {
        state.headingLevel = state.selectedElement.heading_level;
      }
    },

    setSelectedTableComponent: (
      state,
      action: PayloadAction<{
        selectedSection: DocumentSection | null;
        selectedSubsection: DocumentSubsection | null;
        selectedComponent: DocumentElement | null;
        row: number | null;
        column: number | null;
      }>
    ) => {
      state.selectedSection = action.payload.selectedSection;
      state.selectedSubsection = action.payload.selectedSubsection;
      state.selectedElement = action.payload.selectedComponent;
      state.tableRow = action.payload.row;
      state.tableColumn = action.payload.column;

      // Establish the heading level on editor menu
      if (state.selectedElement === null) {
        state.headingLevel =
          state.selectedSection === null
            ? 1
            : state.selectedSection.heading_level;
      } else {
        state.headingLevel = state.selectedElement.heading_level;
      }
    },

    setLocalHeadingLevel: (state, action: PayloadAction<number>) => {
      state.headingLevel = action.payload;
    },
    clearSelectedComponent: (state) => {
      state.selectedSection = null;
      state.selectedSubsection = null;
      state.selectedElement = null;
      state.tableRow = null;
      state.tableColumn = null;
    },

    clearSelectedTableComponent: (state) => {
      state.selectedSection = null;
      state.selectedSubsection = null;
      state.selectedElement = null;
      state.tableRow = null;
      state.tableColumn = null;
    },
    clearPreview: (state) => {
      state.documentPreview = null;
    },
    enableApprovalMode: (state) => {
      state.approvalMode = true;
    },
    setEnableApprovalMode: (state) => {
      state.approvalMode = true;
    },

    disableApprovalMode: (state) => {
      state.approvalMode = false;
    },
    approveChangedSection: (
      state,
      action: PayloadAction<{
        sectionId: number;
      }>
    ) => {
      state.approved_changed_sections.push(action.payload.sectionId);
      state.declined_changed_sections = state.declined_changed_sections.filter(
        (_) => _ != action.payload.sectionId
      );
      state.skipped_changed_sections = state.skipped_changed_sections.filter(
        (_) => _ != action.payload.sectionId
      );
    },
    approveDeletedSection: (
      state,
      action: PayloadAction<{
        sectionId: number;
      }>
    ) => {
      state.approved_deleted_sections.push(action.payload.sectionId);
      state.declined_deleted_sections = state.declined_deleted_sections.filter(
        (_) => _ != action.payload.sectionId
      );
      state.skipped_deleted_sections = state.skipped_deleted_sections.filter(
        (_) => _ != action.payload.sectionId
      );
    },
    approveDeletedSubsection: (
      state,
      action: PayloadAction<{
        subsectionId: number;
      }>
    ) => {
      state.approved_deleted_subsections.push(action.payload.subsectionId);
      state.declined_deleted_subsections =
        state.declined_deleted_subsections.filter(
          (_) => _ != action.payload.subsectionId
        );
      state.skipped_deleted_subsections =
        state.skipped_deleted_subsections.filter(
          (_) => _ != action.payload.subsectionId
        );
    },
    declineChangedSection: (
      state,
      action: PayloadAction<{
        sectionId: number;
      }>
    ) => {
      state.declined_changed_sections.push(action.payload.sectionId);
      state.approved_changed_sections = state.approved_changed_sections.filter(
        (_) => _ != action.payload.sectionId
      );
      state.skipped_changed_sections = state.skipped_changed_sections.filter(
        (_) => _ != action.payload.sectionId
      );
    },
    declineDeletedSection: (
      state,
      action: PayloadAction<{
        sectionId: number;
      }>
    ) => {
      state.declined_deleted_sections.push(action.payload.sectionId);
      state.approved_deleted_sections = state.approved_deleted_sections.filter(
        (_) => _ != action.payload.sectionId
      );
      state.skipped_deleted_sections = state.skipped_deleted_sections.filter(
        (_) => _ != action.payload.sectionId
      );
    },
    declineDeletedSubsection: (
      state,
      action: PayloadAction<{
        subsectionId: number;
      }>
    ) => {
      state.declined_deleted_subsections.push(action.payload.subsectionId);
      state.approved_deleted_subsections =
        state.approved_deleted_subsections.filter(
          (_) => _ != action.payload.subsectionId
        );
      state.skipped_deleted_subsections =
        state.skipped_deleted_subsections.filter(
          (_) => _ != action.payload.subsectionId
        );
    },
    skipChangedSection: (
      state,
      action: PayloadAction<{
        sectionId: number;
      }>
    ) => {
      state.skipped_changed_sections.push(action.payload.sectionId);
      state.declined_changed_sections = state.declined_changed_sections.filter(
        (_) => _ != action.payload.sectionId
      );
      state.approved_changed_sections = state.approved_changed_sections.filter(
        (_) => _ != action.payload.sectionId
      );
    },
    skipDeletedSection: (
      state,
      action: PayloadAction<{
        sectionId: number;
      }>
    ) => {
      state.skipped_deleted_sections.push(action.payload.sectionId);
      state.declined_deleted_sections = state.declined_deleted_sections.filter(
        (_) => _ != action.payload.sectionId
      );
      state.approved_deleted_sections = state.approved_deleted_sections.filter(
        (_) => _ != action.payload.sectionId
      );
    },
    skipDeletedSubsection: (
      state,
      action: PayloadAction<{
        subsectionId: number;
      }>
    ) => {
      state.skipped_deleted_subsections.push(action.payload.subsectionId);
      state.declined_deleted_subsections =
        state.declined_deleted_subsections.filter(
          (_) => _ != action.payload.subsectionId
        );
      state.approved_deleted_subsections =
        state.approved_deleted_subsections.filter(
          (_) => _ != action.payload.subsectionId
        );
    },
    approveChangedElement: (
      state,
      action: PayloadAction<{
        elementId: number;
      }>
    ) => {
      state.approved_changed_elements.push(action.payload.elementId);
      state.declined_changed_elements = state.declined_changed_elements.filter(
        (_) => _ != action.payload.elementId
      );
      state.skipped_changed_elements = state.skipped_changed_elements.filter(
        (_) => _ != action.payload.elementId
      );
    },
    approveDeletedElement: (
      state,
      action: PayloadAction<{
        elementId: number;
      }>
    ) => {
      state.approved_deleted_elements.push(action.payload.elementId);
      state.declined_deleted_elements = state.declined_deleted_elements.filter(
        (_) => _ != action.payload.elementId
      );
      state.skipped_deleted_elements = state.skipped_deleted_elements.filter(
        (_) => _ != action.payload.elementId
      );
    },
    declineChangedElement: (
      state,
      action: PayloadAction<{
        elementId: number;
      }>
    ) => {
      state.declined_changed_elements.push(action.payload.elementId);
      state.approved_changed_elements = state.approved_changed_elements.filter(
        (_) => _ != action.payload.elementId
      );
      state.skipped_changed_elements = state.skipped_changed_elements.filter(
        (_) => _ != action.payload.elementId
      );
    },
    declineDeletedElement: (
      state,
      action: PayloadAction<{
        elementId: number;
      }>
    ) => {
      state.declined_deleted_elements.push(action.payload.elementId);
      state.approved_deleted_elements = state.approved_deleted_elements.filter(
        (_) => _ != action.payload.elementId
      );
      state.skipped_deleted_elements = state.skipped_deleted_elements.filter(
        (_) => _ != action.payload.elementId
      );
    },
    skipChangedElement: (
      state,
      action: PayloadAction<{
        elementId: number;
      }>
    ) => {
      state.skipped_changed_elements.push(action.payload.elementId);
      state.declined_changed_elements = state.declined_changed_elements.filter(
        (_) => _ != action.payload.elementId
      );
      state.approved_changed_elements = state.approved_changed_elements.filter(
        (_) => _ != action.payload.elementId
      );
    },
    skipDeletedElement: (
      state,
      action: PayloadAction<{
        elementId: number;
      }>
    ) => {
      state.skipped_deleted_elements.push(action.payload.elementId);
      state.declined_deleted_elements = state.declined_deleted_elements.filter(
        (_) => _ != action.payload.elementId
      );
      state.approved_deleted_elements = state.approved_deleted_elements.filter(
        (_) => _ != action.payload.elementId
      );
    },
    clearApprovals: (state) => {
      state.approved_changed_sections = [];
      state.approved_deleted_sections = [];
      state.declined_changed_sections = [];
      state.declined_deleted_sections = [];
      state.skipped_changed_sections = [];
      state.skipped_deleted_sections = [];

      state.approved_changed_elements = [];
      state.approved_deleted_elements = [];
      state.declined_changed_elements = [];
      state.declined_deleted_elements = [];
      state.skipped_changed_elements = [];
      state.skipped_deleted_elements = [];
    },
    setImpact: (
      state,
      action: PayloadAction<{
        data: Array<Array<Workspace | DocumentTemplate>>;
      }>
    ) => {
      state.impact = action.payload.data;

      //console.log("A", state.impact);
    },
    setImpactState: (state, action: PayloadAction<any>) => {
      state.ImpactState = action.payload;
    },

    clearImpactState: (state) => {
      state.ImpactState = [];
    },
    setImpactSubsectionAll: (
      state,
      action: PayloadAction<{
        sectionID: number;
        subsectionID: number;
        status: string;
        isDeleted: boolean;
      }>
    ) => {
      const { sectionID, subsectionID, status, isDeleted } = action.payload;
      const impact = state.ImpactState; // Shallow copy of ImpactState
      // Check if the subsectionID exists in the current state
      if (Object.prototype.hasOwnProperty.call(impact, subsectionID)) {
        // Find the array associated with subsectionID
        const sectionArray = impact[subsectionID];

        // Loop through the sectionArray and update the status for each item
        for (let i = 0; i < sectionArray.length; i++) {
          sectionArray[i].sectionID = sectionID;
          sectionArray[i].status = status;
          sectionArray[i].isDeleted = isDeleted;
        }
        // Update the state with the modified impact object
      }
    },

    setImpactSubsection: (
      state,
      action: PayloadAction<{
        sectionID: number;
        subsectionID: number;
        documentID: number;
        status: string;
        isDeleted: boolean;
      }>
    ) => {
      const { sectionID, subsectionID, documentID, status, isDeleted } =
        action.payload;
      const impact = state.ImpactState; // Shallow copy of ImpactState
      // Check if the subsectionID exists in the current state
      if (Object.prototype.hasOwnProperty.call(impact, subsectionID)) {
        // Find the array associated with subsectionID
        const sectionArray = impact[subsectionID];
        // Find the index of the item with the matching documentID
        const index = sectionArray.findIndex(
          (item: any) => item.documentID === documentID
        );

        if (index !== -1) {
          // If the item exists, update its status
          const updatedItem = {
            ...sectionArray[index],
            status,
            sectionID,
            isDeleted
          }; // Shallow copy of the item
          sectionArray[index] = updatedItem;

          // Create a deep copy of the state and return it to trigger a re-render
        }
      }

      // If the subsectionID or documentID doesn't exist, return the current state as is
    },

    setSectionNumbers: (
      state,
      action: PayloadAction<{
        sectionsNumber: SectionInfo[];
      }>
    ) => {
      state.sectionNumbers = action.payload.sectionsNumber;
    },

    setHeadingNumbers: (
      state,
      action: PayloadAction<{
        headingNumber: SubHeadingInfo[];
      }>
    ) => {
      state.headingNumbers = action.payload.headingNumber;
    },

    setTextNumbers: (
      state,
      action: PayloadAction<{
        textNumber: TextElementInfo[];
      }>
    ) => {
      state.textNumbers = action.payload.textNumber;
    },

    setImpactSection: (
      state,
      action: PayloadAction<{
        document_section_id: number;
        document_template_id: number;
        impact: boolean;
      }>
    ) => {
      state.impact = state.impact.map((_) => {
        if (
          _[0].id == action.payload.document_template_id &&
          _[2].id == action.payload.document_section_id
        ) {
          return [
            _[0],
            _[1],
            { ..._[2], impact_section_include: action.payload.impact }
          ];
        } else {
          return _;
        }
      });

      //console.log("A", state.impact);
    }
  }
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  // extraReducers: (builder) => {},
});

export const {
  approveChangedSection,
  approveDeletedSection,
  approveDeletedSubsection,
  declineChangedSection,
  declineDeletedSection,
  declineDeletedSubsection,
  skipChangedSection,
  skipDeletedSection,
  skipDeletedSubsection,
  approveChangedElement,
  approveDeletedElement,
  declineChangedElement,
  declineDeletedElement,
  skipChangedElement,
  skipDeletedElement,
  setStatus,
  setError,
  setSectionNumbers,
  setHeadingNumbers,
  setSelectedTableComponent,
  clearSelectedTableComponent,
  setCreateElementBelow,
  clearDocumentTemplate,
  setDocumentTemplate,
  setDocumentTemplateImportText,
  setSelectedComponent,
  clearSelectedComponent,
  addSectionLocal,
  addSectionTopLocal,
  addSubSectionWithElementLocal,
  updateSectionLocal,
  updateSectionLocalID,
  updateSubsectionElementID,
  updateSectionComponenetOrder,
  updateAllSections,
  updateSubsectionComponentOrderLocal,
  updateSubsectionComponentOrder,
  deleteSectionLocal,
  markDeleteSectionLocal,
  markDeleteSubSectionLocal,
  updateSubsectionsLocal,
  deleteSubsectionLocal,
  updateDocumentElementLocal,
  updateDocumentElementHeadingLocal,
  setReferences,
  clearImpactState,
  setVariables,
  clearPreview,
  enableApprovalMode,
  disableApprovalMode,
  setNewContentAdded,
  clearApprovals,
  setEnableApprovalMode,
  updateDocumentsubSectionHeadingLocal,
  setImpact,
  setImpactSection,
  setImpactState,
  setImpactSubsection,
  setImpactSubsectionAll,
  setLocalHeadingLevel,
  setNeedsApproval,
  updateDocumentImangeContent,
  setTextNumbers
} = editorSlice.actions;

//Selectors

export const selectVariables = (state: RootState) =>
  state.document!.documentTemplate?.variables;
export const selectSectionsNumber = (state: RootState) =>
  state.document!.sectionNumbers;
export const selectsHeadingNumber = (state: RootState) =>
  state.document!.headingNumbers;
export const selectsTextNumber = (state: RootState) =>
  state.document!.textNumbers;
export const selectApprovedChangedSections = (state: RootState) =>
  state.document!.approved_changed_sections;
export const selectApprovedDeletedSections = (state: RootState) =>
  state.document!.approved_deleted_sections;
export const selectApprovedDeletedSubsections = (state: RootState) =>
  state.document!.approved_deleted_subsections;
export const selectDeclinedChangedSections = (state: RootState) =>
  state.document!.declined_changed_sections;
export const selectDeclinedDeletedSections = (state: RootState) =>
  state.document!.declined_deleted_sections;
export const selectDeclinedDeletedSubsections = (state: RootState) =>
  state.document!.declined_deleted_subsections;
export const selectSkippedChangedSections = (state: RootState) =>
  state.document!.skipped_changed_sections;
export const selectSkippedDeletedSections = (state: RootState) =>
  state.document!.skipped_deleted_sections;
export const selectSkippedDeletedSubsections = (state: RootState) =>
  state.document!.skipped_deleted_subsections;
export const selectApprovedChangedElements = (state: RootState) =>
  state.document!.approved_changed_elements;
export const selectApprovedDeletedElements = (state: RootState) =>
  state.document!.approved_deleted_elements;
export const selectDeclinedChangedElements = (state: RootState) =>
  state.document!.declined_changed_elements;
export const selectDeclinedDeletedElements = (state: RootState) =>
  state.document!.declined_deleted_elements;
export const selectSkippedChangedElements = (state: RootState) =>
  state.document!.skipped_changed_elements;
export const selectSkippedDeletedElements = (state: RootState) =>
  state.document!.skipped_deleted_elements;
export const selecterrorState = (state: RootState) => state.document.error;
export const selectElementBelowState = (state: RootState) =>
  state.document.createElementBelow;
export const selectStatusState = (state: RootState) => state.document.status;
export const selectDocumentState = (state: RootState) => state.document!;
export const selectEditingDocumentTemplate = (state: RootState) =>
  state.document.documentTemplate!;
export const selectApprovalMode = (state: RootState) =>
  state.document.approvalMode;
export const selectSections = (state: RootState) =>
  state.document.documentTemplate!.sections!;
export const selectIsEditing = (state: RootState) => state.document.isEditing;
export const selectApprovalState = (state: RootState) =>
  state.document!.ImpactState;

export const selectCurrentTemplateState = (state: RootState) => {
  if (state.document.documentTemplate) {
    return {
      draft: state.document.documentTemplate.new_published_date == null,
      approval:
        state.document.documentTemplate.needs_approval ||
        state.document.documentTemplate.send_for_approvals,
      editing: state.document.documentTemplate.is_editing
    };
  }

  return {
    draft: false,
    approval: false,
    editing: false
  };
};

export const selectSelectedCustomSelector = (state: RootState, props: any) => {
  if (
    props.isHeading &&
    state.document.selectedSection?.id === props.parentSection.id &&
    state.document.selectedSubsection === null
  ) {
    return {
      section: state.document.selectedSection,
      subSection: state.document.selectedSubsection,
      documentElement: state.document.selectedElement
    };
  } else if (
    !props.isHeading &&
    props.parentSubsection?.id === state.document.selectedSubsection?.id
  ) {
    return {
      section: state.document.selectedSection,
      subSection: state.document.selectedSubsection,
      documentElement: state.document.selectedElement
    };
  } else {
    return {
      section: state.document.selectedSection,
      subSection: state.document.selectedSubsection,
      documentElement: state.document.selectedElement
    };
  }
};

export const selectSelected = (state: RootState) => {
  return {
    section: state.document.selectedSection,
    subSection: state.document.selectedSubsection,
    documentElement: state.document.selectedElement
  };
};

export const selectSelectedElementID = (state: RootState) => {
  return {
    documentElement: state.document.selectedElement?.id
  };
};

export const selectSelectedSection = (state: RootState) => {
  return {
    section: state.document.selectedSection
  };
};

export const selectSelectedTable = (state: RootState) => {
  return {
    section: state.document.selectedSection,
    subSection: state.document.selectedSubsection,
    documentElement: state.document.selectedElement,
    row: state.document.tableRow,
    column: state.document.tableColumn
  };
};

export const selectIsImpactPending = (state: RootState) => {
  if (state.document.ImpactState === null)
    return {
      isPending: false,
      subsectionID: null
    };

  const impact = state.document.ImpactState;
  for (const section of state.document.documentTemplate?.sections || []) {
    for (const subsection of section.subsections || []) {
      if (impact[subsection.id]) {
        if (
          impact[subsection.id].some((item: any) => item.status === "pending")
        ) {
          return {
            isPending: true,
            subsectionID: subsection.id
          };
        }
      }
    }
  }

  // for(const tempSubsectionID in impact){
  //   if(Object.prototype.hasOwnProperty.call(impact ,tempSubsectionID)){
  //     const object=  impact[tempSubsectionID]

  //     if(object.some((item:any)=>item.status==="pending")){
  //       return {
  //         isPending: true,
  //         subsectionID:tempSubsectionID,
  //       }
  //     }
  //   }
  // }

  return {
    isPending: false,
    subsectionID: null
  };
};

// export const selectIsImpactPending = (state: RootState) => {
//   if(state.document.ImpactState===null) return  {
//     isPending: false,
//     subsectionID:null,
//   };

//   const impact= state.document.ImpactState

//   for(const tempSubsectionID in impact){
//     if(Object.prototype.hasOwnProperty.call(impact ,tempSubsectionID)){
//       const object=  impact[tempSubsectionID]

//       if(object.some((item:any)=>item.status==="pending")){
//         return {
//           isPending: true,
//           subsectionID:tempSubsectionID,
//         }
//       }
//     }
//   }

//    return {
//     isPending: false,
//     subsectionID:null,
//   }
// };

export const selectDocumentPreview = (state: RootState) =>
  state.document.documentPreview;
export const getImpact = (state: RootState) => state.document!.impact;

export const selectHeadingLevel = (state: RootState) =>
  state.document!.headingLevel;
export default editorSlice.reducer;
