import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import InfoIcon from "@mui/icons-material/Info";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SearchIcon from "@mui/icons-material/Search";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ShareIcon from "@mui/icons-material/Share";
import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Alert from "@mui/material/Alert";
import useActions, { useAppDispatch, useAppSelector } from "../../app/hooks";
import IconButton from "@mui/material/IconButton";
import ImageListItem from "@mui/material/ImageListItem";
import { DocumentRecord, DocumentTemplate } from "../../types/DocumentTemplate";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";
import PreviewSharpIcon from "@mui/icons-material/VisibilitySharp";
import { PDFDocument } from "pdf-lib";
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  apiGetDocumentRecord,
  apiGetVariablesWithRecord,
} from "../../services/documentTemplatesAPI";

import { apiRegenerateRecord } from "../../services/documentTemplatesAPI";
import DocumentRecordInfoForm from "./DocumentRecordInfoForm";
import { getLocalUserToken } from "../../services/userAPI";
import { host } from "../../services/client";
import { useSnackbar } from "notistack";
import { selectOrganizationDetails } from "../auth/authSlice";
/**
 * A single library item showing a DocumentTemplate.
 *
 * @export
 * @return {*}
 */
export default function DocumentRecordLibraryItem(props: {
  documentRecord: DocumentRecord;
  workspaceRole: string;
}) {
  const actions = useActions();
  const record = props.documentRecord;
  const [variables, setVariable] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const isDeleted = record.deleted_at != null;
  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const currentOrgDetails = useAppSelector(selectOrganizationDetails);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isHovered, setIsHovered] = React.useState(false);

  const isShowTable = variables != undefined && variables.length != 0;
  // const isShowTable= true;

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const boxStyles = {
    transition: "background-color 0.3s, transform 0.3s",
    backgroundColor: isHovered ? "#f0f0f0" : "transparent",
    transform: isHovered ? "scale(1.02)" : "scale(1)",
    cursor: isDeleted ? "not-allowed" : "pointer",
    // Add other styles here
  };

  const [dialogOpen, setDialogOpen] = useState(false);
  function handleDialogOpen() {
    setDialogOpen(true);
  }
  function handleDialogClose() {
    setAnchorEl(null);
    setDialogOpen(false);
  }
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [infoOpen, setInfoOpen] = React.useState(false);
  const [generateOpen, setGenerateOpen] = React.useState(false);

  useEffect(() => {
    async function getVariable() {
      const response: any = await apiGetVariablesWithRecord(
        props.documentRecord.id
      );
      setVariable(response.data);
    }
    if (dialogOpen) {
      getVariable();
    }
  }, [dialogOpen]);

  const handleRegenerateRecord = () => {
    apiRegenerateRecord(props.documentRecord.id, props.documentRecord.name);
    setAnchorEl(null);
    setDialogOpen(false);
  };

  const handleFavoriteClicked = () => {
    setAnchorEl(null);
  };
  const handleInfoClicked = () => {
    setAnchorEl(null);
    setInfoOpen(true);
  };
  const handlePreviewClicked = () => {
    const url =
      "https://api.nomiadocs.com/document_records/" +
      record.hidden_link +
      "/get_generate/" +
      record.name;

    window.open(url, "_blank");
    // actions.previewDocumentRecord(record);
    setAnchorEl(null);
  };
  const handleDeleteClicked = () => {
    actions.deleteDocumentRecord(record);
    setAnchorEl(null);
  };

  const handleUnDeleteClicked = () => {
    actions.undeleteDocumentRecord(record);
    setAnchorEl(null);
  };

  let itemContent = (
    <CircularProgress size="150px" style={{ padding: "10px" }} />
  );

  if (
    !props.documentRecord.imagePreview ||
    props.documentRecord.imagePreview.data == null ||
    props.documentRecord.imagePreview.status === "failed"
  ) {
    // Show the file icon
    itemContent = (
      <InsertDriveFileIcon
        sx={{ color: isDeleted ? "grey" : "#008800", fontSize: 96 }}
      />
    );
  } else if (props.documentRecord.imagePreview?.data !== null) {
    itemContent = (
      <Paper elevation={2}>
        <img
          width="180"
          src={URL.createObjectURL(
            props.documentRecord.imagePreview?.data as Blob
          )}
          alt="library picture"
        />
      </Paper>
    );
  }

  // get_generate_auth

  function extractFilenameFromContentDisposition(contentDisposition: any) {
    if (!contentDisposition) return null;
    const match = contentDisposition.match(/filename="(.+)"/);
    return match ? match[1] : null;
  }

  const handleDownloadRecord = async () => {
    const accessToken = getLocalUserToken();
    if (!isDeleted) {
      const token = accessToken;
      const url =
        `${host}/document_records/` +
        record.hidden_link +
        "/get_generate/" +
        record.name;

      try {
        const response = await fetch(url, {
          method: "GET",
          headers: {
            Authorization: `${token}`, // Ensure 'Bearer' is used if needed
          },
        });

        if (response.ok) {
          const blob = await response.blob();
          // Create a download link
          const downloadLink = document.createElement("a");
          const url = URL.createObjectURL(blob);
          downloadLink.href = url;
          downloadLink.download = `${record.name}.pdf`; // Set the desired file name
          document.body.appendChild(downloadLink);
          downloadLink.click();

          // Cleanup
          document.body.removeChild(downloadLink);
          URL.revokeObjectURL(url);
        }
      } catch (error) {
        console.error("Error fetching document:", error);
      }
    }
  };
  const copyToClipboard = async () => {
    const link =
      `${host}/document_records/` +
      record.hidden_link +
      "/get_generate/" +
      record.name;

    // const link =  "https://api.nomiadocs.com/document_records/" +
    // record.id +
    // "/get_generate/" +
    // record.name
    if (navigator.clipboard && navigator.clipboard.writeText) {
      try {
        await navigator.clipboard.writeText(link);

        enqueueSnackbar("Share link successfully copied to clipboard", {
          variant: "success",
        });
        setAnchorEl(null);
      } catch (err) {
        enqueueSnackbar("Failed to copy link to clipboard", {
          variant: "error",
        });
      }
    }
  };

  const handleOpenRecord = async () => {
    const accessToken = getLocalUserToken();
    if (!isDeleted) {
      const token = accessToken;
      const url =
        `${host}/document_records/` +
        record.hidden_link +
        "/get_generate/" +
        record.name;

      try {
        const response = await fetch(url, {
          method: "GET",
          headers: {
            Authorization: `${token}`, // Ensure 'Bearer' is used if needed
          },
        });

        if (response.ok) {
          const blob = await response.blob();
          const pdfBytes = await blob.arrayBuffer();
          const pdfDoc = await PDFDocument.load(pdfBytes);

          // Update metadata
          pdfDoc.setTitle(record.name);
          pdfDoc.setAuthor(record?.user_email ?? "");
          pdfDoc.setSubject(record?.sub_category ?? "");

          // Rename the file
          const pdfBytesWithMetadata = await pdfDoc.save();
          const updatedBlob = new Blob([pdfBytesWithMetadata], {
            type: "application/pdf",
          });

          const pdfBlobUrl = URL.createObjectURL(updatedBlob);

          // Create an HTML content to open the PDF and change the tab title
          const htmlContent = `
<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <title>${record.name}</title>
</head>
<body>
  <script>
    setTimeout(() => {
       document.title = "${record.name}";
      window.location.href = "${pdfBlobUrl}";
    }, 100);
  </script>
  <a id="download-link" href="${pdfBlobUrl}" download="${record.name}.pdf" style="display: none;">Download</a>
</body>
</html>
`;
          // Create a Blob URL for the HTML content
          const htmlBlob = new Blob([htmlContent], { type: "text/html" });
          const htmlBlobUrl = URL.createObjectURL(htmlBlob);
          // Open the HTML Blob URL in a new tab
          window.open(htmlBlobUrl, "_blank");
          // Revoke the object URLs after some time to free up resources
          setTimeout(() => {
            URL.revokeObjectURL(pdfBlobUrl);
            URL.revokeObjectURL(htmlBlobUrl);
          }, 100000);
        } else {
          enqueueSnackbar("Unable to preview this PDF.", { variant: "error" });
          console.error("Failed to fetch document:", response.statusText);
        }
      } catch (error) {
        enqueueSnackbar("Unable to open this PDF.", { variant: "error" });
        console.error("Error fetching document:", error);
      }
    }
  };

  // working corrrectly
  //   const handleOpenRecord = async () => {
  //     const accessToken = getLocalUserToken();
  //     if (!isDeleted) {
  //         const token = accessToken;
  //         const url =
  //             `${host}/document_records/`+
  //             record.id +
  //             "/get_generate_auth/" +
  //             record.name;

  //         try {
  //             const response = await fetch(url, {
  //                 method: "GET",
  //                 headers: {
  //                     "Authorization": `${token}`
  //                 }
  //             });
  //             if (response.ok) {
  //               const contentDisposition = response.headers.get('content-disposition');
  //               let suggestedFileName = "default.pdf"; // Default file name if not found in header
  //               if (contentDisposition) {
  //                 const match = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
  //                 if (match && match[1]) {
  //                   suggestedFileName = match[1].replace(/['"]/g, '');
  //                 }
  //               }
  //               console.log("🚀 ~ handleOpenRecord ~ suggestedFileName:", suggestedFileName)

  //                 const blob = await response.blob();
  //                 const pdfBytes = await blob.arrayBuffer();
  //                 console.log("🚀 ~ handleOpenRecord ~ pdfBytes:", pdfBytes)
  //                 const pdfDoc = await PDFDocument.load(pdfBytes);

  //                 // Update metadata
  //                 // pdfDoc.setTitle(record.name);
  //                 pdfDoc.setAuthor('Your Author');
  //                 pdfDoc.setSubject('Your Subject');

  //                 // Rename the file
  //                 const pdfBytesWithMetadata = await pdfDoc.save();
  //                 const updatedBlob = new Blob([pdfBytesWithMetadata], { type: 'application/pdf'})
  //                 const file = new File([updatedBlob], `haris.pdf`, { type: 'application/pdf' });
  //                console.log(" file.name" ,  file.name)

  //         // Create a URL for the File object
  //                   const blobUrl = URL.createObjectURL(file);

  //                  const blobUrl = URL.createObjectURL(updatedBlob);

  //                 window.open(blobUrl, "_blank");

  //                 setTimeout(() => URL.revokeObjectURL(blobUrl), 10000);
  //             } else {
  //                 console.error("Failed to fetch document:", response.statusText);
  //             }
  //         } catch (error) {
  //             console.error("Error fetching document:", error);
  //         }
  //     }
  // };

  // const handleOpenRecord = async () => {
  //   const accessToken = getLocalUserToken();
  //   if (!isDeleted) {
  //     const token = accessToken;
  //     const url =
  //       `${host}/document_records/` +
  //       record.id +
  //       "/get_generate_auth/" +
  //       record.name;

  //     // Create an iframe
  //     const iframe = document.createElement('iframe');
  //     iframe.style.display = 'none';

  //     // Set the srcdoc of the iframe to include a form with the token
  //     iframe.srcdoc = `
  //       <html>
  //       <body>
  //         <form id="form" action="${url}" method="GET" target="_blank">
  //           <input type="hidden" name="Authorization" value="Bearer ${token}">
  //         </form>
  //         <script>
  //           document.getElementById('form').submit();
  //         </script>
  //       </body>
  //       </html>
  //     `;

  //     document.body.appendChild(iframe);

  //     // Clean up: Remove the iframe after it has done its job
  //     iframe.onload = () => {
  //       document.body.removeChild(iframe);
  //     };
  //   }
  // };

  // const handleOpenRecord = async () => {
  //   const accessToken = getLocalUserToken();
  //   if (!isDeleted) {
  //     const token = accessToken; // Replace this with the actual token
  //     const url =
  //     `${host}/document_records/`+
  //     record.id +
  //     "/get_generate/" +
  //     record.name;

  //     try {

  //       const response222 =  await apiGetDocumentRecord(props.documentRecord)
  //       console.log("🚀 ~ handleOpenRecord ~ response222:", response222)

  //       const response =
  //        await fetch(url, {
  //         method: "GET",
  //         headers: {
  //           "Authorization": `${token}`
  //         }
  //       });
  //       if (response.ok) {
  //         response222.headers.forEach((value, key) => {
  //                   console.log(`${key}: ${value}`);
  //               });

  //         const contentDisposition = response222.headers.get('Content-Disposition');
  //         console.log("🚀 ~ handleOpenRecord ~ contentDisposition:", contentDisposition , response , " response.headers" , response.headers )
  //         const blob = await response.blob();
  //         const updatedFile = new File([blob], "your file name ", { type: blob.type });
  //         const blobUrl = URL.createObjectURL(blob);
  //         window.open(blobUrl , "_blank")

  //       } else {
  //         console.error("Failed to fetch document:", response.statusText);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching document:", error);
  //     }
  //   }
  // };

  // const handleOpenRecord = async () => {
  //   const accessToken = getLocalUserToken();
  //   if (!isDeleted) {
  //     const token = accessToken; // Replace this with the actual token
  //     const url =
  //       "http://192.168.100.23:8000/document_records/" +
  //       record.id +
  //       "/get_generate/" +
  //       record.name;

  //     try {
  //       const response = await fetch(url, {
  //         method: "GET",
  //         headers: {
  //           "Authorization": `${token}`
  //         }
  //       });

  //       if (response.ok) {
  //         const blob = await response.blob();
  //         const reader = new FileReader();

  //         reader.onload = () => {
  //           const dataUrl = reader.result;
  //           if (typeof dataUrl === 'string') {
  //             const newWindow = window.open(dataUrl, "_blank");
  //             if (newWindow) {
  //               newWindow.document.title = record.name; // Set the window title if possible
  //             }
  //           } else {
  //             console.error("Error: dataUrl is not a string");
  //           }
  //         };

  //         reader.readAsDataURL(blob);
  //       }else {
  //         console.error("Failed to fetch document:", response.statusText);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching document:", error);
  //     }
  //   }
  // };

  // const handleOpenRecord = () => {
  //   if (!isDeleted) {
  //     const url =
  //       "https://api.nomiadocs.com/document_records/" +
  //       record.id +
  //       "/get_generate/" +
  //       record.name;

  //     window.open(url, "_blank");
  //   }
  // };

  return (
    <>
      <Box
        style={{
          position: "relative", // Change to relative positioning
          display: "flex", // Use flexbox to control alignment
          alignItems: "flex-start", // Align items to the start of the container
          borderRadius: "3px",
          paddingBottom: "10px",
        }}
      >
        <ImageListItem
          key={record.id}
          sx={{
            justifyContent: "center",
            display: "flex",
            width: 160,
            height: 190,
          }}
        >
          <Box
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={boxStyles}
            onClick={(e) => handleOpenRecord()}
          >
            <Paper
              sx={{
                display: "flex",
                width: 160,
                height: 190,
                borderRadius: "3px",
              }}
              title={
                // isDeleted ? "Unarchive record to enable preview" :
                record.name
              }
            >
              <Box
                sx={{
                  // border: "2px solid black",
                  paddingLeft: "8px",
                  paddingTop: "12px",
                  marginRight: "15px",
                  whiteSpace: "nowrap", // Keep text on a single line
                  overflow: "hidden", // Hide overflow content
                  textOverflow: "ellipsis", // Show ellipsis (...) when text overflows
                }}
              >
                {/* Name */}
                <Typography
                  variant="h5"
                  sx={{
                    fontSize: "13px",
                    fontWeight: "bold",
                    whiteSpace: "nowrap", // Prevent text from wrapping
                    overflow: "hidden", // Hide overflow text
                    textOverflow: "ellipsis", // Add ellipsis to overflow text
                  }}
                >
                  {record.name}
                </Typography>

                {/* Category */}
                <Typography
                  variant="body2"
                  color="black"
                  sx={{
                    fontSize: "12px",
                    padding: "0px",
                    paddingLeft: "5px",

                    whiteSpace: "nowrap", // Prevent text from wrapping
                    overflow: "hidden", // Hide overflow text
                    textOverflow: "ellipsis", // Add ellipsis to overflow text
                  }}
                >
                  {record.category}
                </Typography>

                {/* Purpose */}
                <Typography
                  variant="body2"
                  color="black"
                  sx={{
                    fontSize: "12px",
                    padding: "0px",
                    paddingLeft: "5px",
                    whiteSpace: "nowrap", // Prevent text from wrapping
                    overflow: "hidden", // Hide overflow text
                    textOverflow: "ellipsis", // Add ellipsis to overflow text
                  }}
                >
                  {record.sub_category}
                </Typography>
              </Box>

              <Box
                style={{
                  position: "absolute",
                  top: "70px",
                  left: "5px",
                  zIndex: 100,
                  padding: "2px",
                }}
              >
                <Box
                  sx={{
                    width: "80px",
                    height: "18px",
                    backgroundColor: "#7c7c7c",
                    borderRadius: "3px",
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "center",
                    color: "white",
                    // fontWeight: 'bold',
                    // textTransform: 'uppercase',
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                    fontSize: "13px", // Adjust the font size as needed
                    padding: "2px",
                    paddingLeft: "2px",
                    paddingTop: "4px",
                    fontFamily: "MarkPro",
                  }}
                >
                  {"PDF"}
                </Box>
              </Box>
              {/* </a> */}

              {isDeleted && (
                <Box
                  style={{
                    position: "absolute",
                    top: "100px",
                    left: "5px",
                    zIndex: 100,
                    padding: "2px",
                  }}
                >
                  <Box
                    sx={{
                      width: "80px",
                      height: "18px",
                      backgroundColor: "red",
                      borderRadius: "3px",
                      display: "flex",
                      justifyContent: "left",
                      alignItems: "center",
                      color: "white",
                      // fontWeight: 'bold',
                      // textTransform: 'uppercase',
                      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                      fontSize: "13px", // Adjust the font size as needed
                      padding: "2px",
                      paddingLeft: "2px",
                      paddingTop: "4px",
                      fontFamily: "MarkPro",
                    }}
                  >
                    Archived
                  </Box>
                </Box>
              )}
            </Paper>
          </Box>

          <Box
            style={{
              position: "absolute",
              top: "150px",

              zIndex: 100,
              margin: 0,
              padding: 0,
              width: "100%",
            }}
          >
            <IconButton
              sx={{
                margin: 0, // Pushes the button to the left corner
                padding: 0,
              }}
              onClick={handleOpenMenu}
            >
              <MoreVertIcon />
            </IconButton>
          </Box>
          <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={menuOpen}
            onClose={handleCloseMenu}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            {!isDeleted && (
              <MenuItem
                onClick={handleDeleteClicked}
                disabled={
                  isDeleted ||
                  props.workspaceRole === "READER" ||
                  props.workspaceRole === "READER_CREATOR" ||
                  props.workspaceRole === undefined
                }
              >
                <ListItemIcon>
                  <DeleteIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Archive</ListItemText>
              </MenuItem>
            )}

            {isDeleted && (
              <MenuItem
                onClick={handleUnDeleteClicked}
                disabled={
                  props.workspaceRole === "READER" ||
                  props.workspaceRole === "READER_CREATOR" ||
                  props.workspaceRole === undefined
                }
              >
                <ListItemIcon>
                  <DeleteIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Unarchive</ListItemText>
              </MenuItem>
            )}
            <Divider />
            <MenuItem
              disabled={isDeleted}
              onClick={(e) => handleOpenRecord()}
              //  onClick={handlePreviewClicked}
            >
              <ListItemIcon>
                <PreviewSharpIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Preview</ListItemText>
            </MenuItem>
            <MenuItem
              disabled={isDeleted}
              onClick={(e) => copyToClipboard()}
              //  onClick={handlePreviewClicked}
            >
              <ListItemIcon>
                <ShareIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Share</ListItemText>
            </MenuItem>
            <MenuItem
              disabled={isDeleted}
              onClick={(e) => handleDownloadRecord()}
              //  onClick={handlePreviewClicked}
            >
              <ListItemIcon>
                <FileDownloadIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Download</ListItemText>
            </MenuItem>

            <MenuItem
              disabled={
                currentOrgDetails.isTrialExpired ||
                isDeleted ||
                props.workspaceRole === "READER" ||
                props.workspaceRole === undefined
              }
              onClick={handleDialogOpen}
            >
              <ListItemIcon>
                <SearchIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Regenerate PDF</ListItemText>
            </MenuItem>

            <MenuItem onClick={handleInfoClicked}>
              <ListItemIcon>
                <InfoIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>PDF Properties</ListItemText>
            </MenuItem>
          </Menu>
        </ImageListItem>
      </Box>

      {infoOpen ? (
        <DocumentRecordInfoForm
          parentCloseHandle={() => setInfoOpen(false)}
          documentRecord={props.documentRecord}
        ></DocumentRecordInfoForm>
      ) : null}

      <Dialog open={dialogOpen} onClose={() => handleDialogClose} maxWidth="lg">
        <DialogTitle>
          <Grid container sx={{ alignItems: "center" }}>
            <Grid item xs={3}>
              Document Name
            </Grid>
            <Grid item xs={9}>
              <TextField
                placeholder="Document Name"
                fullWidth
                sx={{ minWidth: "500px" }}
                label="Document Name"
                value={props.documentRecord.name}
              ></TextField>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent>
          {isShowTable && (
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow
                    sx={{ backgroundColor: "primary.main", color: "#FFFFFF" }}
                  >
                    <TableCell sx={{ color: "#fff" }}>Variable</TableCell>
                    <TableCell align="left" sx={{ color: "#fff" }}>
                      Value
                    </TableCell>
                    {/* <TableCell align="right" sx={{color: "#fff"}}>Reset</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {variables?.map(
                    (variable: any) =>
                      !variable.fillable_field && (
                        <TableRow
                          // key={variable.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            <b>{variable?.variable_names}</b>
                          </TableCell>
                          <TableCell align="right">
                            <TextField
                              fullWidth
                              value={variable?.value}
                              variant="standard"
                              margin="none"
                              helperText={
                                variable?.value.trim() == ""
                                  ? "This will be passed as a blank string"
                                  : null
                              }
                            ></TextField>
                          </TableCell>
                        </TableRow>
                      )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <Alert severity="error">
            This will override the Previous Record!
          </Alert>
        </DialogContent>
        <DialogActions sx={{ m: 1 }}>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleRegenerateRecord}
          >
            Regenerate PDF
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
