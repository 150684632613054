import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useActions, { useAppDispatch, useAppSelector } from "../../app/hooks";
import LockResetIcon from "@mui/icons-material/LockReset";
import { useSnackbar } from "notistack";
import {
  Organization,
  User,
  Workspace,
  WorkspaceUserRole
} from "../../types/User";
import AddIcon from "@mui/icons-material/Add";
import {
  Alert,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip
} from "@mui/material";
import { KeyboardReturnOutlined, Workspaces } from "@mui/icons-material";
import {
  selectAuth,
  selectCreateUserError,
  selectCurrentWorkspace,
  setCreaterUserError
} from "../auth/authSlice";

/**
 * Edit the workspace if a workspace is passed in, otherwise create a new one.
 *
 * @export
 * @return {*}
 */
export default function OrganizationConfigForm(props: {
  closeDialogHandler: any;
  organization: Organization;
  workspaces: Array<Workspace>;
}) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [nameError, setNameError] = React.useState("");
  const [dialogError, setDialogError] = React.useState("");
  const dispatch = useAppDispatch();
  const userErrorState = useAppSelector(selectCreateUserError);

  const [addUser, setAddUser] = React.useState<User | undefined>(
    props.organization.users && props.organization.users.length > 0
      ? props.organization.users[0]
      : undefined
  );
  const authState = useAppSelector(selectAuth);
  const [createUserMode, setCreateUserMode] = React.useState<boolean>(false);
  const [addWorkspaces, setAddWorkspaces] = React.useState<
    { id: number; role: string }[]
  >([]);
  const [newUserEmail, setNewUserEmail] = React.useState<string>("");
  const [newUserPassword, setNewUserPassword] = React.useState<string>("");
  const handleEditUserEmail = (e: any) => {
    setNewUserEmail(e.target.value);
    dispatch(setCreaterUserError(undefined));
  };
  const currentWorkSpace = useAppSelector(selectCurrentWorkspace);
  const usersList = (props.organization.adminUsers || []).filter(
    (user) => !user.super_user
  );
  const handleDeleteUser = (user: User) => {
    actions.detachOrganizationUser({ workspace: currentWorkSpace, user: user });
  };

  const handleUserRole = (user: User, roleSelection: string) => {
    const currentRole = user.admin ? "ADMIN" : "NORMAL";
    const userID = parseInt(user.id, 10); // Assuming base 10
    if (currentRole != roleSelection) {
      actions.updateOrganizationUserRole({
        user: userID,
        newRole: roleSelection
      });
    }
  };

  const handleResetPassword = (user: User) => {
    actions.resetUserPassword({ email: user.email });
    const snackbarMessage = "Password reset request sent successfully";
    enqueueSnackbar(snackbarMessage, {
      variant: "success"
    });
  };
  const actions = useActions();

  const handleClose = () => {
    if (createUserMode) {
      setCreateUserMode(!createUserMode);
      dispatch(setCreaterUserError(undefined));
    } else {
      dispatch(setCreaterUserError(undefined));
      props.closeDialogHandler();
    }
  };

  const handleCreateUser = (e: any) => {
    /*if (addUser == null) {
      setDialogError("Please select a user to add.");
      return;
    }*/
    //TODO: Check for duplicates.
    const validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (!newUserEmail.match(validRegex)) {
      enqueueSnackbar("Not a valid email address", { variant: "error" });
      return;
    }
    actions.createUserInOrg({
      email: newUserEmail,
      password: newUserPassword,
      workspaces: addWorkspaces
    });

    // setCreateUserMode(false);
  };

  React.useEffect(() => {
    if (userErrorState === "success") {
      dispatch(setCreaterUserError(undefined));
      setNewUserEmail("");
      setCreateUserMode(false);
    }
  }, [userErrorState]);

  const userEditCard = (
    <Card>
      {!createUserMode && (
        <Box>
          <CardContent>
            <Button onClick={() => setCreateUserMode(true)}>
              <AddIcon fontSize="large"></AddIcon>
              Invite a User
            </Button>
            {/* Existing role types */}
            {
              // props.organization.adminUsers?
              usersList.map((user) => (
                <Grid
                  container
                  sx={{ paddingBottom: 2 }}
                  spacing={1}
                  key={"_user_data" + user.id}
                >
                  <Grid item xs={6}>
                    <Input value={user.email} fullWidth={true} readOnly />
                  </Grid>
                  <Grid item xs={3} textAlign="center">
                    <FormControl fullWidth>
                      <InputLabel id={"demo-select-small-label__Role"}>
                        Role
                      </InputLabel>
                      <Select
                        label="Role"
                        fullWidth
                        value={
                          user.super_user
                            ? "SuperAdmin"
                            : user.admin
                            ? "ADMIN"
                            : "NORMAL"
                        }
                        disabled={
                          user.id == authState!.user?.user_id || user.super_user
                        }
                        onChange={(event) => {
                          handleUserRole(user, event.target.value);
                        }}
                        sx={{ height: "2.2rem" }}
                      >
                        <MenuItem value="ADMIN">ADMIN</MenuItem>
                        <MenuItem value="NORMAL">NORMAL</MenuItem>
                        {user.super_user && (
                          <MenuItem value="SuperAdmin">SUPER</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={1} textAlign="center">
                    <IconButton
                      disabled={
                        user.id == authState!.user?.user_id || user.super_user
                      }
                      sx={{ fontSize: "medium" }}
                      onClick={(e) => handleDeleteUser(user)}
                    >
                      <DeleteIcon></DeleteIcon>
                    </IconButton>
                  </Grid>
                  <Grid item xs={2} textAlign="center">
                    {/* <Button onClick={() => handleResetPassword(user)}>
            <LockResetIcon fontSize="small"></LockResetIcon>
            Reset
          </Button> */}

                    <IconButton
                      sx={{ fontSize: "medium" }}
                      onClick={(e) => handleResetPassword(user)}
                    >
                      <LockResetIcon />
                      {/* RESET */}
                      <Typography variant="body2" sx={{ fontSize: "0.8em" }}>
                        RESET
                      </Typography>
                    </IconButton>
                  </Grid>
                </Grid>
              ))
            }
          </CardContent>
        </Box>
      )}

      {createUserMode && (
        <Box>
          <CardHeader title="Invite a New User"></CardHeader>
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={8} sx={{ marginBottom: "20px" }}>
                {/* <InputLabel>Email</InputLabel> */}
                <TextField
                  value={newUserEmail}
                  onChange={handleEditUserEmail}
                  fullWidth={true}
                  error={
                    userErrorState !== undefined && userErrorState !== "success"
                  }
                  label={"Email"}
                  helperText={
                    userErrorState !== undefined && userErrorState !== "success"
                      ? userErrorState
                      : ""
                  }
                />
              </Grid>
              {/*<Grid item xs={5}>
              <InputLabel>Password</InputLabel>
              <Input
                value={newUserPassword}
                onChange={handleEditUserPassword}
              />
          </Grid> */}
            </Grid>

            {addWorkspaces.map((workspace, jj) => (
              <Grid container spacing={2} key={"ws_" + workspace.id + "_" + jj}>
                <Grid item xs={5}>
                  <Select
                    label="Role"
                    value={workspace.id}
                    onChange={(event) => {
                      if (
                        addWorkspaces
                          .map((_) => _.id)
                          .includes(Number(event.target.value))
                      ) {
                        return;
                      }

                      if (event.target.value !== null) {
                        setAddWorkspaces(
                          addWorkspaces.map((_, kk) => {
                            if (jj == kk) {
                              return {
                                id: Number(event.target.value),
                                role: _.role
                              };
                            } else {
                              return _;
                            }
                          })
                        );
                      }
                    }}
                    fullWidth={true}
                  >
                    {props.workspaces.map((ws) => (
                      <MenuItem value={ws.id}>{ws.name}</MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={5}>
                  <Select
                    label="Role"
                    value={workspace.role}
                    onChange={(event) => {
                      setAddWorkspaces(
                        addWorkspaces.map((_) => {
                          if (_.id == workspace.id) {
                            return {
                              id: _.id,
                              role: event.target.value
                            };
                          } else {
                            return _;
                          }
                        })
                      );
                    }}
                    fullWidth={true}
                  >
                    <MenuItem value="READER">Reader</MenuItem>
                    <MenuItem key="READER_CREATOR" value="READER_CREATOR">
                      Creator
                    </MenuItem>
                    <MenuItem value="CREATOR">Editor</MenuItem>
                    <MenuItem value="PUBLISHER_CREATOR">Publisher</MenuItem>
                  </Select>
                </Grid>
              </Grid>
            ))}
            <Button
              sx={{ marginBottom: "20px" }}
              onClick={() => {
                if (props.workspaces.length > 0) {
                  const missing = props.workspaces.filter(
                    (_) => !addWorkspaces.map((__) => __.id).includes(_.id)
                  );

                  if (missing.length > 0) {
                    setAddWorkspaces([
                      ...addWorkspaces,
                      { id: missing[0].id, role: "READER" }
                    ]);
                  }
                }
              }}
            >
              Add to Workspace
            </Button>
            <Box>
              <Button
                variant={"outlined"}
                startIcon={<AddIcon />}
                onClick={(event) => handleCreateUser(event)}
              >
                Create User
              </Button>
            </Box>
          </CardContent>
        </Box>
      )}
    </Card>
  );

  return (
    <Dialog
      open={true}
      onClose={(e) => {
        props.closeDialogHandler();
      }}
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogTitle>Organization Users</DialogTitle>
      <DialogContent>
        {dialogError !== "" ? (
          <Alert severity="error">{dialogError}</Alert>
        ) : null}
        {userEditCard}
      </DialogContent>
      <DialogActions>
        <Button variant={"outlined"} onClick={handleClose}>
          {createUserMode ? "BACK" : "ClOSE"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
