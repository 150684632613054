import {
  Alert,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  Input,
  Snackbar,
  makeStyles,
} from "@mui/material";
import { Organization } from "../../../types/User";
import { apiGetOrganizationAdmins } from "../../../services/organizationAPI";
import ContentPasteIcon from "@mui/icons-material/ContentPasteTwoTone";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";

import NoteAltIcon from "@mui/icons-material/NoteAlt";

export default function AdminAssistanceForm(props: {
  closeDialogHandler: any;
  organization: Organization;
}) {
  const handleClose = () => {
    props.closeDialogHandler();
  };

  const [Admins, setAdmins] = React.useState([]);
  const [requestStatus, setRequestStatus] = React.useState("idle");
  const [copied, setCopied] = React.useState(false);

  const [clickedButton, setClickedButton] = React.useState(null);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        setRequestStatus("loading");
        const AdminResponse = await apiGetOrganizationAdmins(
          props.organization.id
        );
        // const usersList = (AdminResponse.users || []).filter((user:any) => !user.super_user);
        // console.log("🚀 ~ file: AdminAssistanceForm.tsx:46 ~ fetchData ~ usersList:", usersList)
        setAdmins(AdminResponse.users);
        setRequestStatus("idle");
      } catch (error) {
        setRequestStatus("failed");
        console.error(
          "An error occurred while fetching organization admins:",
          error
        );
      }
    };
    fetchData();
  }, []);

  const copyEmailToClipboard = (email: any, index: any) => {
    navigator.clipboard.writeText(email).then(() => {
      setCopied(true);
      setClickedButton(index);
    });
  };

  const handleCloseSnackbar = () => {
    setCopied(false);
    // setClickedButton(null);
  };

  const dialogStyle = {
    maxWidth: "600px",
    margin: "0 auto",
  };

  const copyButtonStyle = {
    marginLeft: "16px",
  };

  const inputStyle = {
    width: "400px",
    marginBottom: "16px",
    display: "flex",
    alignItems: "center",
  };
  return (
    <Dialog open={true} onClose={handleClose} style={dialogStyle}>
      <DialogTitle style={{ textAlign: "left", fontWeight: "bold" }}>
        Organization Admins
      </DialogTitle>
      {requestStatus == "idle" ? (
        <Box style={{ padding: "20px", paddingLeft:"25px", paddingTop:0 }}>
          {Admins.map((user, index) => (
            <div style={inputStyle}>
              <Input value={user} fullWidth key={user} readOnly style={{ flex: 1 }} />
              <IconButton
                onClick={() => copyEmailToClipboard(user, index)}
                style={clickedButton === index ? { color: "blue" } : {}}
              >
                {clickedButton === index && <AssignmentTurnedInIcon sx={{fontSize:"0.9em"}} />}

                {clickedButton !== index && <NoteAltIcon sx={{fontSize:"0.9em"}} />}
              </IconButton>
            </div>
          ))}
          <Snackbar
            open={copied}
            autoHideDuration={3000}
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          >
            <Alert severity="success" onClose={handleCloseSnackbar}>
              Email copied to clipboard
            </Alert>
          </Snackbar>
        </Box>
      ) : (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "150px",
            width: "420px",
          }}
        >
          {requestStatus == "loading" ? (
            <CircularProgress />
          ) : (
            <Typography fontWeight="bold">Unable to fetch data</Typography>
          )}
        </Box>
      )}
    </Dialog>
  );
}

{
  /* <Box style={{ padding: "16px" }}>
        {Admins.map((user) => (
          <Input
            value={user}
            fullWidth={true}
            key={user}
            style={inputStyle}
          />
        ))}
      </Box> */
}
