import { MarkChatUnreadOutlined } from "@mui/icons-material";
import { Box, Container, Drawer, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import CommentsCard from "./CommentsCard";
import AddCommentCard from "./AddCommentCard";
import {
  apiAddComments,
  apiGetComments,
} from "src/services/shareAndCommentsAPI";
import { useAppSelector } from "src/app/hooks";
import { selectEditingDocumentTemplate } from "../editor/editorSlice";
import { useDispatch, useSelector } from "react-redux";
import { setAllComments } from "./commentingDetailsSlice";
import { RootState } from "src/app/store";

type Props = {
  data?: any;
};

const drawerWidth = "19vw";

const CommentsContainer = (props: Props) => {
  const [comment, setcomment] = useState("");
  const dispatch = useDispatch();
  const [arr, setarr] = useState([
    "asdwd",
    "asdwdas",
    "gljlajf",
    "pwoeiqpw",
    "poawasd",
    "asdwd",
    "asdwdas",
    "gljlajf",
    "pwoeiqpw",
    "poawasd",
    "asdwd",
    "asdwdas",
    "gljlajf",
    "pwoeiqpw",
    "poawasd",
    "asdwd",
    "asdwdas",
    "gljlajf",
    "pwoeiqpw",
    "poawasd",
    "asdwd",
    "asdwdas",
    "gljlajf",
    "pwoeiqpw",
    "poawasd",
  ]);

  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const getAllComments = useSelector(
    (state: RootState) => state.apiCommenting.allComments
  );

  const selectedDocumentTemplate: any = useAppSelector(
    selectEditingDocumentTemplate
  );

  const { organization_id, workspace_id, id } = selectedDocumentTemplate as any;

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [arr]);

  //consoles for debugging

  console.log(organization_id, workspace_id, id);

  const _handleallcomments = async () => {
    const _payload = {
      document_section_id: null,
      document_sub_section_id: null,
      document_element_id: null,
    };
    const response: any = await apiGetComments(
      organization_id,
      workspace_id,
      id,
      _payload
    );
    if (response.data) {
      dispatch(setAllComments(response.data.data));
    }
  };

  useEffect(() => {
    _handleallcomments();
  }, []);

  const Warning = () => (
    <Box
      display={"flex"}
      flexDirection={"column"}
      height={"100%"}
      padding={"20px"}
      justifyContent={"center"}
      justifyItems={"center"}
      justifySelf={"center"}
    >
      <MarkChatUnreadOutlined
        style={{
          textAlign: "center",
          color: "gray",
          display: "flex",
          alignSelf: "center",
        }}
      />
      <Typography fontSize={"small"} textAlign={"center"} color={"gray"}>
        Please select any comment to display
      </Typography>
    </Box>
  );
  const { data } = props;
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      {data ? (
        <Warning />
      ) : (
        <Box
          display={"flex"}
          flexDirection={"column"}
          sx={{
            height: "100%",
            overflow: "auto",
            width: "100%",
          }}
        >
          {getAllComments?.map((mainComment: any, index: number) => (
            <>
              {!mainComment.supercedes_id && (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <CommentsCard
                    showActions={false}
                    item={mainComment}
                    key={mainComment.id || index}
                    fromEditor={false}
                  />
                </div>
              )}

              {getAllComments
                ?.filter(
                  (subComment: any, _i: number) =>
                    subComment.supercedes_id === mainComment.id
                )
                .map((subComment: any, subIndex: number) => {
                  return (
                    <div
                      key={subComment.id || subIndex}
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        position: "relative",
                      }}
                    >
                      {/* Thread Line (Vertical and Horizontal) */}
                      <div
                        style={{
                          position: "absolute",
                          left: "10px",
                          top: "0",
                          bottom: "0",
                          width: "10px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            position: "relative",
                            width: "2px",
                            backgroundColor: "#4c3fff",
                            height: "100%",
                          }}
                        >
                          <div
                            style={{
                              position: "absolute",
                              top: "50%",
                              left: "0",
                              width: "20px",
                              height: "2px",
                              backgroundColor: "#4c3fff",
                            }}
                          ></div>
                        </div>
                      </div>

                      {/* CommentsCard for each sub-comment */}
                      <div
                        style={{
                          marginLeft: "20px",
                          minWidth: "82%",
                          maxWidth: "82%",
                        }}
                      >
                        <CommentsCard
                          key={subComment.id || index}
                          showActions={false}
                          item={subComment}
                          fromEditor={false}
                        />
                      </div>
                    </div>
                  );
                })}
            </>
          ))}
          <div ref={messagesEndRef} />
        </Box>
      )}
    </Box>
  );
};

export default CommentsContainer;
