import * as XLSX from "xlsx";
import { Variable } from "../../../types/DocumentTemplate";
import VariableTable from "../variableTables/VariableTable";


export const hasMatchingVariables=(variables:any, documentVariables:any)=>  {
  const variableSet = new Set();
  
  if(documentVariables.lenght===0){
    return  {
      invalid:false ,
      dupliacte:false,
      invalidVariable: ""

    } 
  }
  
  for (const variable of variables) {
    if (variableSet.has(variable)) {
      return {
        invalid:true ,
        dupliacte:true,
        invalidVariable: variable 

      }  // Found a duplicate, so return true
    } else {
      variableSet.add(variable); // Add the variable to the set
    }
  }

  for (const docVar of documentVariables) {
    if (!variableSet.has(docVar.variable_name)) {
      return {
        invalid:true ,
        dupliacte:false,
        invalidVariable:docVar.variable_name
      }
    }

  return {
    invalid: false,  
    dupliacte:false,
    invalidVariable:""
  }
}
}


export const generateDocumentExcelTemplate = (
  variables: Variable[],
  documentName: string
) => {
  let data;

  // Initialize the data array with headers

  if (variables.length == 0) {
    data = [["Document name", "Variable"]];

    // Add rows for each document
    for (let i = 1; i <= 4; i++) {
      data.push([
        `Document ${i}, Please provide name here (must be unique)`,
        "null",
      ]);
    }
  } else {
    data = [
      [
        "Document name",
        ...variables.map(
          (variable) => variable?.variable_name || "Variable Name"
        ),
      ],
    ];

    // Add rows for each document
    for (let i = 1; i <= variables.length; i++) {
      data.push([
        `Document ${i}, Please provide name here (must be unique)`,
        ...variables.map((variable) => 
        // variable.default_value
         "Please provide variable value here"
        ),
      ]);
    }
  }
  const comment = "Copy above format if more documents needed…";

  // Add the comment as a separate row in the data array
  data.push([comment, ...variables.map(() => "")]);

  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.aoa_to_sheet(data);

  // Calculate the range of cells to merge for Document name
  const mergeStartRow = 1;
  const mergeEndRow = 1 + variables.length - 1;

  const mergeCell = {
    s: { r: mergeStartRow, c: 0 },
    e: { r: mergeEndRow, c: 0 },
  };

  // ws["!merges"] = [mergeCell]; // Set the merged cells in the worksheet

  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
  const blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${documentName} Bulk Template.xlsx`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const s2ab = (s: string) => {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i < s.length; i++) {
    view[i] = s.charCodeAt(i) & 0xff;
  }
  return buf;
};

export function validateData(data: any, variables: Variable[]) {
  const documentNamesInData = data.map((obj: any) =>
    obj.documentName !== undefined ? obj.documentName : undefined
  );
  const variableNamesInData = data.map((obj: any) =>
    obj.variableName !== undefined ? obj.variableName : undefined
  );


  
  if (variables.length == 0) {
    const documentNameCounts = documentNamesInData.reduce(
      (counts: any, documentName: any) => {
        counts[documentName] = (counts[documentName] || 0) + 1;
        return counts;
      },
      {}
    );
    // Filter out document names with counts not equal to the number of variables
    const invalidDocumentNames = Object.entries(documentNameCounts).filter(
      ([documentName, count]) => {
        return count !== 1;
      }
    );
    if (invalidDocumentNames.length > 0) {
      // Invalid data found, return an error message or handle as needed
      return {
        isValid: false,
        invalidDocumentNames,
        invalidVariableNames: [],
        invalidVariableValues: [],
      };
    }
  } else {
    // correct
    // Check if any variable name from the data is not in the variables array

    const invalidVariableNames = variableNamesInData.filter(
      (variableName: any) => {
        return !variables.some(
          (variable) => variable.variable_name === variableName
        );
      }
    );
  
    // correct
    const invalidVariableValues = data.filter((obj: any) => {
      return obj.variableValue.startsWith("@");
      // obj.variableValue === "" ||
      // obj.variableValue === null ||
      // obj.variableValue === undefined
    });

    // Count occurrences of each document name
    const documentNameCounts = documentNamesInData.reduce(
      (counts: any, documentName: any) => {
        counts[documentName] = (counts[documentName] || 0) + 1;
        return counts;
      },
      {}
    );

    // Filter out document names with counts not equal to the number of variables
    const invalidDocumentNames = Object.entries(documentNameCounts).filter(
      ([documentName, count]) => {
        return count !== variables.length;
      }
    );

    if (
      invalidVariableNames.length > 0 ||
      invalidVariableValues.length > 0 ||
      invalidDocumentNames.length > 0
    ) {
      // Invalid data found, return an error message or handle as needed
      return {
        isValid: false,
        invalidVariableNames,
        invalidVariableValues,
        invalidDocumentNames,
      };
    }
  }
  // All variable names are valid
  return {
    isValid: true,
  };
}

//  export function validateData(data: any, variables: Variable[]) {
//     const documentNamesInData = data.map((obj: any) =>
//       obj.documentName !== undefined ? obj.documentName : undefined
//     );
//     const variableNamesInData = data.map((obj: any) =>
//       obj.variableName !== undefined ? obj.variableName : undefined
//     );
//     if (variables.length == 0) {
//       const documentNameCounts = documentNamesInData.reduce(
//         (counts: any, documentName: any) => {
//           counts[documentName] = (counts[documentName] || 0) + 1;
//           return counts;
//         },
//         {}
//       );
//       // Filter out document names with counts not equal to the number of variables
//       const invalidDocumentNames = Object.entries(documentNameCounts).filter(
//         ([documentName, count]) => {
//           return count !== 1;
//         }
//       );
//       if (invalidDocumentNames.length > 0) {
//         // Invalid data found, return an error message or handle as needed
//         return {
//           isValid: false,
//           invalidDocumentNames,
//           invalidVariableNames:[],
//           invalidVariableValues:[],
//         };
//       }
//     } else {
//       // correct
//       // Check if any variable name from the data is not in the variables array
//       const invalidVariableNames = variableNamesInData.filter(
//         (variableName: any) => {
//           return !variables.some(
//             (variable) => variable.variable_name === variableName
//           );
//         }
//       );
//       // correct
//       const invalidVariableValues = data.filter((obj: any) => {
//         return (

//           obj.variableValue.startsWith("@")
//           // obj.variableValue === "" ||
//           // obj.variableValue === null ||
//           // obj.variableValue === undefined
//         );
//       })

//       // Count occurrences of each document name
//       const documentNameCounts = documentNamesInData.reduce(
//         (counts: any, documentName: any) => {
//           counts[documentName] = (counts[documentName] || 0) + 1;
//           return counts;
//         },
//         {}
//       );

//       // Filter out document names with counts not equal to the number of variables
//       const invalidDocumentNames = Object.entries(documentNameCounts).filter(
//         ([documentName, count]) => {
//           return count !== variables.length;
//         }
//       );

//       if (
//         invalidVariableNames.length > 0 ||
//         invalidVariableValues.length > 0 ||
//         invalidDocumentNames.length > 0
//       ) {
//         // Invalid data found, return an error message or handle as needed
//         return {
//           isValid: false,
//           invalidVariableNames,
//           invalidVariableValues ,
//           invalidDocumentNames,
//         };
//       }
//     }
//     // All variable names are valid
//     return {
//       isValid: true,
//     };
//   }
