import { shallowEqual } from "react-redux";
import { useAppSelector } from "../../../../app/hooks";
import {
  findChildSubSections,
  findChildSubSectionsOfSubHeadingH6,
  findParentSubHeading
} from "../../../../app/sagas/utils";
import {
  ContentType,
  DocumentElement,
  DocumentSection,
  DocumentSubsection,
  DocumentTemplate,
  SectionSetting
} from "../../../../types/DocumentTemplate";

import { selectFormattingSection } from "../../header/documentMasterSlice";
import {
  applySeperators,
  applySeperatorsHeader,
  applySeperatorsHeaderCompound,
  documentMasterFormat,
  isEditorDisabled
} from "../HelperEditorFunctions";
import { attachedSubsectionElement, isDeletedSubsection } from "../utils";

export interface SectionInfo {
  id: any;
  count: number;
}
export interface SubHeadingInfo {
  parentSectionID: number;
  subSectionId: number;
  elementId: number;
  number: string;
  level: number;
  componentOrder: number;
}
export interface TextElementInfo {
  parentSectionID: number;
  subSectionId: number;
  elementId: number;
  number: string;
  level: number;
  parentSubsection: DocumentSection;
  componentOrder: number;
}

export const getSectionDocumentMaster = (
  sectionID: number,
  sectionMasterState: any,
  documentMasterState: any
) => {
  if (
    sectionID === undefined ||
    Object.keys(sectionMasterState).length == 0 ||
    sectionMasterState[sectionID] == null
  ) {
    return {
      ...documentMasterState,
      documentMaster: documentMasterState.documentMaster,
      isFollowSecSettings: false,
      numberingSetting: "CN"
    };
  }

  const currentSectionFormatting = JSON.parse(sectionMasterState[sectionID]);
  const isFollowSecSettings =
    Object.keys(sectionMasterState).length !== 0 &&
    sectionMasterState[sectionID] &&
    !JSON.parse(sectionMasterState[sectionID]).followDTM;

  // Select the appropriate section formatting
  const sectionFormatting = currentSectionFormatting.followDTM
    ? documentMasterState.documentMaster
    : currentSectionFormatting.previousSettings
    ? documentMasterState.sortedSectionsDocumentMaster[sectionID]
    : currentSectionFormatting;

  return {
    ...documentMasterState,
    documentMaster: sectionFormatting ?? documentMasterState.documentMaster,
    isFollowSecSettings: isFollowSecSettings,
    numberingSetting: currentSectionFormatting.numberingSetting ?? "CN"
  };
};

export const documentSectionsNumbers = (
  documentTemplate: DocumentTemplate,
  approvalsEnabled: boolean,
  sectionMaster: any,
  documentMaster: any
): SectionInfo[] => {
  const documentTemplateState = documentTemplate;

  let sectionCount = 0;
  const sectionInfo: SectionInfo[] = [];
  const sections = documentTemplateState.sections;
  try {
    for (const section of sections || []) {
      if (
        !isEditorDisabled(section.heading) &&
        (section.pending_deleted_at === null || approvalsEnabled)
      ) {
        const sectionDocumentMaster = getSectionDocumentMaster(
          section.id,
          sectionMaster,
          documentMaster
        );

        if (sectionDocumentMaster?.numberingSetting == "RN" ?? false) {
          sectionCount = 1;

          sectionInfo.push({ id: section.id, count: sectionCount });
        } else if (sectionDocumentMaster?.numberingSetting == "NN" ?? false) {
          sectionInfo.push({ id: section.id, count: sectionCount });
          /// donothing
        } else {
          sectionCount++;
          sectionInfo.push({ id: section.id, count: sectionCount });
        }
      }
    }

    return sectionInfo;
  } catch (e) {
    console.log("  ~ sectionInfo: unble to calculate section numbering :", e);
    return sectionInfo;
  }
};

const parseCompoundNumbering = (
  currentLevel: number,
  headingFormats: any[],
  sectionCount: number,
  countHeadingH2: number,
  countHeadingH3: number,
  countHeadingH4: number,
  countHeadingH5: number,
  countHeadingH6: number
) => {
  let numbering = applySeperators(headingFormats[0], sectionCount);

  const countArray = [
    sectionCount,
    countHeadingH2,
    countHeadingH3,
    countHeadingH4,
    countHeadingH5,
    countHeadingH6
  ];
  for (let n = 1; n < currentLevel; n++) {
    if (headingFormats[n].compound) {
      numbering += applySeperatorsHeader(headingFormats[n], countArray[n]);
    } else {
      numbering = applySeperators(headingFormats[n], countArray[n]);
    }
  }

  return numbering;
};

export const documentSectionsNumber = (
  documentTemplate: DocumentTemplate,
  approvalsEnabled: boolean
): SectionInfo[] => {
  const documentTemplateState = documentTemplate;

  let sectionCount = 0;
  const sectionInfo: SectionInfo[] = [];
  const sections = documentTemplateState.sections;
  for (const section of sections || []) {
    if (
      !isEditorDisabled(section.heading) &&
      (section.pending_deleted_at === null || approvalsEnabled)
    ) {
      sectionCount++;
      sectionInfo.push({ id: section.id, count: sectionCount });
    }
  }
  return sectionInfo;
};

export const documentSubHeadingsNumber = (
  documentTemplate: DocumentTemplate,
  documentMaster: any,
  sectionNumber: SectionInfo[],
  sectionMasterState: any
) => {
  // const documentTemplateState = useAppSelector(selectDocumentState);
  const documentTemplateState = documentTemplate;
  // const documentMasterState = documentMaster;
  try {
    const subHeadingInfo: SubHeadingInfo[] = [];
    const sections = documentTemplateState.sections;
    if (sections) {
      sections.forEach((section: DocumentSection, indexSection: number) => {
        const documentMasterState = getSectionDocumentMaster(
          section.id,
          sectionMasterState,
          documentMaster
        );
        const headingFormats = Array.from({ length: 6 }, (_, i) => {
          const headingFormat = JSON.parse(
            documentMasterState.documentMaster[`heading${i + 1}_formatting`]
          );
          return headingFormat;
        });

        const sectionCount =
          sectionNumber.find((s) => s.id === section.id)?.count || 0;
        let countHeadingH2 = 0;
        let countHeadingH3 = 0;
        let countHeadingH4 = 0;
        let countHeadingH5 = 0;
        let countHeadingH6 = 0;
        const headingSubsections = section.subsections?.filter(
          (subsection) =>
            subsection.elements &&
            subsection.elements[0]?.content_type === ContentType.Heading
        );
        (headingSubsections || []).map(
          (subSection: DocumentSubsection, subsectionIndex: number) => {
            const element: DocumentElement =
              attachedSubsectionElement(subSection);
            if (
              !isDeletedSubsection(subSection)
              //     ||  props.approvalsEnabled
            ) {
              let numbering = "";
              if (element.heading_level === 2) {
                countHeadingH2++;
                countHeadingH3 = 0;
                countHeadingH4 = 0;
                countHeadingH5 = 0;
                countHeadingH6 = 0;
                if (
                  headingFormats[element.heading_level - 1].compound === true
                ) {
                  numbering = `${applySeperators(
                    headingFormats[0],
                    sectionCount
                  )}${applySeperatorsHeader(
                    headingFormats[1],
                    countHeadingH2
                  )}`;

                  numbering = parseCompoundNumbering(
                    element.heading_level,
                    headingFormats,
                    sectionCount,
                    countHeadingH2,
                    countHeadingH3,
                    countHeadingH4,
                    countHeadingH5,
                    countHeadingH6
                  );
                } else {
                  numbering = `${applySeperators(
                    headingFormats[element.heading_level - 1],
                    countHeadingH2
                  )}`;
                }
                subHeadingInfo.push({
                  subSectionId: subSection.id,
                  elementId: element.id,
                  number: numbering,
                  level: element.heading_level,
                  parentSectionID: section.id,
                  componentOrder: subSection.component_order
                });
              } else if (element.heading_level === 3) {
                countHeadingH3++;
                countHeadingH4 = 0;
                countHeadingH5 = 0;
                countHeadingH6 = 0;
                if (
                  headingFormats[element.heading_level - 1].compound === true
                ) {
                  numbering = `${applySeperators(
                    headingFormats[0],
                    sectionCount
                  )}${applySeperatorsHeader(
                    headingFormats[1],
                    countHeadingH2
                  )}${applySeperatorsHeader(
                    headingFormats[2],
                    countHeadingH3
                  )}`;

                  numbering = parseCompoundNumbering(
                    element.heading_level,
                    headingFormats,
                    sectionCount,
                    countHeadingH2,
                    countHeadingH3,
                    countHeadingH4,
                    countHeadingH5,
                    countHeadingH6
                  );
                } else {
                  numbering = `${applySeperators(
                    headingFormats[element.heading_level + 1],
                    countHeadingH3
                  )}`;
                }
                subHeadingInfo.push({
                  subSectionId: subSection.id,
                  elementId: element.id,
                  number: numbering,
                  level: element.heading_level,
                  parentSectionID: section.id,
                  componentOrder: subSection.component_order
                });
              } else if (element.heading_level === 4) {
                countHeadingH4++;
                countHeadingH5 = 0;
                countHeadingH6 = 0;
                if (
                  headingFormats[element.heading_level - 1].compound === true
                ) {
                  numbering = `${applySeperators(
                    headingFormats[0],
                    sectionCount
                  )}${applySeperatorsHeader(
                    headingFormats[1],
                    countHeadingH2
                  )}${applySeperatorsHeader(
                    headingFormats[2],
                    countHeadingH3
                  )}${applySeperatorsHeader(
                    headingFormats[3],
                    countHeadingH4
                  )}`;
                  numbering = parseCompoundNumbering(
                    element.heading_level,
                    headingFormats,
                    sectionCount,
                    countHeadingH2,
                    countHeadingH3,
                    countHeadingH4,
                    countHeadingH5,
                    countHeadingH6
                  );
                } else {
                  numbering = `${applySeperators(
                    headingFormats[element.heading_level - 1],
                    countHeadingH4
                  )}`;
                }
                subHeadingInfo.push({
                  subSectionId: subSection.id,
                  elementId: element.id,
                  number: numbering,
                  level: element.heading_level,
                  parentSectionID: section.id,
                  componentOrder: subSection.component_order
                });
              } else if (element.heading_level === 5) {
                countHeadingH5++;
                countHeadingH6 = 0;

                if (
                  headingFormats[element.heading_level - 1].compound === true
                ) {
                  numbering = `${applySeperators(
                    headingFormats[0],
                    sectionCount
                  )}${applySeperatorsHeader(
                    headingFormats[1],
                    countHeadingH2
                  )}${applySeperatorsHeader(
                    headingFormats[2],
                    countHeadingH3
                  )}${applySeperatorsHeader(
                    headingFormats[3],
                    countHeadingH4
                  )}${applySeperatorsHeader(
                    headingFormats[4],
                    countHeadingH5
                  )}`;
                  numbering = parseCompoundNumbering(
                    element.heading_level,
                    headingFormats,
                    sectionCount,
                    countHeadingH2,
                    countHeadingH3,
                    countHeadingH4,
                    countHeadingH5,
                    countHeadingH6
                  );
                } else {
                  numbering = `${applySeperators(
                    headingFormats[element.heading_level - 1],
                    countHeadingH5
                  )}`;
                }

                subHeadingInfo.push({
                  subSectionId: subSection.id,
                  elementId: element.id,
                  number: numbering,
                  level: element.heading_level,
                  parentSectionID: section.id,
                  componentOrder: subSection.component_order
                });
              } else if (element.heading_level === 6) {
                countHeadingH6++;

                if (
                  headingFormats[element.heading_level - 1].compound === true
                ) {
                  numbering = `${applySeperators(
                    headingFormats[0],
                    sectionCount
                  )}${applySeperatorsHeader(
                    headingFormats[1],
                    countHeadingH2
                  )}${applySeperatorsHeader(
                    headingFormats[2],
                    countHeadingH3
                  )}${applySeperatorsHeader(
                    headingFormats[3],
                    countHeadingH4
                  )}${applySeperatorsHeader(
                    headingFormats[4],
                    countHeadingH5
                  )}${applySeperatorsHeader(
                    headingFormats[5],
                    countHeadingH6
                  )}`;
                  numbering = parseCompoundNumbering(
                    element.heading_level,
                    headingFormats,
                    sectionCount,
                    countHeadingH2,
                    countHeadingH3,
                    countHeadingH4,
                    countHeadingH5,
                    countHeadingH6
                  );
                } else {
                  numbering = `${applySeperators(
                    headingFormats[5],
                    countHeadingH6
                  )}`;
                }
                subHeadingInfo.push({
                  subSectionId: subSection.id,
                  elementId: element.id,
                  number: numbering,
                  level: element.heading_level,
                  parentSectionID: section.id,
                  componentOrder: subSection.component_order
                });
              }
            }
          }
        );
      });
    }

    return subHeadingInfo;
  } catch (error) {
    console.warn(
      "An error occurred while calculating SubHeading numbering:",
      error
    );
    // return "Error: Unable to calculate numbering"; // Return a fallback value for display
    return [];
  }
};

const findSubHeadingNumbering = (
  parentSubsection: DocumentSubsection,
  selectedSubHeadingNumber: SubHeadingInfo[]
) => {
  const headingNumbering = selectedSubHeadingNumber.find(
    (s) => s.subSectionId == parentSubsection.id
  )?.number;
  if (headingNumbering) return headingNumbering;

  return "";
};

const paragraphCounts = (
  paramChildsOfParents: any,
  subSectionid: number,
  documentMasterState: any
) => {
  let L1 = 0;
  let L2 = 0;
  let L3 = 0;
  let L4 = 0;
  let L5 = 0;
  let L6 = 0;
  for (const current of paramChildsOfParents) {
    if (current.type === "TEXT") {
      if (
        documentMasterState.documentMaster !== undefined &&
        documentMasterState.documentMaster !== null
      ) {
        if (current.heading_Level === 1 && current.isNumberDisplay) {
          L1++;
          L2 = 0;
          L3 = 0;
          L3 = 0;
          L4 = 0;
          L5 = 0;
          L6 = 0;
        } else if (current.heading_Level === 2 && current.isNumberDisplay) {
          L2++;
          L3 = 0;
          L3 = 0;
          L4 = 0;
          L5 = 0;
          L6 = 0;
        } else if (current.heading_Level === 3 && current.isNumberDisplay) {
          L3++;
          L4 = 0;
          L5 = 0;
          L6 = 0;
        } else if (current.heading_Level === 4 && current.isNumberDisplay) {
          L4++;
          L5 = 0;
          L6 = 0;
        } else if (current.heading_Level === 5 && current.isNumberDisplay) {
          L5++;
          L6 = 0;
        } else if (current.heading_Level === 6) {
          L6++;
        }
      }

      if (current.SubsectionID === subSectionid) {
        break;
      }
    }
  }

  return {
    L1Count: L1,
    L2Count: L2,
    L3Count: L3,
    L4Count: L4,
    L5Count: L5,
    L6Count: L6
  };
};

// todo ? optimise text Numebering
export const documentTextNumber = (
  documentTemplate: DocumentTemplate,
  _documentMasterState: any,
  sectionNumbers: SectionInfo[],
  headingNumbers: SubHeadingInfo[],
  sectionMasterState: any
) => {
  try {
    const textElementInfo: TextElementInfo[] = [];
    const headingFormats: any = [];
    const textFormats: any = [];

    let numbering = "";
    const sections = documentTemplate.sections;

    const shouldBreakLoop = false; // Flag variable to control the loop

    if (sections) {
      sections.forEach((section, indexSection) => {
        const documentMasterState = getSectionDocumentMaster(
          section.id,
          sectionMasterState,
          _documentMasterState
        );

        const headingFormats = [
          JSON.parse(documentMasterState.documentMaster.heading1_formatting),
          JSON.parse(documentMasterState.documentMaster.heading2_formatting),
          JSON.parse(documentMasterState.documentMaster.heading3_formatting),
          JSON.parse(documentMasterState.documentMaster.heading4_formatting),
          JSON.parse(documentMasterState.documentMaster.heading5_formatting),
          JSON.parse(documentMasterState.documentMaster.heading6_formatting)
        ];

        const textFormats = [
          JSON.parse(documentMasterState.documentMaster.paragraph1_formatting),
          JSON.parse(documentMasterState.documentMaster.paragraph2_formatting),
          JSON.parse(documentMasterState.documentMaster.paragraph3_formatting),
          JSON.parse(documentMasterState.documentMaster.paragraph4_formatting),
          JSON.parse(documentMasterState.documentMaster.paragraph5_formatting),
          JSON.parse(documentMasterState.documentMaster.paragraph6_formatting)
        ];

        const sectionFormat = headingFormats[0];
        const headingFormat2 = textFormats[1];
        const headingFormat3 = textFormats[2];
        const headingFormat4 = textFormats[3];
        const headingFormat5 = textFormats[4];
        const headingFormat6 = textFormats[5];

        const sectionCount =
          sectionNumbers.find((s) => s.id === section.id)?.count || 0;

        let generalTextcount = 0;
        let countHeadingH2 = 0;
        let countHeadingH3 = 0;
        let countHeadingH4 = 0;
        let countHeadingH5 = 0;
        let countHeadingH6 = 0;

        if (section.subsections) {
          const textSubsections = section.subsections?.filter(
            (subsection) =>
              subsection.elements &&
              subsection.elements[0]?.content_type === ContentType.Text
          );
          (textSubsections || []).forEach(
            (subSection: any, indexSubsection: any) => {
              const parentSubsection = subSection;
              if (!isDeletedSubsection(subSection)) {
                const element: DocumentElement =
                  attachedSubsectionElement(subSection);

                const isDisplay = JSON.parse(
                  attachedSubsectionElement(subSection).content_format
                )?.isNumberDisplay;

                // find parent of current text Element done
                const parentComponentOfCurrent = findParentSubHeading(
                  documentTemplate,
                  section.id,
                  subSection.id,
                  subSection.component_order
                );
                const childsOfParents = findChildSubSections(
                  documentTemplate,
                  section,
                  parentComponentOfCurrent
                );

                if (subSection?.elements?.[0]?.content_type === "TEXT") {
                  generalTextcount++;
                }

                if (
                  element.heading_level === 2 &&
                  subSection?.elements?.[0]?.content_type === "TEXT" &&
                  JSON.parse(
                    attachedSubsectionElement(subSection)?.content_format
                  )?.isNumberDisplay
                ) {
                  countHeadingH2++;
                  countHeadingH3 = 0;
                  countHeadingH4 = 0;
                  countHeadingH5 = 0;
                  countHeadingH6 = 0;
                } else if (
                  attachedSubsectionElement(subSection).heading_level === 3 &&
                  subSection?.elements?.[0]?.content_type ===
                    ContentType.Text &&
                  JSON.parse(
                    attachedSubsectionElement(subSection).content_format
                  )?.isNumberDisplay
                ) {
                  countHeadingH3++;
                  countHeadingH4 = 0;
                  countHeadingH5 = 0;
                  countHeadingH6 = 0;
                } else if (
                  attachedSubsectionElement(subSection).heading_level === 4 &&
                  subSection?.elements?.[0]?.content_type ===
                    ContentType.Text &&
                  JSON.parse(
                    attachedSubsectionElement(subSection).content_format
                  )?.isNumberDisplay
                ) {
                  countHeadingH4++;
                  countHeadingH5 = 0;
                  countHeadingH6 = 0;
                } else if (
                  attachedSubsectionElement(subSection).heading_level === 5 &&
                  subSection?.elements?.[0]?.content_type ===
                    ContentType.Text &&
                  JSON.parse(
                    attachedSubsectionElement(subSection).content_format
                  )?.isNumberDisplay
                ) {
                  countHeadingH5++;
                  countHeadingH6 = 0;
                } else if (
                  attachedSubsectionElement(subSection).heading_level === 6 &&
                  subSection?.elements?.[0]?.content_type ===
                    ContentType.Text &&
                  JSON.parse(
                    attachedSubsectionElement(subSection).content_format
                  )?.isNumberDisplay
                ) {
                  countHeadingH6++;
                }

                if (parentSubsection?.id === subSection.id) {
                  // todo: do here if any parent exist or not
                  if (
                    Object.keys(parentComponentOfCurrent).length === 0 &&
                    attachedSubsectionElement(parentSubsection).heading_level !=
                      1
                  ) {
                    if (
                      attachedSubsectionElement(parentSubsection)
                        .heading_level === 2 &&
                      parentSubsection?.elements?.[0]?.content_type ===
                        ContentType.Text
                    ) {
                      if (headingFormat2.compound === true) {
                        numbering = `${applySeperators(
                          sectionFormat,
                          sectionCount
                        )}${applySeperatorsHeader(
                          headingFormat2,
                          countHeadingH2
                        )}`;
                      } else {
                        numbering = `${applySeperators(
                          headingFormat2,
                          countHeadingH2
                        )}`;
                      }
                      textElementInfo.push({
                        subSectionId: subSection.id,
                        elementId: element.id,
                        number: numbering,
                        level: element.heading_level,
                        parentSectionID: section.id,
                        parentSubsection: parentSubsection,
                        componentOrder: subSection.component_order
                      });
                    } else if (
                      attachedSubsectionElement(parentSubsection)
                        .heading_level === 3 &&
                      parentSubsection?.elements?.[0]?.content_type ===
                        ContentType.Text
                    ) {
                      if (headingFormat3.compound === true) {
                        numbering = `${applySeperators(
                          sectionFormat,
                          sectionCount
                        )}${applySeperatorsHeader(
                          headingFormat2,
                          countHeadingH2
                        )}${applySeperatorsHeader(
                          headingFormat3,
                          countHeadingH3
                        )}`;
                      } else {
                        numbering = `${applySeperators(
                          headingFormat3,
                          countHeadingH3
                        )}`;
                      }
                      textElementInfo.push({
                        subSectionId: subSection.id,
                        elementId: element.id,
                        number: numbering,
                        level: element.heading_level,
                        parentSectionID: section.id,
                        componentOrder: subSection.component_order,
                        parentSubsection: parentSubsection
                      }); // Set the flag to break the loop
                    } else if (
                      attachedSubsectionElement(parentSubsection)
                        .heading_level === 4 &&
                      parentSubsection?.elements?.[0]?.content_type ===
                        ContentType.Text
                    ) {
                      if (headingFormat4.compound === true) {
                        numbering = `${applySeperators(
                          sectionFormat,
                          sectionCount
                        )}${applySeperatorsHeader(
                          headingFormat2,
                          countHeadingH2
                        )}${applySeperatorsHeader(
                          headingFormat3,
                          countHeadingH3
                        )}${applySeperatorsHeader(
                          headingFormat4,
                          countHeadingH4
                        )}`;
                      } else {
                        numbering = `${applySeperators(
                          headingFormat4,
                          countHeadingH4
                        )}`;
                      }
                      textElementInfo.push({
                        subSectionId: subSection.id,
                        elementId: element.id,
                        number: numbering,
                        level: element.heading_level,
                        parentSectionID: section.id,
                        componentOrder: subSection.component_order,
                        parentSubsection: parentSubsection
                      }); // Set the flag to break the loop
                    } else if (
                      attachedSubsectionElement(parentSubsection)
                        .heading_level === 5 &&
                      parentSubsection?.elements?.[0]?.content_type ===
                        ContentType.Text
                    ) {
                      if (headingFormat5.compound === true) {
                        numbering = `${applySeperators(
                          sectionFormat,
                          sectionCount
                        )}${applySeperatorsHeader(
                          headingFormat2,
                          countHeadingH2
                        )}${applySeperatorsHeader(
                          headingFormat3,
                          countHeadingH3
                        )}${applySeperatorsHeader(
                          headingFormat4,
                          countHeadingH4
                        )}${applySeperatorsHeader(
                          headingFormat5,
                          countHeadingH5
                        )}`;
                      } else {
                        numbering = `${applySeperators(
                          headingFormat5,
                          countHeadingH5
                        )}`;
                      }
                      textElementInfo.push({
                        subSectionId: subSection.id,
                        elementId: element.id,
                        number: numbering,
                        level: element.heading_level,
                        parentSectionID: section.id,
                        componentOrder: subSection.component_order,
                        parentSubsection: parentSubsection
                      }); // Set the flag to break the loop
                    } else if (
                      attachedSubsectionElement(parentSubsection)
                        .heading_level === 6 &&
                      parentSubsection.elements?.[0]?.content_type ===
                        ContentType.Text
                    ) {
                      if (headingFormat6.compound === true) {
                        numbering = `${applySeperators(
                          sectionFormat,
                          sectionCount
                        )}${applySeperatorsHeader(
                          headingFormat2,
                          countHeadingH2
                        )}${applySeperatorsHeader(
                          headingFormat3,
                          countHeadingH3
                        )}${applySeperatorsHeader(
                          headingFormat4,
                          countHeadingH4
                        )}${applySeperatorsHeader(
                          headingFormat5,
                          countHeadingH5
                        )}${applySeperatorsHeader(
                          headingFormat6,
                          countHeadingH6
                        )}`;
                      } else {
                        numbering = `${applySeperators(
                          headingFormat6,
                          countHeadingH6
                        )}`;
                      }
                      textElementInfo.push({
                        subSectionId: subSection.id,
                        elementId: element.id,
                        number: numbering,
                        level: element.heading_level,
                        parentSectionID: section.id,
                        componentOrder: subSection.component_order,
                        parentSubsection: parentSubsection
                      }); // Set the flag to break the loop
                    }
                  } else if (
                    attachedSubsectionElement(parentComponentOfCurrent)
                      .heading_level === 2
                  ) {
                    if (
                      attachedSubsectionElement(parentSubsection)
                        .heading_level === 2 &&
                      parentSubsection?.elements?.[0]?.content_type ===
                        ContentType.Text
                    ) {
                      const textCout = paragraphCounts(
                        childsOfParents,
                        parentSubsection?.id,
                        documentMasterState
                      );

                      if (headingFormat2.compound === true) {
                        numbering = `${findSubHeadingNumbering(
                          parentComponentOfCurrent,
                          headingNumbers
                        )}${applySeperatorsHeader(
                          headingFormat2,
                          textCout?.L2Count
                        )}`;
                      } else {
                        numbering = `${applySeperators(
                          headingFormat2,
                          textCout?.L2Count
                        )}`;
                      }
                      textElementInfo.push({
                        subSectionId: subSection.id,
                        elementId: element.id,
                        number: numbering,
                        level: element.heading_level,
                        parentSectionID: section.id,
                        componentOrder: subSection.component_order,
                        parentSubsection: parentSubsection
                      }); // Set the flag to break the loop
                    } else if (
                      attachedSubsectionElement(parentSubsection)
                        .heading_level === 3 &&
                      parentSubsection?.elements?.[0]?.content_type ===
                        ContentType.Text
                    ) {
                      const textCout = paragraphCounts(
                        childsOfParents,
                        parentSubsection?.id,
                        documentMasterState
                      );
                      if (headingFormat3.compound === true) {
                        numbering = `${findSubHeadingNumbering(
                          parentComponentOfCurrent,
                          headingNumbers
                        )}${applySeperatorsHeader(
                          headingFormat3,
                          textCout?.L3Count
                        )}`;
                      } else {
                        numbering = `${applySeperators(
                          headingFormat3,
                          textCout?.L3Count
                        )}`;
                      }
                      textElementInfo.push({
                        subSectionId: subSection.id,
                        elementId: element.id,
                        number: numbering,
                        level: element.heading_level,
                        parentSectionID: section.id,
                        componentOrder: subSection.component_order,
                        parentSubsection: parentSubsection
                      }); // Set the flag to break the loop
                    } else if (
                      attachedSubsectionElement(parentSubsection)
                        .heading_level === 4 &&
                      parentSubsection?.elements?.[0]?.content_type ===
                        ContentType.Text
                    ) {
                      const textCout = paragraphCounts(
                        childsOfParents,
                        parentSubsection?.id,
                        documentMasterState
                      );
                      if (headingFormat4.compound === true) {
                        numbering = `${findSubHeadingNumbering(
                          parentComponentOfCurrent,
                          headingNumbers
                        )}${applySeperatorsHeader(
                          headingFormat3,
                          textCout?.L3Count
                        )}${applySeperatorsHeader(
                          headingFormat4,
                          textCout?.L4Count
                        )}`;
                      } else {
                        numbering = `${applySeperators(
                          headingFormat4,
                          textCout?.L4Count
                        )}`;
                      }
                      textElementInfo.push({
                        subSectionId: subSection.id,
                        elementId: element.id,
                        number: numbering,
                        level: element.heading_level,
                        parentSectionID: section.id,
                        componentOrder: subSection.component_order,
                        parentSubsection: parentSubsection
                      }); // Set the flag to break the loop
                    } else if (
                      attachedSubsectionElement(parentSubsection)
                        .heading_level === 5 &&
                      parentSubsection?.elements?.[0]?.content_type ===
                        ContentType.Text
                    ) {
                      const textCout = paragraphCounts(
                        childsOfParents,
                        parentSubsection?.id,
                        documentMasterState
                      );
                      if (headingFormat5.compound === true) {
                        numbering = `${findSubHeadingNumbering(
                          parentComponentOfCurrent,
                          headingNumbers
                        )}${applySeperatorsHeader(
                          headingFormat3,
                          textCout?.L3Count
                        )}${applySeperatorsHeader(
                          headingFormat4,
                          textCout?.L4Count
                        )}${applySeperatorsHeader(
                          headingFormat5,
                          textCout?.L5Count
                        )}`;
                      } else {
                        numbering = `${applySeperators(
                          headingFormat5,
                          textCout?.L5Count
                        )}`;
                      }
                      textElementInfo.push({
                        subSectionId: subSection.id,
                        elementId: element.id,
                        number: numbering,
                        level: element.heading_level,
                        parentSectionID: section.id,
                        componentOrder: subSection.component_order,
                        parentSubsection: parentSubsection
                      }); // Set the flag to break the loop
                    } else if (
                      attachedSubsectionElement(parentSubsection)
                        .heading_level === 6 &&
                      parentSubsection.elements?.[0]?.content_type ===
                        ContentType.Text
                    ) {
                      const textCout = paragraphCounts(
                        childsOfParents,
                        parentSubsection?.id,
                        documentMasterState
                      );
                      if (headingFormat6.compound === true) {
                        numbering = `${findSubHeadingNumbering(
                          parentComponentOfCurrent,
                          headingNumbers
                        )}${applySeperatorsHeader(
                          headingFormat3,
                          textCout?.L3Count
                        )}${applySeperatorsHeader(
                          headingFormat4,
                          textCout?.L4Count
                        )}${applySeperatorsHeader(
                          headingFormat5,
                          textCout?.L5Count
                        )}${applySeperatorsHeader(
                          headingFormat6,
                          textCout?.L6Count
                        )}`;
                      } else {
                        numbering = `${applySeperators(
                          headingFormat6,
                          textCout?.L6Count
                        )}`;
                      }
                      textElementInfo.push({
                        subSectionId: subSection.id,
                        elementId: element.id,
                        number: numbering,
                        level: element.heading_level,
                        parentSectionID: section.id,
                        componentOrder: subSection.component_order,
                        parentSubsection: parentSubsection
                      }); // Set the flag to break the loop
                    }
                  } else if (
                    attachedSubsectionElement(parentComponentOfCurrent)
                      .heading_level === 3
                  ) {
                    if (
                      attachedSubsectionElement(parentSubsection)
                        .heading_level === 4 &&
                      parentSubsection?.elements?.[0]?.content_type ===
                        ContentType.Text
                    ) {
                      const textCout = paragraphCounts(
                        childsOfParents,
                        parentSubsection?.id,
                        documentMasterState
                      );
                      if (headingFormat4.compound === true) {
                        numbering = `${findSubHeadingNumbering(
                          parentComponentOfCurrent,
                          headingNumbers
                        )}${applySeperatorsHeader(
                          headingFormat4,
                          textCout?.L4Count
                        )}`;
                      } else {
                        numbering = `${applySeperators(
                          headingFormat4,
                          textCout?.L4Count
                        )}`;
                      }
                      textElementInfo.push({
                        subSectionId: subSection.id,
                        elementId: element.id,
                        number: numbering,
                        level: element.heading_level,
                        parentSectionID: section.id,
                        componentOrder: subSection.component_order,
                        parentSubsection: parentSubsection
                      }); // Set the flag to break the loop
                    } else if (
                      attachedSubsectionElement(parentSubsection)
                        .heading_level === 5 &&
                      parentSubsection?.elements?.[0]?.content_type ===
                        ContentType.Text
                    ) {
                      const textCout = paragraphCounts(
                        childsOfParents,
                        parentSubsection?.id,
                        documentMasterState
                      );
                      if (headingFormat5.compound === true) {
                        numbering = `${findSubHeadingNumbering(
                          parentComponentOfCurrent,
                          headingNumbers
                        )}${applySeperatorsHeader(
                          headingFormat4,
                          textCout?.L4Count
                        )}${applySeperatorsHeader(
                          headingFormat5,
                          textCout?.L5Count
                        )}`;
                      } else {
                        numbering = `${applySeperators(
                          headingFormat5,
                          textCout?.L5Count
                        )}`;
                      }
                      textElementInfo.push({
                        subSectionId: subSection.id,
                        elementId: element.id,
                        number: numbering,
                        level: element.heading_level,
                        parentSectionID: section.id,
                        componentOrder: subSection.component_order,
                        parentSubsection: parentSubsection
                      }); // Set the flag to break the loop
                    } else if (
                      attachedSubsectionElement(parentSubsection)
                        .heading_level === 6 &&
                      parentSubsection.elements?.[0]?.content_type ===
                        ContentType.Text
                    ) {
                      const textCout = paragraphCounts(
                        childsOfParents,
                        parentSubsection?.id,
                        documentMasterState
                      );
                      if (headingFormat6.compound === true) {
                        numbering = `${findSubHeadingNumbering(
                          parentComponentOfCurrent,
                          headingNumbers
                        )}${applySeperatorsHeader(
                          headingFormat4,
                          textCout?.L4Count
                        )}${applySeperatorsHeader(
                          headingFormat5,
                          textCout?.L5Count
                        )}${applySeperatorsHeader(
                          headingFormat6,
                          textCout?.L6Count
                        )}`;
                      } else {
                        numbering = `${applySeperators(
                          headingFormat6,
                          textCout?.L6Count
                        )}`;
                      }
                      textElementInfo.push({
                        subSectionId: subSection.id,
                        elementId: element.id,
                        number: numbering,
                        level: element.heading_level,
                        parentSectionID: section.id,
                        componentOrder: subSection.component_order,
                        parentSubsection: parentSubsection
                      }); // Set the flag to break the loop
                    }
                  } else if (
                    attachedSubsectionElement(parentComponentOfCurrent)
                      .heading_level === 4
                  ) {
                    if (
                      attachedSubsectionElement(parentSubsection)
                        .heading_level === 5 &&
                      parentSubsection?.elements?.[0]?.content_type ===
                        ContentType.Text
                    ) {
                      const textCout = paragraphCounts(
                        childsOfParents,
                        parentSubsection?.id,
                        documentMasterState
                      );
                      if (headingFormat5.compound === true) {
                        numbering = `${findSubHeadingNumbering(
                          parentComponentOfCurrent,
                          headingNumbers
                        )}${applySeperatorsHeader(
                          headingFormat5,
                          textCout?.L5Count
                        )}`;
                      } else {
                        numbering = `${applySeperators(
                          headingFormat5,
                          textCout?.L5Count
                        )}`;
                      }
                      textElementInfo.push({
                        subSectionId: subSection.id,
                        elementId: element.id,
                        number: numbering,
                        level: element.heading_level,
                        parentSectionID: section.id,
                        componentOrder: subSection.component_order,
                        parentSubsection: parentSubsection
                      }); // Set the flag to break the loop
                    } else if (
                      attachedSubsectionElement(parentSubsection)
                        .heading_level === 6 &&
                      parentSubsection.elements?.[0]?.content_type ===
                        ContentType.Text
                    ) {
                      const textCout = paragraphCounts(
                        childsOfParents,
                        parentSubsection?.id,
                        documentMasterState
                      );
                      if (headingFormat6.compound === true) {
                        numbering = `${findSubHeadingNumbering(
                          parentComponentOfCurrent,
                          headingNumbers
                        )}${applySeperatorsHeader(
                          headingFormat5,
                          textCout?.L5Count
                        )}${applySeperatorsHeader(
                          headingFormat6,
                          textCout?.L6Count
                        )}`;
                      } else {
                        numbering = `${applySeperators(
                          headingFormat6,
                          textCout?.L6Count
                        )}`;
                      }
                      textElementInfo.push({
                        subSectionId: subSection.id,
                        elementId: element.id,
                        number: numbering,
                        level: element.heading_level,
                        parentSectionID: section.id,
                        componentOrder: subSection.component_order,
                        parentSubsection: parentSubsection
                      }); // Set the flag to break the loop
                    }
                  } else if (
                    attachedSubsectionElement(parentComponentOfCurrent)
                      .heading_level === 5
                  ) {
                    if (
                      attachedSubsectionElement(parentSubsection)
                        .heading_level === 6 &&
                      parentSubsection.elements?.[0]?.content_type ===
                        ContentType.Text
                    ) {
                      const textCout = paragraphCounts(
                        childsOfParents,
                        parentSubsection?.id,
                        documentMasterState
                      );

                      if (headingFormat6.compound === true) {
                        numbering = `${findSubHeadingNumbering(
                          parentComponentOfCurrent,
                          headingNumbers
                        )}${applySeperatorsHeader(
                          headingFormat6,
                          textCout?.L6Count
                        )}`;
                      } else {
                        numbering = `${applySeperators(
                          headingFormat6,
                          textCout?.L6Count
                        )}`;
                      }
                      textElementInfo.push({
                        subSectionId: subSection.id,
                        elementId: element.id,
                        number: numbering,
                        level: element.heading_level,
                        parentSectionID: section.id,
                        componentOrder: subSection.component_order,
                        parentSubsection: parentSubsection
                      }); // Set the flag to break the loop
                    }
                  } else if (
                    attachedSubsectionElement(parentComponentOfCurrent)
                      .heading_level === 6
                  ) {
                    if (
                      attachedSubsectionElement(parentSubsection)
                        .heading_level === 6 &&
                      parentSubsection.elements?.[0]?.content_type ===
                        ContentType.Text
                    ) {
                      const childsOfH6 = findChildSubSectionsOfSubHeadingH6(
                        documentTemplate,
                        section,
                        parentComponentOfCurrent
                      );

                      const textCout = paragraphCounts(
                        childsOfH6,
                        parentSubsection.id,
                        documentMasterState
                      );

                      if (headingFormat6.compound === true) {
                        numbering = `${findSubHeadingNumbering(
                          parentComponentOfCurrent,
                          headingNumbers
                        )}${applySeperatorsHeader(
                          headingFormat6,
                          textCout?.L6Count
                        )}`;
                      } else {
                        numbering = `${applySeperators(
                          headingFormat6,
                          textCout?.L6Count
                        )}`;
                      }
                      textElementInfo.push({
                        subSectionId: subSection.id,
                        elementId: element.id,
                        number: numbering,
                        level: element.heading_level,
                        parentSectionID: section.id,
                        componentOrder: subSection.component_order,
                        parentSubsection: parentSubsection
                      }); // Set the flag to break the loop
                    }
                  }
                }
              }
            }
          );
        }
        if (shouldBreakLoop) {
          return; // Exit the forEach loop if the flag is set
        }
      });
    }
    return textElementInfo;
  } catch (error) {
    console.warn(
      "An error occurred while calculating paragraph numbering:",
      error
    );
    // return "Error: Unable to calculate numbering"; // Return a fallback value for display
    return [];
  }
};
