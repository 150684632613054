import React, { Component, useState } from "react";
import Box from "@mui/material/Box";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import SpaceBarIcon from "@mui/icons-material/SpaceBar";
import DialogActions from "@mui/material/DialogActions";
import InputLabel from "@mui/material/InputLabel";
import DialogTitle from "@mui/material/DialogTitle";
import ExpandRoundedIcon from "@mui/icons-material/ExpandRounded";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import {
  DocumentElement,
  DocumentSection,
  DocumentSectionSummary,
  DocumentSubsection,
  DocumentTemplate,
  DocumentTemplateSummary,
} from "../../../types/DocumentTemplate";

import DeleteIcon from "@mui/icons-material/Delete";
import UndoIcon from "@mui/icons-material/Undo";

import useActions, { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  clearSelectedComponent,
  selectApprovalState,
  selectSelected,
  setSelectedComponent,
} from "../editorSlice";
import Divider from "@mui/material/Divider";
import { ViewPreferences } from "../header/preferencesSlice";
import Typography from "@mui/material/Typography";
import { useDrop } from "react-dnd";
import { DebugID } from "./DebugID";
import { DropSkeleton } from "./DropSkeleton";
import {
  Dialog,
  Grid,
  IconButton,
  InputAdornment,
  Tooltip,
} from "@mui/material";
import { Approvals } from "./Approvals";
import { SpaceBarRounded } from "@mui/icons-material";
import LibraryContentConfigFormSubSection from "./libraryContentSubSection/LibraryContentFormSubSection";
import SettingsIcon from "@mui/icons-material/Settings";
import { secondarySilver } from "../../../globals";
import EditorUndoForm from "./CustomEditorDecorators/EditorUndoForm";
import SwitchSubsection from "./CustomEditorDecorators/SwitchSubSection";
import LibraryContentIcon from "./CustomEditorDecorators/LibraryContentIcon";
import { isIncludeImpact } from "../drawers/right/Approval/ApprovalHelperFunctions/approvalHelper";
import { COLORS } from "../../shared/Constants";
import DeleteUnsharedConfirm from "./CustomEditorDecorators/DeleteUnsharedConfirm";
/**
 * Spacer
 *
 * @export
 * @param {({
 *   element: DocumentElement | null;
 *   parentSubsection: DocumentSubsection | null;
 *   parentSection: DocumentSection;
 *   documentTemplate: DocumentTemplate;
 *   organizationId: number;
 *   workspaceId: number;
 *   viewPreferences: ViewPreferences;
 * })} props
 * @return {*}
 */
export function SpacerElementEditor(props: {
  element: DocumentElement | null;
  parentSubsection: DocumentSubsection | null;
  parentSection: DocumentSection | DocumentSectionSummary;
  parentSection__: DocumentSection | null;
  documentTemplate: DocumentTemplate | DocumentTemplateSummary;
  organizationId: number;
  workspaceId: number;
  viewPreferences: ViewPreferences;
  readOnly?: boolean;
  approvalsEnabled: boolean;
}) {
  const [allow, setallow] = useState(false);
  const approvalState = useAppSelector(selectApprovalState);
  const [IsLibraryOpen, SetIsLibarayOpen] = useState<boolean>(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const actions = useActions();
  const [isDeleteUnsharedOpen, setIsDeleteUnsharedOpen] =
  useState<boolean>(false);
  const startElement = React.useMemo(() => {
    return JSON.parse(props.element!.content_format);
  }, []);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [breakHeight, setBreakHeight] = React.useState<string>(
    startElement.height
  );

  const numericBreakHeight = parseFloat(breakHeight);
  function handleDialogOpen() {
    setDialogOpen(true);
  }
  function handleDialogClose() {
    const newParms = JSON.stringify({
      height: breakHeight,
    });
    actions.updateDocumentElement({
      section: props.parentSection as DocumentSection,
      subSection: props.parentSubsection as DocumentSubsection,
      documentElement: {
        ...props.element,
        content_format: newParms,
      } as DocumentElement,
    });
    setDialogOpen(false);
  }

  const handleHeight = (event: any) => {
    let inputValue = event.target.value;
    // Remove non-digit characters
    inputValue = inputValue.replace(/\D/g, "");

    // If the value is empty, set it to zero
    if (inputValue === "") {
      inputValue = "0";
    }
    // Remove leading zeros
    inputValue = inputValue.replace(/^0+/, "");

    setBreakHeight(inputValue);

    const newParms = JSON.stringify({
      // height: breakHeight,
      height: inputValue,
    });
    actions.updateDocumentElement({
      section: props.parentSection as DocumentSection,
      subSection: props.parentSubsection as DocumentSubsection,
      documentElement: {
        ...props.element,
        content_format: newParms,
      } as DocumentElement,
    });
  };

  // Only allow drag+drop if this is not read only.
  const [{ isOver }, drop] = props.readOnly
    ? [{ isOver: null }, null]
    : useDrop(() => ({
        accept: "right-drawer-item",
        drop: (dropItem: any) => addElementToSection({ ...dropItem.element }),
        collect: (monitor) => ({
          isOver: !!monitor.isOver(),
          canDrop: !!monitor.canDrop(),
        }),
      }));
  function addElementToSection(element: DocumentElement) {
    if (element.content_type === "LIBRARYCONTENTSUBSECTION") {
      SetIsLibarayOpen(true);
    } else {
      actions.createSubsectionWithElement({
        section: props.parentSection as DocumentSection,
        documentElement: element,
        subsectionAbove: props.parentSubsection!,
      });
    }
  }

  /**
   * When the component is focused, update the state.
   * @param {*} e
   * @return {*}  {*}
   */

  function handleOnFocus(e: any): any {
    setIsFocused(true)
    dispatch(
      setSelectedComponent({
        selectedSection: props.parentSection as DocumentSection,
        selectedSubsection: props.parentSubsection,
        selectedComponent: props.element,
      })
    );
  }
  /**
   * Similarly track when focus is lost.
   * @param {*} e
   */
  function handleOnBlur(e: any) {
    setIsFocused(false)
    dispatch(clearSelectedComponent());
  }
  
  function handleDeleteClicked(e: any) {

    setIsDeleteUnsharedOpen(true)
    // actions.deleteSubsection({
    //   section: props.parentSection as DocumentSection,
    //   subSection: props.parentSubsection as DocumentSubsection,
    // });
    // dispatch(clearSelectedComponent());
  }
  const isDeleted = props.parentSubsection?.pending_deleted_at != null;

  const isSelected = isFocused
    // props.element === null || selected.documentElement === null
    //   ? false
    //   : selected.documentElement!.id == props.element!.id;

  function border(): any {
if (props.parentSubsection &&
      props.approvalsEnabled &&
      isIncludeImpact(approvalState, props.parentSubsection.id)
    ) {
      return {
        border: 2.5,
        borderColor: " #00C482 !important",
        borderStyle: isSelected ? "solid" : "dashed",
      };
    }
    
    if (
      (props.parentSection.pending_deleted_at != null || isDeleted) &&
      props.approvalsEnabled
    ) {
      return {
        border: COLORS.BORDER_SIZE,
        borderRadius: 0,
        borderStyle: isSelected ? "solid" : "dashed",
        borderColor: COLORS.NOMIA_RED,
      };
    }

    // if (props.element !== null && props.element.element_approvals) {
    //   return {
    //     border:props.approvalsEnabled? COLORS.BORDER_SIZE:1,
    //     borderColor: props.approvalsEnabled
    //       ? "orange !important"
    //       : secondarySilver,
    //     borderStyle: isSelected ? "solid" : "dashed",
    //   };
    // }

    if (props.viewPreferences.showSections) {
      return {
        borderRadius: 0,
        border: isSelected
          ? `1px solid ${secondarySilver}`
          : `1px dashed ${secondarySilver}`, // Add border property
        borderColor: secondarySilver, // Add border color property
      };
    }
    return {};
  }

  return (
    <>
       <ClickAwayListener  onClickAway={(e)=>{
    if(props.approvalsEnabled){
      handleOnBlur(e)
    }
 }}>
      <Box
        id={ `subsection-${props.parentSubsection!.id}`
        }
        ref={drop}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        onClick={ (e)=>{if(props.approvalsEnabled){
          handleOnFocus(e)
        }}}
        sx={Object.assign(
          {
            padding: 1,
            margin: 1,
            mx: 1,
            backgroundColor:  isSelected  ? "secondarySilver":"white",
          },
          border()
          
        )}
        alignItems="center"
        justifyContent="center"
      >
        {/* <Approvals
          approvalsEnabled={props.approvalsEnabled}
          element={props.element}
          parentSubsection={props.parentSubsection}
          parentSection={props.parentSection__}
        /> */}
        {props.viewPreferences.showIDs ? (
          <DebugID
            name="Element"
            id={props.element !== null ? props.element.id : -1}
            componentOrder={0}
          />
        ) : null}
        <Box
          sx={{
            position: "relative",
            zIndex: 99,
          }}
        >
                 <SwitchSubsection
          parentSection={props.parentSection as DocumentSection}
          parentSubsection={props.parentSubsection as DocumentSubsection}
          direction={"up"}
          isDisabled={false}
          element={props.element as DocumentElement}
          style={{
            paddingBottom:0,
          }}
          readonly={props.readOnly}
        />
          <LibraryContentIcon
            isHeading={false}
            parentSection={props.parentSection as DocumentSection}
            parentSubsection={props.parentSubsection as DocumentSubsection}
            documentTemplate={props.documentTemplate as DocumentTemplate}
            style={{
              outline: "none",
              color: "#7284A3",
              position: "absolute",
              top: 6,
              right: 52,
              transition: "none", // Remove transition on button press
              margin: 0,
              padding: 0,
              width: "fit-content",
            }}
          />

          {props.approvalsEnabled && isDeleted && (
            <IconButton
              onMouseDown={(e) => e.preventDefault()}
              onClick={(e) => {
                e.preventDefault();
                setIsDeleteOpen(true);
                e.stopPropagation(); // Add this line to stop event propagation
              }}
              title="Undo Deletion"
              style={{
                position: "absolute",
                top: 6,
                right: 3,
                transition: "none", // Remove transition on button press
                margin: 0,
                padding: 0,
                width: "fit-content",
                zIndex: 99,
              }}
            >
              <UndoIcon
                style={{ fontSize: 20, outline: "none", color: "#7284A3" }}
              />
            </IconButton>
          )}

          {!props.approvalsEnabled && !props.readOnly && (
            <>
              <IconButton
                style={{
                  position: "absolute",
                  top: 6,
                  right: 25,
                  transition: "none", // Remove transition on button press
                  margin: 0,
                  padding: 0,
                  width: "fit-content",
                }}
                onMouseDown={(e) => e.preventDefault()}
                onClick={handleDeleteClicked}
              >
                <DeleteIcon
                  style={{
                    fontSize: 20,
                    outline: "none",
                    color: "#7284A3",
                  }}
                />
              </IconButton>

              <Tooltip title="Spacer Settings" placement="top">
                <IconButton
                  onMouseDown={(e) => e.preventDefault()}
                  onClick={handleDialogOpen}
                  style={{
                    position: "absolute",
                    top: 6,
                    right: 3,
                    transition: "none", // Remove transition on button press
                    margin: 0,
                    padding: 0,
                    width: "fit-content",
                    zIndex: 99,
                  }}
                >
                  <SettingsIcon
                    style={{ fontSize: 20, outline: "none", color: "#7284A3" }}
                  />
                </IconButton>
              </Tooltip>
            </>
          )}
        </Box>
        <Box
          sx={{
            mx: 14,
            paddingTop: (numericBreakHeight * (96 / 25.4)) / 2 + "px",
          }}
        >
          <Box display="flex" alignItems="center" justifyContent="center">
            <Grid container spacing={1}>
              <Grid item xs={12} md={4}>
                <div
                  style={{
                    textAlign: "right",
                    paddingTop: "3vh",
                    paddingRight: "1vh",
                    fontSize: "18px",
                    fontWeight: "bold",
                    paddingBottom: "0px",
                  }}
                >
                  Spacer
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                
                  margin="dense"
                  id="height"
                  variant="filled"
                  value={breakHeight}
                 disabled={props.readOnly}
                  placeholder="Provide Height"
                  onChange={handleHeight}
                  sx={{
                    backgroundColor: "rgba(255, 255, 255, 0.8)", // Transparent white
                    width: "25vh",
                    display: "flex", // Ensures content alignment
                  }}
                  inputProps={{
                    style: {
                      textAlign: "center",
                      padding: "16px 16px",
                      fontSize: "16px",
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        style={{ alignSelf: "center" }}
                      >
                        mm
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Box>

      

          {/* <Divider
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <SpaceBarIcon
              onClick={handleDialogOpen}
              fontSize="large"
              color="primary"
            />
          </Divider>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "16px",
            }}
          >
            <Button variant="outlined" size="small" onClick={handleDialogOpen}>
              Adjust Spacer 
            </Button>

            
          </Box> */}
        </Box>
        <SwitchSubsection
            parentSection={props.parentSection as DocumentSection}
            parentSubsection={props.parentSubsection as DocumentSubsection}
            direction={"down"}
            isDisabled={false}
            element={props.element as DocumentElement}
            style={{
              margin:0,
              padding:0,
              paddingTop:4,
            }}
            readonly={props.readOnly}
          />
      </Box>

      </ClickAwayListener>
      {/* The drag+drop element showing that you are going to drop something here. */}
      {isOver ? <DropSkeleton /> : null}

      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        {/* <ExpandRoundedIcon/>   */}
        <DialogTitle style={{ paddingRight: "-3px", fontWeight: "bold" }}>
          {" "}
          Spacer{" "}
        </DialogTitle>

        <Box sx={{ px: 2, m: 0 }} style={{ paddingTop: "0px" }}>
          <InputLabel>Enter Spacer Height </InputLabel>
          <TextField
            autoFocus
            margin="dense"
            id="height"
            label="Height *(mm)"
            fullWidth
            variant="filled"
            value={breakHeight}
            placeholder="Provide a Spacer Height"
            onChange={handleHeight}
          />
        </Box>

        <DialogActions sx={{ justifyContent: "center" }}>
          <Button variant="outlined" onClick={() => setDialogOpen(false)}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleDialogClose}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
      {IsLibraryOpen && (
        <LibraryContentConfigFormSubSection
          closeDialogHandler={() => SetIsLibarayOpen(false)}
          organizationId={props.organizationId}
          workspaceId={props.workspaceId}
          parentSection={props.parentSection as DocumentSection}
          parentSubsection={props.parentSubsection as DocumentSubsection}
          viewPreferences={props.viewPreferences}
        />
      )}

{isDeleteUnsharedOpen === true ? (
        <DeleteUnsharedConfirm
          isHeading={false}
          parentSection={props.parentSection as DocumentSection}
          parentSubsection={props.parentSubsection as DocumentSubsection}
          closeDialogHandler={() => setIsDeleteUnsharedOpen(false)}
        />
      ) : null} 
      
      {isDeleteOpen === true ? (
        <EditorUndoForm
          isHeading={false}
          parentSection={props.parentSection as DocumentSection}
          parentSubsection={props.parentSubsection as DocumentSubsection}
          closeDialogHandler={() => setIsDeleteOpen(false)}
        />
      ) : null}
    </>
  );
}
