// spreadsheetUtils.ts

import { getAlignment, getLatexAlignment } from "../HelperFuctions/TableUtils";
import { constructInitialCells } from "@gridsheet/react-core";
export interface CellFormatting {
  style: CellStyle;
}

export interface SelectedCell {
  row: number;
  col: number;
  style: CellStyle;
}

export const initialSelect = {
  row: 1,
  col: 1,
  points: {
    pointing: {
      y: 1,
      x: 1,
    },
    selectingFrom: {
      y: -1,
      x: -1,
    },
    selectingTo: {
      y: -1,
      x: -1,
    },
  },
  style: {
    alignment: "left",
    fontSize: "12",
    backgroundColor: "#ffffff",
    color: "#333333",
    textDecoration: "normal",
    fontWeight: "normal",
    fontStyle: "normal",
  },
};

interface Coordinates {
  x: number;
  y: number;
}

interface Selection {
  pointing: Coordinates;
  selectingFrom: Coordinates;
  selectingTo: Coordinates;
}

export function iterateSelectedRange(
  selection: Selection,
  callback: (x: number, y: number) => void
) {
  const { selectingFrom, selectingTo } = selection;

  const startX = Math.min(selectingFrom.x, selectingTo.x);
  const endX = Math.max(selectingFrom.x, selectingTo.x);
  const startY = Math.min(selectingFrom.y, selectingTo.y);
  const endY = Math.max(selectingFrom.y, selectingTo.y);

  for (let y = startY; y <= endY; y++) {
    for (let x = startX; x <= endX; x++) {
      callback(x, y);
    }
  }
}

export interface CellStyle {
  backgroundColor: string;
  textColor: string;
  textAlign: string;
  fontSize: string;
  font?: string;
  columnWidth?: string;
  borderColor?: string;
  editorColumnWidth?: string;
  editorRowHeight?: string;
  fontWeight?: string;
  fontStyle?: string;
  textDecoration?: string;
  alignment?: string;
}

export interface CellDimensions {
  width: number;
  height: number;
}

export const convertToInitialCells = (
  content: string[][],
  formatting: CellFormatting[][],
  borderColor: string,
  borderStyle: string,
  fontFamily: string
) => {
  const cells: { [key: string]: any } = {};

  for (let row = 0; row < content.length; row++) {
    for (let col = 0; col < content[row].length; col++) {
      const cellValue = content[row][col];

      const cellStyle = (formatting[row] &&
        formatting[row][col] &&
        formatting[row][col].style) || {
        backgroundColor: "#b41919",
        textColor: "000000",
        textAlign: "left",
        fontSize: "12",
        editorColumnWidth: 45,
        editorRowHeight: 20,
        borderColor: "#" + borderColor,
        textDecoration: "normal",
        fontWeight: "normal",
        fontStyle: "normal",
      };

      const cellKey = String.fromCharCode(65 + col) + (row + 1); // Generate cell key like A1, B1, etc.
      // console.log("CELL KEY", cellStyle);
      cells[cellKey] = {
        value: cellValue,
        width: 400,
        height: 3000,
        alignItems: "center",
        justifyContent: getAlignment(cellStyle.textAlign),
        style: {
          color: `#${cellStyle.textColor}`,
          textAlign: getAlignment(cellStyle.textAlign),
          backgroundColor: `#${cellStyle.backgroundColor}`,
          fontSize: `${cellStyle.fontSize}pt`,
          border: `1px ${borderStyle} #${cellStyle.borderColor}`,
          textDecoration: cellStyle.textDecoration,
          fontWeight: cellStyle.fontWeight,
          fontStyle: cellStyle.fontStyle,
          overflow: "hidden",
          whiteSpace: "normal",
          fontFamily,
          borderTop: `${borderStyle} 1px #${borderColor}`,
          borderLeft: `${borderStyle} 1px #${borderColor}`,
          borderRight: `${borderStyle} 1px #${borderColor}`,
          borderBottom: `${borderStyle} 1px #${borderColor}`,
        },
      };
    }
  }

  return constructInitialCells({ cells });
};

export const separateContentAndFormatting = (
  matrix: any,
  dimensions: CellDimensions[][],
  previousFormatting: any[][]
): { content: string[][]; updatdformatting: CellStyle[][] } => {
  const content: string[][] = [];
  const updatdformatting: CellStyle[][] = [];

  let totalWidth = 0;
  for (let i = 0; i < previousFormatting[0].length; i++) {
    totalWidth += parseInt(previousFormatting[0][i].style.editorColumnWidth);
  }

  for (let i = 0; i < matrix.length; i++) {
    const row = matrix[i];
    const rowContent: string[] = [];
    const rowFormatting: any[] = [];

    for (let j = 0; j < row.length; j++) {
      const cell = row[j];
      const cellDimensions = dimensions[i][j]; // Get the dimensions for the current cell

      // Safely check if previousFormatting[i][j] and style exist
      const previousStyle = previousFormatting[i]?.[j]?.style ?? {};

      rowContent.push(cell.value);
      rowFormatting.push({
        style: {
          backgroundColor: cell.style.backgroundColor?.slice(1),
          textColor: cell.style.color?.slice(1),
          textAlign: getLatexAlignment(cell.justifyContent),
          fontSize: cell.style.fontSize?.slice(0, -2), // Remove the 'pt' from the end
          font: cell.style.font,
          columnWidth: previousStyle.columnWidth ?? "none", // Safely access columnWidth
          editorColumnWidth: cellDimensions.width.toString(),
          editorTotalWidth: totalWidth,
          editorRowHeight: cellDimensions.height.toString(), // Set the row height from dimensions
          borderColor:
            cell.style.border?.match(/#[0-9a-fA-F]{6}/)?.[0]?.slice(1) ||
            "000000",
          textDecoration: cell.style.textDecoration,
          fontWeight: cell.style.fontWeight,
          fontStyle: cell.style.fontStyle,
          isBold: cell.style.fontWeight === "bold",
          isItalic: cell.style.fontStyle === "italic",
          isUnderline: cell.style.textDecoration === "underline",
        },
      });
    }
    content.push(rowContent);
    updatdformatting.push(rowFormatting);
  }

  return { content, updatdformatting };
};

export function createDimensionsMatrixFromMatrix(
  matrix: any
): CellDimensions[][] {
  const rowHeights: number[] = [];
  const columnWidths: number[] = [];

  // Extract row heights from each row
  for (let i = 0; i < matrix.length; i++) {
    for (const key in matrix[i]) {
      const cell = matrix[i][key];
      if (i === 0 && cell.height !== undefined) {
        rowHeights.push(cell.height);
      }
      if (cell.width !== undefined && key.match(/^[A-Z]$/)) {
        columnWidths.push(cell.width);
      }
    }
  }

  // Initialize a matrix with dimensions
  const dimensionsMatrix: CellDimensions[][] = rowHeights.map(() =>
    columnWidths.map(() => ({ width: 0, height: 0 }))
  );

  // Fill the matrix with the corresponding dimensions
  for (let i = 0; i < rowHeights.length; i++) {
    for (let j = 0; j < columnWidths.length; j++) {
      dimensionsMatrix[i][j] = {
        width: columnWidths[j],
        height: rowHeights[i],
      };
    }
  }

  return dimensionsMatrix;
}

export const updateCell = (
  matrix: any[][],
  rowIndex: number,
  colIndex: number,
  newAlignment: {
    alignItems?: string;
    justifyContent?: string;
  },
  decorators: {
    fontWeight?: string;
    fontStyle?: string;
    textDecoration?: string;
  },

  newBackgroundColor: string,
  newTextColor: string,
  fontSize?: string
) => {
  if (matrix && matrix[rowIndex - 1] && matrix[rowIndex - 1][colIndex - 1]) {
    const cell = matrix[rowIndex - 1][colIndex - 1];
    if (newAlignment.justifyContent !== undefined) {
      cell.justifyContent = newAlignment.justifyContent;
    }
    if (fontSize !== undefined && fontSize !== "") {
      cell.style!.fontSize = `${fontSize}pt`;
    }
    if (decorators.fontStyle != undefined && cell.style!.fontStyle) {
      cell.style!.fontStyle = decorators.fontStyle;
    }
    if (decorators.fontWeight != undefined && cell.style!.fontWeight) {
      cell.style!.fontWeight = decorators.fontWeight;
    }

    if (decorators.textDecoration != undefined && cell.style!.textDecoration) {
      cell.style!.textDecoration = decorators.textDecoration;
    }

    if (newBackgroundColor != "") {
      cell.style!.backgroundColor = newBackgroundColor;
    }
    if (newTextColor != "") {
      cell.style!.color = newTextColor;
    }
  } else {
    console.error(
      `Cell at row ${rowIndex} and column ${colIndex} does not exist.`
    );
  }
};

export const fontSizes = [7, 8, 9, 10, 11, 12, 13, 14, 16, 18, 20];

export const functionsList = [
  // {
  //   name: "=ABS()",
  //   description: "Returns the absolute value of a number.",
  //   example: "=ABS(-5) returns 5",
  // },
  // {
  //   name: "=ACOS()",
  //   description:
  //     "Returns the arccosine (inverse cosine) of a number in radians.",
  //   example: "=ACOS(0.5) returns 1.047197551 (which is π/3 radians)",
  // },
  // {
  //   name: "=ADD()",
  //   description: "Adds two numbers together.",
  //   example: "=A1 + B1 or =ADD(5, 10) returns 15",
  // },
  // {
  //   name: "=AND()",
  //   description:
  //     "Returns TRUE if all of its arguments are TRUE; returns FALSE if one or more arguments are FALSE.",
  //   example:
  //     "=AND(A1 > 0, B1 > 0) returns TRUE if both A1 and B1 are greater than 0",
  // },
  // {
  //   name: "=ASIN()",
  //   description: "Returns the arcsine (inverse sine) of a number in radians.",
  //   example: "=ASIN(0.5) returns 0.523598776 (which is π/6 radians)",
  // },
  // {
  //   name: "=ATAN()",
  //   description:
  //     "Returns the arctangent (inverse tangent) of a number in radians.",
  //   example: "=ATAN(1) returns 0.785398163 (which is π/4 radians)",
  // },
  // {
  //   name: "=ATAN2()",
  //   description: "Returns the arctangent of the quotient of its arguments.",
  //   example: "=ATAN2(1, 1) returns 0.785398163 (which is π/4 radians)",
  // },
  {
    name: "=AVERAGE()",
    description: "Returns the average of the arguments.",
    example: "=AVERAGE(A1:A5) returns the average of values in cells A1 to A5",
  },
  // {
  //   name: "=COL()",
  //   description: "Returns the column number of a reference.",
  //   example: "=COL(A1) returns 1",
  // },
  {
    name: "=CONCAT()",
    description: "Concatenates multiple values into one.",
    example: "=CONCAT(A1, B1) returns the concatenation of values in A1 and B1",
  },
  // {
  //   name: "=CONCATENATE()",
  //   description: "Concatenates multiple values into one.",
  //   example:
  //     "=CONCATENATE(A1, B1) returns the concatenation of values in A1 and B1",
  // },
  // {
  //   name: "=COS()",
  //   description: "Returns the cosine of a given angle in radians.",
  //   example: "=COS(PI()) returns -1",
  // },
  {
    name: "=COUNT()",
    description: "Counts the number of cells that contain numbers.",
    example:
      "=COUNT(A1:A5) returns the number of cells that contain numbers in A1 to A5",
  },
  {
    name: "=COUNTA()",
    description: "Counts the number of non-empty cells.",
    example: "=COUNTA(A1:A5) returns the number of non-empty cells in A1 to A5",
  },
  {
    name: "=COUNTIF()",
    description: "Counts the number of cells that meet a specific condition.",
    example:
      '=COUNTIF(A1:A10, ">5") returns the number of cells in A1:A10 that are greater than 5',
  },
  {
    name: "=DIVIDE()",
    description: "Divides one number by another.",
    example: "=A1 / B1 or =DIVIDE(10, 2) returns 5",
  },
  // {
  //   name: "=EQ()",
  //   description: "Returns TRUE if two values are equal.",
  //   example: "=EQ(A1, B1) returns TRUE if A1 equals B1",
  // },
  // {
  //   name: "=EXP()",
  //   description: "Returns e raised to the power of a given number.",
  //   example: "=EXP(1) returns 2.718281828",
  // },
  // {
  //   name: "=GT()",
  //   description:
  //     "Returns TRUE if the first argument is greater than the second.",
  //   example: "=GT(A1, B1) returns TRUE if A1 is greater than B1",
  // },
  // {
  //   name: "=GTE()",
  //   description:
  //     "Returns TRUE if the first argument is greater than or equal to the second.",
  //   example: "=GTE(A1, B1) returns TRUE if A1 is greater than or equal to B1",
  // },
  // {
  //   name: "=HLOOKUP()",
  //   description:
  //     "Searches for a value in the top row of a table and returns a value in the same column from another row.",
  //   example:
  //     '=HLOOKUP("Product", A1:C10, 2, FALSE) returns the value from the second row that matches "Product" in the top row',
  // },
  // {
  //   name: "=IF()",
  //   description:
  //     "Returns one value if a condition is TRUE and another value if it is FALSE.",
  //   example:
  //     '=IF(A1 > 10, "Over 10", "10 or less") returns "Over 10" if A1 is greater than 10',
  // },
  // {
  //   name: "=IFERROR()",
  //   description:
  //     "Returns a value if an error is found, or the original value if no error is found.",
  //   example: '=IFERROR(A1/B1, "Error") returns "Error" if B1 is 0',
  // },
  {
    name: "=LEN()",
    description: "Returns the number of characters in a text string.",
    example: '=LEN("Hello") returns 5',
  },
  // {
  //   name: "=LENB()",
  //   description:
  //     "Returns the number of bytes used to represent the characters in a text string.",
  //   example: '=LENB("Hello") returns 5',
  // },
  // {
  //   name: "=LN()",
  //   description: "Returns the natural logarithm of a number.",
  //   example: "=LN(2.718281828) returns 1",
  // },
  // {
  //   name: "=LOG()",
  //   description: "Returns the logarithm of a number to a specified base.",
  //   example: "=LOG(100, 10) returns 2",
  // },
  // {
  //   name: "=LOG10()",
  //   description: "Returns the base-10 logarithm of a number.",
  //   example: "=LOG10(100) returns 2",
  // },
  // {
  //   name: "=LT()",
  //   description: "Returns TRUE if the first argument is less than the second.",
  //   example: "=LT(A1, B1) returns TRUE if A1 is less than B1",
  // },
  // {
  //   name: "=LTE()",
  //   description:
  //     "Returns TRUE if the first argument is less than or equal to the second.",
  //   example: "=LTE(A1, B1) returns TRUE if A1 is less than or equal to B1",
  // },
  {
    name: "=MAX()",
    description: "Returns the largest number in a set of values.",
    example: "=MAX(A1:A5) returns the largest number in cells A1 to A5",
  },
  // {
  //   name: "=MIN()",
  //   description: "Returns the smallest number in a set of values.",
  //   example: "=MIN(A1:A5) returns the smallest number in cells A1 to A5",
  // },
  {
    name: "=MINUS()",
    description: "Subtracts the second number from the first number.",
    example: "=A1 - B1 or =MINUS(10, 5) returns 5",
  },
  // {
  //   name: "=MOD()",
  //   description: "Returns the remainder after dividing one number by another.",
  //   example: "=MOD(10, 3) returns 1",
  // },
  {
    name: "=MULTIPLY()",
    description: "Multiplies two numbers.",
    example: "=A1 * B1 or =MULTIPLY(5, 10) returns 50",
  },
  // {
  //   name: "=NE()",
  //   description: "Returns TRUE if two values are not equal.",
  //   example: "=NE(A1, B1) returns TRUE if A1 is not equal to B1",
  // },
  // {
  //   name: "=NOT()",
  //   description: "Reverses the logic of its argument.",
  //   example: "=NOT(A1 > 10) returns TRUE if A1 is not greater than 10",
  // },
  // {
  //   name: "=NOW()",
  //   description: "Returns the current date and time.",
  //   example: "=NOW() returns the current date and time",
  // },
  // {
  //   name: "=OR()",
  //   description:
  //     "Returns TRUE if any argument is TRUE; returns FALSE if all arguments are FALSE.",
  //   example:
  //     "=OR(A1 > 0, B1 > 0) returns TRUE if either A1 or B1 is greater than 0",
  // },
  // {
  //   name: "=PI()",
  //   description: "Returns the value of pi (π).",
  //   example: "=PI() returns 3.14159265358979",
  // },
  {
    name: "=POW()",
    description: "Returns a number raised to a power.",
    example: "=POW(2, 3) returns 8",
  },
  // {
  //   name: "=POWER()",
  //   description: "Returns a number raised to a power.",
  //   example: "=POWER(2, 3) returns 8",
  // },
  // {
  //   name: "=PRODUCT()",
  //   description: "Multiplies all the numbers given as arguments.",
  //   example: "=PRODUCT(A1:A3) returns the product of values in A1 to A3",
  // },
  // {
  //   name: "=RADIANS()",
  //   description: "Converts degrees to radians.",
  //   example: "=RADIANS(180) returns 3.14159265358979 (which is π radians)",
  // },
  // {
  //   name: "=RAND()",
  //   description: "Returns a random number between 0 and 1.",
  //   example: "=RAND() returns a random number between 0 and 1",
  // },
  // {
  //   name: "=ROUND()",
  //   description: "Rounds a number to a specified number of digits.",
  //   example: "=ROUND(3.14159, 2) returns 3.14",
  // },
  // {
  //   name: "=ROUNDDOWN()",
  //   description: "Rounds a number down, towards zero.",
  //   example: "=ROUNDDOWN(3.14159, 2) returns 3.14",
  // },
  // {
  //   name: "=ROUNDUP()",
  //   description: "Rounds a number up, away from zero.",
  //   example: "=ROUNDUP(3.14159, 2) returns 3.15",
  // },
  // {
  //   name: "=ROW()",
  //   description: "Returns the row number of a reference.",
  //   example: "=ROW(A1) returns 1",
  // },
  // {
  //   name: "=SIN()",
  //   description: "Returns the sine of a given angle in radians.",
  //   example: "=SIN(PI()/2) returns 1",
  // },
  // {
  //   name: "=SQRT()",
  //   description: "Returns the square root of a number.",
  //   example: "=SQRT(16) returns 4",
  // },
  {
    name: "=SUM()",
    description: "Adds all the numbers in a range of cells.",
    example: "=SUM(A1:A5) returns the sum of values in cells A1 to A5",
  },
  // {
  //   name: "=SUMIF()",
  //   description: "Adds the cells specified by a given condition.",
  //   example:
  //     '=SUMIF(A1:A10, ">5") returns the sum of cells in A1:A10 that are greater than 5',
  // },
  // {
  //   name: "=TAN()",
  //   description: "Returns the tangent of a given angle in radians.",
  //   example: "=TAN(PI()/4) returns 1",
  // },
  // {
  //   name: "=UMINUS()",
  //   description: "Reverses the sign of a number.",
  //   example: "=UMINUS(5) returns -5",
  // },
  // {
  //   name: "=VLOOKUP()",
  //   description:
  //     "Searches for a value in the first column of a table and returns a value in the same row from another column.",
  //   example:
  //     '=VLOOKUP("Product", A1:C10, 2, FALSE) returns the value from the second column that matches "Product" in the first column',
  // },
];

export const isUserFromShareLink = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const fromShare = urlParams.get("fromShare") === "true";
  return fromShare;
};

export const getCommenterId = (): any => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const commenterId = urlParams.get("commenter_id");
  return commenterId ? commenterId : null;
};
