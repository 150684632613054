import {
  Box,
  Button,
  IconButton,
  Modal,
  Paper,
  Typography,
} from "@mui/material";
import {
  DocumentSection,
  DocumentSectionSummary,
  DocumentTemplate,
} from "../../../../types/DocumentTemplate";
import "./style.css";
import { ViewPreferences } from "../../header/preferencesSlice";
import CloseIcon from "@mui/icons-material/Close";
import FastRewindIcon from "@mui/icons-material/FastRewind";

import { useState } from "react";
import DocumentElementEditor from "../DocumentElementEditor";
import { approveChangedElement } from "../../editorSlice";
import useActions, { useAppDispatch } from "../../../../app/hooks";

const style = {
  iconStyle: {
    marginRight: "2px", // You can adjust the margin as needed
    fontSize: "2.0rem",
    color: "#4C33FF", // You can adjust the color as needed
  },
  buttonStyle: {
    margin: "0 10px", // Adjust the margin as needed
    borderRadius: "3px", // Add some border-radius for rounded buttons
    backgroundColor: "primary.main", // Customize the background color
    // color: "white", // Customize the text color
    cursor: "pointer",
    padding: "5px 10px", // Adjust padding as needed
    width: "80px",
  },
};

export default function SectionVersionControl(props: {
  documentSectionSummary: DocumentSection;
  documentTemplateSummary: DocumentTemplate;
  organizationId: number;
  workspaceId: number;
  viewPreferences: ViewPreferences;
  closeDialogHandler: any;
}) {
  const pendingSection = { ...props.documentSectionSummary };
  const section = { ...props.documentSectionSummary, pending_heading: null };
  const dispatch = useAppDispatch();
  const actions = useActions();
  const [selectedVersion, setSelectedVersion] = useState<number | null>(null);
  
  const handleClose = () => {
    props.closeDialogHandler();
  };

  const handleApprove = () => {
    actions.approveDocumentSection({
      approveSectionID: section.id,
      declinedSectionID: null,
    });
    props.closeDialogHandler();
  };


  const handleDecline = () => {
    actions.approveDocumentSection({
      approveSectionID: null,
      declinedSectionID: section.id,
    });
    props.closeDialogHandler();
  };

  const handleOnClick= () => {
if(selectedVersion===1){
  setSelectedVersion(null)
}else {
  setSelectedVersion(1)
}

  };


  return (
    <>
      <Modal  
        open={true}
        onClose={handleClose}
        className="customModalStyle"
        sx={{ display: "flex", alignItems: "left", justifyContent: "center" }}
      >
        <Paper
          className="paperStyle"
          style={{ maxHeight: "90vh", overflowY: "auto" , width:'40%' }}
        >
          <div className="headerStyle">
            <FastRewindIcon style={style.iconStyle} />
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Version Control
            </Typography>
            <IconButton
              disableRipple
              className="closeButtonStyle"
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div>
            <Box 
             sx={{
              borderColor: "grey !important",
              backgroundColor:"grey !important",
              mb: 1,
              borderRadius: 0.5,
              padding: 0.5,
              border: 1,
              transition: "box-shadow 0.3s ease",
              cursor:"not-allowed"
             
            }}>
      {/* <span style={{fontWeight:"bold" , fontSize:"14px" }}>
          Version: 2
        </span> */}
              <DocumentElementEditor
                key={pendingSection.id + "Pending_"}
                element={null}
                parentSubsection={null}
                parentSection={pendingSection}
                documentTemplate={
                  props.documentTemplateSummary as DocumentTemplate
                }
                organizationId={props.organizationId}
                workspaceId={props.workspaceId}
                isHeading={true}
                viewPreferences={props.viewPreferences}
                forApproval={false}
                approvalsEnabled={false}
                approvalOf={-1}
                readOnly={true}
              />
            </Box>
            <Box 
             sx={{
              borderColor: selectedVersion===1 ?  "green !important" : "#7284A3 !important",
              backgroundColor: selectedVersion===1 ?  "green !important" : "white !important",
              mb: 1,
              borderRadius: 0.5,
              padding: 0.5,
              border: 1,
              transition: "box-shadow 0.3s ease",
              mt:1.5,
            cursor:"pointer"
              
            }}
            onClick={handleOnClick}
            >
                  {/* <span style={{fontWeight:"bold" , fontSize:"14px" }}>
          Version: 1
        </span> */}
              <DocumentElementEditor
              key={section.id + "___ section_Approved"}
                element={null}
                parentSubsection={null}
                parentSection={section}
                documentTemplate={
                  props.documentTemplateSummary as DocumentTemplate
                }
                organizationId={props.organizationId}
                workspaceId={props.workspaceId}
                isHeading={true}
                viewPreferences={props.viewPreferences}
                forApproval={false}
                approvalsEnabled={false}
                approvalOf={-1}
                readOnly={true}
              />
            </Box>
          </div>
          <div className="divButtons">
            <Button
              style={style.buttonStyle}
              variant="outlined"
              onClick={handleClose}
            >
          cancel
            </Button>

            <Button
              style={style.buttonStyle}
              variant="contained"
              onClick={handleDecline}
             disabled={selectedVersion===null}
            >
            Attach
            </Button>
          </div>
        </Paper>
      </Modal>
    </>
  );
}
