import {
  DocumentElement,
  DocumentSection,
} from "../../../../../types/DocumentTemplate";
import { setSelectedComponent } from "../../../editorSlice";
import { getTableArray } from "../TableElementEditor";
import {
  formatTableWithTheme,
  TABLE_ALIGNMENT,
  updateCellBackgroundFormatting,
  updateCellFormatting,
} from "./TableUtils";

export const createUpdateTable = (
  data: any,
  totalRows: number,
  totalColumns: number
) => {
  const emptyTable: any[][] = [];

  for (let i = 0; i < totalRows; i++) {
    const row: any[] = [];
    for (let j = 0; j < totalColumns; j++) {
      // You can initialize the cells with a default value if needed
      row.push(data[i]?.[j] !== undefined ? data[i][j] : "");
    }
    emptyTable.push(row);
  }
  return emptyTable;
};

export function updateRowBackground(
  tableData: any[][],
  rowIndex: number,
  updatedValue: string
): any[][] {
  // Make a deep copy of the original tableData
  const updatedTableData = JSON.parse(JSON.stringify(tableData));

  // Check if the row index is within bounds
  if (rowIndex >= 0 && rowIndex < updatedTableData.length) {
    // Update the background color for each cell in the specified row
    updatedTableData[rowIndex].forEach((cell: any) => {
      cell.style.backgroundColor = updatedValue;
    });
  }
  return updatedTableData;
}

export function DuplicateRowFormatting(
  tableData: any[][],
  rowIndex: number,
  direction: "above" | "below"
): any[][] {
  // Make a deep copy of the original tableData
  const updatedTableData = JSON.parse(JSON.stringify(tableData));

  // Check if the row index is within bounds
  if (rowIndex >= 0 && rowIndex < updatedTableData.length) {
    // Create a copy of the row
    const newRow = JSON.parse(JSON.stringify(updatedTableData[rowIndex]));

    // Update the textAlign for each cell in the new row
    // newRow.forEach((cell: any) => {
    //   cell.style.textAlign = updatedValue;
    // });

    // Insert the new row above or below the specified rowIndex
    if (direction === "above") {
      updatedTableData.splice(rowIndex, 0, newRow);
    } else if (direction === "below") {
      updatedTableData.splice(rowIndex + 1, 0, newRow);
    }
  }

  return updatedTableData;
}

export function updateRowAlignmnet(
  tableData: any[][],
  rowIndex: number,
  updatedValue: string
): any[][] {
  // Make a deep copy of the original tableData
  const updatedTableData = JSON.parse(JSON.stringify(tableData));

  // Check if the row index is within bounds
  if (rowIndex >= 0 && rowIndex < updatedTableData.length) {
    // Update the background color for each cell in the specified row
    updatedTableData[rowIndex].forEach((cell: any) => {
      cell.style.textAlign = updatedValue;
    });
  }
  return updatedTableData;
}

export function updateRowTextColor(
  tableData: any[][],
  rowIndex: number,
  updatedValue: string
): any[][] {
  // Make a deep copy of the original tableData
  const updatedTableData = JSON.parse(JSON.stringify(tableData));

  // Check if the row index is within bounds
  if (rowIndex >= 0 && rowIndex < updatedTableData.length) {
    // Update the background color for each cell in the specified row
    updatedTableData[rowIndex].forEach((cell: any) => {
      cell.style.textColor = updatedValue;
    });
  }
  return updatedTableData;
}

export function updateColumnTextColor(
  tableData: any[][],
  columnIndex: number,
  updatedValue: string
): any[][] {
  // Make a deep copy of the original tableData
  const updatedTableData = JSON.parse(JSON.stringify(tableData));

  // Check if the column index is within bounds
  if (columnIndex >= 0 && columnIndex < updatedTableData[0].length) {
    // Update the background color for each cell in the specified column
    updatedTableData.forEach((row: any) => {
      row[columnIndex].style.textColor = updatedValue;
    });
  }

  return updatedTableData;
}

export function updateColumnWidth(
  tableData: any[][],
  columnIndex: number,
  updatedValue: string
): any[][] {
  // Make a deep copy of the original tableData
  const updatedTableData = JSON.parse(JSON.stringify(tableData));
  // Check if the column index is within bounds
  if (columnIndex >= 0 && columnIndex < updatedTableData[0].length) {
    // Update the background color for each cell in the specified column
    updatedTableData.forEach((row: any) => {
      row[columnIndex].style.columnWidth = updatedValue;
    });
  }

  return updatedTableData;
}

export function updateColumnType(
  tableData: any[][],
  columnIndex: number,
  updatedValue: string
): any[][] {
  // Make a deep copy of the original tableData
  const updatedTableData = JSON.parse(JSON.stringify(tableData));
  // Check if the column index is within bounds
  if (columnIndex >= 0 && columnIndex < updatedTableData[0].length) {
    updatedTableData.forEach((row: any) => {
      row[columnIndex].style.colType = updatedValue;
    });
  }

  return updatedTableData;
}

export function updateColumnAlignment(
  tableData: any[][],
  columnIndex: number,
  updatedValue: string
): any[][] {
  // Make a deep copy of the original tableData
  const updatedTableData = JSON.parse(JSON.stringify(tableData));

  // Check if the column index is within bounds
  if (columnIndex >= 0 && columnIndex < updatedTableData[0].length) {
    // Update the background color for each cell in the specified column
    updatedTableData.forEach((row: any) => {
      row[columnIndex].style.textAlign = updatedValue;
    });
  }

  return updatedTableData;
}

export function updateColumnBackground(
  tableData: any[][],
  columnIndex: number,
  updatedValue: string
): any[][] {
  // Make a deep copy of the original tableData
  const updatedTableData = JSON.parse(JSON.stringify(tableData));

  // Check if the column index is within bounds
  if (columnIndex >= 0 && columnIndex < updatedTableData[0].length) {
    // Update the background color for each cell in the specified column
    updatedTableData.forEach((row: any) => {
      row[columnIndex].style.backgroundColor = updatedValue;
    });
  }

  return updatedTableData;
}

export function deleteRowOrColumn(
  tableData: any[][],
  index: number,
  type: "row" | "column"
): any[][] {
  // Make a deep copy of the original tableData
  const updatedTableData = JSON.parse(JSON.stringify(tableData));

  // Check if the index is within bounds
  if (type === "row" && index >= 0 && index < updatedTableData.length) {
    // Remove the specified row
    updatedTableData.splice(index, 1);
  } else if (
    type === "column" &&
    index >= 0 &&
    index < updatedTableData[0].length
  ) {
    // Remove the specified column
    updatedTableData.forEach((row: any) => {
      row.splice(index, 1);
    });
  }
  return updatedTableData;
}

export function duplicateColumnFormatting(
  tableData: any[][],
  columnIndex: number,
  direction: "left" | "right"
): any[][] {
  // Make a deep copy of the original tableData
  const updatedTableData = JSON.parse(JSON.stringify(tableData));

  // Check if the column index is within bounds
  if (columnIndex >= 0 && columnIndex < updatedTableData[0].length) {
    // Iterate over each row in the table
    updatedTableData.forEach((row: any[]) => {
      // Create a copy of the cell in the specified column
      const newCell = JSON.parse(JSON.stringify(row[columnIndex]));

      // Update the formatting of the new cell
      // For example, if you want to update textAlign, uncomment the following line
      // newCell.style.textAlign = updatedValue;

      // Insert the new cell to the left or right based on the direction
      if (direction === "left") {
        row.splice(columnIndex, 0, newCell);
      } else if (direction === "right") {
        row.splice(columnIndex + 1, 0, newCell);
      }
    });
  }

  return updatedTableData;
}

export function moveRow(
  tableData: any[][],
  rowIndex: number,
  direction: "above" | "below"
): any[][] {
  // Make a deep copy of the original tableData
  const updatedTableData = JSON.parse(JSON.stringify(tableData));

  // Check if the row index is within bounds
  if (rowIndex >= 0 && rowIndex < updatedTableData.length) {
    // Calculate the target row index based on the specified direction
    const targetIndex = direction === "above" ? rowIndex - 1 : rowIndex + 1;

    // Check if the target row index is within bounds
    if (targetIndex >= 0 && targetIndex < updatedTableData.length) {
      // Move the row to the target index
      const movedRow = updatedTableData.splice(rowIndex, 1)[0];
      updatedTableData.splice(targetIndex, 0, movedRow);
    }
  }

  return updatedTableData;
}

export function moveColumn(
  tableData: any[][],
  columnIndex: number,
  direction: "left" | "right"
): any[][] {
  // Make a deep copy of the original tableData
  const updatedTableData = JSON.parse(JSON.stringify(tableData));

  // Check if the column index is within bounds
  if (columnIndex >= 0 && columnIndex < updatedTableData[0].length) {
    // Calculate the target column index based on the specified direction
    const targetIndex =
      direction === "left" ? columnIndex - 1 : columnIndex + 1;

    // Check if the target column index is within bounds
    if (targetIndex >= 0 && targetIndex < updatedTableData[0].length) {
      // Iterate over each row and swap the positions of the specified column and the target column
      updatedTableData.forEach((row: any) => {
        const temp = row[targetIndex];
        row[targetIndex] = row[columnIndex];
        row[columnIndex] = temp;
      });
    }
  }

  return updatedTableData;
}

export const deleteColumn = (data: any, columnIndex: number) => {
  const newData = data.map((row: any) => [...row]); // Create a copy of the original array
  newData.forEach((row: any) => row.splice(columnIndex, 1)); // Remove the specified column from each row
  return { newData, rowCount: newData.length, columnCount: newData[0].length };
};

export const deleteRow = (data: any, rowIndex: any) => {
  const newData = [...data]; // Create a copy of the original array
  newData.splice(rowIndex, 1); // Remove the specified row

  return { newData, rowCount: newData.length, columnCount: newData[0].length };
};

// Function to insert a row below the specified rowIndex
export const insertRowBelow = (data: any, rowIndex: number) => {
  const newData = [...data]; // Create a copy of the original array
  const newRow = Array(data[0].length).fill(""); // Create a new row with empty values

  newData.splice(rowIndex + 1, 0, newRow); // Insert the new row below the specified rowIndex

  return { newData, rowCount: newData.length, columnCount: newData[0].length };
};

// Function to insert a row above the specified rowIndex
export const insertRowAbove = (data: any, rowIndex: number) => {
  const newData = [...data]; // Create a copy of the original array
  const newRow = Array(data[0].length).fill(""); // Create a new row with empty values

  newData.splice(rowIndex, 0, newRow); // Insert the new row above the specified rowIndex

  return { newData, rowCount: newData.length, columnCount: newData[0].length };
};

// Function to insert a column to the left of the specified columnIndex
export const insertColumnLeft = (data: any, columnIndex: number) => {
  const newData = data.map((row: any) => [...row]); // Create a copy of the original array

  newData.forEach((row: any) => row.splice(columnIndex, 0, "")); // Insert an empty value in each row at the specified columnIndex
  return { newData, rowCount: newData.length, columnCount: newData[0].length };
};

// Function to insert a column to the right of the specified columnIndex
export const insertColumnRight = (data: any, columnIndex: number) => {
  const newData = data.map((row: any) => [...row]); // Create a copy of the original array

  newData.forEach((row: any) => row.splice(columnIndex + 1, 0, "")); // Insert an empty value in each row to the right of the specified columnIndex

  return { newData, rowCount: newData.length, columnCount: newData[0].length };
};

// Function to duplicate a row above the specified rowIndex
export const duplicateRowAbove = (data: any, rowIndex: number) => {
  const newData = [...data]; // Create a copy of the original array

  // Check if the rowIndex is within bounds
  if (rowIndex >= 0 && rowIndex < newData.length) {
    // Duplicate the row
    const duplicatedRow = newData[rowIndex].map((cell: any) =>
      JSON.parse(JSON.stringify(cell))
    );

    // Insert the duplicated row above the original row
    newData.splice(rowIndex, 0, duplicatedRow);
  }
  return { newData, rowCount: newData.length, columnCount: newData[0].length };
};

// Function to duplicate a row below the specified rowIndex
export const duplicateRowBelow = (data: any, rowIndex: number) => {
  const newData = [...data]; // Create a copy of the original array

  // Check if the rowIndex is within bounds
  if (rowIndex >= 0 && rowIndex < newData.length) {
    // Duplicate the row
    const duplicatedRow = newData[rowIndex].map((cell: any) =>
      JSON.parse(JSON.stringify(cell))
    );

    // Insert the duplicated row below the original row
    newData.splice(rowIndex + 1, 0, duplicatedRow);
  }

  return { newData, rowCount: newData.length, columnCount: newData[0].length };
};

// Function to duplicate a column to the left of the specified columnIndex
export const duplicateColumnLeft = (data: any, columnIndex: number) => {
  const newData = data.map((row: any) => [...row]); // Create a copy of the original array

  newData.forEach((row: any, rowIndex: number) => {
    // Duplicate the cell at the specified columnIndex
    const duplicatedCell = JSON.parse(JSON.stringify(row[columnIndex]));

    // Insert the duplicated cell to the left of the original cell
    row.splice(columnIndex, 0, duplicatedCell);
  });
  return { newData, rowCount: newData.length, columnCount: newData[0].length };
};

// Function to duplicate a column to the right of the specified columnIndex
export const duplicateColumnRight = (data: any, columnIndex: number) => {
  const newData = data.map((row: any) => [...row]); // Create a copy of the original array

  newData.forEach((row: any, rowIndex: number) => {
    // Duplicate the cell at the specified columnIndex
    const duplicatedCell = JSON.parse(JSON.stringify(row[columnIndex]));

    // Insert the duplicated cell to the right of the original cell
    row.splice(columnIndex + 1, 0, duplicatedCell);
  });

  return { newData, rowCount: newData.length, columnCount: newData[0].length };
};

// Function to move a row below the specified rowIndex
export const moveRowBelow = (data: any, rowIndex: number) => {
  const newData = [...data]; // Create a copy of the original array

  // Check if the rowIndex is within bounds
  if (rowIndex >= 0 && rowIndex < newData.length - 1) {
    // Remove the row from its current position
    const movedRow = newData.splice(rowIndex, 1)[0];

    // Insert the removed row one position below
    newData.splice(rowIndex + 1, 0, movedRow);
  }

  return { newData, rowCount: newData.length, columnCount: newData[0].length };
};

// Function to move a row above the specified rowIndex
export const moveRowAbove = (data: any, rowIndex: number) => {
  const newData = [...data]; // Create a copy of the original array

  // Check if the rowIndex is within bounds
  if (rowIndex > 0 && rowIndex < newData.length) {
    // Remove the row from its current position
    const movedRow = newData.splice(rowIndex, 1)[0];

    // Insert the removed row one position above
    newData.splice(rowIndex - 1, 0, movedRow);
  }
  return { newData, rowCount: newData.length, columnCount: newData[0].length };
};

// Function to move a column to the left of the specified columnIndex
export const moveColumnLeft = (data: any, columnIndex: number) => {
  const newData = data.map((row: any) => [...row]); // Create a copy of the original array

  // Check if the columnIndex is within bounds
  if (columnIndex > 0 && columnIndex < newData[0].length) {
    // Move each cell in the specified column one position to the left
    newData.forEach((row: any) => {
      const movedCell = row.splice(columnIndex, 1)[0];
      row.splice(columnIndex - 1, 0, movedCell);
    });
  }

  return { newData, rowCount: newData.length, columnCount: newData[0].length };
};

// Function to move a column to the right of the specified columnIndex
export const moveColumnRight = (data: any, columnIndex: number) => {
  const newData = data.map((row: any) => [...row]); // Create a copy of the original array

  // Check if the columnIndex is within bounds
  if (columnIndex >= 0 && columnIndex < newData[0].length - 1) {
    // Move each cell in the specified column one position to the right
    newData.forEach((row: any) => {
      const movedCell = row.splice(columnIndex, 1)[0];
      row.splice(columnIndex + 1, 0, movedCell);
    });
  }

  return { newData, rowCount: newData.length, columnCount: newData[0].length };
};

export const handleUpdateFormatting = (
  action: string,
  value: string,
  props: any,
  dispatch: React.Dispatch<any>,
  updateDocumentElementFunction: any
) => {
  const updatedDocumentElement = { ...props.element };
  const Formatting = JSON.parse(props.element!.formatting!);
  let updatedFormatting;

  if (action === "Change Cell Color") {
    updatedFormatting = updateCellBackgroundFormatting(
      Formatting,
      props.row,
      props.column,
      action,
      value
    );
  } else if (action === "Change Entire Row Color") {
    updatedFormatting = updateRowBackground(Formatting, props.row, value);
  } else if (action === "Change Entire Column Color") {
    updatedFormatting = updateColumnBackground(Formatting, props.column, value);
  } else if (action === "Row Align Left") {
    updatedFormatting = updateRowAlignmnet(
      Formatting,
      props.row,
      TABLE_ALIGNMENT.left
    );
  } else if (action === "Row Align Center") {
    updatedFormatting = updateRowAlignmnet(
      Formatting,
      props.row,
      TABLE_ALIGNMENT.center
    );
  } else if (action === "Row Align Right") {
    updatedFormatting = updateRowAlignmnet(
      Formatting,
      props.row,
      TABLE_ALIGNMENT.right
    );
  } else if (action === "Column Align Left") {
    updatedFormatting = updateColumnAlignment(
      Formatting,
      props.column,
      TABLE_ALIGNMENT.left
    );
  } else if (action === "Column Align Center") {
    updatedFormatting = updateColumnAlignment(
      Formatting,
      props.column,
      TABLE_ALIGNMENT.center
    );
  } else if (action === "Column Align Right") {
    updatedFormatting = updateColumnAlignment(
      Formatting,
      props.column,
      TABLE_ALIGNMENT.right
    );
  } else if (action === "Cell Content Color") {
    updatedFormatting = updateCellFormatting(
      Formatting,
      props.row,
      props.column,
      "",
      value
    );
  } else if (action === "Entire Row Content Color") {
    updatedFormatting = updateRowTextColor(Formatting, props.row, value);
  } else if (action === "Entire Column Content Color") {
    updatedFormatting = updateColumnTextColor(Formatting, props.column, value);
  } else {
    console.error(`Unhandled action: ${action}`);
    return;
  }

  updatedDocumentElement.formatting = JSON.stringify(updatedFormatting);

  updateDocumentElementFunction({
    section: props.parentSection as DocumentSection,
    subSection: props.parentSubsection!,
    documentElement: updatedDocumentElement as DocumentElement,
  });

  dispatch(
    setSelectedComponent({
      selectedSection: props.parentSection as DocumentSection,
      selectedSubsection: props.parentSubsection!,
      selectedComponent: updatedDocumentElement as DocumentElement,
    })
  );
};

export const handleUpdateCell = (
  action: string,
  props: any,
  dispatch: React.Dispatch<any>,
  updateDocumentElementFunction: any
) => {
  const updatedDocumentElement = { ...props.element };
  const startElement = JSON.parse(props.element!.content_format);
  const originalTable = getTableArray(
    props.element!.content,
    startElement.rows,
    startElement.columns
  );
  const rowIndex = props.row;
  const columnIndex = props.column;
  const formatting = JSON.parse(props.element.formatting);
  let updatedTable = formatting;
  let formattedTableData;
  if (action === "Insert Row Above") {
    updatedTable = insertRowAbove(originalTable, rowIndex);
    formattedTableData = DuplicateRowFormatting(formatting, rowIndex, "above");
  } else if (action === "Insert Row Below") {
    formattedTableData = DuplicateRowFormatting(formatting, rowIndex, "below");
    updatedTable = insertRowBelow(originalTable, rowIndex);
  } else if (action === "Insert Column Left") {
    formattedTableData = duplicateColumnFormatting(
      formatting,
      columnIndex,
      "left"
    );
    updatedTable = insertColumnLeft(originalTable, columnIndex);
  } else if (action === "Insert Column Right") {
    formattedTableData = duplicateColumnFormatting(
      formatting,
      columnIndex,
      "right"
    );
    updatedTable = insertColumnRight(originalTable, columnIndex);
  } else if (action === "Delete Entire Column") {
    updatedTable = deleteColumn(originalTable, columnIndex);
    formattedTableData = deleteRowOrColumn(formatting, columnIndex, "column");
  } else if (action === "Delete Entire Row") {
    updatedTable = deleteRow(originalTable, rowIndex);
    formattedTableData = deleteRowOrColumn(formatting, rowIndex, "row");
  } else if (action === "Duplicate Row Above") {
    updatedTable = duplicateRowAbove(originalTable, columnIndex);
    formattedTableData = DuplicateRowFormatting(formatting, rowIndex, "above");
  } else if (action === "Duplicate Row Below") {
    updatedTable = duplicateRowBelow(originalTable, columnIndex);
    formattedTableData = DuplicateRowFormatting(formatting, rowIndex, "below");
  } else if (action === "Duplicate Column Left") {
    updatedTable = duplicateColumnLeft(originalTable, columnIndex);
    formattedTableData = duplicateColumnFormatting(
      formatting,
      columnIndex,
      "left"
    );
  } else if (action === "Duplicate Column Right") {
    updatedTable = duplicateColumnRight(originalTable, columnIndex);
    formattedTableData = duplicateColumnFormatting(
      formatting,
      columnIndex,
      "right"
    );
  } else if (action === "Move Row Above") {
    updatedTable = moveRowAbove(originalTable, rowIndex);
    formattedTableData = moveRow(formatting, columnIndex, "above");
  } else if (action === "Move Row Below") {
    updatedTable = moveRowBelow(originalTable, rowIndex);
  } else if (action === "Move Column Left") {
    updatedTable = moveColumnLeft(originalTable, columnIndex);
    formattedTableData = moveColumn(formatting, columnIndex, "left");
  } else if (action === "Move Column Right") {
    formattedTableData = moveColumn(formatting, columnIndex, "right");
    updatedTable = moveColumnRight(originalTable, columnIndex);
  } else {
    console.error(`Unhandled action: ${action}`);
    return;
  }

  const newTableArray: Array<string> = [];
  updatedTable.newData.forEach((_: any) => {
    newTableArray.push(_.join("!TC"));
  });

  // const formattedTableData = formatTableWithTheme(
  //   updatedTable.rowCount,
  //   updatedTable.columnCount,
  //   startElement.themeDetails
  // );
  const stringFormat = JSON.stringify(formattedTableData);

  const contentFormatObject = JSON.parse(props.element!.content_format);
  contentFormatObject.rows = updatedTable.rowCount;
  contentFormatObject.columns = updatedTable.columnCount;
  const newTableString = newTableArray.join("!TR");
  updatedDocumentElement.content_format = JSON.stringify(contentFormatObject);
  updatedDocumentElement.content = newTableString;
  updatedDocumentElement.formatting = stringFormat;

  // Update the document element
  updateDocumentElementFunction({
    section: props.parentSection as DocumentSection,
    subSection: props.parentSubsection!,
    documentElement: updatedDocumentElement as DocumentElement,
  });

  // Update state and dispatch other actions as needed
  // setOriginalTable(updatedTable.newData);
  // setColumns(updatedTable.columnCount);
  // setRows(updatedTable.rowCount);

  dispatch(
    setSelectedComponent({
      selectedSection: props.parentSection as DocumentSection,
      selectedSubsection: props.parentSubsection!,
      selectedComponent: updatedDocumentElement as DocumentElement,
    })
  );

  // Reset context menu
  // setContextMenu({
  //   visible: false,
  //   options: [],
  //   position: { x: 0, y: 0 },
  //   cell: { row: 0, column: 0 },
  // });
};
