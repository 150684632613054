import React, { useEffect, useRef, useState } from "react";
import FormatColorTextRoundedIcon from "@mui/icons-material/FormatColorTextRounded";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Menu from "@mui/material/Menu";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
import RefreshIcon from "@mui/icons-material/Refresh";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import FormatPaintIcon from "@mui/icons-material/FormatPaint";
import CancelIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import PreviewSharpIcon from "@mui/icons-material/VisibilitySharp";
import useActions, { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  Button,
  LinearProgress,
  ListItemText,
  makeStyles,
  Popover
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { grey } from "@mui/material/colors";
import WebAssetIcon from "@mui/icons-material/WebAsset";
import {
  clearSelectedComponent,
  selectApprovalMode,
  selectCurrentTemplateState,
  selectEditingDocumentTemplate,
  selectHeadingLevel,
  selectSelected,
  selectSelectedTable,
  setSelectedComponent
} from "../editorSlice";
import {
  ContentType,
  DocumentElement,
  DocumentSection,
  DocumentSubsection,
  DocumentTemplate
} from "../../../types/DocumentTemplate";
import { FormattingMenu } from "./FormattingMenu";
import DocumentMasterConfigForm from "./DocumentFormatMaster/DocumentMasterConfigForm";
import { PreferencesMenu } from "./PreferencesMenu";
import ButtonGroup from "@mui/material/ButtonGroup";
import {
  redoEditor,
  setEditorState,
  toggleColor,
  undoEditor
} from "../markdownTextEditorSlice";
import { selectDocumentPreview } from "../../library/librarySlice";
import { ColorPicker } from "mui-color";
import { ChromePicker } from "react-color";
import {
  findParentSubHeadingLevel,
  findParentTextElementLevel
} from "../../../app/sagas/utils";
import {
  getUpdatedSection,
  getUpdatedSubsection
} from "../document/HelperEditorFunctions";
import { width } from "@mui/system";
import { attachedSubsectionElement } from "../document/utils";
import DeleteConfirmForm from "../document/CustomEditorDecorators/DeleteConfirmForm";
import {
  updateCellBackgroundFormatting,
  updateCellFormatting
} from "../document/TableElement/HelperFuctions/TableUtils";
import TableFormattingMenu from "./TableFomatting/TableFormattingMenu";
import TableFontSizeSelector from "./TableFomatting/TableFontSizeSelector";
import DeleteUnsharedConfirm from "../document/CustomEditorDecorators/DeleteUnsharedConfirm";

export function EditorHeaderMenu(props: {
  organizationId: number;
  workspaceId: number;
  documentTemplate: DocumentTemplate;
}) {
  const actions = useActions();
  const dispatch = useAppDispatch();
  const documentTemplate = useAppSelector(selectEditingDocumentTemplate);
  const selectedComponents = useAppSelector(selectSelected);
  const approvalsEnabled = useAppSelector(selectApprovalMode);
  const [showTooltip, setShowTooltip] = useState(true);
  const showTooltips = documentTemplate.sections?.length == 0 && showTooltip;
  const selectedCurrentTemplateState = useAppSelector(
    selectCurrentTemplateState
  );

  const documentPreview = useAppSelector(selectDocumentPreview);
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] =
    useState<boolean>(false);
  const [isDeleteUnsharedOpen, setIsDeleteUnsharedOpen] =
    useState<boolean>(false);
  const [openedSelected, setOpenedSelected] = React.useState<any>(null);
  const headingLevel = useAppSelector(selectHeadingLevel);
  const isHeadingSelected =
    selectedComponents.section !== null &&
    selectedComponents.subSection === null &&
    selectedComponents.documentElement === null;

  const isSubHeadingSelected =
    selectedComponents.section !== null &&
    selectedComponents.documentElement?.content_type === "HEADING";
  const isTextSelected =
    selectedComponents.section !== null &&
    selectedComponents.documentElement?.content_type === "TEXT";

  const isNothingSelected =
    selectedComponents.section === null &&
    selectedComponents.subSection === null &&
    selectedComponents.documentElement === null;

  const isTableSelected =
    selectedComponents.documentElement !== null &&
    selectedComponents.documentElement.content_type === ContentType.Table;

  const selectedTableComponents = useAppSelector(selectSelectedTable);
  const [documentMasterFormOpen, setDocumentMasterFormOpen] =
    React.useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [colorPickerOpen, setColorPickerOpen] = useState(false);
  const [selectedColor, setSelectedColor] = useState<string>("#000000");
  const [colorBackgroundPickerOpen, setColorBackgroundPickerOpen] =
    useState(false);

  const [selectedBackgroundColor, setSelectedBackgroundColor] =
    useState<string>("#000000");
  const handleColorPicker = (
    color: any,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    event.preventDefault();
    if (colorBackgroundPickerOpen) {
      setSelectedBackgroundColor(color.hex);
    } else {
      setSelectedColor(color.hex);
    }

    event.stopPropagation();
  };

  const handleColorPickerClose = (event: React.MouseEvent) => {
    event.preventDefault();
    setColorBackgroundPickerOpen(false);
    setColorPickerOpen(false);
    event.stopPropagation();
  };

  const handleColorPickerSave = (event: React.MouseEvent) => {
    event.preventDefault();

    if (isTableSelected) {
      if (colorBackgroundPickerOpen) {
        handleUpdateTableCellBackgroundColor(
          selectedBackgroundColor.substring(1)
        );
      } else {
        handleUpdateTableCellTextColor(selectedColor.substring(1));
      }
    } else {
      //  dispatch(toggleColor(selectedColor))
      updateElementColor(selectedColor.substring(1));
    }
    setColorPickerOpen(false);
    setColorBackgroundPickerOpen(false);
    event.stopPropagation();
  };

  const handleColorPickerOpen = () => {
    if (isTableSelected) {
      setOpenedSelected(selectedTableComponents);
    } else {
      setOpenedSelected(selectedComponents);
    }
    setColorPickerOpen((prevState: any) => !prevState);
  };

  const handleColorBgPickerOpen = () => {
    if (isTableSelected) {
      setOpenedSelected(selectedTableComponents);
    } else {
      setOpenedSelected(selectedComponents);
    }

    setColorBackgroundPickerOpen((prevState: any) => !prevState);
  };

  function handlePreviewClicked(e: any) {
    setIsLoading(true);
    actions.generateDocumentTemplate(props.documentTemplate);
  }

  const handleUpdateTableCellBackgroundColor = (color: any) => {
    try {
      if (
        selectedTableComponents.documentElement &&
        selectedTableComponents.row != null &&
        selectedTableComponents.column != null &&
        selectedTableComponents.subSection &&
        selectedTableComponents.documentElement.formatting
      ) {
        const Formatting = JSON.parse(
          selectedTableComponents.documentElement.formatting
        );
        const updatedFormatting = updateCellBackgroundFormatting(
          Formatting,
          selectedTableComponents.row,
          selectedTableComponents.column,
          "backGroundColor",
          color
        );
        const updatedElement = { ...selectedTableComponents.documentElement };
        updatedElement.formatting = JSON.stringify(updatedFormatting);
        actions.updateDocumentElementContent({
          section: selectedComponents.section as DocumentSection,
          subSection: selectedComponents.subSection!,
          documentElement: updatedElement as DocumentElement
        });
        dispatch(
          setSelectedComponent({
            selectedSection: selectedComponents.section as DocumentSection,
            selectedSubsection: selectedComponents.subSection!,
            selectedComponent: updatedElement as DocumentElement
          })
        );
      }
    } catch (e) {
      console.log("unable to update the Table cell alignmnet");
    }
  };

  const handleUpdateTableCellTextColor = (color: any) => {
    try {
      if (
        selectedTableComponents.documentElement &&
        selectedTableComponents.row != null &&
        selectedTableComponents.column != null &&
        selectedTableComponents.subSection &&
        selectedTableComponents.documentElement.formatting
      ) {
        const Formatting = JSON.parse(
          selectedTableComponents.documentElement.formatting
        );
        const updatedFormatting = updateCellFormatting(
          Formatting,
          selectedTableComponents.row,
          selectedTableComponents.column,
          "textColor",
          color
        );
        const updatedElement = { ...selectedTableComponents.documentElement };
        updatedElement.formatting = JSON.stringify(updatedFormatting);
        actions.updateDocumentElementContent({
          section: selectedComponents.section as DocumentSection,
          subSection: selectedComponents.subSection!,
          documentElement: updatedElement as DocumentElement
        });
        dispatch(
          setSelectedComponent({
            selectedSection: selectedComponents.section as DocumentSection,
            selectedSubsection: selectedComponents.subSection!,
            selectedComponent: updatedElement as DocumentElement
          })
        );
      }
    } catch (e) {
      console.log("unable to update the Table cell alignmnet");
    }
  };

  const updateElementColor = (color: any) => {
    //If the Seletec is Element
    if (
      openedSelected.section &&
      openedSelected.subSection &&
      openedSelected.documentElement
    ) {
      const subsectionElement = getUpdatedSubsection(
        documentTemplate,
        openedSelected.section,
        openedSelected.subSection
      );
      const updatedElement = { ...openedSelected.documentElement };
      updatedElement.content =
        subsectionElement?.elements &&
        attachedSubsectionElement(subsectionElement)
          ? attachedSubsectionElement(subsectionElement).content
          : openedSelected.documentElement.content;
      const contentFormatObject = JSON.parse(updatedElement.content_format);
      contentFormatObject.color = color;
      contentFormatObject.isColorApplied = true;
      updatedElement.content_format = JSON.stringify(contentFormatObject);
      updatedElement.formatting = JSON.stringify({
        ...JSON.parse(
          openedSelected.documentElement?.formatting !== null &&
            openedSelected.documentElement?.formatting !== undefined
            ? openedSelected.documentElement?.formatting
            : "{}"
        ),
        color: color
      });
      actions.updateDocumentElementContent({
        section: openedSelected.section as DocumentSection,
        subSection: openedSelected.subSection!,
        documentElement: updatedElement as DocumentElement
      });
    }
    // if the Selected is Section.
    else {
      const section = getUpdatedSection(
        documentTemplate,
        openedSelected.section
      );
      const updatedSection = Object.assign(
        {},
        openedSelected.section as DocumentSection,
        {
          ...openedSelected,
          heading: section?.heading,
          formatting: JSON.stringify({
            ...JSON.parse(openedSelected.section?.formatting),
            color: color
          })
        }
      );
      actions.updateSectionContent(updatedSection);
    }
  };

  useEffect(() => {
    setIsLoading(false);
  }, [documentPreview]);

  function handleDeleteClicked(e: any, isLibraryContent: boolean) {
    if (selectedComponents.documentElement) {
      actions.deleteSubsection({
        section: selectedComponents.section!,
        subSection: selectedComponents.subSection!,
        currentDocOnly: isLibraryContent
      });
    } else if (selectedComponents.section) {
      if (isLibraryContent) {
        actions.deleteSectionAllDocuments(selectedComponents.section);
      } else actions.deleteSection(selectedComponents.section);
    }
    dispatch(clearSelectedComponent());
    dispatch(setEditorState(null));
  }

  function handleRefreshClicked() {
    actions.setEditingDocumentTemplate(props.documentTemplate);
  }

  function handlePublishClicked() {
    actions.publishDocumentTemplate(props.documentTemplate);
  }

  function handleDocumentMasterClicked(event: any) {
    setAnchorEl(event.currentTarget);
    setShowTooltip(false);
    setDocumentMasterFormOpen(true);
  }

  function handleUndoClicked() {
    dispatch(undoEditor());
  }

  function handleRedoClicked() {
    dispatch(redoEditor());
  }

  const disable = false;

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClosePopover = () => {
    setShowTooltip(false);
    setAnchorEl(null);
  };

  const open = showTooltips;
  const id = open ? "document-master-popover" : undefined;
  return (
    <>
      <AppBar
        elevation={5}
        position="relative"
        sx={{ borderRadius: 0, width: "98%" }}
      >
        <Toolbar variant="dense" sx={{ bgcolor: grey[200], borderRadius: 0 }}>
          <FormattingMenu />

          <ButtonGroup>
            {/* <Tooltip title="Undo">
            <IconButton
              disabled={
                !currentMarkdownEditorDetails ||
                !currentMarkdownEditorDetails.isUndoAvailable
              }
              onMouseDown={(e) => e.preventDefault()}
              onClick={handleUndoClicked}
            >
              <UndoIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Redo">
            <IconButton
              disabled={
                !currentMarkdownEditorDetails ||
                !currentMarkdownEditorDetails.isRedoAvailable
              }
              onMouseDown={(e) => e.preventDefault()}
              onClick={handleRedoClicked}
            >
              <RedoIcon />
            </IconButton>
          </Tooltip> */}
            {!isTableSelected && (
              <Select
                value={selectedComponents.section !== null ? headingLevel : 2}
                sx={{ marginTop: "10px", height: "20px", marginBottom: "10px" }}
                style={{
                  padding: "4px",
                  display: "center"

                  /* Add any other styles you want */
                }}
                disabled={
                  selectedComponents.documentElement === null ||
                  !(
                    selectedComponents.documentElement.content_type ===
                      "HEADING" ||
                    selectedComponents.documentElement.content_type === "TEXT"
                  ) ||
                  approvalsEnabled
                }
                onOpen={() => {
                  setOpenedSelected(selectedComponents);
                  dispatch(clearSelectedComponent());
                }}
                onChange={(event) => {
                  if (openedSelected.documentElement === null) {
                    return;
                  }
                  dispatch(clearSelectedComponent());
                  actions.setHeadingLevel({
                    selected: openedSelected,
                    heading_level: event.target.value as number
                  });
                }}
              >
                {isNothingSelected && openedSelected === null && (
                  <MenuItem value={2} disabled>
                    H2
                  </MenuItem>
                )}
                {isHeadingSelected && <MenuItem value={1}>H1</MenuItem>}
                {/* if the Selected is heading  */}
                {isSubHeadingSelected && (
                  <MenuItem value={1} disabled>
                    H1
                  </MenuItem>
                )}
                {isSubHeadingSelected && <MenuItem value={2}>H2</MenuItem>}
                {isSubHeadingSelected && <MenuItem value={3}>H3</MenuItem>}
                {isSubHeadingSelected && <MenuItem value={4}>H4</MenuItem>}
                {isSubHeadingSelected && <MenuItem value={5}>H5</MenuItem>}
                {isSubHeadingSelected && <MenuItem value={6}>H6</MenuItem>}

                {isTextSelected && <MenuItem value={2}>L2</MenuItem>}

                {isTextSelected && <MenuItem value={3}>L3</MenuItem>}
                {isTextSelected && <MenuItem value={4}>L4</MenuItem>}
                {isTextSelected && <MenuItem value={5}>L5</MenuItem>}
                {isTextSelected && <MenuItem value={6}>L6</MenuItem>}

                {!isHeadingSelected &&
                  selectedComponents.documentElement?.content_type !== "TEXT" &&
                  openedSelected &&
                  openedSelected.documentElement?.content_type !== "TEXT" && (
                    // <MenuItem value="2">H2</MenuItem>
                    <MenuItem
                      value={2}
                      disabled={
                        2 <
                        findParentSubHeadingLevel(
                          documentTemplate,
                          openedSelected.section,
                          openedSelected.subSection
                        )
                      }
                    >
                      H2
                    </MenuItem>
                  )}
                {!isHeadingSelected &&
                  selectedComponents.documentElement?.content_type !== "TEXT" &&
                  openedSelected &&
                  openedSelected.documentElement?.content_type !== "TEXT" && (
                    <MenuItem
                      value={3}
                      disabled={
                        3 <
                          findParentSubHeadingLevel(
                            documentTemplate,
                            openedSelected.section,
                            openedSelected.subSection
                          ) ||
                        3 >
                          findParentSubHeadingLevel(
                            documentTemplate,
                            openedSelected.section,
                            openedSelected.subSection
                          ) +
                            1
                      }
                    >
                      H3
                    </MenuItem>
                  )}
                {!isHeadingSelected &&
                  selectedComponents.documentElement?.content_type !== "TEXT" &&
                  openedSelected &&
                  openedSelected.documentElement?.content_type !== "TEXT" && (
                    // <MenuItem value="4">H4</MenuItem>
                    <MenuItem
                      value={4}
                      disabled={
                        4 <
                          findParentSubHeadingLevel(
                            documentTemplate,
                            openedSelected.section,
                            openedSelected.subSection
                          ) ||
                        4 >
                          findParentSubHeadingLevel(
                            documentTemplate,
                            openedSelected.section,
                            openedSelected.subSection
                          ) +
                            1
                      }
                    >
                      H4
                    </MenuItem>
                  )}
                {!isHeadingSelected &&
                  selectedComponents.documentElement?.content_type !== "TEXT" &&
                  openedSelected &&
                  openedSelected.documentElement?.content_type !== "TEXT" && (
                    // <MenuItem value="5">H5</MenuItem>
                    <MenuItem
                      value={5}
                      disabled={
                        5 <
                          findParentSubHeadingLevel(
                            documentTemplate,
                            openedSelected.section,
                            openedSelected.subSection
                          ) ||
                        5 >
                          findParentSubHeadingLevel(
                            documentTemplate,
                            openedSelected.section,
                            openedSelected.subSection
                          ) +
                            1
                      }
                    >
                      H5
                    </MenuItem>
                  )}
                {selectedComponents.documentElement?.content_type !== "TEXT" &&
                  openedSelected &&
                  !isHeadingSelected &&
                  openedSelected.documentElement?.content_type !== "TEXT" && (
                    <MenuItem
                      value={6}
                      disabled={
                        6 <
                          findParentSubHeadingLevel(
                            documentTemplate,
                            openedSelected.section,
                            openedSelected.subSection
                          ) ||
                        6 >
                          findParentSubHeadingLevel(
                            documentTemplate,
                            openedSelected.section,
                            openedSelected.subSection
                          ) +
                            1
                      }
                    >
                      H6
                    </MenuItem>
                  )}

                {!isHeadingSelected &&
                  !isSubHeadingSelected &&
                  openedSelected &&
                  openedSelected.documentElement?.content_type === "TEXT" && (
                    // <MenuItem value="2">L2</MenuItem>
                    <MenuItem
                      value={2}
                      disabled={
                        2 <=
                          findParentSubHeadingLevel(
                            documentTemplate,
                            openedSelected.section,
                            openedSelected.subSection
                          ) ||
                        2 >
                          findParentTextElementLevel(
                            documentTemplate,
                            openedSelected.section,
                            openedSelected.subSection
                          ) +
                            1
                      }
                    >
                      L2
                    </MenuItem>
                  )}
                {!isHeadingSelected &&
                  !isSubHeadingSelected &&
                  openedSelected &&
                  openedSelected.documentElement?.content_type === "TEXT" && (
                    <MenuItem
                      value={3}
                      disabled={
                        3 <=
                          findParentSubHeadingLevel(
                            documentTemplate,
                            openedSelected.section,
                            openedSelected.subSection
                          ) ||
                        3 >
                          findParentTextElementLevel(
                            documentTemplate,
                            openedSelected.section,
                            openedSelected.subSection
                          ) +
                            1
                      }
                    >
                      L3
                    </MenuItem>
                  )}
                {!isHeadingSelected &&
                  !isSubHeadingSelected &&
                  openedSelected &&
                  openedSelected.documentElement?.content_type === "TEXT" && (
                    <MenuItem
                      value={4}
                      disabled={
                        4 <=
                          findParentSubHeadingLevel(
                            documentTemplate,
                            openedSelected.section,
                            openedSelected.subSection
                          ) ||
                        4 >
                          findParentTextElementLevel(
                            documentTemplate,
                            openedSelected.section,
                            openedSelected.subSection
                          ) +
                            1
                      }
                    >
                      L4
                    </MenuItem>
                  )}
                {!isHeadingSelected &&
                  !isSubHeadingSelected &&
                  openedSelected &&
                  openedSelected.documentElement?.content_type === "TEXT" && (
                    <MenuItem
                      value={5}
                      disabled={
                        5 <=
                          findParentSubHeadingLevel(
                            documentTemplate,
                            openedSelected.section,
                            openedSelected.subSection
                          ) ||
                        5 >
                          findParentTextElementLevel(
                            documentTemplate,
                            openedSelected.section,
                            openedSelected.subSection
                          ) +
                            1
                      }
                    >
                      L5
                    </MenuItem>
                  )}
                {!isHeadingSelected &&
                  !isSubHeadingSelected &&
                  openedSelected &&
                  openedSelected.documentElement?.content_type === "TEXT" && (
                    <MenuItem
                      value={6}
                      disabled={
                        6 <=
                          findParentSubHeadingLevel(
                            documentTemplate,
                            openedSelected.section,
                            openedSelected.subSection
                          ) ||
                        6 >
                          findParentTextElementLevel(
                            documentTemplate,
                            openedSelected.section,
                            openedSelected.subSection
                          ) +
                            1
                      }
                    >
                      L6
                    </MenuItem>
                  )}
              </Select>
            )}

            <Tooltip title="Delete">
              <span>
                <IconButton
                  disableRipple
                  sx={{
                    marginTop: "10px",
                    height: "18px",
                    marginBottom: "10px"
                  }}
                  style={{
                    padding: "4px",
                    display: "center"
                  }}
                  disabled={
                    (!selectedComponents.section &&
                      !selectedComponents.subSection &&
                      !selectedComponents.documentElement) ||
                    approvalsEnabled
                  }
                  onMouseDown={(e) => e.preventDefault()}
                  onClick={(e) => {
                    if (
                      (selectedComponents.subSection &&
                        (!!documentTemplate.included_subSections?.[
                          selectedComponents.subSection?.id
                        ] ||
                          !!documentTemplate.imported_sub_sections?.[
                            selectedComponents.subSection?.id
                          ])) ||
                      (isHeadingSelected &&
                        selectedComponents.section &&
                        (!!documentTemplate.included_section?.[
                          selectedComponents.section?.id
                        ] ||
                          !!documentTemplate.imported_sections?.[
                            selectedComponents.section?.id
                          ]))
                    ) {
                      e.preventDefault();
                      if (
                        selectedCurrentTemplateState.approval ||
                        selectedCurrentTemplateState.editing ||
                        !selectedCurrentTemplateState.draft
                      ) {
                        setIsDeleteConfirmOpen(true);
                      } else {
                        handleDeleteClicked(e, true);
                      }

                      e.stopPropagation(); // Add this line to stop event propagation
                    } else {
                      setIsDeleteUnsharedOpen(true);
                      // handleDeleteClicked(e, false);
                    }
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Reload document">
              <IconButton
                disableRipple
                sx={{ marginTop: "10px", height: "18px", marginBottom: "10px" }}
                onClick={handleRefreshClicked}
                style={{
                  padding: "4px",
                  display: "center"
                }}
              >
                <RefreshIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Preview">
              <IconButton
                disableRipple
                sx={{ marginTop: "10px", height: "18px", marginBottom: "10px" }}
                onClick={handlePreviewClicked}
                disabled={documentTemplate.sections?.length == 0}
                style={{
                  padding: "5px",
                  display: "center"
                }}
              >
                <PreviewSharpIcon />
              </IconButton>
            </Tooltip>

            {showTooltips && (
              <Tooltip
                title={
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "left",
                      alignItems: "flex-start"
                    }}
                  >
                    <div style={{ flexGrow: 1, textAlign: "center" }}>
                      {showTooltips
                        ? "Define your document format master settings here."
                        : "Document Template Master"}
                    </div>
                    <IconButton
                      disableRipple
                      onClick={() => setShowTooltip(false)}
                      style={{
                        position: "absolute",
                        top: "-5px",
                        right: "-8px"
                      }}
                    >
                      <CancelIcon />
                    </IconButton>
                  </div>
                }
                open={showTooltips}
                arrow
                slotProps={{
                  popper: {
                    sx: {
                      [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                        {
                          marginTop: "10px",
                          fontWeight: "bold",
                          fontSize: "15px",
                          backgroundColor: "white",
                          color: "rgba(0, 0, 0, 0.87)",
                          maxWidth: "230px !important",
                          zIndex: 999,
                          border: "1px solid #7284A3",
                          boxShadow: showTooltips ? "0 0 10px #7284A3" : "none" // Glow effect
                        }
                    }
                  }
                }}
              >
                <IconButton
                  disableRipple
                  disabled={approvalsEnabled}
                  sx={{
                    marginTop: "10px",
                    height: "18px",
                    marginBottom: "10px",
                    color: showTooltips ? "#4C33FF" : "none"
                  }}
                  onClick={handleDocumentMasterClicked}
                  style={{
                    padding: "4px",
                    display: "center"
                  }}
                >
                  <FormatPaintIcon />
                </IconButton>
              </Tooltip>
            )}

            {!showTooltips && (
              <Tooltip title="Document Template Master">
                <IconButton
                  disableRipple
                  disabled={approvalsEnabled}
                  sx={{
                    marginTop: "10px",
                    height: "18px",
                    marginBottom: "10px",
                    color: showTooltips ? "#4C33FF" : "none"
                  }}
                  onClick={handleDocumentMasterClicked}
                  style={{
                    padding: "4px",
                    display: "center"
                  }}
                >
                  <FormatPaintIcon />
                </IconButton>
              </Tooltip>
            )}

            <PreferencesMenu />

            {documentMasterFormOpen ? (
              <DocumentMasterConfigForm
                closeDialogHandler={() => setDocumentMasterFormOpen(false)}
              />
            ) : (
              false
            )}
          </ButtonGroup>
        </Toolbar>
        {isLoading && (
          <Box sx={{ width: "50%" }}>
            <LinearProgress
              sx={{
                height: 5 // Adjust the height as needed
              }}
              color="primary"
            />
          </Box>
        )}
      </AppBar>
      {isDeleteConfirmOpen === true ? (
        <DeleteConfirmForm
          isHeading={isHeadingSelected}
          parentSection={selectedComponents.section as DocumentSection}
          parentSubsection={selectedComponents.subSection as DocumentSubsection}
          closeDialogHandler={() => setIsDeleteConfirmOpen(false)}
        />
      ) : null}

      {isDeleteUnsharedOpen === true ? (
        <DeleteUnsharedConfirm
          isHeading={isHeadingSelected}
          parentSection={selectedComponents.section as DocumentSection}
          parentSubsection={selectedComponents.subSection as DocumentSubsection}
          closeDialogHandler={() => setIsDeleteUnsharedOpen(false)}
        />
      ) : null}
    </>
  );
}
