import {
  AccountCircle,
  CheckCircleOutline,
  CompareOutlined,
  DeleteOutline,
} from "@mui/icons-material";
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
  Stack,
  Tooltip,
  Checkbox,
} from "@mui/material";
import { blue, grey, red } from "@mui/material/colors";
import React, { useState } from "react";
import IOSSwitch from "./IOSSwitch";

type Props = {
  name: string;
  email: string;
  loading: boolean;
  onInvite: any;
  fromInvited?: boolean;
  status?: boolean | any;
  onToggleLock?: any;
  commenter_id?: number;
  onDeleteCommenter?: any;
  checked?: boolean;
};

const ShareUserCard = (props: Props) => {
  const { name, email } = props;
  const [selectedEmail, setselectedEmail] = useState<null | string>(null);
  const [lock, setlock] = useState<boolean>(props.status);

  return (
    <Card
      variant="elevation"
      elevation={0}
      style={{
        display: "flex",
        alignItems: "center",
        padding: "16px",
        backgroundColor: grey[50],
        marginBottom: 3,
      }}
    >
      <Grid container alignItems="center" spacing={2}>
        <Grid item>
          <AccountCircle style={{ fontSize: 50, color: grey[300] }} />
        </Grid>
        <Grid item xs>
          <CardContent style={{ padding: 0 }}>
            <Typography fontSize={"normal"}>{name}</Typography>
            <Typography fontSize={"small"} color={grey[700]}>
              {email}
            </Typography>
          </CardContent>
        </Grid>
        <Grid item>
          {!props.fromInvited ? (
            <Checkbox checked={props.checked} onChange={props.onInvite} />
          ) : (
            <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
              <Typography fontSize={"small"}>
                {lock ? "Locked" : "Unlocked"}
              </Typography>
              <IOSSwitch
                checked={!lock}
                onChange={() => {
                  setlock((prev: boolean) => !prev);
                  props.onToggleLock(props.commenter_id, !lock, props.status);
                }}
              />
              <Tooltip
                onClick={props.onDeleteCommenter}
                title="Delete Commenter"
                placement="top"
              >
                <DeleteOutline
                  sx={{
                    color: red[500],
                    fontSize: "30px",
                    cursor: "pointer",
                  }}
                />
              </Tooltip>
            </Stack>
          )}
        </Grid>
      </Grid>
    </Card>
  );
};

export default ShareUserCard;
