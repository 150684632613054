import * as React from "react";
import { HeaderMenu } from "./features/headermenu/HeaderMenu";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Library from "./features/library/Library";
import useActions, { useAppSelector } from "./app/hooks";
import { selectEditingDocumentTemplate } from "./features/editor/editorSlice";
import { selectAuth, selectUser } from "./features/auth/authSlice";
import EditorContainer from "./features/editor/EditorContainer";
import SignInModal from "./features/auth/SignInModal";
import ResetModal from "./features/auth/ResetModal";
import UpdateUserDetailsModal from "./features/auth/updateUserDetailsModal";

import "./App.css";

import { CircularProgress } from "@mui/material";
import { getRememberMeState } from "./services/userAPI";
import {
  getCommenterId,
  isUserFromShareLink,
} from "./features/editor/document/TableElement/EditContent/helper";
import { apiGetCommentsData } from "./services/shareAndCommentsAPI";
import ErrorPage from "./features/errorPage/ErrorPage";
import { useDispatch, useSelector } from "react-redux";
import { setCommenter } from "./features/comments/commentingDetailsSlice";
import { RootState } from "./app/store";

function getParameterByName(name: string, url = window.location.href) {
  name = name.replace(/[[\]]/g, "\\$&");
  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

function App() {
  const authState = useAppSelector(selectAuth);
  const rememberMeState = getRememberMeState();
  const userData = useAppSelector(selectUser);
  const actions = useActions();
  const dispatch = useDispatch();
  //TODO: Implement a better state management
  const selectedDocumentTemplate = useAppSelector(
    selectEditingDocumentTemplate
  );

  const [isLoading, setisLoading] = React.useState(false);
  const [pageError, setpageError] = React.useState<any>(null);

  const getCommentsData = async () => {
    setisLoading(true);
    const commenter_id: any = getCommenterId();
    const response: any = await apiGetCommentsData(commenter_id);
    if (response?.data?.error) {
      setpageError(response.data.error);
    } else {
      dispatch(
        setCommenter({
          commenter_id: response?.commenter_id,
          name: response?.fullname,
          email: response?.email,
        })
      );
      actions.setEditingDocumentTemplate(response);
    }
  };

  React.useEffect(() => {
    if (isUserFromShareLink()) {
      getCommentsData();
    }
  }, []);

  React.useEffect(() => {
    if (selectedDocumentTemplate) {
      setisLoading(false);
    }
  }, [selectedDocumentTemplate]);

  // For password reset
  if (window.location.pathname == "/reset" && !isUserFromShareLink()) {
    const token = getParameterByName("token");
    if (token !== null) {
      return <ResetModal authState={authState} token={token}></ResetModal>;
    } else {
      (window as any).location = "/";
    }
  }

  if (pageError) {
    return <ErrorPage message={pageError} />;
  }

  if (authState.status === "logged-out" && !isUserFromShareLink()) {
    return <SignInModal authState={authState} open={true}></SignInModal>;
  }

  if (authState.status === "change-password" && !isUserFromShareLink()) {
    if (userData !== null) {
      return (
        <UpdateUserDetailsModal
          email={userData.email}
          authState={authState}
        ></UpdateUserDetailsModal>
      );
    }
  }

  // when ever user refresh/reload the page it check the local storage if user exist refresh the all the organizations.
  //todo? check if existing token expires?
  if (
    !isUserFromShareLink() &&
    authState.status === "logged-in" &&
    rememberMeState &&
    authState.organizations === null
  ) {
    actions.getOrganizations();
  }

  //Home Screen

  if (!isUserFromShareLink()) {
    const library =
      authState.status === "logged-in" ? (
        <Box>
          <Library></Library>
        </Box>
      ) : null;
    return (
      <Box>
        {!isUserFromShareLink() && !selectedDocumentTemplate && <CssBaseline />}
        {!isUserFromShareLink() && <HeaderMenu></HeaderMenu>}
        {selectedDocumentTemplate !== null ? <EditorContainer /> : library}
      </Box>
    );
  } else {
    return selectedDocumentTemplate ? (
      <EditorContainer />
    ) : (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }
}

export default App;
