import {
  DocumentSection,
  DocumentSubsection,
  DocumentTemplate,
} from "../../../../types/DocumentTemplate";

import MenuBookIcon from "@mui/icons-material/MenuBook";

export default function LibraryContentIcon(props: {
  isHeading: boolean;
  parentSection: DocumentSection;
  parentSubsection: DocumentSubsection;
  documentTemplate: DocumentTemplate;
  style: any;
}) {
  return (
    <>
      {
      // (props.isHeading &&
      //   !!(props.documentTemplate as DocumentTemplate).included_section?.[
      //     props.parentSection.id
      //   ]) ||
      (!props.isHeading &&
        !!(props.documentTemplate as DocumentTemplate).included_subSections?.[
          props.parentSubsection!.id
        ]) ||
      (!props.isHeading &&
        !!(props.documentTemplate as DocumentTemplate).imported_sub_sections?.[
          props.parentSubsection!.id
        ]) ? (
        <MenuBookIcon
          style={{
            ...props.style, // Spread the style from props
            fontSize: 22,
            zIndex: 99,
          }}
        />
      ) : null}
    </>
  );
}
