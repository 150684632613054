import { DocumentSection, DocumentSubsection } from "../../types/DocumentTemplate";

export enum ModalState {
  ImportLibraryContent = "importLibraryContent",
  DeleteConfirmUnshared = "deleteConfirmUnshared",
  DeleteConfirmShared = "deleteConfirmShared",
  UndoConfirm = "undoConfirm",
  SelectElementVersion = "selectElementVersion",
  SelectSectionVersion = "selectSectionVersion",
  None = ""
}

export const MESSAGES = {
    DOCUMENT_CREATED: '',
    DOCUMENT_UPDATED: '',
    DOCUMEN_DELETED: '',
    DOCUMENT_NOT_FOUND: '',
    DOCUMENT_INVALID: '',
    DOCUMENT_UNABLE_MOVE: 'Permission denied. Unable to move this document!',
    DOCUMNET_SUCCESS_MOVED:'Document moved successfully!',
    ELEMENT_IMPORT_FAILED:"Unable to import library content!"

}

export const COLORS={
    NOMIA_PINK:"#FF0062",
    NOMIA_GREEN:"#00C482",
    NOMIA_RED:"red",
    NOMIA_ORANGE:"#FFA500",
    NOMIA_BLUE:"#4C33FF",
    BORDER_SIZE:"3px",
    NOMIA_PURPLE:"#a02b93",
}

const newSubsection: DocumentSubsection = {
    id: -1,
    heading: "notused",
    component_order: 0,

    elements: [],
    pending_deleted_at: null,
    pending_heading: null,
    pending_addition: false,
  };

  export const dummySection: DocumentSection = {
    component_order: 10,
    content_format: "notused",
    edit: false,
    heading: "1",
    heading_level: 2,
    id: -101,
    pending_addition: false,
    pending_deleted_at: null,
    pending_heading: null,
    pending_heading_level: null,
    subsections: [newSubsection],
    impact_section_include: true,
  };