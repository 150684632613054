import React from 'react';
import { useDrag } from 'react-dnd';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { grey } from '@mui/material/colors';

export function DragItem(props: {id: number, icon: any, data: any, element: any, type: any, text: any, enabled: boolean }) {
  const [{isDragging}, drag] = useDrag(() => ({
    type: props.type,
    item: {
      id: props.id,
      type: props.type,
      element: {...props.element} // Copy the element
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging()
    }),
  }));

  
  return (
  <ListItem 
      id={props.id.toString()} 
      ref={props.enabled? drag : null} 
      sx={{
        m:"0px", 
        bgcolor: props.enabled ? null : grey[300], 
        border: isDragging ? "2px dashed steelblue" : "1px solid lightgrey", 
        boxShadow: 1, 
        borderRadius: 0  }}
      dense={true}>
    <ListItemIcon>{props.icon}</ListItemIcon>
    <ListItemText primary={props.text} />
  </ListItem>)
}

export default DragItem;