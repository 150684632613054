import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Modal,
  Paper,
  Typography,
} from "@mui/material";
import useActions from "../../../../app/hooks";
import {
  DocumentSection,
  DocumentSectionSummary,
  DocumentSubsection,
  DocumentTemplate,
  DocumentTemplateSummary,
} from "../../../../types/DocumentTemplate";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ListItemIcon,
  ListItemText,
  MenuItem,
} from "@mui/material";
import { useMemo } from "react";
import { TroubleshootTwoTone } from "@mui/icons-material";

export default function DeleteConfirmForm(props: {
  isHeading: boolean;
  parentSection: DocumentSection;
  parentSubsection: DocumentSubsection;
  customMsg?:string,
  closeDialogHandler: any;
}) {

  const [isHeading, setIsHeading] = React.useState<boolean>();
  const [parentSection, setParentSection] = React.useState<DocumentSection|null>();
  const [parentSubsection, setParentSubsection] = React.useState<DocumentSubsection |null >();
  
  const msg = props.customMsg
  ? props.customMsg
  : `Are you sure you want to delete this library content ${
      isHeading ? "section" : "subsection"
    }? It may impact other documents.`;

  useEffect(()=>{
    if(props.parentSection!=null && props.parentSubsection!=null){
      setIsHeading(false);
      setParentSection(props.parentSection);
      setParentSubsection(props.parentSubsection)
    }else if(props.parentSection!=null && props.parentSubsection==null){
      setIsHeading(true);
      setParentSection(props.parentSection);
      setParentSubsection(props.parentSubsection)
    }

  },[props.isHeading , props.parentSection])
  

  const actions = useActions();
  const handleClose = () => {
    props.closeDialogHandler();
  };

  const handleDelete = () => {
    if (isHeading) {
      actions.deleteSection(parentSection as DocumentSection);
    } else {
      actions.deleteSubsection({
        section: parentSection as DocumentSection,
        subSection: parentSubsection as DocumentSubsection,
      });
    }
    props.closeDialogHandler();
  };
  const handleDetachFromCurrentDoc = () => {
    if (isHeading) {
      actions.deleteSectionAllDocuments(parentSection as DocumentSection);
    } else {
      actions.deleteSubsection({
        section: parentSection as DocumentSection,
        subSection: parentSubsection as DocumentSubsection,
       currentDocOnly:true
      });
    }
    props.closeDialogHandler();
  };
  return (
    <Dialog open={true} onClose={handleClose}>
      <DialogTitle sx={{ fontWeight: "bold" }}>
        Confirm Delete {isHeading ? "Section" : "Subsection"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography variant="body1" color="textPrimary">

            {msg}
           {/* Are you sure you want to delete this library content{" "}
            {isHeading ? "section?" : "subsection?"}
            {" It may cause issues in other documents."} */}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined">
          Cancel
        </Button>
        <Button
           onClick={handleDetachFromCurrentDoc}

          variant="contained"
          color="primary"
          autoFocus
        >
        {"delete from this document only"}
        </Button>
  <Button
          onClick={handleDelete}
          variant="contained"
          color="primary"
          autoFocus
        >
        {"delete for all"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
