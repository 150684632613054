/**
 * Renders a table component that displays transaction history details.
 * The table includes columns for user_id, organization_id, customer_id, plan_id, subscription_code, subscription_status, subscription_cycle, and created_at.
 * The table is sortable by subscription status, with active subscriptions displayed first.
 * The table supports pagination, with the ability to change the number of rows displayed per page.
 * The table is styled using Material-UI components and custom styles.
 *
 * @param {object[]} [transactionDetails] - An array of transaction details objects.
 * @returns {JSX.Element} - The rendered transaction history table.
 */
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: any) => ({
  tableHeader: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontSize: "8pt",
    textAlign: "center",
    padding: theme.spacing(1),
  },
  tableCell: {
    padding: theme.spacing(1),
    textAlign: "center",
    fontSize: "0.875em",
    position: "relative",
  },
  paper: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    borderRadius: "12px",
    boxShadow: "0 4px 10px rgba(0,0,0,0.1)",
  },
  tableRow: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  tableRowActive: {
    backgroundColor: "#e3f2fd",
    color: "#000000",
  },
  tableContainer: {
    maxHeight: 440,
    borderRadius: "12px",
  },
  tablePagination: {
    backgroundColor: theme.palette.background.paper,
  },
  hoverText: {
    visibility: "hidden",
    backgroundColor: "#000000 !important",
    color: "#fffff !important",
    textAlign: "center",
    borderRadius: "6px",
    padding: theme.spacing(1),
    position: "absolute",
    zIndex: 1,
    bottom: "100%",
    left: "50%",
    transform: "translateX(-50%)",
    marginBottom: "5px",
    fontSize: "0.75em",
    width: "180px",
    "&::after": {
      content: '""',
      position: "absolute",
      top: "100%",
      left: "50%",
      transform: "translateX(-50%)",
      borderWidth: "5px",
      borderStyle: "solid",
      borderColor: "#000 transparent transparent transparent",
    },
  },
  tableCellHover: {
    position: "relative",
    "&:hover $hoverText": {
      visibility: "visible",
    },
  },
}));

export default function TransactionHistory(props: {
  transactionDetails?: any[];
}) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getColumns = (): string[] => {
    return [
      "plan_name",
      "credits",
      "subscription_status",
      "subscription_cycle",
    ];
  };

  const { transactionDetails = [] } = props;

  // Sort transaction details by created at date


  const sortedTransactionDetails = [...transactionDetails];
  //sort by like this 



  const getHoverText = (status: string): string => {
    switch (status) {
      case "active":
        return "The subscription is currently active";
      case "not_renew":
        return "The subscription is set to non-renew and you will not be charged anymore and it will be disabled at the end of the billing period";
      case "payment_failed":
        return "Due to insufficient funds or card expiry, the payment has failed. Please try again";
      case "disable":
          return "The subscription is disabled and you will not be charged anymore.";
      case "pending":
          return "The transaction is abandoned  and the payment is pending.";
      default:
        return "";
    }

  };

  const columns = getColumns();
  return (
    <Box>
      <Typography
        variant="h6"
        sx={{
          textAlign: "center",
          mb: 2,
          fontWeight: "bold !important",
          color: "#3f51b5",
          paddingTop: "20px",
        }}
      >
        Transaction History
      </Typography>
      <Paper className={classes.paper}>
        <TableContainer className={classes.tableContainer}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column} className={classes.tableHeader}>
                    {column.replace("_", " ").toUpperCase()}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedTransactionDetails
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((transaction: any, index: any) => (
                  <TableRow
                    key={index}
                    className={`${classes.tableRow} ${
                      transaction.subscription_status === "active"
                        ? classes.tableRowActive
                        : ""
                    }`}
                  >
                    {columns.map((column) => (
                      <TableCell
                        key={column}
                        title = {column === "subscription_status" ? getHoverText(transaction[column]) : ""}
                        className={`${classes.tableCell} ${
                          transaction.subscription_status === "active"
                            ? classes.tableRowActive
                            : ""
                        } ${column === "subscription_status" ? classes.tableCellHover : ""}`}
                      >
                        { column === "subscription_cycle" || column === "subscription_status" ? (
                          transaction[column].toUpperCase()
                        ) : column === "created_at" ? (
                          new Date(transaction[column]).toLocaleString()
                        ) : (
                          transaction[column]?.toString()
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          className={classes.tablePagination}
          component="div"
          count={sortedTransactionDetails.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
